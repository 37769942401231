import { KitIcon, KitText } from '@omni/kit/components';
import KitInfiniteScroll from '@omni/kit/components/KitInfiniteScroll';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { convertToListItem } from '@omni/kit/feeds/ListItemConverter';
import { SearchResultItemProps } from '@omni/kit/feeds/searchListItemTypes';
import AccountsService from '@omni/kit/services/AccountsService';
import { IFeaturedApp, getFeaturedApps } from '@omni/kit/services/FeedsService';
import { ContainerAppMembership } from '@omni/kit/services/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { isAppKeyTCA } from '@omni/kit/utilities/utilities';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import FullPageLoader from '../../components/FullPageLoader';
import calculateTotalPages from '../../utils/calculateTotalPages';
import SearchResultItem from './SearchResultItem';

const debug = require('debug')('tca:search:screens:search:FeaturedApps.tsx');

const PAGE_SIZE = 15;

interface Props {
  appFilterValue: string;
  horizontalSpacing?: SpacingType;
}

export default ({ appFilterValue }: Props): JSX.Element => {
  const { colorForScheme } = useContext(ThemeContext);

  const { t } = useTranslation(['common', 'search']);

  const rootAppKey = useRootAppContext()?.id || '';
  const feedsServiceEnabled = isAppKeyTCA(rootAppKey);

  const [results, setResults] = useState<SearchResultItemProps[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);

  const mountedRef = useRef(true);

  // For now, only TCA supports obtaining featured apps from the feeds service
  // All other container apps will feature apps obtained from container app memberships.
  const fetchFeaturedApps = useCallback(
    async (page = 1) => {
      try {
        if (feedsServiceEnabled) {
          const data = await getFeaturedApps({ appKey: rootAppKey });

          const listItems: SearchResultItemProps[] =
            data.body?.map((item: IFeaturedApp) => {
              item.type = 'featured-app';

              return convertToListItem(item, item.type);
            }) || [];

          setHasNextPage(false);
          setCurrentPage(1);

          return listItems;
        } else if (rootAppKey !== undefined) {
          const data = await AccountsService.getContainerAppMemberships(
            rootAppKey,
            page,
            PAGE_SIZE
          );

          const nextPage = page + 1;
          const totalPages = calculateTotalPages(data.total, PAGE_SIZE);
          setHasNextPage(nextPage <= totalPages);
          setCurrentPage(nextPage);

          const listItems: SearchResultItemProps[] = data.memberships.map(
            (item: ContainerAppMembership) => {
              item.type = 'container-app-membership';

              return convertToListItem(item, item.type);
            }
          );

          return listItems;
        }
      } catch (e) {
        debug('search request failed', e);
      }
    },
    [feedsServiceEnabled, rootAppKey]
  );

  const onFetchData = () => fetchFeaturedApps(currentPage);

  const renderItem = ({
    item,
    index,
  }: {
    item: SearchResultItemProps;
    index: number;
  }) => {
    return (
      <SearchResultItem
        appFilterValue={appFilterValue}
        item={item}
        index={index}
      />
    );
  };

  const headerComponent = (): JSX.Element => {
    const isTCA = isAppKeyTCA(rootAppKey);

    if (!isTCA) {
      return <View style={{ flex: 1, paddingTop: Spacing.l }} />;
    }

    return (
      <View
        style={{
          paddingHorizontal: Spacing.xl,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <KitIcon
          size={36}
          color={Colors.N300}
          name='discover'
          style={{
            marginTop: Spacing.xxl,
            marginBottom: Spacing.l,
          }}
        />
        <KitText body1 bold black center>
          {t('search:discoverTitle', { context: 'church' })}
        </KitText>
        <KitText body1 center>
          {t('search:discoverSubtitle', { context: 'church' })}
        </KitText>
        <KitText
          semiBold
          color={
            colorForScheme?.({ light: Colors.N900, dark: Colors.N0 }) ||
            Colors.N900
          }
          style={{
            marginTop: Spacing.xxl,
            marginBottom: Spacing.l,
            alignSelf: 'flex-start',
          }}
        >
          {t('common:featured')}
        </KitText>
      </View>
    );
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    fetchFeaturedApps(1)
      .then((data) => mountedRef.current && setResults(data))
      .finally(() => mountedRef.current && setIsLoading(false));
  }, [fetchFeaturedApps]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <KitInfiniteScroll<SearchResultItemProps>
      headerComponent={headerComponent}
      onFetchData={onFetchData}
      renderItem={renderItem}
      hasMoreItems={hasNextPage}
      initialData={results}
    />
  );
};
