import {
  Keyboard,
  KeyboardEvent,
  KeyboardEventName,
  NativeEventSubscription,
  Platform,
} from 'react-native';

import { IKeyboardEvent, KeyboardListenerCallback } from './types';

const debug = require('debug')(
  'tca:search:components:KeyboardContext:keyboardListeners.ts'
);

enum EVENT_NAMES {
  KEYBOARD_DID_SHOW = 'keyboardDidShow',
  KEYBOARD_DID_HIDE = 'keyboardDidHide',
  KEYBOARD_WILL_SHOW = 'keyboardWillShow',
  KEYBOARD_WILL_HIDE = 'keyboardWillHide',
}

const callbacks: Record<string, KeyboardListenerCallback> = {
  keyboardDidShow: () => null,
  keyboardDidHide: () => null,
  keyboardWillShow: () => null,
  keyboardWillHide: () => null,
};

let removeKeyboardEventListeners: NativeEventSubscription[] = [];

/**
 * https://reactnative.dev/docs/keyboard
 *
 * Register to keyboard events
 *
 * @param callback
 */
export const registerKeyboardListeners = (
  callback: (e: IKeyboardEvent) => void
): void => {
  debug('registerKeyboardListeners');

  callbacks.keyboardDidShow = addKeyboardListener({
    eventName: EVENT_NAMES.KEYBOARD_DID_SHOW,
    show: true,
    callback,
  });

  callbacks.keyboardDidHide = addKeyboardListener({
    eventName: EVENT_NAMES.KEYBOARD_DID_HIDE,
    show: false,
    callback,
  });

  if (Platform.OS === 'android') return;
  /**
   * Android does not support below events natively
   * 1. 'keyboardWillShow'
   * 2. 'keyboardWillHide'
   */

  callbacks.keyboardWillShow = addKeyboardListener({
    eventName: EVENT_NAMES.KEYBOARD_WILL_SHOW,
    show: true,
    callback,
  });

  callbacks.keyboardWillHide = addKeyboardListener({
    eventName: EVENT_NAMES.KEYBOARD_WILL_HIDE,
    show: false,
    callback,
  });
};

/**
 * Remove all keyboard events
 */
export const unregisterKeyboardListeners = (): void => {
  debug('unregisterKeyboardListeners');

  removeKeyboardEventListeners.forEach((listener) => listener.remove());
  removeKeyboardEventListeners = [];
};

const addKeyboardListener = ({
  eventName,
  show,
  callback,
}: {
  eventName: KeyboardEventName;
  show: boolean;
  callback: (e: IKeyboardEvent) => void;
}): KeyboardListenerCallback => {
  const cb = (e: KeyboardEvent) => {
    callback({ ...e, eventName, show });
  };
  removeKeyboardEventListeners.push(Keyboard.addListener(eventName, cb));

  return cb;
};
