import { useShellContext } from '@omni/kit';
import { ENV } from '@omni/kit/Environment';
import {
  KitButton,
  KitListItem,
  KitLoader,
  KitText,
} from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitIcon, { IconName } from '@omni/kit/components/KitIcon';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import KitSnack, {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import NoUpcomingEvents from '@omni/kit/components/NoUpcomingEvents';
import {
  SizeClassV2,
  WindowSizeMin,
  useSizeClass,
} from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import {
  ImageServiceType,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import BlockUserModal from '@omni/messaging/mobile/scenes/channel/components/BlockUserModal';
import ReportUserModal from '@omni/messaging/mobile/scenes/channel/components/ReportUserModal';
import UserActionSheet from '@omni/messaging/mobile/scenes/channel/components/UserActionSheet';
import InviteScreen from '@omni/messaging/shared/scenes/channelDetails/InviteScreen';
import MemberListScreen from '@omni/messaging/shared/scenes/channelDetails/MemberListScreen';
import ActionConfirmModal from '@omni/messaging/shared/scenes/channelDetails/components/details/ActionConfirmModal';
import MoreActionModal from '@omni/messaging/shared/scenes/channelDetails/components/details/MoreActionModal';
import { useNavigation, useRoute } from '@react-navigation/native';
import { PushTriggerOption, ReportCategory, User } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import { useChannelGroup } from '../../../hooks/useChannelGroup';
import { ChannelMember } from '../../../mobile/Types';
import ChannelDetailHeader from '../../../mobile/components/channelDetails/ChannelDetailHeader';
import MemberActionSheet from '../../../mobile/components/channelDetails/MemberActionSheet';
import ActionSheetItem from '../../../mobile/components/chat/actionSheet/ActionSheetItem';
import ConfirmModal from '../../../mobile/components/web/ConfirmModal';
import {
  sbGetChannelMetadata,
  sbGetUsersById,
} from '../../../utilities/sendbird/channelFunctions';
import * as Constants from '../../Constants';
import {
  blockUser,
  changeManagerToUser,
  changeUserToManager,
  getBlockedUserList,
  getInvitedList,
  getMemberList,
  removeUser,
  unblockUser,
} from '../../redux/actions/ChatActions';
import {
  clearSelectedGroupChannel,
  deleteChannel,
  hideChannel,
  onLeaveChannelPress,
  setPushPreference,
} from '../../redux/actions/SystemActions';
import {
  appKeySelector,
  channelSelector,
  memberListSelector,
  ownerListSelector,
  pushPreferenceSelector,
  userIdSelector,
} from '../../redux/selectors';
import { ChannelType } from '../../redux/types';
import GroupEventListItems from './components/GroupEventListItems';
import { MemberSection } from './components/details';

const debug = require('debug')('tca:chat:screen:ChannelDetailsScreen');

export default function ChannelDetailsScreen(): JSX.Element {
  const { t } = useTranslation(['groups']);
  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user, [tokens.user]);

  const [isSelectedUserOwner, setIsSelectedUserOwner] = useState(false);
  const [selectedUser, setSelectedUser] = useState<ChannelMember | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [managersExpanded, setManagersExpanded] = useState<boolean>(false);
  const [membersExpanded, setMembersExpanded] = useState<boolean>(false);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  const [openInviteScreenModal, setOpenInviteScreenModal] =
    useState<boolean>(false);
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });
  const { sizeClassV2 } = useSizeClass();

  const isLarge = sizeClassV2 >= SizeClassV2.L;
  const { smallestWindowWidth, windowWidth } = useSizeClass();
  const isSmall =
    Platform.OS === 'web'
      ? windowWidth < WindowSizeMin.L
      : smallestWindowWidth < WindowSizeMin.L;

  const { windowHeight } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;

  const [memberListModal, setMemberListModal] = useState<boolean>(false);
  const [moreActionModal, setMoreActionModal] = useState<boolean>(false);
  const [userToReport, setUserToReport] = useState<ChannelMember | null>(null);
  const [userToBlock, setUserToBlock] = useState<ChannelMember | null>(null);
  const [channelToDelete, setChannelToDelete] = useState<boolean>(false);
  const [channelToLeave, setChannelToLeave] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState(false);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmActionButtonText, setConfirmActionButtonText] = useState('');
  const [confirmActionTitle, setConfirmActionTitle] = useState('');
  const [confirmActionMessage, setConfirmActionMessage] = useState('');
  const [confirmActionKey, setConfirmActionKey] = useState('');

  const ownerList = useSelector(ownerListSelector);
  const memberList = useSelector(memberListSelector);
  const myUserId = useSelector(userIdSelector);
  const isMe = myUserId === selectedUser?.userId;
  const { app } = useShellContext();
  const [groupEventList, setGroupEventList] = useState<
    IGroupEvent[] | undefined
  >();
  const [isLoadingGroupEventList, setIsLoadingGroupEventList] =
    useState<boolean>(false);
  const groupEventsEnabled = app.appKey === 'CHURCH' && ENV === 'dev';

  const sortedMemberList = memberList.sort((a, b) => {
    if (a.role === 'operator' && b.role !== 'operator') {
      return -1;
    }

    if (a.role !== 'operator' && b.role === 'operator') {
      return 1;
    }

    return a.nickname.localeCompare(b.nickname);
  });

  // dev note: this channel becomes undefined when you 'leave' a channel
  const channel = useSelector(channelSelector) as GroupChannel;
  const pushPreference = useSelector(pushPreferenceSelector);

  const appKey = useSelector(appKeySelector);

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const openMoreActionModal = () => {
    setMoreActionModal(true);
  };

  const closeMoreActionModal = () => {
    setMoreActionModal(false);
  };

  // Group Data
  const group = useChannelGroup(channel?.url);
  const isManager = channel?.myRole === 'operator';
  const allowInvites = isManager || group?.enrollment_type === 'open';

  //****************************************************************************
  // Methods
  //****************************************************************************

  const updateMemberList = () => {
    dispatch(getMemberList(channel?.url));
  };

  const updateBlockedUserList = () => {
    dispatch(getBlockedUserList());
  };

  const updateInvitedList = () => {
    dispatch(getInvitedList(channel?.url));
  };

  const updateIsRestricted = () => {
    const keys = ['restricted'];
    sbGetChannelMetadata(channel, keys).then((metadata: any) => {
      setIsRestricted(metadata.restricted === 'true');
    });
  };

  const parseChannelData = useCallback(() => {
    try {
      const data = JSON.parse(channel?.data);
      debug('channel data ', data);

      return data;
    } catch {
      debug('Could not parse channel data');
    }
  }, [channel]);

  const _onMemberEditPress = (member: ChannelMember) => {
    setSelectedUser(member);
    setIsSelectedUserOwner(false);
  };

  const _onOwnerEditPress = (member: ChannelMember) => {
    setSelectedUser(member);
    setIsSelectedUserOwner(true);
  };

  const _askLeaveChannel = () => {
    const title = `Leave ${channel?.name}`;
    const msg =
      'Are you sure you want to leave this group? You will no longer have access to the group or its messages.';
    _showConfirmAlert(title, msg, 'Leave', 'leave-channel');
  };

  const _askDeleteChannel = () => {
    const title = 'Delete group';
    const msg = `Are you sure you want to delete this group? Deleting "${channel?.name}" cannot be undone.`;
    _showConfirmAlert(title, msg, 'Delete', 'delete-channel');
  };

  const _askHideConversation = () => {
    const title = 'Confirm Hide Conversation';
    const msg =
      'Hiding this conversation removes it from your conversation list, but will not delete it for other users.';
    _showConfirmAlert(title, msg, 'Hide', 'hide-conversation');
  };

  const _confirmLeaveChannel = () => {
    dispatch(onLeaveChannelPress(channel));

    // goBack is needed for web and for native messaging on large screens
    navigation.goBack();

    if (Platform.OS !== 'web') {
      navigation.navigate(Constants.CHANNEL_LIST_SCREEN);
    }

    showKitSnack(`You left ${channel?.name}`, KitSnackDuration.LONG);
  };

  const _confirmDeleteChannel = () => {
    debug('Confirm Delete Channel');
    dispatch(deleteChannel(channel?.url));

    // goBack is needed for web and for native messaging on large screens
    navigation.goBack();

    if (Platform.OS !== 'web') {
      navigation.navigate(Constants.CHANNEL_LIST_SCREEN);
    }
  };

  const _confirmHideConversation = () => {
    debug('Confirm Hide Conversation');
    dispatch(hideChannel(channel));

    // goBack is needed for web and for native messaging on large screens
    navigation.goBack();

    if (Platform.OS !== 'web') {
      navigation.navigate(Constants.CHANNEL_LIST_SCREEN);
    }
  };

  const _confirmUnblockUser = (chan: GroupChannel, user: User) => {
    dispatch(unblockUser(chan, user));
    setSelectedUser(null);
  };

  const _confirmBlockUser = () => {
    if (userToBlock) {
      if (channel) {
        dispatch(blockUser(channel, userToBlock));
      }

      showKitSnack(
        t('memberBlocked', { member: userToBlock.nickname }),
        KitSnackDuration.SHORT
      );
      setUserToReport(null);
    }
  };

  const _confirmPromoteUser = (user: ChannelMember) => {
    dispatch(changeUserToManager(channel as GroupChannel, user));
    showKitSnack(
      `${selectedUser?.nickname} changed to Manager`,
      KitSnackDuration.SHORT
    );
    setSelectedUser(null);
  };

  const _confirmDemoteUser = (user: ChannelMember) => {
    dispatch(changeManagerToUser(channel as GroupChannel, user, appKey));
    showKitSnack(
      `${selectedUser?.nickname} changed to Member`,
      KitSnackDuration.SHORT
    );
    setSelectedUser(null);
  };

  const _confirmRemoveUser = (chan: GroupChannel, member: ChannelMember) => {
    dispatch(removeUser(chan, member));
    setSelectedUser(null);
  };

  const _navToMemberListScreen = (managers = false) => {
    navigation.navigate(Constants.MEMBER_LIST_SCREEN, {
      title: managers ? t('managers') : t('members'),
      showManagers: managers,
      allowInvites,
    });
  };

  const _navToGroupEventListScreen = () => {
    navigation.navigate(Constants.GROUP_EVENTS_LIST_SCREEN, {
      title: t('groups:groupEventsList'),
      groupEventList: groupEventList,
    });
  };

  //The route param to pass to MemberListScreen if the Platform.OS ==='web'
  const webRoute = (managers = false) => {
    return {
      params: {
        title: managers ? t('managers') : t('members'),
        showManagers: managers,
        allowInvites,
      },
    };
  };

  //The route param to pass to InviteScreen if the Platform.OS ==='web'
  const inviteRoute = {
    params: {
      channel: channel,
    },
  };

  const fetchGroupEventList = useCallback(() => {
    const _fetchGroupEventList = async () => {
      // This will be added when backend will be ready
      // async (id?: string) => {
      try {
        setIsLoadingGroupEventList(true);
        const response = await GroupsService.GetGroupEventList({
          // This will be added when backend will be ready
          // id,
          // token: accessToken,
        });

        if (response.body) {
          setGroupEventList(response.body._embedded?.events);
        } else {
          debug('Could not fetch group events');
        }
      } catch {
      } finally {
        setIsLoadingGroupEventList(false);
      }
    };
    _fetchGroupEventList();
    // This will be added when backend will be ready

    // group?._embedded?.event?.id
    //   ? _fetchGroupEventList(group._embedded.event.id)
    //   : setGroupEventList(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, navigation]);

  // Hooks
  useEffect(() => {
    fetchGroupEventList();
  }, [fetchGroupEventList]);

  const _renderChannelDetails = () => {
    return (
      <View
        style={{
          flex: 1,
          maxWidth: '100%',
          marginTop: Platform.OS !== 'web' && isLandscape ? 14 : 0,
          borderTopWidth: Platform.OS !== 'web' && isLandscape ? 1 : 0,
          borderTopColor:
            Platform.OS !== 'web' && isLandscape ? Colors.N100 : Colors.N0,
        }}
      >
        <View
          style={{
            paddingHorizontal: isLarge ? Spacing.xxxxl + 8 : Spacing.l,
            paddingTop: Spacing.m,
            paddingBottom: Spacing.xxl,
          }}
        >
          <ChannelDetailHeader
            coverUrl={channel?.coverUrl}
            name={channel?.name}
            description={channelData?.description}
            groupType={group?._embedded?.type?.name}
          />
        </View>

        <TouchableWithoutFeedback
          onPress={
            Platform.OS === 'web'
              ? () => setMemberListModal(true)
              : () => _navToMemberListScreen(false)
          }
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: isLarge ? Spacing.xxxxl + 8 : Spacing.l,
              // @ts-ignore
              cursor: 'pointer',
            }}
          >
            <KitText fontSize={20} color={Colors.N900} bold>
              {memberList.length} members
            </KitText>
            <KitIcon
              size={20}
              name='arrow-right'
              color={Colors.N300}
              style={{ marginLeft: Spacing.s }}
            />
          </View>
        </TouchableWithoutFeedback>

        {/* Open the MemberListScreen and InviteScreen in modal for the Platform.OS==='web' */}
        <KitModalV2
          modalStyle={{
            width: '100%',
            maxWidth: 620,
            height: '100%',
            maxHeight: 700,
            paddingBottom: 0,
          }}
          coverScreen={true}
          anchorBottom={isSmall}
          isVisible={memberListModal}
          onClose={() => setMemberListModal(false)}
          enableScroll
          noScrollView
        >
          {openInviteScreenModal ? (
            <InviteScreen
              route={inviteRoute}
              navigation={navigation}
              openInviteScreenModal={openInviteScreenModal}
              setOpenInviteScreenModal={setOpenInviteScreenModal}
            />
          ) : (
            <MemberListScreen
              navigation={navigation}
              route={webRoute(false)}
              isOpenMemberListModal={memberListModal}
              setMemberListModal={setMemberListModal}
              setOpenInviteScreenModal={setOpenInviteScreenModal}
            />
          )}
        </KitModalV2>

        <View
          style={{
            position: 'relative',
            paddingHorizontal: isLarge ? Spacing.xxxxl - 10 : 0,
          }}
        >
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {sortedMemberList.map((member, i) => (
              <View
                key={i}
                style={{
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginLeft: Spacing.l,
                  marginTop: Spacing.l,
                  marginRight:
                    i === sortedMemberList.length - 1 ? Spacing.l : 0,
                }}
              >
                <Pressable
                  onPress={() => {
                    setSelectedUser(member);
                  }}
                  style={{
                    flexDirection: 'column',
                    width: 54,
                  }}
                >
                  <KitAvatar
                    nickname={member.nickname}
                    size={Spacing.xxxl + 6}
                    imageUrl={
                      member.profileUrl
                        ? member.profileUrl
                        : member._embedded?.['chat-user']?.profile_url
                    }
                  />
                  <KitText
                    fontSize={12}
                    color={Colors.N500}
                    style={{
                      textAlign: 'center',
                      lineHeight: 16,
                      marginTop: Spacing.s,
                    }}
                    numberOfLines={2}
                  >
                    {member.nickname}
                  </KitText>
                </Pressable>
              </View>
            ))}
          </ScrollView>
          {isLarge && (
            <LinearGradient
              colors={['#ffffff00', 'white']}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              style={{
                position: 'absolute',
                right: 62,
                top: 0,
                bottom: 0,
                width: 30,
              }}
            />
          )}
          {isLarge && (
            <LinearGradient
              colors={['white', '#ffffff00']}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              style={{
                position: 'absolute',
                left: 62,
                top: 0,
                bottom: 0,
                width: 30,
              }}
            />
          )}
        </View>

        {/* Event List for group members in group details */}
        {groupEventsEnabled ? (
          <>
            <TouchableWithoutFeedback onPress={_navToGroupEventListScreen}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginHorizontal: isLarge ? Spacing.xxxxl + 8 : Spacing.l,
                  marginTop: 21,
                  // @ts-ignore
                  cursor: 'pointer',
                  borderTopColor: Colors.N100,
                  borderTopWidth: 1,
                }}
              >
                <KitText
                  fontSize={20}
                  color={Colors.N900}
                  bold
                  style={{ marginTop: Spacing.xl }}
                >
                  {t('groups:calendar')}
                </KitText>
                <KitIcon
                  size={20}
                  name='arrow-right'
                  color={Colors.N300}
                  style={{ marginTop: Spacing.xl, marginLeft: Spacing.s }}
                />
              </View>
            </TouchableWithoutFeedback>

            <View
              style={{
                paddingHorizontal: isLarge ? Spacing.xxxxl + 8 : Spacing.l,
              }}
            >
              {groupEventList ? (
                <GroupEventListItems
                  accessToken={accessToken}
                  events={groupEventList}
                  openListEventModal={_navToGroupEventListScreen}
                />
              ) : (
                <NoUpcomingEvents />
              )}
            </View>
          </>
        ) : null}
      </View>
    );
  };

  const _renderDirectDetails = () => {
    return (
      <View
        style={[
          isLarge && { marginHorizontal: 'auto', minWidth: 800, maxWidth: 800 },
          styles.viewContainer,
        ]}
      >
        <MemberSection
          isRestricted={true}
          isDirect={true}
          isOwner={false}
          onMemberEditPress={_onMemberEditPress}
          onOwnerEditPress={_onOwnerEditPress}
          renderMemberWebActionSheet={_renderWebActionSheet(false)}
          renderOwnerWebActionSheet={_renderWebActionSheet(true)}
        />
        <View style={{ marginTop: Spacing.l }}>
          <KitButton
            secondary
            title='Hide conversation'
            onPress={_askHideConversation}
          />
        </View>
        <View style={{ height: Spacing.xxl }} />
      </View>
    );
  };

  const _showConfirmAlert = (
    title: string,
    message: string,
    confirmButtonText: string,
    actionKey: string,
    confirmStyle:
      | 'cancel'
      | 'default'
      | 'destructive'
      | undefined = 'destructive'
  ) => {
    if (Platform.OS === 'web') {
      setConfirmActionTitle(title);
      setConfirmActionMessage(message);
      setConfirmActionButtonText(confirmButtonText);
      setConfirmActionKey(actionKey);
      setConfirmModalVisible(true);

      return;
    }

    Alert.alert(title, message, [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: confirmButtonText,
        onPress: () => _onConfirm(actionKey),
        style: confirmStyle,
      },
    ]);
  };

  const _renderWebActionSheet =
    (isOwner: boolean) => (member: ChannelMember, closeMenu: () => void) => {
      const _askUnblockUser = () => {
        setSelectedUser(member);
        const title = `Unblock ${member.nickname}`;
        const msg = `You will be able to receive new messages from ${
          member.nickname?.split(' ')?.[0]
        }, but not anything that may have been sent during the block.`;
        _showConfirmAlert(title, msg, 'Unblock', 'unblock-user');
        closeMenu();
      };

      const _askBlockUser = () => {
        setSelectedUser(member);
        const title = `Block ${member.nickname}`;
        const msg =
          'They will not be able to send or receive messages from you.';
        _showConfirmAlert(title, msg, 'Block', 'block-user');
        closeMenu();
      };

      const _askPromoteUser = () => {
        setSelectedUser(member);
        const title = `Change ${member.nickname}'s role to Manager`;
        const message =
          'They will have the ability to edit the group, delete messages, and remove Members.';
        _showConfirmAlert(title, message, 'Change', 'promote-user');
        closeMenu();
      };

      const _askDemoteUser = () => {
        setSelectedUser(member);
        const title = `Change ${member.nickname}'s role to Member`;
        const message =
          'They will lose the ability to edit the group, delete messages, and remove Members.';
        _showConfirmAlert(title, message, 'Change', 'demote-user');
        closeMenu();
      };

      const _askRemoveUser = () => {
        setSelectedUser(member);
        const title = `Remove ${member.nickname}`;
        const msg =
          'Are you sure you want to remove this member? They will no longer have access to the group or its messages.';
        _showConfirmAlert(title, msg, 'Remove', 'remove-user');
        closeMenu();
      };

      const enableBlockUserActions = channel?.customType === ChannelType.Direct;
      const isSelectedUserBlocked = member?.isBlockedByMe ?? false;
      const enableChannelActions = channel?.customType !== ChannelType.Direct;

      return (
        <>
          {enableBlockUserActions &&
            (isSelectedUserBlocked ? (
              <ActionSheetItem
                icon={'' as IconName}
                label='Unblock User'
                onPress={_askUnblockUser}
              />
            ) : (
              <ActionSheetItem
                icon='block'
                label='Block User'
                onPress={_askBlockUser}
              />
            ))}

          {enableChannelActions && (
            <ActionSheetItem
              icon='remove'
              label='Remove from group'
              onPress={_askRemoveUser}
            />
          )}

          {enableChannelActions &&
            (isOwner ? (
              <ActionSheetItem
                icon='person-circle'
                label='Change to Member'
                onPress={_askDemoteUser}
              />
            ) : (
              <ActionSheetItem
                icon='person-circle'
                label='Change role to Manager'
                onPress={_askPromoteUser}
              />
            ))}
        </>
      );
    };

  const _onConfirm = (action: string) => {
    setConfirmModalVisible(false);
    switch (action) {
      case 'leave-channel':
        _confirmLeaveChannel();
        break;

      case 'delete-channel':
        _confirmDeleteChannel();
        break;

      case 'hide-conversation':
        _confirmHideConversation();
        break;

      case 'unblock-user':
        // @ts-ignore (this will work with ChannelMember type as well)
        selectedUser && _confirmUnblockUser(channel, selectedUser);
        break;

      case 'block-user':
        // @ts-ignore (this will work with ChannelMember type as well)
        selectedUser && _confirmBlockUser(channel, selectedUser);
        break;

      case 'promote-user':
        selectedUser && _confirmPromoteUser(selectedUser);
        break;

      case 'demote-user':
        selectedUser && _confirmDemoteUser(selectedUser);
        break;

      case 'remove-user':
        selectedUser && _confirmRemoveUser(channel, selectedUser);
        break;
    }
  };

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  useEffect(() => {
    if (channel) {
      dispatch(clearSelectedGroupChannel());
      updateMemberList();
      updateBlockedUserList();
      updateInvitedList();
      updateIsRestricted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const channelData = parseChannelData();

  const _hideUserActionSheet = () => {
    setSelectedUser(null);
  };

  const _onReportUser = () => {
    if (selectedUser) {
      setUserToReport(selectedUser);
      setSelectedUser(null);
    }
  };

  const _onBlockUser = () => {
    if (selectedUser) {
      setUserToBlock(selectedUser);
      setSelectedUser(null);
    }
  };

  const _confirmReportUser = async (
    category: ReportCategory,
    description: string
  ) => {
    if (userToReport?._embedded?.['chat-user']?.id) {
      await sbGetUsersById([userToReport?._embedded?.['chat-user']?.id])
        .then((user) => {
          channel
            ?.reportUser(user[0], category, description)
            .then(() => {
              showKitSnack(
                t('memberReported', { member: userToReport.nickname }),
                KitSnackDuration.SHORT
              );

              setUserToReport(null);
            })
            .catch((error) => {
              debug('Could not report user: ', error);
              setUserToReport(null);
              showKitSnack(t('reportPersonError'), KitSnackDuration.SHORT);
            });
        })
        .catch((e) => {
          debug('Could not get users by ID: ', e);
        });
    }
  };

  const _onLeaveGroup = () => {
    setMoreActionModal(false);
    setTimeout(() => {
      setChannelToLeave(true);
    }, 500);
  };
  const _onDeleteGroup = () => {
    setMoreActionModal(false);
    setTimeout(() => {
      setChannelToDelete(true);
    }, 500);
  };

  const _onMuteGroup = () => {
    setMoreActionModal(false);
    const preference = isMuted
      ? PushTriggerOption.ALL
      : PushTriggerOption.MENTION_ONLY;
    setIsMuted(!isMuted);
    dispatch(setPushPreference(preference, (channel as GroupChannel).url));
    KitSnack.show(
      isMuted ? t('groupUnmuted') : t('groupMuted'),
      KitSnackDuration.SHORT
    );
  };

  useEffect(() => {
    navigation.setParams({ openMoreActionModal });
    navigation.setParams({ isManager: isManager });
    navigation.setParams({ isMuted: isMuted });
    navigation.setParams({
      canLeave: channel?.myRole !== 'operator' || ownerList.length > 1,
    });
    navigation.setParams({ onLeaveGroup: _onLeaveGroup });
    navigation.setParams({ onDeleteGroup: _onDeleteGroup });
    navigation.setParams({ onMuteGroup: _onMuteGroup });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation, isManager, isMuted, channel?.myRole]);

  return (
    <>
      <View style={styles.safeContainer}>
        {isLoadingGroupEventList ? (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <KitLoader />
          </View>
        ) : (
          <ScrollView>
            {channel?.customType !== ChannelType.Direct &&
              _renderChannelDetails()}
            {channel?.customType === ChannelType.Direct &&
              _renderDirectDetails()}
          </ScrollView>
        )}

        <MemberActionSheet
          visible={modalVisible}
          setVisible={setModalVisible}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          isSelectedUserOwner={isSelectedUserOwner}
          isSelectedUserBlocked={selectedUser?.isBlockedByMe ?? false}
          enableChannelActions={channel?.customType !== ChannelType.Direct}
          enableBlockUserActions={channel?.customType === ChannelType.Direct}
          showKitSnack={showKitSnack}
        />
      </View>

      <Modal
        isVisible={confirmModalVisible}
        animationIn='fadeIn'
        animationOut='fadeOut'
      >
        <ConfirmModal
          title={confirmActionTitle}
          message={confirmActionMessage}
          cancelButtonText='Cancel'
          confirmButtonText={confirmActionButtonText}
          setConfirmModalVisible={setConfirmModalVisible}
          onConfirm={_onConfirm}
          action={confirmActionKey}
        />
      </Modal>

      {!isMe && (
        <UserActionSheet
          isVisible={selectedUser !== null}
          // @ts-ignore
          user={selectedUser}
          onClose={_hideUserActionSheet}
          onReportUser={_onReportUser}
          onBlockUser={_onBlockUser}
          showMessageButton={true}
          isChannel={true}
        />
      )}
      <ReportUserModal
        isVisible={userToReport !== null}
        // @ts-ignore
        userToReport={userToReport}
        onClose={() => setUserToReport(null)}
        confirmReportUser={_confirmReportUser}
      />
      <BlockUserModal
        isVisible={userToBlock !== null}
        // @ts-ignore
        userToBlock={userToBlock}
        onClose={() => setUserToBlock(null)}
        confirmBlockUser={_confirmBlockUser}
      />

      {Platform.OS !== 'web' && (
        <>
          {/* More Action Modal */}
          <MoreActionModal
            isVisible={moreActionModal}
            isManager={isManager}
            canLeave={channel?.myRole !== 'operator' || ownerList.length > 1}
            isMuted={isMuted}
            onClose={closeMoreActionModal}
            onLeaveGroup={_onLeaveGroup}
            onDeleteGroup={_onDeleteGroup}
            onMuteGroup={_onMuteGroup}
          />
        </>
      )}

      <ActionConfirmModal
        isVisible={channelToDelete}
        onClose={() => setChannelToDelete(false)}
        confirmAction={_confirmDeleteChannel}
        title={t('labelDeleteGroup')}
        subtitle={t('deleteGroupConfirmation')}
        actionType='delete'
      />
      <ActionConfirmModal
        isVisible={channelToLeave}
        onClose={() => setChannelToLeave(false)}
        confirmAction={_confirmLeaveChannel}
        title={t('labelLeaveGroup')}
        subtitle={t('leaveGroupConfirmation')}
        actionType='leave'
      />

      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    paddingHorizontal: Spacing.l,
  },
  safeContainer: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
  image: {
    borderRadius: 18,
    height: 36,
    width: 36,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colorForScheme({ default: Colors.brand }),
  },
  banner: {
    backgroundColor: Colors.N50,
    marginHorizontal: Spacing.l,
    borderRadius: 3,
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 25,
      },
    }),
    marginBottom: Spacing.l,
  },
});
