import AccountsService from '../../services/AccountsService';
import FetchAccessToken from './FetchAccessToken';
import FetchUserProfile from './FetchUserProfile';
import TrackMediaProgressState from './TrackMediaProgressState';
import UpdateServerWithAllSavedProgress from './UpdateServerWithAllSavedProgress';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:UploadAllLocalProgress'
);

/**
 * Determine whether to upload all local progress upon app launch
 *
 * Do not attempt to FetchAccessToken for the container app (GH936H) instance.
 * Attempting to `FetchAccessToken` with `GH936H` app key will cache a sapToken
 * that cannot be used to obtain a fresh accessToken on any child apps
 * otherwise a 400 error will occur on the request to refresh the token for a child app.
 *
 * e.g.
 * On first launch of a container app, the Tour/Search UX is visible because no app key
 * has been selected. So the default active app key will be the container app key
 * which is the same as the root app key (GH936H).
 *
 * TLDR:
 * - Do not cache a sapToken on the container app (GH936H) instance
 * - Uploading saved local progress will not be possible without a valid accessToken
 * therefore we skip this step for the container app
 */
export default async (appKey: string): Promise<void> => {
  debug('Upload all local progress upon app launch', appKey);

  try {
    // Since we cannot use ApplicationContext imperatively
    // use the same options as useAppData to increase chance of cache-hit
    const app = await AccountsService.getApp(appKey, {
      includeAppStoreInfo: true,
      includeBranding: true,
      includeFeatures: true,
      includeContainerAppInfo: true,
    });

    if (app?.is_container) {
      debug('App key is a container app. Skip uploading local progress');

      return;
    }
  } catch (e) {
    debug('Failed to fetch app data', e);

    return;
  }

  try {
    await FetchAccessToken(appKey);
    TrackMediaProgressState.userProfile = await FetchUserProfile(appKey);
    await UpdateServerWithAllSavedProgress(
      TrackMediaProgressState.userProfile?.userID
    );
  } catch (e) {
    debug('Failed to update all progress upon app launch', e);
  }
};
