import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import KitImage from '@omni/kit/components/KitImage';
import KitLoader from '@omni/kit/components/KitLoader';
import KitMediaDurationIndicator from '@omni/kit/components/KitMediaDurationIndicator';
import KitProgress from '@omni/kit/components/KitProgress';
import KitText from '@omni/kit/components/KitText';
import Show from '@omni/kit/components/Show';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { useScreenVisibility } from '@omni/kit/contexts/ScreenVisibilityContext';
import { EActionHandlers } from '@omni/kit/feeds/ActionHandlerTypes';
import useMediaStatus from '@omni/kit/hooks/useMediaStatus';
import EventService from '@omni/kit/services/EventService';
import {
  EVENT_SERVICE_EVENTS,
  IListenerCallbackData,
} from '@omni/kit/services/EventService/types';
import { IMediaPlays } from '@omni/kit/services/MediaPersonalizationService/MediaPlayService';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import {
  ImageServiceType,
  parseImageUrl,
  useCoalescedSize,
  usePrevious,
} from '@omni/kit/utilities/utilities';
import Color from 'color';
import moment from 'moment';
import {
  default as React,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  NativeModules,
  PixelRatio,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import LiveCountdown from '../../components/MediaBanner/LiveCountdown';
import LiveTag from '../../components/MediaBanner/LiveTag';
import PlayAndListenButtons from '../../components/MediaBanner/PlayAndListenButtons';
import { renderBlock } from '../resolver';
import GetLatestMediaPlay from './GetLatestMediaPlay';
import { ARTWORK_BORDER_RADIUS } from './constants';
import { IMediaBannerBlockProps } from './types';

const debug = require('debug')('tca:blocks:MediaBannerBlock');
let abortController: AbortController | null;

export default function MediaBannerBlock({
  blocks,
  insetStyle = {},
  horizontalSpacing,
  playlistUrl = '',
  posterAspectRatio = 1.75, // default aspect ratio, used on poster for media detail
  posterImageUrl = '',
  bannerBackgroundUrl = '',
  averageColor = '#FFFFFF',
  vibrantColor = '#FFFFFF',
  appKey = '',
  authProviderId,
  mediaItemId = '',
}: IMediaBannerBlockProps): JSX.Element {
  const { tokens, user } = useShellContext();
  const isAuthenticated = Boolean(tokens?.user);
  const { colorForScheme } = useContext(ThemeContext);

  const userID = useMemo(() => user?.id, [user?.id]);

  const isScreenVisible = useScreenVisibility();
  const userToken = useMemo(() => tokens.user, [tokens.user]);

  const {
    playlistData,
    broadcastData,
    _beginPolling,
    broadcastStatus,
    updateBroadcastStarted,
    broadcastStarted,
    hasAudio,
    hasVideo,
    error,
  } = useMediaStatus({
    playlistUrl,
    enablePolling: true,
    authProviderId,
  });

  const shouldFetchMediaPlay =
    !broadcastData || broadcastStatus === 'on-demand';
  const prevBroadcastStatus = usePrevious(broadcastStatus);
  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const [mediaPlay, setMediaPlay] = useState<IMediaPlays | undefined>();
  const [mediaPlayIsLoading, setMediaPlayIsLoading] = useState<boolean>(
    shouldFetchMediaPlay ? Boolean(mediaItemId && isAuthenticated) : false
  );

  useEffect(() => {
    if (mediaItemId && userToken) {
      debug('AuthorizationChanged');
      getMediaPlay({
        mediaItemId,
        userId: userID,
        appKey,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  const hasMedia = hasAudio || hasVideo;
  const mediaProgressPercentage = mediaPlay
    ? Math.round((mediaPlay.progress_ms / mediaPlay.duration_ms) * 100)
    : 0;

  const renderError = (): JSX.Element => {
    return (
      <View style={styles.errorContainer}>
        <KitText
          white
          semiBold
          center
          style={{ lineHeight: 44, width: mediaWidth / 1.5 }}
        >
          {error}
        </KitText>
      </View>
    );
  };

  const _renderPlaylistButtons = (): JSX.Element | null | undefined => {
    if (error) renderError();

    if (
      (!broadcastData && !playlistData && playlistUrl) ||
      mediaPlayIsLoading
    ) {
      const background = averageColor ? Color(averageColor) : Color('#ffffff');
      const loaderColor = { color: background.grayscale().negate().toString() };

      return <KitLoader large delay={500} {...loaderColor} />;
    }

    if (
      (!broadcastData ||
        broadcastStatus === 'live' ||
        broadcastStatus === 'on-demand') &&
      playlistData
    ) {
      if (broadcastData && !broadcastStarted) return null;

      if (!hasVideo && !hasAudio) return null;

      return (
        <PlayAndListenButtons
          onPlay={onPlay}
          onResume={onResume}
          progress={mediaPlay ? mediaPlay.progress_ms : 0}
          duration={mediaPlay ? mediaPlay.duration_ms : 0}
        />
      );
    }

    if (!playlistUrl) return null; // Empty media item, just show banner

    if (!['ended', 'never-happened'].includes(broadcastStatus)) return;

    return (
      <View
        style={[
          styles.liveEndedContainer,
          {
            width: mediaWidth,
            height: mediaHeight,
          },
        ]}
      >
        <View style={styles.liveEndedTextContainer}>
          <KitText
            white
            extraBold
            fontSize={24}
            center
            style={{ lineHeight: 35, width: mediaWidth / 1.3 }}
          >
            This live stream has ended.
          </KitText>
        </View>
      </View>
    );
  };

  const onResume = async () => {
    let positionMsec = 0;

    if (mediaPlay) {
      positionMsec = mediaPlay.progress_ms;
    }

    dispatch({
      handler: 'video',
      url: playlistUrl,
      positionMsec: positionMsec,
      authProviderId: authProviderId,
    });
  };

  const onPlay = async () => {
    const isLive =
      broadcastStatus === 'scheduled' || broadcastStatus === 'live';
    const isChatEnabled = broadcastData?.chat?.enabled;

    // launch live chat app or native media player
    dispatch({
      handler:
        isLive && isChatEnabled
          ? EActionHandlers.NativeShell
          : EActionHandlers.Video,
      presentationStyle: 'modal',
      url: playlistUrl,
      positionMsec: 0,
      authProviderId: authProviderId,
      moduleCommand: {
        route: `/live/${mediaItemId}`,
      },
    });
  };

  const onListen = (): void => {
    // Launch player with audio
    const playAction = {
      handler: 'audio',
      url: playlistUrl,
      positionMsec: 0,
      authProviderId: authProviderId,
    };
    dispatch(playAction);
  };

  const hasBroadcastStarted = (): boolean => {
    if (!broadcastData) return false;

    const now = moment.utc();
    const startAt = moment.utc(broadcastData?.start_at);

    return now.diff(startAt, 's') >= 0;
  };

  /**
   * isBroadcastScheduled is true if
   * 1. broadcast status is scheduled
   * OR
   * 2. current time stamp has not reached the scheduled start at time
   *
   * @Note
   * 1. Need to calculate the `hasBroadcastStarted` at run time to ensure
   * accuracy. `broadcastStart` is too slow on component mount.
   *
   */
  const isBroadcastScheduled = (): boolean => {
    if (!broadcastData) return false;

    return broadcastStatus === 'scheduled' || !hasBroadcastStarted();
  };

  const autoPlayMedia = (): void => {
    debug('Autoplay Media');

    if (hasVideo) onPlay();
    else if (hasAudio) onListen();
  };

  const playMediaAndPoll = (): void => {
    debug('playMediaAndPoll');
    updateBroadcastStarted();
    autoPlayMedia();
    _beginPolling();
  };

  /**
   * ARTEMIS-1237: Convert paddingBottom -> marginBottom for MediaBanner.
   * Media Banner artwork is designed to render partially beneath the
   * backgroundContainer regardless of the number of blocks in a page.
   */
  const viewStyle = {
    ...insetStyle,
    paddingBottom: 0,
    marginBottom: insetStyle.paddingBottom,
  };

  const vibrantOverlay = Color(
    vibrantColor
      ? vibrantColor
      : colorForScheme?.({ light: Colors.N100, dark: Colors.N500 })
  )
    .alpha(0.8)
    .toString();
  const darkenOverlay = colorForScheme?.({
    light: Color(Colors.N1000).alpha(0.15).toString(),
    dark: Color(Colors.N1000).alpha(0.5).toString(),
  });

  const mediaWidth = viewPortWidth - edgeSpacing * 2;
  const mediaHeight = mediaWidth / posterAspectRatio;

  const pixelRatio = PixelRatio.get();
  const width = Math.round(Math.round(mediaWidth) * pixelRatio);
  const height = Math.round(Math.round(mediaHeight) * pixelRatio);
  const {
    coalescedWidth: coalescedBannerWidth,
    coalescedHeight: coalescedBannerHeight,
  } = useCoalescedSize(width, height);

  const posterImage = parseImageUrl(
    posterImageUrl,
    coalescedBannerWidth,
    coalescedBannerHeight,
    ImageServiceType.ImageJpeg
  );

  const backgroundImage = parseImageUrl(
    bannerBackgroundUrl,
    25,
    25,
    ImageServiceType.ImageJpeg
  );

  const getMediaPlay = useCallback(
    async ({
      userId,
      mediaItemId,
      appKey,
    }: {
      userId?: string;
      mediaItemId: string;
      appKey: string;
    }) => {
      if (!userId) {
        return;
      }

      debug('GetMediaPlay');
      setMediaPlayIsLoading(true);

      if (abortController) {
        abortController.abort();
        abortController = null;
      }

      abortController = new AbortController();

      const mediaPlayResource = await GetLatestMediaPlay({
        userId,
        mediaItemId,
        appKey,
        signal: abortController.signal,
      });

      if (mediaPlayResource) {
        setMediaPlay(mediaPlayResource);
      }

      setMediaPlayIsLoading(false);
    },
    []
  );

  useEffect(() => {
    const removeListener = EventService.addListener(
      EVENT_SERVICE_EVENTS.UPDATED_MEDIA_PLAY_RESOURCE,
      (props?: IListenerCallbackData) => {
        const { data, source } = props || {};

        if (isScreenVisible) {
          debug(
            `Media play resource is updated from else where. Refresh media play resource, source = ${source}, data = ${data}`
          );

          /**
           * Race condition:
           * Cancel getLatestMediaPlay get request to avoid overwriting latest progress dispatched
           * from TrackMediaProgress:updateMediaPlay
           */
          try {
            abortController?.abort();
            debug('Abort getLatestMediaPlay success');
          } catch (e) {
            debug('Failed to abort getLatestMediaPlay', e);
          }
          if (data && data._embedded['media-item'].id === mediaItemId) {
            setMediaPlay(data);
          }
        }
      }
    );

    return () => {
      removeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenVisible, setMediaPlay]);

  // The app will show a Google Cast button in the top bar of any media screen
  useEffect(() => {
    if (hasMedia) {
      try {
        NativeModules.ReactPlatformBridge.setCurrentScreenHasMedia(true);
      } catch {}
    }
  }, [hasMedia]);

  useEffect(() => {
    if (mediaItemId && userID && isScreenVisible && shouldFetchMediaPlay) {
      getMediaPlay({
        mediaItemId,
        userId: userID,
        appKey,
      });
    }
  }, [
    mediaItemId,
    userID,
    appKey,
    getMediaPlay,
    shouldFetchMediaPlay,
    isScreenVisible,
  ]);

  /**
   * If the current time has passed the scheduled start time but
   * the live stream has not started, this will wait and play
   * the broadcast as soon as the stream goes live
   */
  useEffect((): void => {
    if (
      broadcastStarted &&
      prevBroadcastStatus === 'scheduled' &&
      broadcastStatus === 'live'
    ) {
      autoPlayMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastStatus, broadcastStarted]);

  return (
    <View style={viewStyle}>
      <View style={styles.backgroundContainer}>
        <KitImage
          fallback={Platform.OS === 'android'} // DISP-3915: workaround missing images issue on certain Android devices
          source={{ uri: backgroundImage }}
          style={styles.backgroundImage}
          resizeMode='cover'
        />
        <View
          style={[
            styles.backgroundOverlay,
            { backgroundColor: vibrantOverlay },
          ]}
        />
        <View
          style={[styles.backgroundOverlay, { backgroundColor: darkenOverlay }]}
        />
      </View>
      <View style={styles.headerContainer}>
        <View
          style={[
            styles.artworkContainer,
            {
              backgroundColor: averageColor
                ? averageColor
                : colorForScheme?.({ light: Colors.N100, dark: Colors.N900 }),
              width: mediaWidth,
              height: mediaHeight,
              borderRadius: ARTWORK_BORDER_RADIUS,
            },
            Platform.select({
              android: {
                elevation: 10,
              },
              default: {
                shadowColor: '#000000',
                shadowOpacity: 0.15,
                shadowOffset: { width: 0, height: 12 },
                shadowRadius: 20,
              },
            }),
            {
              opacity: 0.99, // Workaround for: https://github.com/facebook/react-native/issues/23090
            },
          ]}
        >
          <KitImage
            source={{
              uri: posterImage,
            }}
            resizeMode='cover'
            style={[
              styles.bannerImage,
              {
                width: mediaWidth,
                height: mediaHeight,
              },
            ]}
          />
          {broadcastStatus === 'live' && broadcastStarted && <LiveTag />}
          <Show show={!blocks}>
            <View>{_renderPlaylistButtons()}</View>
          </Show>
          <Show show={Boolean(blocks)}>
            <View>
              {blocks &&
                blocks.map((button, index) =>
                  renderBlock(button, {
                    key: `block-${index}`,
                    ...button.props,
                    index,
                  })
                )}
            </View>
          </Show>
          {isBroadcastScheduled() && (
            <LiveCountdown
              untilDate={moment.utc(broadcastData.start_at)}
              width={mediaWidth}
              height={mediaHeight}
              broadcastStatus={broadcastStatus}
              playMedia={playMediaAndPoll}
            />
          )}
          <Show show={Boolean(mediaPlay?.id)}>
            <LinearGradient
              colors={['#00000000', '#00000026']}
              end={{ x: 0, y: 1 }}
              start={{ x: 0, y: 0.01 }}
              style={styles.progressWrapper}
            >
              <View
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <KitMediaDurationIndicator duration={mediaPlay?.duration_ms} />
              </View>
              <KitProgress
                percentage={mediaProgressPercentage}
                percentageStyle={styles.percentage}
                progressContainerStyle={styles.progressContainer}
              />
            </LinearGradient>
          </Show>
        </View>
      </View>
    </View>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  backgroundContainer: {
    bottom: 0,
    marginBottom: 36,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  backgroundImage: {
    bottom: 0,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  backgroundOverlay: {
    bottom: 0,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  errorContainer: {
    alignItems: 'center',
    backgroundColor: '#000000dd',
    borderRadius: 44,
    justifyContent: 'center',
  },
  headerContainer: {
    alignItems: 'center',
  },
  liveEndedContainer: {
    alignItems: 'center',
    backgroundColor: '#000000dd',
    borderRadius: ARTWORK_BORDER_RADIUS,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
  },
  liveEndedTextContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  artworkContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Spacing.l,
  },
  bannerImage: {
    borderRadius: ARTWORK_BORDER_RADIUS,
    position: 'absolute',
  },
  progressWrapper: {
    borderBottomEndRadius: BorderRadius.m,
    borderBottomStartRadius: BorderRadius.m,
    bottom: 0,
    display: 'flex',
    height: 44,
    justifyContent: 'flex-end',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  progressContainer: {
    backgroundColor: '#ffffff66',
    borderBottomEndRadius: BorderRadius.m,
    borderBottomStartRadius: BorderRadius.m,
    height: 3,
  },
  percentage: {
    backgroundColor: Colors.N0,
    borderBottomStartRadius: BorderRadius.m,
    height: 3,
  },
});
