import Groups from '@omni/groups/Groups';
import { AuthNavigator } from '@omni/kit/auth';
import { KitLoader } from '@omni/kit/components';
import { KitTab, KitTabGroup } from '@omni/kit/components/KitTabGroup';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { useChatAuth } from '../../context/ChatAuthContext';
import useAppInfo from '../../hooks/useAppInfo';
import { CONVERSATIONS_TAB, DISCOVER_TAB } from '../../shared/Constants';
import { viewIsReadySelector } from '../../shared/redux/selectors';
import { sbHasGroupChannels } from '../../utilities/sendbird/channelFunctions';
import { sbIsConnected } from '../../utilities/sendbird/userFunctions';
import AppLoadingView from '../components/AppLoadingView';
import ChannelListScreen from '../screens/ChannelListScreen';

/**
 * When refactoring this, please check the following criteria:
 * 1. Default to Discover when unauthenticated
 * 2. Default to Conversations if you're authenticated and have channels
 * 3. If you're on Discover and you authenticate, stay on Discover group details
 * 4. Default to Discover if you're authenticated but have NO channels (and without flickering)
 * 5. Log in view Conversations tab. If you have no channels, it should take you to Discover tab.
 * 6. Test accepting chat invite sent to a user that has not yet created a chat user account
 * 7. When logging in via side menu, scenarios 1-6 should still work
 * 8. When logging in via small screen web messaging, scenarios 1-6 should still work
 * 9. In iOS/Android, login to Messaging, then use deep link to set an invalid refresh token,
 *    then use deep link to set an invalid access token.
 *    expected: The user should be prompted to login when Messaging is opened after the 2nd deep link.
 * 10. In iOS/Android, login to Messaging, then use deep link to set an invalid access token.
 *     expected: Messaging should automatically refresh the token and
 *     Messaging conversations should load without prompting user to login.
 * 11. On iPad mini, In a logged-in state, open Discover tab, send app to background,
 *     wait 2 seconds, then bring app back to foreground several times.
 *     expected: Messaging should render Discover tab and app should not attempt
 *     token refresh while app is in background. See workaround in SizeClassContext.tsx.
 */

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function MessagingTabs({ screenProps }): JSX.Element {
  const appKey: string = screenProps.appKey;
  const { orgKey } = useAppInfo(appKey);

  const { isAuthenticated, isReady: isAuthReady } = useChatAuth();
  const [initialTab, setInitialTab] = useState<string | undefined>();

  /**
   * TODO: refactor
   * viewIsReady is necessary to keep AppLoadingView mounted
   * until it can dispatch the pending module command (if any)
   */
  const viewIsReady = useSelector(viewIsReadySelector);
  const isAuthenticatedWithSendbird =
    isAuthReady && isAuthenticated && sbIsConnected() && viewIsReady;
  const isWaitingOnSendbirdSession =
    isAuthenticated && isAuthReady && (!sbIsConnected() || !viewIsReady);

  const checkHasChannels = useCallback(async (): Promise<boolean> => {
    let hasChannels = false;
    try {
      hasChannels = await sbHasGroupChannels();
    } catch {}

    return hasChannels;
  }, []);

  useEffect(() => {
    /**
     * We only need to configure an initial tab that should be rendered
     * based on the current auth state when a user opens Messaging.
     * Do not attempt to change tab when user logs in within Messaging.
     */
    if (initialTab) {
      return;
    }

    // waiting for login state to resolve
    if (!isAuthReady) {
      return;
    }

    // user is logged-out, show Discover tab
    if (isAuthReady && !isAuthenticated) {
      setInitialTab(DISCOVER_TAB);

      return;
    }

    if (isWaitingOnSendbirdSession) {
      return;
    }

    checkHasChannels().then((hasChannels) => {
      setInitialTab(hasChannels ? CONVERSATIONS_TAB : DISCOVER_TAB);
    });
  }, [
    checkHasChannels,
    initialTab,
    isAuthReady,
    isAuthenticated,
    isWaitingOnSendbirdSession,
  ]);

  // When user logs in and there are no channels, go to Discover tab
  // Do not run this effect on initial render (aka !initialTab) to prevent the Discover tab from flickering
  useEffect(() => {
    if (!isAuthenticatedWithSendbird || !initialTab) {
      return;
    }

    // when user is logged in w/ sendbird and no channels, then change initialTab + re-render the KitTabGroup
    // cause KitTabGroup to unmount, in order to take user to Discover tab when there are no channels
    checkHasChannels().then((hasChannels) => {
      if (!hasChannels) {
        // trigger effect that determines initial tab
        setInitialTab(undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkHasChannels, isAuthenticatedWithSendbird]);

  if (!initialTab && !isAuthReady) {
    return (
      <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <KitLoader />
      </View>
    );
  }

  // AppLoadingView is responsible for creating a user session with Sendbird,
  // The end user auth state must be resolved before attempting to show the AppLoadingView
  if (!initialTab && isWaitingOnSendbirdSession) {
    return <AppLoadingView screenProps={screenProps} />;
  }

  // Do not render until we can resolve whether the user is a member of any channels
  if (!initialTab) {
    return (
      <View style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
        <KitLoader />
      </View>
    );
  }

  return (
    <>
      {/* We must keep the KitTabGroup mounted while the user is logging in inside
          Messaging in order to preserve the active tab of the user.
          If KitTabGroup is ever unmounted during the login process,
          it will cause the initialTab to be shown again, which is unexpected.
         */}
      {initialTab && isWaitingOnSendbirdSession && (
        <View style={{ height: '100%' }}>
          <AppLoadingView screenProps={screenProps} />
        </View>
      )}

      {/* The initialTab only takes effect on first render of the KitTabGroup,
          which is why we need to wait to render this until we have
          resolved the initialTab, otherwise the initialTab will have no effect.
          */}
      <KitTabGroup initialRouteName={initialTab}>
        <KitTab name='Conversations'>
          {(props) =>
            isAuthenticated ? (
              <ChannelListScreen {...props} screenProps={screenProps} />
            ) : (
              <AuthNavigator
                headerShown={false}
                appKey={appKey}
                orgKey={orgKey}
                screenProps={screenProps}
                targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
              />
            )
          }
        </KitTab>
        <KitTab name={DISCOVER_TAB} options={{ title: 'Discover' }}>
          {() => <Groups appKey={appKey} />}
        </KitTab>
      </KitTabGroup>
    </>
  );
}
