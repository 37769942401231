import KitImage from '@omni/kit/components/KitImage';
import KitLoader from '@omni/kit/components/KitLoader';
import { isEqual } from 'lodash';
import React, { Component } from 'react';
import { Animated, ImageStyle, StyleSheet, View } from 'react-native';

import { IPicture } from './ImageMessageBubble';

const debug = require('debug')('tca:chat:component:KitImageLoader');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  style: ImageStyle;
  source?: string;
  thumbnails: IPicture[];
  showLoadingIndicator: boolean;
}

export interface IState {
  currSource: string;
  loadedIndex: number;
  nextIndex: number;
  opacity: Animated.Value;
  imageScaleValue: Animated.Value;
  isLightboxOpen: boolean;
  showActionSheet: boolean;
}

class ImageLoader extends Component<IProps, IState> {
  //****************************************************************************
  // State
  //****************************************************************************

  state: IState = {
    currSource:
      this.props.thumbnails.length > 0
        ? this.props.thumbnails[0].url
        : this.props.source
        ? this.props.source
        : '',
    loadedIndex: -1,
    nextIndex: 0,
    opacity: new Animated.Value(0),
    imageScaleValue: new Animated.Value(0),
    isLightboxOpen: false,
    showActionSheet: false,
  };

  //****************************************************************************
  // Methods
  //****************************************************************************

  private _loadNextImage = () => {
    const index = this.state.nextIndex;

    if (
      this.props.thumbnails &&
      this.props.thumbnails.length > 0 &&
      this.props.thumbnails.length > index
    ) {
      const source = this.props.thumbnails[this.state.nextIndex].url;

      if (source !== null) {
        this.setState({ currSource: source, nextIndex: index + 1 });
      }
    }
  };

  private _onLoad = () => {
    this.setState({ loadedIndex: this.state.nextIndex + 1 });

    // Attempt to start loading the next item in the source set array
    this._loadNextImage();
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidMount() {
    this._loadNextImage();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidUpdate(prevProps: IProps) {
    if (!isEqual(prevProps.thumbnails, this.props.thumbnails)) {
      // If source set changes, reset counters and start loading from the beginning
      this.setState({ loadedIndex: -1, nextIndex: 0 });
      this._loadNextImage();
    }
  }

  render(): JSX.Element {
    return (
      <View style={[styles.container, this.props.style]}>
        {this.props.showLoadingIndicator && (
          <KitLoader style={styles.loading} delay={700} />
        )}
        <KitImage
          style={this.props.style}
          source={{ uri: this.state.currSource }}
          // @ts-ignore
          onLoad={this._onLoad}
        />
      </View>
    );
  }
}
export default ImageLoader;

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  image: {
    tintColor: '#262626',
    marginRight: 18,
    resizeMode: 'stretch',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
