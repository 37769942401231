import { IEndUser } from '../ChatService';
import { IImage } from '../ImageService/Types';
import { ILinks2 } from '../Types';

export interface IGroup {
  id: string;
  name: string;
  description?: string;
  app_key: string;
  org_key: string;
  member_count: number;
  schedule?: string;
  location?: string;
  frequency?: string;
  day_of_week?: string;
  time_of_day?: number | null;
  timezone?: string;
  scheduleTitle?: string;
  type: string;
  enrollment_type: IEnrollmentType;
  public: boolean;
  created_at: Date;
  updated_at: Date;
  _embedded?: {
    image?: {
      id: string;
      average_color_hex?: string;
    };
    'group-type'?: IGroupType;
    'group-members'?: IGroupMember[];
    type?: IGroupType;
    location?: { id: string };
  };
}

export interface IGroupType {
  id: string;
  name: string;
}

export type IEnrollmentType = 'open' | 'closed' | 'request_to_join';

export interface IGroupTypes {
  count: number;
  _embedded?: {
    'group-types': IGroupType[];
  };
}

export interface IGroupLocation {
  app_key: string;
  title: string;
  subtitle: string;
  type: string[];
  id: string;
  _embedded: {
    address: {
      id: string;
      city: string;
      latitude: number;
      longitude: number;
      state: string;
      street: string;
    };
  };
}

export interface IGroupAddress {
  _embedded: {
    manager: {
      id: string;
    };
  };
  app_key: string;
  city: string;
  country: string;
  country_obj: {
    code: string;
    name: string;
  };
  name: string;
  created_at: string;
  deleted_at: string;
  id: string;
  latitude: number;
  longitude: number;
  org_key: string;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  updated_at: string;
}

export interface IGroupMember {
  id: string;
  role: 'member' | 'manager';
  messaging_status: 'invited' | 'active' | 'banned' | 'error' | undefined;
  created_at: Date;
  updated_at: Date;
  _embedded: {
    group?: IGroup;
    'end-user'?: IEndUser;
  };
}

export interface IGroupMembers {
  count: number;
  _embedded?: {
    'group-members': IGroupMember[];
  };
}

export interface IProfile {
  id: string;
  short_id: number;
  org_key: string;
  first_name?: string;
  last_name?: string;
  emails: IEmail[];
  email?: string;
  phone?: string;
  photo_url?: string;
  status: 'active' | unknown;
  created_at: Date;
  updated_at: Date;
  _embedded?: {
    ['end-user']?: {
      id?: string;
    };
    photo?: {
      id?: string;
    };
  };
}

export interface IEmail {
  value: string;
}

export const GroupRoles = {
  Manager: 'manager',
  Member: 'member',
};

export interface IJoinRequest {
  id: string;
  status: 'pending' | 'approved' | 'rejected' | 'denied';
  created_at: Date;
  updated_at: Date;
  _embedded: {
    'requesting-user': IEndUser;
    group: IGroup;
    'responding-manager'?: IGroupMember;
  };
}

export interface IJoinRequests {
  count: number;
  _embedded?: {
    'join-requests': IJoinRequest[];
  };
}

export type IGroupEvent = {
  _links?: {
    self: {
      href: string;
    };
  };
  all_day?: boolean;
  app_key?: string;
  check_in_enabled?: boolean;
  created_at?: string;
  description?: string;
  email?: string;
  end_at?: string;
  id?: string;
  phone?: string;
  published?: boolean;
  published_at?: string;
  recurring?: boolean;
  short_code?: string;
  start_at?: string;
  status?: string;
  subtitle?: string;
  timezone?: string;
  title?: string;
  updated_at?: string;
  visibility?: string;
  _embedded?: {
    calendar?: IGroupEventsCalendar;
    location?: {
      id: string;
    };
    images?: IGroupEventsImage[];
  };
};

export type IGroupEventsCalendar = {
  _embedded?: {
    images?: { type: string; id: string }[];
  };
  _links: {
    self: {
      href: string;
    };
  };
  app_key: string;
  color: string;
  created_at: string;
  id: string;
  published: boolean;
  published_at?: string;
  short_code: string;
  status: string;
  title: string;
  updated_at: string;
};

export interface IGroupEventsImage {
  _embedded?: any;
  _links: ILinks2;
  app_key: string;
  average_color_hex: string;
  content_type?: string;
  created_at: string;
  file_size?: number;
  height: number;
  id: string;
  is_stock?: boolean;
  md5?: string;
  s3_object_key?: string;
  title: string;
  type: string;
  updated_at: string;
  uploaded_at?: string;
  vibrant_color_hex: string;
  width: number;
}
