/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { User } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';

import { ChannelMember, MemberRole } from '../../Types';
import { IAppState } from './types';

//******************************************************************************
// Chat Selectors
//******************************************************************************
export const currentMessagesSelector = (state: IAppState) =>
  state.chat.messageList;
export const currentPrayerSelector = (state: IAppState) =>
  state.chat.prayerList;
export const userTypingSelector = (state: IAppState) => state.chat.userTyping;
export const ownerListSelector = (state: IAppState) =>
  state.chat.memberList.filter((member) => member.role === MemberRole.Operator);
export const memberListSelector = (state: IAppState): ChannelMember[] =>
  state.chat.memberList;
export const blockedUserListSelector = (state: IAppState) =>
  state.chat.blockedUserList;
export const invitedListSelector = (state: IAppState) => state.chat.invitedList;
export const chatIsLoadingSelector = (state: IAppState) => state.chat.isLoading;
export const blockedUserSelector = (state: IAppState) => state.chat.blockedUser;
export const unblockedUserSelector = (state: IAppState) =>
  state.chat.unblockedUser;
export const addedUsersSelector = (state: IAppState) =>
  state.chat.addedUsersList;
export const removedUserSelector = (state: IAppState) => state.chat.removedUser;
export const channelSelector = (state: IAppState): GroupChannel | null =>
  state.chat.channel;
export const channelNameSelector = (state: IAppState) => state.chat.channelName;
export const parentMessageIdSelector = (state: IAppState) =>
  state.chat.parentMessageId;
export const sendToChannelSelector = (state: IAppState) =>
  state.chat.sendToChannel;
export const inviteAcceptedSelector = (state: IAppState) =>
  state.chat.inviteAccepted;
export const joinRequestsSelector = (state: IAppState) =>
  state.chat.joinRequests;

//******************************************************************************
// System Selectors
//******************************************************************************
export const userSelector = (state: IAppState): User | null =>
  state.system.user;
export const userIdSelector = (state: IAppState) => state.system.userId;
export const userListSelector = (state: IAppState) => state.system.userList;
export const channelListSelector = (state: IAppState) =>
  state.system.channelsList;
export const publicChannelListSelector = (state: IAppState) =>
  state.system.publicChannels;
export const appKeySelector = (state: IAppState) => state.system.appKey;
export const orgKeySelector = (state: IAppState) => state.system.orgKey;
export const appIdSelector = (state: IAppState) => state.system.appId;
export const appTitleSelector = (state: IAppState) => state.system.appTitle;
export const userTokenSelector = (state: IAppState) => state.system.userToken;
export const accessTokenSelector = (state: IAppState) =>
  state.system.accessToken;
export const environmentSelector = (state: IAppState) =>
  state.system.environment;
export const brandColorSelector = (state: IAppState) => state.system.brandColor;
export const systemErrorSelector = (state: IAppState) =>
  state.system.systemError;
export const systemIsLoadingSelector = (state: IAppState) =>
  state.system.isLoading;
export const canCreateDirectMessageSelector = (state: IAppState) =>
  state.system.canCreateDirectMessage;
export const canCreateChannelsSelector = (state: IAppState) =>
  state.system.canCreateChannels;
export const pushPreferenceSelector = (state: IAppState) =>
  state.system.pushPreference;
export const deletedChannelSelector = (state: IAppState) =>
  state.system.deletedChannel;
export const viewIsReadySelector = (state: IAppState) =>
  state.system.viewIsReady;
export const bibleBooksSelector = (state: IAppState) => state.system.bibleBooks;
export const bibleLocationSelector = (state: IAppState) =>
  state.system.bibleLocation;
export const appBrandingSelector = (state: IAppState) =>
  state.system.appBranding;
export const profileImageUrlSelector = (state: IAppState) =>
  state.system.profileImageUrl;
