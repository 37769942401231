import React, { createContext, useContext } from 'react';

import useAppData from '../hooks/useAppData';
import { App } from '../services/Types';
import { IRootProps } from './types';

export const RootAppContext = createContext<App | undefined>(undefined);

interface ProviderProps {
  children?: React.ReactNode;
  props?: IRootProps;
}

/**
 * A context provider for the root app in container apps.
 * This is only needed in micro-apps for container-app specific behaviors.
 * e.g. Search and App Switcher
 * This is not needed in shell apps as there are only a handful of
 * container-app specific behaviors in micro-apps.
 */
export const RootAppContextProvider = ({
  children,
  props,
}: ProviderProps): null | JSX.Element => {
  const { app } = useAppData(props?.rootAppKey);

  if (!app) {
    return null;
  }

  return (
    <RootAppContext.Provider value={app}>{children}</RootAppContext.Provider>
  );
};

export const useRootAppContext = (): App | undefined => {
  return useContext<App | undefined>(RootAppContext);
};
