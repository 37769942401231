import React, { useContext } from 'react';

import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';
import ImageMessageBubble from '../../../../../mobile/scenes/channel/components/messageTypes/ImageMessageBubble';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PhotoMessage({ message, disabled }): JSX.Element {
  const { handleSelectingMessage } = useContext(ChatContext);

  return (
    // @ts-ignore
    <ImageMessageBubble
      message={message}
      onLongPress={handleSelectingMessage}
      progress={message.progress}
      disabled={disabled}
    />
  );
}
