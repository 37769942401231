import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import { updateChatUnreadNumber } from '@omni/kit/utilities/NativeHelpers';
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import SendbirdChat, { BaseChannel, ChannelType } from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  FileMessageCreateParams,
  MessageRequestHandler,
  MessageRetrievalParams,
  PreviousMessageListQuery,
  ThreadedMessageListParams,
  UserMessage,
  UserMessageCreateParams,
  UserMessageUpdateParams,
} from '@sendbird/chat/message';
import emojiRegex from 'emoji-regex';
import { debounce } from 'lodash';
import moment from 'moment';
import { InteractionManager, Platform } from 'react-native';

import {
  CustomMessageData,
  MessageUpdates,
  SendbirdMessage,
  SendbirdSenderMessage,
  SendbirdUserMessage,
} from '../../Types';
import {
  IFile,
  MediaMessageDataType,
  MessageType,
} from '../../shared/redux/types';
import { sbGetGroupChannel } from './channelFunctions';
import {
  sbGetTotalUnreadMessageCount,
  sbHasUser,
  sbIsConnected,
} from './userFunctions';

const debug = require('debug')('tca:chat:utilities:sendbird:chatFunctions');

type Message = UserMessage | FileMessage | AdminMessage;

export const sbCreatePreviousMessageListQuery = (
  channel: GroupChannel,
  limit = 30,
  customTypesFilter?: string[]
): PreviousMessageListQuery | null => {
  if (!sbHasUser()) {
    debug('Skipping sbCreatePreviousMessageListQuery. No sb user.');

    return null;
  }

  try {
    return channel.createPreviousMessageListQuery({
      includeReactions: true,
      includeThreadInfo: true,
      customTypesFilter,
      limit,
      reverse: true,
    });
  } catch (error) {
    debug('Error creating prev message list query: ', error);

    return null;
  }
};

export const sbGetMessageList = (
  previousMessageListQuery: PreviousMessageListQuery
): Promise<BaseMessage[]> => {
  if (!sbHasUser()) {
    debug('Skipping previousMessageListQuery. No sb user.');

    return Promise.reject('No user. Skipping query');
  }

  return previousMessageListQuery.load();
};

export const sbAdjustMessageList = (list: BaseMessage[]): SendbirdMessage[] => {
  /* eslint-disable dot-notation */
  return list
    .sort((a, b) => b.createdAt - a.createdAt)
    .map((message, i) => {
      // @ts-ignore
      message['time'] = messageTimeSince(message.createdAt);
      // @ts-ignore
      message['readCount'] = 0;
      if (message.isUserMessage() || message.isFileMessage()) {
        // @ts-ignore
        message['isUser'] =
          (message as UserMessage | FileMessage).sender.userId ===
          SendbirdChat.instance.currentUser?.userId;
      } else {
        // @ts-ignore
        message['isUser'] = false;
      }

      if ((message as SendbirdSenderMessage).sender) {
        // @ts-ignore
        (message as SendbirdSenderMessage).sender['showAvatarAndName'] = false;

        // @ts-ignore
        (message as SendbirdSenderMessage).sender['showUsername'] = false;
        // @ts-ignore
        (message as SendbirdSenderMessage).sender['showAvatar'] = false;
      }

      // @ts-ignore
      message['showTime'] = i === 0;

      const isSenderMessage = (message: BaseMessage) =>
        message && (message.isUserMessage() || message.isFileMessage());
      const isSystemMessage = (message: BaseMessage) =>
        message && message.messageType === 'admin';

      if (i < list.length) {
        const prevMessage = list[i + 1];
        const nextMessage = list[i - 1];

        if (isSenderMessage(message)) {
          if (isSystemMessage(prevMessage)) {
            // @ts-ignore
            (message as SendbirdSenderMessage).sender['showAvatarAndName'] =
              true;
            // @ts-ignore
            (message as SendbirdSenderMessage).sender['showUsername'] = true;
          }

          if (isSystemMessage(nextMessage)) {
            // @ts-ignore
            (message as SendbirdSenderMessage).sender['showAvatar'] = true;
          }

          if (isSenderMessage(prevMessage)) {
            if (
              (prevMessage as SendbirdSenderMessage).sender.userId !==
              (message as SendbirdSenderMessage).sender.userId
            ) {
              // @ts-ignore
              (message as SendbirdSenderMessage).sender['showAvatarAndName'] =
                true;
              // @ts-ignore
              (message as SendbirdSenderMessage).sender['showUsername'] = true;
            }
          }

          if (isSenderMessage(nextMessage)) {
            if (
              (nextMessage as SendbirdSenderMessage).sender.userId !==
              (message as SendbirdSenderMessage).sender.userId
            ) {
              // @ts-ignore
              (message as SendbirdSenderMessage).sender['showAvatar'] = true;
            }

            // if the next message is a prayer, the prev message should show avatar even if same sender
            if (
              (nextMessage as SendbirdSenderMessage)?.sender.userId ===
                (message as SendbirdSenderMessage)?.sender.userId &&
              nextMessage.customType === MessageType.Prayer
            ) {
              // @ts-ignore`
              (message as SendbirdSenderMessage).sender['showAvatar'] = true;
            }
          }

          if (i === 0) {
            // @ts-ignore
            (message as SendbirdSenderMessage).sender['showAvatar'] = true;
          }
        }
      }

      return message;
    }) as SendbirdMessage[];
};

export const sbGetMessage = (
  messageId: number,
  channel: GroupChannel
): Promise<BaseMessage | null> => {
  if (!sbHasUser()) {
    return Promise.reject('No user. Skipping sbGetMessage');
  }

  const params: MessageRetrievalParams = {
    channelType: ChannelType.GROUP,
    channelUrl: channel.url,
    messageId: messageId,
  };

  return SendbirdChat.instance.message.getMessage(
    params
  ) as Promise<BaseMessage | null>;
};

export const sbGetThreadedMessageList = (
  parentMessage: UserMessage
): Promise<BaseMessage[]> => {
  // TODO: Need to support a way to get more than 100 messages...
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbGetThreadedMessageList');
  }

  const params: ThreadedMessageListParams = {
    nextResultSize: 100,
    prevResultSize: 100,
    isInclusive: true,
    includeReactions: true,
  };

  return parentMessage
    .getThreadedMessagesByTimestamp(parentMessage.createdAt, params)
    .then(({ parentMessage, threadedMessages }) => {
      return [parentMessage, ...threadedMessages];
    });
};

export const sbSendTextMessage = (
  channel: GroupChannel,
  textMessage: string,
  mentions: string[],
  data: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendTextMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = {
    message: textMessage,
    customType: MessageType.Text,
    mentionedUserIds: mentions,
  };

  addDataParams(params, data);

  return channel.sendUserMessage(params);
};

export const sbUpdateMessage = (
  channel: GroupChannel,
  messageId: number,
  updates: MessageUpdates = {}
): Promise<UserMessage> => {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbUpdateMessage');
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageUpdateParams = {};

  if (updates.message) {
    params.message = updates.message;
  }

  if (updates.customType) {
    params.customType = updates.customType;
  }

  if (updates.data) {
    params.data = JSON.stringify(updates.data);
  }

  return channel.updateUserMessage(messageId, params);
};

export const sbUpdateMessageType = (
  channel: GroupChannel,
  messageId: number,
  customType: MessageType
): Promise<UserMessage> => {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbUpdateMessageType');
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageUpdateParams = {};

  params.customType = customType;

  return channel.updateUserMessage(messageId, params);
};

export const sbSendPollMessage = (
  channel: GroupChannel,
  question: string,
  data: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendPollMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: question };

  params.customType = MessageType.Poll;
  addDataParams(params, data);

  return channel.sendUserMessage(params);
};

export const sbSendPrayerRequestMessage = (
  channel: GroupChannel,
  request: string,
  data: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendPrayerRequestMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: request };

  params.customType = MessageType.Prayer;
  addDataParams(params, data);

  return channel.sendUserMessage(params);
};

export const sbSendBibleMessage = (
  channel: GroupChannel,
  verseData: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendBibleMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: 'Sent a Bible passage' };

  params.customType = MessageType.Bible;
  addDataParams(params, verseData);

  return channel.sendUserMessage(params);
};

export const sbSendEventMessage = (
  channel: GroupChannel,
  eventData: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendEventMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: 'Event created' };

  params.customType = MessageType.GroupEvent;
  addDataParams(params, eventData);

  return channel.sendUserMessage(params);
};

export const sbToggleReaction = (
  channel: GroupChannel,
  message: SendbirdUserMessage,
  emojiKey: string,
  userId: string
): void => {
  const reaction = message.reactions?.find((reactionEvent) => {
    return (
      reactionEvent.userIds.includes(userId) && reactionEvent.key === emojiKey
    );
  });

  if (reaction) {
    channel
      .deleteReaction(message, emojiKey)
      .then((reactionEvent) => {
        message.applyReactionEvent(reactionEvent);
      })
      .catch((error) => {
        console.warn('Error deleting reaction: ', error);
      });
  } else {
    channel
      .addReaction(message, emojiKey)
      .then((reactionEvent) => {
        message.applyReactionEvent(reactionEvent);
      })
      .catch((error) => {
        console.warn('Error adding reaction: ', error);
      });
  }
};

export const sbSendGifMessage = (
  channel: GroupChannel,
  data: CustomMessageData
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendGifMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: 'Sent a GIF' };

  params.customType = MessageType.Gif;
  addDataParams(params, data);

  return channel.sendUserMessage(params);
};

export const sbSendFileMessage = (
  channel: GroupChannel,
  file: IFile,
  data?: object
): MessageRequestHandler | null => {
  /**
   * Normally we check 'sbIsConnected' before making a Sendbird API call,
   * but this is a special case where sending file messages from certain
   * contexts like MessageTypeActionSheet _onBrowsePhotosPress and _onFilePress
   * have a 'closed' or 'connecting' Sendbird connectionState when a native
   * picker has been opened. See ARTEMIS-2808.
   */
  const user = Boolean(SendbirdChat.instance.currentUser);

  if (!user) {
    console.log('Not connected. Skipping sbSendFileMessage');

    return null;
  }

  const params: FileMessageCreateParams = {};
  params.file = file.uri
    ? { uri: file.uri, name: file.name, type: file.type }
    : file.file;
  params.customType = file.customType;

  // @ts-ignore
  addDataParams(params, data);

  return channel.sendFileMessage(params);
};

export const sbSendMediaMessage = (
  channel: GroupChannel,
  data: MediaMessageDataType
): MessageRequestHandler | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbSendMediaMessage');

    return null;
  }

  if (channel.isGroupChannel()) {
    channel.endTyping();
  }

  const params: UserMessageCreateParams = { message: 'Sent Media' };

  params.customType = MessageType.Media;
  addDataParams(params, data);

  return channel.sendUserMessage(params);
};

// @ts-ignore
const addDataParams = (params, data: CustomMessageData | string) => {
  if (data) {
    try {
      const dataObj = typeof data === 'string' ? JSON.parse(data) : data;

      if (dataObj.replyId && !dataObj.inChannel) {
        params.parentMessageId = dataObj.replyId;
      }

      params.data = JSON.stringify(dataObj);
    } catch {}
  }
};

export const sbTypingStart = (channelUrl: string): Promise<GroupChannel> => {
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel) => {
        channel.startTyping();
        resolve(channel);
      })
      .catch(reject);
  });
};

export const sbTypingEnd = (channelUrl: string): Promise<GroupChannel> => {
  return new Promise((resolve, reject) => {
    sbGetGroupChannel(channelUrl)
      .then((channel) => {
        channel.endTyping();
        resolve(channel);
      })
      .catch(reject);
  });
};

export const sbIsTyping = (channel: GroupChannel): string => {
  if (channel.isTyping) {
    const typingMembers = channel.getTypingUsers();

    if (typingMembers.length === 1) {
      return `${typingMembers[0].nickname} is typing...`;
    } else if (typingMembers.length === 2) {
      return `${typingMembers[0].nickname} and ${typingMembers[1].nickname} are typing...`;
    } else {
      return 'A few people are typing...';
    }
  } else {
    return '';
  }
};

/**
 * sbMarkAsRead() is called several times in rapid succession when a user opens a channel
 * which can lead to rate limiting with Sendbird API
 *
 * We must debounce markAsRead to reduce chance of rate limiting
 * per https://sendbird.com/docs/chat/v3/platform-api/guides/rate-limits#2-rate-limited-apis
 *
 * This also reduces chance of an unhandled Sendbird error 'Command received no ack.'
 * per https://community.sendbird.com/t/sendbirdexception-command-received-no-ack/1925/8
 */
const debouncedMarkAsRead = debounce((channel: GroupChannel) => {
  if (!sbIsConnected()) {
    debug(
      `Skipping and canceling debouncedMarkAsRead. Not connected. sbUser:${Boolean(
        SendbirdChat.instance.currentUser
      )}, connectionState:${SendbirdChat.instance.connectionState}`
    );

    debouncedMarkAsRead.cancel();

    return;
  }

  debug(
    'Calling channel.markAsRead(). Calling this method too frequently may lead to rate limiting errors '
  );

  channel
    .markAsRead()
    .then(() => {
      debug('Success: channel.markAsRead');
    })
    .catch((e) => {
      debug(`Unable to markAsRead, error: ${JSON.stringify(e)}`);
    });
}, 2000);

export const sbMarkAsRead = (
  channel: GroupChannel | null
): Promise<GroupChannel | null> => {
  return new Promise((resolve, _reject) => {
    if (!sbIsConnected()) {
      debug(
        `Skipping and canceling sbMarkAsRead. Not connected. sbUser:${Boolean(
          SendbirdChat.instance.currentUser
        )}, connectionState:${SendbirdChat.instance.connectionState}`
      );
      debouncedMarkAsRead.cancel();
      resolve(channel);

      return;
    }

    if (channel) {
      debouncedMarkAsRead(channel);
    } else {
      debug('Skipping markAsRead.. Called without channel.');
    }

    resolve(channel);
  });
};

export const updateUnread = (): void => {
  InteractionManager.runAfterInteractions(() => {
    sbGetTotalUnreadMessageCount()
      .then((count) => {
        if (Platform.OS === 'ios') {
          PushNotificationIOS.setApplicationIconBadgeNumber(count as number);
        }

        // notify both iOS and Android
        updateChatUnreadNumber(count as number);
      })
      .catch((err) => {
        debug('sbGetTotalUnreadMessageCount error:', err);
      });
  });
};

export const messageTimeSince = (date: number): string => {
  const currentDate = new Date();
  const seconds = Math.floor(
    (currentDate.valueOf() - new Date(date).valueOf()) / 1000
  );
  let interval = Math.floor(seconds / 31536000);

  // Years
  if (interval > 1) {
    return moment(date).format('MMM D YY, h:mm a');
  }

  // Months
  interval = seconds / 2592000;
  if (interval > 1) {
    return moment(date).format('MMM D, h:mm a');
  }

  // Yesterday
  if (Number(moment(date).format('DD')) - Number(moment().format('DD')) === 1) {
    return 'Yesterday h:mm a';
  }

  // Days
  interval = seconds / 86400;
  if (interval > 6) {
    return moment(date).format('MMM D, h:mm a');
  } else if (interval > 1) {
    return moment(date).format('ddd h:mm a');
  }

  // Hours
  interval = seconds / 3600;
  if (interval > 1) {
    return moment(date).format('h:mm a');
  }

  // Minutes
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' min';
  }

  // Seconds
  return 'Just now';
};

export const isEmojiString = (string: string): boolean => {
  const regex = new RegExp(`^(${emojiRegex()}){0,3}$`);

  if (regex.test(string)) {
    return true;
  } else {
    return false;
  }
};

export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

export const onMessageReceived = (
  callback: (channel: BaseChannel, message: BaseMessage) => void
): (() => void) => {
  if (!sbIsConnected()) {
    debug('Skipping onMessageReceived. Not connected.');

    return () => null;
  }

  debug('SendbirdChat.instance.addChannelHandler');

  const handlerId = Date.now().toString();
  const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
    onMessageReceived: callback,
  });
  (
    SendbirdChat.instance as SendbirdGroupChat
  ).groupChannel.addGroupChannelHandler(handlerId, groupChannelHandler);

  return () => {
    (
      SendbirdChat.instance as SendbirdGroupChat
    ).groupChannel.removeGroupChannelHandler(handlerId);
  };
};

interface SnoozeResponse {
  isSnoozeOn: boolean;
  endTs?: number;
  startTs?: number;
}

export function sbSetSnoozePeriod(
  snoozeOn: boolean,
  startTime: number,
  endTime: number
): Promise<SnoozeResponse> {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbSetSnoozePeriod');
  }

  return SendbirdChat.instance.setSnoozePeriod(snoozeOn, startTime, endTime);
}

export function sbGetSnoozePeriod(): Promise<SnoozeResponse> {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbGetSnoozePeriod');
  }

  return SendbirdChat.instance.getSnoozePeriod();
}
