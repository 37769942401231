import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitLinkPreview from '@omni/kit/components/KitLinkPreview';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { UserMessage } from '@sendbird/chat/message';
import moment from 'moment';
import React from 'react';
import { Linking, Platform, StyleSheet, View } from 'react-native';
import ParsedText from 'react-native-parsed-text';

import MessageWrapper from './MessageWrapper';

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /((http|ftp|https):\/\/)?((x|z|q)\.com|[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6})\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export interface IProps {
  message: UserMessage;
}

export default function Message({ message }: IProps): JSX.Element {
  const formatTime = (time: number): string => {
    return moment(time).format('h:mma');
  };

  const onLinkPressed = (url: string) => {
    const formattedUrl = `${!url.includes('http') ? 'https://' : ''}${url}`;

    if (Platform.OS === 'web') {
      const win = window.open(formattedUrl, '_blank');
      win?.focus();

      return;
    }

    // We are not using canOpenUrl because on Android 11 it returns false unexpectedly.
    Linking.openURL(formattedUrl).catch(() => {
      /** do nothing */
    });
  };

  return (
    <MessageWrapper message={message}>
      <View style={styles.container}>
        <View style={styles.avatarContainer}>
          <KitAvatar
            imageUrl={message.sender.profileUrl}
            nickname={message.sender.nickname}
            size={36}
          />
        </View>
        <View style={styles.messageContainer}>
          <View style={styles.messageTitle}>
            <KitText bold color={Colors.N900}>
              {message.sender.nickname}
            </KitText>
            <KitText
              color={Colors.N500}
              fontSize={12}
              style={{ paddingLeft: Spacing.s }}
            >
              {formatTime(message.createdAt)}
            </KitText>
          </View>
          <View style={styles.message}>
            <ParsedText
              style={styles.textMessage}
              parse={[
                {
                  pattern: URL_REGEX,
                  style: styles.textLink,
                  onPress: onLinkPressed,
                },
              ]}
            >
              {message.message}
            </ParsedText>
            <KitLinkPreview url={message.message} />
          </View>
        </View>
      </View>
    </MessageWrapper>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    justifyContent: 'center',
    marginVertical: Spacing.m,
    paddingHorizontal: Spacing.l,
    width: '100%',
  },
  message: {
    display: 'flex',
    fontFamily: 'inherit',
    height: 'auto',
    width: '100%',
  },
  messageContainer: {
    display: 'flex',
    flex: 1,
    height: 'auto',
    paddingLeft: Spacing.s,
  },
  messageTitle: {
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    width: '100%',
  },
  textLink: {
    textDecorationLine: 'underline',
  },
  textMessage: {
    color: Colors.N900,
    fontFamily: 'inherit',
    fontSize: 16,
  },
});
