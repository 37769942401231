import { dispatch } from '@omni/kit/ActionHandler';
import { BridgeAction } from '@omni/kit/Types';
import { KitSection } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import KitText from '@omni/kit/components/KitText';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableHighlight, View } from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:GivingBlock');

export interface GivingBlockProps extends IBlockProps {
  /** An action that will occur when a user taps/clicks on the block. */
  action?: BridgeAction | null;
}

export default function GivingBlock({
  action,
}: GivingBlockProps): JSX.Element | null {
  const { dispatchAction } = useBlockPageContext();
  const { t } = useTranslation(['giving']);
  const { sizeClass } = useSizeClass();
  const addSpacing = sizeClass !== SizeClass.Small;
  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: undefined,
  });

  return (
    <View
      style={[
        styles.container,
        {
          paddingHorizontal: addSpacing ? edgeSpacing : 0,
        },
      ]}
    >
      <KitSection title={t('giving:headerTitle')}>
        <TouchableHighlight
          underlayColor={Colors.N50}
          onPress={() => {
            dispatchAction(action);
          }}
        >
          <View style={styles.rowContainer}>
            <View style={{ justifyContent: 'center' }}>
              <View style={styles.givingIcon}>
                <KitIcon color={Colors.N300} name='dollar' size={20} />
              </View>
            </View>
            <View style={styles.textContainer}>
              <KitText
                style={{ flex: 1, flexWrap: 'wrap' }}
                color={Colors.N1000}
              >
                {t('giving:accountMenuItemTitle')}
              </KitText>
              <KitText style={{ flex: 1, flexWrap: 'wrap', fontSize: 14 }}>
                {t('giving:accountMenuItemSubtitle')}
              </KitText>
            </View>
            <View style={{ justifyContent: 'center' }}>
              <KitIcon name='arrow-right' size={20} color={Colors.N300} />
            </View>
          </View>
        </TouchableHighlight>
      </KitSection>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginTop: Spacing.xxl,
  },
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: Spacing.l,
    marginHorizontal: Spacing.l,
    minHeight: 78,
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  },
  givingIcon: {
    alignItems: 'center',
    backgroundColor: Colors.N50,
    borderRadius: BorderRadius.m,
    display: 'flex',
    padding: Spacing.s,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: Spacing.m,
  },
});
