import { DatePicker } from '@waves/date-picker';
import { Popover } from '@waves/popover';
import moment from 'moment';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';

import { KitDatePickerInputProps, KitDatePickerInputRefType } from './types';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'waves-date-picker': {
        value: string | undefined;
        ref: React.RefObject<unknown>;
        placement?:
          | 'top'
          | 'top-start'
          | 'top-end'
          | 'right'
          | 'right-start'
          | 'right-end'
          | 'bottom'
          | 'bottom-start'
          | 'bottom-end'
          | 'left'
          | 'left-start'
          | 'left-end';
        disabled?: boolean;
        'max-date'?: string | undefined;
        'min-date'?: string | undefined;
        theme?: 'default' | 'dark';
        testID?: string | undefined;
      };
    }
  }
}

const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined';

if (isBrowser && !window.customElements.get('waves-popover')) {
  window.customElements.define('waves-popover', Popover);
}

if (isBrowser && !window.customElements.get('waves-date-picker')) {
  window.customElements.define('waves-date-picker', DatePicker);
}

const KitDatePickerInput = forwardRef<
  KitDatePickerInputRefType,
  KitDatePickerInputProps
>((props, ref): JSX.Element => {
  const {
    date, //string (mm/dd/yyyy)
    disabled, // default false
    minimumDate, //string (mm/dd/yyyy)
    maximumDate, //string (mm/dd/yyyy)
    theme = 'default', // 'default' or 'dark'
    placement = 'bottom-start',
    onChange,
    dateFormat,
    testID,
  } = props;

  const wavesDatePickerRef = useRef<HTMLElement>(null);

  const onChangeDate = useCallback(
    (event: Event) => {
      if (onChange) {
        // @ts-ignore
        onChange(event, event.detail.date);
      }
    },
    [onChange]
  );

  useEffect(() => {
    const wavesDatePicker = wavesDatePickerRef.current;

    if (wavesDatePicker) {
      // Attach the event listener to the HTML element
      wavesDatePicker.addEventListener('change', onChangeDate);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (wavesDatePicker) {
        wavesDatePicker.removeEventListener('change', onChangeDate);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wavesDatePickerRef]);

  return (
    <>
      <waves-date-picker
        ref={wavesDatePickerRef}
        value={moment(date).format('MM/DD/YYYY')}
        placement={placement}
        disabled={disabled}
        max-date={
          maximumDate ? moment(maximumDate).format('MM/DD/YYYY') : undefined
        }
        min-date={
          minimumDate ? moment(minimumDate).format('MM/DD/YYYY') : undefined
        }
        theme={theme}
        testID={testID}
      />
    </>
  );
});

export default KitDatePickerInput;
