import { SpacingType } from '@omni/kit/theming/SpacingType';
import { ViewStyle } from 'react-native';

import { IErrorBlockProps } from './ErrorBlock';

export type BlockData = {
  props?: IBlockProps;
  type: ComponentType;
};

export type ErrorBlockData = {
  props?: Partial<IErrorBlockProps>;
  type: ComponentType.Error;
};

export enum ComponentType {
  Account = 'account',
  ActionRow = 'actionRow',
  Avatar = 'avatar',
  AvatarRow = 'avatarRow',
  Button = 'button',
  Browser = 'browser',
  CircleButtonRow = 'circleButtonRow',
  CopyrightLogo = 'copyrightLogo',
  ContinuePlaying = 'continuePlaying',
  Error = 'error',
  Frame = 'frame',
  Giving = 'giving',
  Icon = 'icon',
  IconRow = 'iconRow',
  Image = 'image',
  Input = 'input',
  List = 'list',
  MediaBanner = 'mediaBanner',
  Schedule = 'schedule',
  Section = 'section',
  Text = 'text',
  PreCheck = 'pre-check',
}

export interface IBlockProps {
  bottomSpacing?: SpacingType;
  insetStyle?: ViewStyle;
  topSpacing?: SpacingType;

  /** When undefined, defaults are applied based on display width. */
  horizontalSpacing?: SpacingType;
}

export enum ListBlockStyle {
  Rows = 'rows',
  Grid = 'grid',
}

export interface IInsetStyles {
  top: { paddingTop: number; paddingLeft: number; paddingRight: number };
  mid: { paddingLeft: number; paddingRight: number };
  bottom: { paddingBottom: number; paddingLeft: number; paddingRight: number };
}

export interface SettingInterface {
  type?: string;
  feature?: string;
  value?: string | string[] | number | boolean | null;
  old_email?: string;
  defaultValue?: string | string[] | number | boolean;
  sapid?: string;
}

export interface UpdateActionInterface {
  handler: string;
  contentUrl?: string;
  updateUrl: string;
  confirmEmailAccountVerificationCodeUrl: string;
  url?: string;
  authProviderId?: string;
  command: string;
  style?: string;
  settings?: SettingInterface[];
}
