/** Forked from https://www.npmjs.com/package/react-to-webcomponent */
import React, { FunctionComponent } from 'react';
import { createRoot } from 'react-dom/client';

const renderSymbol = Symbol.for('r2wc.reactRender');
const shouldRenderSymbol = Symbol.for('r2wc.shouldRender');

function toDashedStyle(camelCase = '') {
  return camelCase.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

function toCamelCaseStyle(dashedStyle = '') {
  return dashedStyle.replace(/-([a-z0-9])/g, function (g) {
    return g[1].toUpperCase();
  });
}

const receiverMethods = {
  /**
   * An `expando` is a new property added to an existing object.
   * This `expando` function is adding a new attribute to the receiver that will
   * will force a rerender of the receiver whenever a new value is set on it.
   */
  expando: function (
    receiver: Record<symbol, () => void>,
    componentAttribute: string,
    attributeValue: string
  ) {
    Object.defineProperty(receiver, componentAttribute, {
      enumerable: true,
      get: function () {
        return attributeValue || true;
      },
      set: function (newValue) {
        attributeValue = newValue;
        this[renderSymbol]();
      },
    });
    receiver[renderSymbol]();
  },
};

/**
 * Convert React component into webcomponent by wrapping it in a Proxy object.
 */
export default function (
  ReactComponent: Partial<FunctionComponent>,
  options: { shadow?: boolean /* Use shadow DOM rather than light DOM */ } = {}
): CustomElementConstructor {
  const renderAddedProperties: Record<string | symbol, unknown> = {
    isConnected: 'isConnected' in HTMLElement.prototype,
  };
  let rendering = false;
  // Create the web component "class"
  const WebComponent: Partial<CustomElementConstructor> & {
    observedAttributes?: string[];
    prototype?: unknown;
  } = function (this: typeof WebComponent, ...args: unknown[]): HTMLElement {
    const self: HTMLElement = Reflect.construct(
      HTMLElement,
      args,
      this.constructor
    );

    if (options.shadow) {
      self.attachShadow({ mode: 'open' });
    }

    return self;
  };

  // Make the class extend HTMLElement
  const targetPrototype = Object.create(
    HTMLElement.prototype
  ) as HTMLElement & {
    connectedCallback?: () => void;
    attributeChangedCallback?: (
      name: string,
      oldValue: string,
      newValue: string
    ) => void;
  } & Record<string | symbol, boolean | string | (() => void)>;
  targetPrototype.constructor = WebComponent as CustomElementConstructor;

  // But have that prototype be wrapped in a proxy.
  const proxyPrototype = new Proxy(targetPrototype, {
    has: function (_target, key) {
      return key in (ReactComponent.propTypes || {}) || key in targetPrototype;
    },

    // when any undefined property is set, create a getter/setter that re-renders
    set: function (target, key, value, receiver) {
      if (rendering) {
        renderAddedProperties[key] = true;
      }

      if (
        typeof key === 'symbol' ||
        renderAddedProperties[key] ||
        key in target
      ) {
        return Reflect.set(target, key, value, receiver);
      } else {
        receiverMethods.expando(receiver, key, value);
      }

      return true;
    },
    // makes sure the property looks writable
    getOwnPropertyDescriptor: function (target, key) {
      const own = Reflect.getOwnPropertyDescriptor(target, key);

      if (own) {
        return own;
      }

      if (key in (ReactComponent.propTypes || {})) {
        return {
          configurable: true,
          enumerable: true,
          value: undefined,
          writable: true,
        };
      }
    },
  });
  WebComponent.prototype = proxyPrototype;

  // Setup lifecycle methods
  targetPrototype.connectedCallback = function (this: typeof targetPrototype) {
    // Once connected, it will keep updating the innerHTML.
    // We could add a render method to allow this as well.
    this[shouldRenderSymbol] = true;
    (this[renderSymbol] as () => void)();
  };
  targetPrototype[renderSymbol] = function () {
    if (this[shouldRenderSymbol] === true) {
      const data: Record<string, unknown> = {};
      Object.keys(this).forEach(function (this: typeof targetPrototype, key) {
        if (renderAddedProperties[key] !== false) {
          data[key] = this[key];
        }
      }, this);
      rendering = true;
      // Container is either shadow DOM or light DOM depending on `shadow` option.
      const container = options.shadow ? this.shadowRoot : this;

      // Use react to render element in container
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const root = createRoot(container!);
      root.render(
        React.createElement(ReactComponent as FunctionComponent, data)
      );

      rendering = false;
    }
  };

  // Handle attributes changing
  if (ReactComponent.propTypes) {
    WebComponent.observedAttributes = Object.keys(ReactComponent.propTypes).map(
      function (prop) {
        return toDashedStyle(prop);
      }
    );
    targetPrototype.attributeChangedCallback = function (
      name,
      _oldValue,
      newValue
    ) {
      this[toCamelCaseStyle(name)] = newValue || true;
    };
  }

  return WebComponent as CustomElementConstructor;
}
