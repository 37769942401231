import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

export default function MoreActionModal({
  isManager,
  isVisible,
  onClose,
  onEditEvent,
  onDeleteEvent,
}: {
  isManager: boolean;
  isVisible: boolean;
  onClose: () => void;
  onEditEvent: () => void;
  onDeleteEvent: () => void;
}): JSX.Element | null {
  const { t } = useTranslation(['groups']);

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={onClose}
      anchorBottom={true}
      coverScreen
      modalStyle={{
        padding: Spacing.l,
        alignItems: 'flex-start',
        paddingBottom: Spacing.xl,
      }}
    >
      {isManager && (
        <>
          <ActionSheetItem
            icon='edit'
            iconColor={Colors.N900}
            label={t('labelEditEvent')}
            onPress={onEditEvent}
          />
          <ActionSheetItem
            icon='delete'
            iconColor={Colors.R500}
            label={t('labelDeleteEvent')}
            onPress={onDeleteEvent}
          />
        </>
      )}
    </KitModalV2>
  );
}
//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({});
