import { KitButton, KitText, KitTouchable } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ReportCategory } from '@sendbird/chat';
import { UserMessage } from '@sendbird/chat/message';
import React, { useState } from 'react';
import { View } from 'react-native';

import { MessageType } from '../../../../shared/redux/types';
import Message from '../../../../shared/scenes/channel/components/Message';
import { SendbirdFileMessage } from '../../../Types';

export default function ConfirmReportMessageContent({
  selectedMessage,
  setConfirmReportMessageVisible,
  confirmReportMessage,
}: {
  selectedMessage: UserMessage | SendbirdFileMessage;
  setConfirmReportMessageVisible: (isVisible: boolean) => void;
  confirmReportMessage: (category: ReportCategory, description: string) => void;
}): JSX.Element {
  const categories: ReportCategory[] = [
    ReportCategory.INAPPROPRIATE,
    ReportCategory.HARASSING,
    ReportCategory.SPAM,
    ReportCategory.SUSPICIOUS,
  ];
  const { sizeClass } = useSizeClass();
  const [category, setCategory] = useState<ReportCategory | undefined>();
  const isTextMessage = selectedMessage?.customType === MessageType.Text;
  const type =
    selectedMessage?.customType === 'prayer'
      ? 'prayer request'
      : selectedMessage?.customType;
  const title = isTextMessage ? 'Report message' : `Report ${type}`;

  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <KitText
        black
        extraBold
        fontSize={22}
        style={{
          marginBottom: Spacing.s,
          textTransform: !isTextMessage ? 'capitalize' : 'none',
        }}
      >
        {title}
      </KitText>
      <KitText gray fontSize={16}>
        Report this message for us to review. Please select the option that best
        describes the problem.
      </KitText>
      <View
        style={{
          borderBottomWidth: 1,
          borderTopWidth: 1,
          borderColor: Colors.N100,
          marginVertical: Spacing.l,
          paddingVertical: Spacing.m,
        }}
      >
        <View
          style={{
            maxHeight: 350,
            /* @ts-ignore */
            overflowY: 'scroll',
          }}
        >
          {/* @ts-ignore */}
          <Message message={selectedMessage} disabled />
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: sizeClass === SizeClass.Small ? 'column' : 'row',
          flexWrap: sizeClass === SizeClass.Small ? 'nowrap' : 'wrap',
          marginLeft: sizeClass === SizeClass.Small ? 0 : -4,
          marginRight: sizeClass === SizeClass.Small ? 0 : -4,
          marginBottom: -8,
        }}
      >
        {categories.map((c) => (
          <KitTouchable
            key={c}
            style={{
              width: sizeClass === SizeClass.Small ? '100%' : 'calc(50% - 8px)',
              padding: c !== category ? Spacing.l : 17,
              height: 58,
              borderWidth: c === category ? 2 : 1,
              borderColor: c === category ? Colors.N900 : Colors.N100,
              borderRadius: BorderRadius.m,
              marginBottom: Spacing.s,
              marginLeft: sizeClass === SizeClass.Small ? 0 : Spacing.xs,
              marginRight: sizeClass === SizeClass.Small ? 0 : Spacing.xs,
            }}
            onPress={() => setCategory(c)}
            borderRadius={BorderRadius.m}
          >
            <KitText black style={{ textTransform: 'capitalize' }}>
              {c}
            </KitText>
          </KitTouchable>
        ))}
      </View>
      <View
        style={{
          ...(sizeClass === SizeClass.Small && {
            flexDirection: 'column',
          }),
          ...(sizeClass !== SizeClass.Small && {
            flexDirection: 'row-reverse',
            marginTop: Spacing.l,
          }),
        }}
      >
        <KitButton
          color={Colors.N900}
          title='Report'
          disabled={category === undefined}
          style={{
            ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
          }}
          onPress={
            category ? () => confirmReportMessage(category, '') : undefined
          }
          small={sizeClass !== SizeClass.Small}
        />
        <KitButton
          secondary
          color={Colors.N100}
          title='Cancel'
          onPress={() => setConfirmReportMessageVisible(false)}
          style={{
            ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
          }}
          small={sizeClass !== SizeClass.Small}
        />
      </View>
    </View>
  );
}
