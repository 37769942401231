import React, { forwardRef, useEffect, useRef } from 'react';
import { View } from 'react-native';

import usePagination from '../hooks/usePagination';
import { DEFAULT_PAGE_SIZE } from '../services/SearchService/Constants';
import Spacing from '../theming/Spacing';
import KitList from './KitList';
import KitPagination from './KitPagination';
import Show from './Show';

interface Props<T> {
  animationEnabled?: boolean;
  data?: T[];
  onChangePage?: (page: number) => void;
  pageSize?: number;
  renderItem: ({ item, index }: { item: any; index: number }) => JSX.Element;
  total: number;
}

export default forwardRef(
  <T,>(
    {
      animationEnabled,
      data,
      onChangePage,
      pageSize = DEFAULT_PAGE_SIZE,
      renderItem,
      total,
    }: Props<T>,
    ref: React.ForwardedRef<View>
  ): JSX.Element => {
    const [page, handlePreviousPage, handlePageSelection, handleNextPage] =
      usePagination(pageSize, total);
    const pageRef = useRef(page);
    const showPagination = total > pageSize;

    useEffect(() => {
      if (page !== pageRef.current) {
        pageRef.current = page;
        onChangePage?.(page);
      }
    }, [onChangePage, page]);

    return (
      <View ref={ref}>
        <KitList
          animationEnabled={animationEnabled}
          data={data}
          renderItem={renderItem}
        />
        <Show show={showPagination}>
          <View
            style={{
              marginTop: Spacing.xl,
              marginBottom: Spacing.xxl,
              alignItems: 'center',
            }}
          >
            <KitPagination
              handleNextPage={handleNextPage}
              handlePageSelection={handlePageSelection}
              handlePreviousPage={handlePreviousPage}
              page={page}
              pageSize={pageSize}
              total={total}
            />
          </View>
        </Show>
      </View>
    );
  }
);
