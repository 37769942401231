import { KitButton, KitText, KitTouchable } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ReportCategory } from '@sendbird/chat';
import { Sender } from '@sendbird/chat/message';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View } from 'react-native';

export default function ReportUserModal({
  isVisible,
  userToReport,
  onClose,
  confirmReportUser,
}: {
  isVisible: boolean;
  userToReport: Sender | null;
  onClose: (isVisible: boolean) => void;
  confirmReportUser: (category: ReportCategory, description: string) => void;
}): JSX.Element | null {
  const { sizeClass } = useSizeClass();
  const [category, setCategory] = useState<ReportCategory | undefined>();
  const categories: ReportCategory[] = [
    ReportCategory.INAPPROPRIATE,
    ReportCategory.HARASSING,
    ReportCategory.SPAM,
    ReportCategory.SUSPICIOUS,
  ];

  if (userToReport === null) {
    return null;
  }

  const onCloseReportModal = () => {
    setCategory(undefined);
    onClose(false);
  };

  const onConfirmReportModal = (category: ReportCategory) => {
    confirmReportUser(category, '');
    setCategory(undefined);
  };

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={() => onCloseReportModal()}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen
    >
      <View
        style={{
          width: '100%',
        }}
      >
        <KitText
          black
          extraBold
          fontSize={22}
          style={{ marginBottom: Spacing.s }}
        >
          Report person
        </KitText>
        <KitText gray fontSize={16}>
          Report this person for us to review. Please select the option that
          best describes the problem.
        </KitText>
        <View
          style={{
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderColor: Colors.N100,
            marginVertical: Spacing.l,
            paddingVertical: Spacing.m,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <KitAvatar
              imageUrl={userToReport.plainProfileUrl}
              nickname={userToReport.nickname}
              size={36}
            />
            <KitText black style={{ marginLeft: Spacing.m }}>
              {userToReport.nickname}
            </KitText>
          </View>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: sizeClass === SizeClass.Small ? 'column' : 'row',
            flexWrap: sizeClass === SizeClass.Small ? 'nowrap' : 'wrap',
            marginLeft: sizeClass === SizeClass.Small ? 0 : -4,
            marginRight: sizeClass === SizeClass.Small ? 0 : -4,
            marginBottom: -8,
          }}
        >
          {categories.map((c) => (
            <KitTouchable
              key={c}
              style={{
                width:
                  sizeClass === SizeClass.Small ? '100%' : 'calc(50% - 8px)',
                padding: c !== category ? Spacing.l : 17,
                height: 58,
                borderWidth: c === category ? 2 : 1,
                borderColor: c === category ? Colors.N900 : Colors.N100,
                borderRadius: BorderRadius.m,
                marginBottom: Spacing.s,
                marginLeft: sizeClass === SizeClass.Small ? 0 : Spacing.xs,
                marginRight: sizeClass === SizeClass.Small ? 0 : Spacing.xs,
              }}
              onPress={() => setCategory(c)}
              borderRadius={BorderRadius.m}
            >
              <KitText black style={{ textTransform: 'capitalize' }}>
                {c}
              </KitText>
            </KitTouchable>
          ))}
        </View>
        <View
          style={{
            ...(sizeClass === SizeClass.Small && {
              flexDirection: 'column',
            }),
            ...(sizeClass !== SizeClass.Small && {
              flexDirection: 'row-reverse',
              marginTop: Spacing.l,
            }),
          }}
        >
          <KitButton
            color={Colors.N900}
            title='Report'
            disabled={category === undefined}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            }}
            onPress={
              category ? () => onConfirmReportModal(category) : undefined
            }
            small={sizeClass !== SizeClass.Small}
          />
          <KitButton
            secondary
            color={Colors.N100}
            title='Cancel'
            onPress={() => onCloseReportModal()}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
              ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
            }}
            small={sizeClass !== SizeClass.Small}
          />
        </View>
      </View>
    </KitModalV2>
  );
}
