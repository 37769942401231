import { KitFloatingButton } from '@omni/kit/components';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { setReactNativeAppIsAtRoot } from '@omni/kit/utilities/NativeHelpers';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import SendbirdChat from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelListQuery,
  PublicGroupChannelListQuery,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BackHandler,
  FlatList,
  NativeEventSubscription,
  Platform,
  AppState as ReactAppState,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import * as Constants from '../../shared/Constants';
import { CHAT_SCREEN } from '../../shared/Constants';
import { setChannel } from '../../shared/redux/actions/ChatActions';
import {
  createChannelListHandler,
  getGroupChannelListThunk,
  getPublicChannelListThunk,
} from '../../shared/redux/actions/SystemActions';
import {
  appIdSelector,
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
  channelListSelector,
  channelSelector,
  userIdSelector,
  userSelector,
  userTokenSelector,
  viewIsReadySelector,
} from '../../shared/redux/selectors';
import { ChannelType } from '../../shared/redux/types';
import ChannelListRow from '../../shared/scenes/channelList/components/ChannelListRow';
import EmptyConversationList from '../../shared/scenes/channelList/components/EmptyConversationList';
import EmptyConversationListLarge from '../../shared/scenes/channelList/components/EmptyConversationListLarge';
import JoinRequestsCount from '../../shared/scenes/joinRequests/components/JoinRequestsCount';
import { getShortChannelUrl } from '../../utilities/chatUtilities';
import { sbCreateGroupChannelListQuery } from '../../utilities/sendbird/channelFunctions';
import { updateUnread } from '../../utilities/sendbird/chatFunctions';
import {
  sbConnect,
  sbIsConnected,
} from '../../utilities/sendbird/userFunctions';
import { dismissChat } from '../components/LeftNavButton';
import ListFabActionSheet from '../components/chatList/ListFabActionSheet';
import OpenInAppModal from './OpenInAppModal';

const debug = require('debug')('tca:chat:screen:ChannelList');

//******************************************************************************
// Types
//******************************************************************************

interface ChannelListScreenProps {
  screenProps: object;
}

export default function ChannelListScreen({
  screenProps,
}: ChannelListScreenProps): JSX.Element {
  const [groupChannelListQuery, setGroupChannelListQuery] = useState<any>(null);
  const [publicChannelListQuery, setPublicChannelListQuery] =
    useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [navigateCreate, setNavigateCreate] = useState<string | null>(null);

  const viewIsReady = useSelector(viewIsReadySelector);
  const user = useSelector(userSelector);
  const channelList = useSelector(channelListSelector);
  const selectedChannel = useSelector(channelSelector);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const canCreateChannels = useSelector(canCreateChannelsSelector);
  const userId = useSelector(userIdSelector);
  const userToken = useSelector(userTokenSelector);
  const appId = useSelector(appIdSelector);

  const nonBlockedChannels = useMemo(() => {
    return channelList?.filter((channel) => {
      if (channel.customType !== ChannelType.Direct) {
        return true;
      }

      const blockedMembers = channel.members.filter(
        (member) => member.isBlockedByMe
      );
      const allMembersBlocked =
        blockedMembers.length === channel.memberCount - 1;

      return !allMembersBlocked;
    });
  }, [channelList]);

  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const { sizeClass } = useSizeClass();
  const navigation = useNavigation();

  //****************************************************************************
  // Methods
  //****************************************************************************

  const _keyExtractor = (item: GroupChannel) => item.url;

  const _handleAppStateChange = async (nextAppState: string) => {
    if (nextAppState === 'active') {
      debug('ChannelListScreen is in the foreground');
      if (!sbIsConnected()) {
        // check the connection after coming from background
        userId &&
          userToken &&
          appId &&
          (await sbConnect({ userId, token: userToken, appId }));
      }

      _getGroupChannelList(true);
      _getPublicChannelList(true);
    } else if (nextAppState === 'background') {
      debug('ChannelListScreen is in the background');

      /**
       * This is needed to update the iOS app badge number for the following scenarios:
       * - A user sent app to background after receiving new messages on the channel list screen
       * - A user sent app to background after opening a chat thread and receiving messages from one of the other chat threads
       * - A user sent app to background quickly after opening a chat thread with unread messages
       */
      updateUnread();
    }
  };

  const _onCreatePressed = () => {
    if (canCreateChannels && canCreateDirectMessage) {
      _toggleModal();
    } else if (canCreateChannels) {
      navigation.navigate(Constants.CREATE_CHANNEL_MODAL);
    } else {
      navigation.navigate(Constants.CREATE_DIRECT_MODAL);
    }
  };

  const _onChannelPress = (channel: GroupChannel) => {
    dispatch(setChannel(channel));

    if (Platform.OS === 'web') {
      navigation.navigate(CHAT_SCREEN, {
        channelId: getShortChannelUrl(channel.url),
      });
    } else {
      navigation.navigate(CHAT_SCREEN, { channelName: channel.name });
    }
  };

  const _navToDiscover = () => {
    navigation.navigate(Constants.DISCOVER_TAB);
  };

  const _toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const _renderList = ({ item: channel }: { item: GroupChannel }) => {
    const selected = selectedChannel === channel;

    return (
      <ChannelListRow
        channel={channel}
        selected={selected}
        onChannelSelection={_onChannelPress}
      />
    );
  };

  const _renderChannelList = () => {
    if (nonBlockedChannels && nonBlockedChannels.length === 0) {
      return sizeClass === SizeClass.Small ? (
        <EmptyConversationList onCreatePressed={_onCreatePressed} />
      ) : (
        <EmptyConversationListLarge navToDiscover={_navToDiscover} />
      );
    } else {
      return (
        <FlatList<GroupChannel>
          contentInset={{ bottom: Spacing.xxl }}
          data={nonBlockedChannels}
          renderItem={_renderList}
          inverted={false}
          keyExtractor={_keyExtractor}
          contentContainerStyle={[
            // Fixes scroll on small screen web
            // @ts-ignore
            Platform.OS === 'web' && sizeClass === SizeClass.Small
              ? { height: '80vh', zIndex: 2 }
              : {},
          ]}
          showsVerticalScrollIndicator={sizeClass !== SizeClass.Small}
          ListHeaderComponent={<JoinRequestsCount />}
          ListFooterComponent={
            Platform.OS !== 'web' ? <View style={{ height: 100 }} /> : undefined
          }
          onEndReached={() => debouncedGetGroupChannelList()}
        />
      );
    }
  };

  const _getGroupChannelList = (init = false) => {
    if (init) {
      const query = sbCreateGroupChannelListQuery() as GroupChannelListQuery;
      setGroupChannelListQuery(query);
      dispatch(getGroupChannelListThunk(query));
    } else {
      dispatch(getGroupChannelListThunk(groupChannelListQuery, true));
    }
  };

  const debouncedGetGroupChannelList = debounce(_getGroupChannelList, 500);

  const _getPublicChannelList = (init = false) => {
    if (init) {
      const query: PublicGroupChannelListQuery = sbCreateGroupChannelListQuery(
        true
      ) as PublicGroupChannelListQuery;
      setPublicChannelListQuery(query);
      dispatch(getPublicChannelListThunk(query));
    } else {
      dispatch(getPublicChannelListThunk(publicChannelListQuery));
    }
  };

  useEffect(() => {
    let listener: NativeEventSubscription | undefined;

    if (viewIsReady) {
      if (Platform.OS !== 'web') {
        listener = ReactAppState.addEventListener(
          'change',
          _handleAppStateChange
        );
      }

      (
        SendbirdChat.instance as SendbirdGroupChat
      ).groupChannel.removeAllGroupChannelHandlers();

      if (user && sbIsConnected()) {
        _getGroupChannelList(true);
        _getPublicChannelList(true);
        dispatch(createChannelListHandler());
      }
    }

    return () => {
      if (Platform.OS !== 'web') {
        listener?.remove();
      }

      (
        SendbirdChat.instance as SendbirdGroupChat
      ).groupChannel.removeAllGroupChannelHandlers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewIsReady, user?.userId]);

  useEffect(() => {
    if (navigateCreate) {
      setNavigateCreate(null);
      navigation.navigate(navigateCreate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateCreate]);

  useEffect(() => {
    const onBackPress = () => {
      dismissChat();

      return true;
    };

    if (isFocused) {
      onFocus();
      BackHandler.addEventListener('hardwareBackPress', onBackPress);
    }

    /**
     * we need to disable the drawer menu when we leave ChannelListScreen
     * so that people can swipe to navigate back on iOS, instead of accidentally
     * opening the drawer.
     *
     * When coming back to this screen, it's enabled again
     */
    setReactNativeAppIsAtRoot(isFocused);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  const onFocus = () => {
    if (sizeClass === SizeClass.Small) {
      debug('onFocus and sizeclass is small: set channel null');
      dispatch(setChannel(null));
    }

    if (!viewIsReady) {
      return;
    }

    if (sizeClass === SizeClass.Small && sbIsConnected()) {
      (
        SendbirdChat.instance as SendbirdGroupChat
      ).groupChannel.removeAllGroupChannelHandlers();
      setReactNativeAppIsAtRoot(true);
      dispatch(createChannelListHandler());
      debug('onFocus: set channel null');
      dispatch(setChannel(null));
    }

    return true;
  };

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        backgroundColor: colorForScheme({ default: Colors.N0 }),
      }}
    >
      {_renderChannelList()}
      {(canCreateDirectMessage || canCreateChannels) &&
        sizeClass === SizeClass.Small && (
          <View
            style={{
              position: 'absolute',
              bottom: Spacing.l,
              right: Spacing.l,
              zIndex: 3,
            }}
          >
            <KitFloatingButton
              icon='add'
              onPress={_onCreatePressed}
              customStyle={{
                backgroundColor: colorForScheme({ default: Colors.N900 }),
              }}
            />
          </View>
        )}
      <View
        style={{
          marginLeft: 8,
          marginBottom: 8,
          position: 'absolute',
          bottom: Spacing.s,
          left: Spacing.l,
        }}
      />
      <ListFabActionSheet
        visible={modalVisible}
        setVisible={setModalVisible}
        onNewMessagePressed={() => {
          setModalVisible(false);
          setTimeout(() => {
            setNavigateCreate(Constants.CREATE_DIRECT_MODAL);
          }, 100);
        }}
        onNewGroupChannelPressed={() => {
          setModalVisible(false);
          setTimeout(() => {
            setNavigateCreate(Constants.CREATE_CHANNEL_MODAL);
          }, 100);
        }}
      />
      <OpenInAppModal screenName={Constants.CHANNEL_LIST_SCREEN} />
    </View>
  );
}
