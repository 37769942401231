import { useShellContext } from '@omni/kit';
import {
  KitButton,
  KitSelect,
  KitSnack,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import KitInputValidationMessage from '@omni/kit/components/KitInputValidationMessage';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import KitTimePicker from '@omni/kit/components/KitTimePicker';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';

import { accessTokenSelector } from '../../redux/selectors';
import DayPicker from './components/DayPicker';

interface Props {
  navigation: any;
  route: any;
  isOpenScheduleModal: boolean;
  setIsOpenScheduleModal: (open: boolean) => void;
}
export interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

type RefType = {
  closePicker: () => void;
};

export default function AddMeetingScheduleScreen({
  route,
  navigation,
  setIsOpenScheduleModal,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { app } = useShellContext();
  const dateTimePickerRef = useRef<RefType>(null);
  const [group, setGroup] = useState(route.params.group);
  const [frequencyValue, setFrequencyValue] = useState(group.frequency);
  const [dayValue, setDayValue] = useState(group.day_of_week);
  const [timeValue, setTimeValue] = useState<string | undefined>(
    group.time_of_day || group.time_of_day === 0
      ? String(group.time_of_day).padStart(4, '0')
      : undefined
  );

  const { windowWidth, windowHeight } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';
  const isAndroid = Platform.OS === 'android';
  const accessToken = useSelector(accessTokenSelector);
  const [isOpenTimePicker, setOpenTimePicker] = useState<boolean>(
    group.time_of_day || group.time_of_day === 0 ? true : false
  );
  const timezone = moment().tz(`${group.timezone}`).zoneAbbr();

  const isEdited = useMemo(() => {
    if (!group.frequency && frequencyValue === 'unset') {
      return false;
    }

    const timeValueString = timeValue ? String(timeValue) : '';

    const isValidTime = moment(timeValueString, 'HHmm', true).isValid();

    const groupTimeOfDayString =
      group.time_of_day || group.time_of_day === 0
        ? String(group.time_of_day).padStart(4, '0')
        : '';

    return (
      frequencyValue !== group.frequency ||
      dayValue !== group.day_of_week ||
      (timeValueString !== groupTimeOfDayString && isValidTime)
    );
  }, [frequencyValue, dayValue, timeValue, group]);

  const handleSave = useCallback(() => {
    const updateGroup = async () => {
      const response = await GroupsService.UpdateGroup({
        body: {
          frequency: frequencyValue === 'unset' ? null : frequencyValue,
          day_of_week:
            dayValue === 'unset' ||
            frequencyValue === 'daily' ||
            frequencyValue === 'unset'
              ? null
              : dayValue,
          time_of_day: timeValue === null ? null : Number(timeValue),
          timezone: app.timezone?.name,
        },
        id: group.id,
        token: accessToken,
      });

      if (response.status === 200 && response.body) {
        setGroup(response.body);
        navigation.goBack();
        KitSnack.show(
          t('messaging:successMeetingScheduleMessage'),
          KitSnackDuration.SHORT
        );
        setIsOpenScheduleModal(false);
      } else {
        KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
      }
    };

    if (isEdited) {
      updateGroup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdited,
    frequencyValue,
    dayValue,
    timeValue,
    group,
    accessToken,
    navigation,
  ]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <KitTouchable
            onPress={() => handleSave()}
            disabled={!isEdited}
            style={{
              paddingLeft: 12,
              paddingRight: Platform.OS === 'ios' ? 12 : 18,
              paddingVertical: 6,
              marginRight: -6,
              borderRadius: 40,
            }}
          >
            <KitText
              color={isEdited ? Colors.brand : Colors.N400}
              fontSize={16}
            >
              {t('common:buttonTitleSave')}
            </KitText>
          </KitTouchable>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited, handleSave, navigation]);

  useEffect(() => {
    if (isOpenTimePicker && timeValue === undefined) {
      setTimeValue(moment(new Date()).format('HHmm'));
    }
  }, [isOpenTimePicker, timeValue]);

  const handleRemoveTime = () => {
    setOpenTimePicker(false);
    setTimeValue(undefined);
  };
  const handleAddMeetingTime = () => {
    setOpenTimePicker(true);
    const currentTime = moment(new Date()).format('HHmm');
    setTimeValue(currentTime);
  };

  const containerPadding = {
    padding: !isWeb && isLandscape ? Spacing.xl : 0,
    paddingHorizontal:
      (isIOS && !isLandscape) || isAndroid ? Spacing.l : isWeb ? 0 : Spacing.xl,
    paddingTop:
      (isIOS && !isLandscape) || isAndroid ? Spacing.m : isWeb ? 0 : Spacing.xl,
  };

  const handleTimeChange = useCallback((time: Date | null) => {
    if (time) {
      const formattedTime = moment(time).format('HHmm');
      setTimeValue(formattedTime);
    }
  }, []);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        dateTimePickerRef?.current?.closePicker?.();
      }}
      accessible={false}
    >
      <View style={[styles.container, containerPadding]}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText color={Colors.N900} style={styles.title}>
            {t('messaging:meetingSchedule')}
          </KitText>
        </View>
        <View style={styles.selectContainer}>
          <KitSelect
            items={[
              {
                value: 'unset',
                label: t('messaging:unset'),
              },
              {
                value: 'daily',
                label: t('groups:frequency_daily'),
              },
              {
                value: 'weekly',
                label: t('groups:frequency_weekly'),
              },
              {
                value: 'every_other_week',
                label: t('groups:frequency_every_other_week'),
              },
              {
                value: 'monthly',
                label: t('groups:frequency_monthly'),
              },
            ]}
            label={t('messaging:frequencyLabel')}
            labelStyle={{ marginBottom: 8 }}
            onValueChange={(value) => setFrequencyValue(value.value as string)}
            value={frequencyValue || 'unset'}
          />
        </View>
        {!(
          frequencyValue === 'daily' ||
          frequencyValue === 'unset' ||
          !frequencyValue
        ) && (
          <View>
            <DayPicker
              selectedDay={dayValue || 'unset'}
              onDaySelect={(day) => setDayValue(day as string)}
            />
          </View>
        )}

        <View>
          <KitText color={Colors.N600} style={styles.timeTitle}>
            {t('groups:timeLabel')} ({timezone})
          </KitText>
          <KitButton
            testID={
              isOpenTimePicker
                ? t('groups:removeMeetingTimeButtonTitle')
                : t('groups:addMeetingTimeButtonTitle')
            }
            secondary
            icon={isOpenTimePicker ? 'minus-circle' : 'clock'}
            iconSize={14}
            title={
              isOpenTimePicker
                ? t('groups:removeMeetingTimeButtonTitle')
                : t('groups:addMeetingTimeButtonTitle')
            }
            onPress={isOpenTimePicker ? handleRemoveTime : handleAddMeetingTime}
            style={
              isOpenTimePicker
                ? { position: 'absolute', top: 85, width: '100%' }
                : undefined
            }
          />
          {isOpenTimePicker && (
            <KitTimePicker
              ref={dateTimePickerRef}
              onTimeChange={(time) => handleTimeChange(time)}
              initialTime={String(timeValue).padStart(4, '0')}
              style={{ marginBottom: Spacing.l }}
            />
          )}
        </View>

        {(Platform.OS === 'web' || isLandscape) && (
          <View
            style={[
              styles.buttonContainer,
              { marginTop: isOpenTimePicker ? 60 : 20 },
            ]}
          >
            <KitButton
              secondary
              title={t('messaging:buttonTitleCancel')}
              onPress={
                Platform.OS === 'web'
                  ? () => setIsOpenScheduleModal(false)
                  : navigation.goBack
              }
              style={styles.rightButton}
            />
            <KitButton
              testID='save_button'
              title={t('common:buttonTitleSave')}
              color={Colors.N900}
              disabled={!isEdited}
              onPress={handleSave}
              style={styles.rightButton}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.N0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: Platform.OS === 'web' ? 24 : 18,
  },
  selectContainer: {
    marginTop: Platform.OS !== 'android' ? -5 : 0,
    marginBottom: Spacing.l,
    // @ts-ignore
    appearance: 'none',
    outlineStyle: 'none',
    width: '100%',
  },

  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rightButton: {
    marginLeft: Spacing.s,
    width: 100,
  },
  timeTitle: {
    fontSize: 14,
    fontWeight: '600',
    marginBottom: Spacing.s,
  },
});
