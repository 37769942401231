import Environment from '../../../Environment';
import { IGroupLocation } from '../Types';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from './../../BaseServiceV2';

const HOST = Environment.groupsHost;
const RESOURCE = 'locations';

interface IGetGroupLocation extends IUri {
  id: string;
  token?: string;
}

interface IGetGroupLocationResponse extends IHttpResponse {
  body?: IGroupLocation;
}

export default async function ({
  token,
  ...buildUrlProps
}: IGetGroupLocation): Promise<IGetGroupLocationResponse> {
  const url = buildUrl({
    host: HOST,
    resource: RESOURCE,
    ...buildUrlProps,
  });

  return BaseServiceV2.Get({
    url,
    token,
    skipTimestamp: true,
  });
}
