/**
 * This file is used to stub out the ScriptureModalContext for the web
 * to avoid importing the bible module which is suspected have caused
 * memory leak in the webshell.
 *
 * This is related to the web app outage issue:
 * https://subsplash.atlassian.net/wiki/spaces/PD/pages/2819424366/2024-09-08+-+Web-App+Outage
 *
 * Note:
 * - The ScriptureModalContext is currently only used in the mobile app so this will not affect web
 */
import React, { createContext, useContext } from 'react';

import {
  IScriptureModalContextProviderProps,
  TScriptureModalContextType,
} from './types';

const debug = require('debug')('omni:kit:contexts:ScriptureModalContext');

const initialScriptureModalContext: TScriptureModalContextType = {
  showScriptureModal: (osis: string) => undefined,
};

export const ScriptureModalContext = createContext(
  initialScriptureModalContext
);

export const ScriptureModalContextProvider = ({
  children,
}: IScriptureModalContextProviderProps): null | JSX.Element => {
  debug('ScriptureModalContextProvider is not implemented on the web platform');

  return <>{children}</>;
};

export const useScriptureModalContext = (): TScriptureModalContextType => {
  return useContext<TScriptureModalContextType>(ScriptureModalContext);
};
