import { useShellContext } from '@omni/kit';
import {
  KitButton,
  KitIcon,
  KitInput,
  KitListItem,
  KitText,
  KitToggle,
  KitTouchable,
} from '@omni/kit/components';
import KitDatePickerInput from '@omni/kit/components/KitDatePickerInput/KitDatePickerInput';
import KitEditableImage from '@omni/kit/components/KitEditableImage/KitEditableImage';
import KitSnack, { KitSnackDuration } from '@omni/kit/components/KitSnack';
import KitTimePicker from '@omni/kit/components/KitTimePicker';
import { RefType } from '@omni/kit/components/KitTimePicker/types';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import GroupsService from '@omni/kit/services/GroupsService';
import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import {
  getTimestampUTC,
  roundToNextHour,
} from '@omni/kit/utilities/utilities';
import { RouteProp } from '@react-navigation/core/lib/typescript/src/types';
import {
  ParamListBase,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  ScrollView,
  ScrollViewProps,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { sendEventMessage } from '../../redux/actions/ChatActions';
import { accessTokenSelector, channelSelector } from '../../redux/selectors';

export interface RouteProps extends RouteProp<ParamListBase, string> {
  params: {
    event: IEvent;
    channel: GroupChannel;
  };
}

export default function CreateEditGroupEventScreen({
  setCreateGroupEventModal,
}: {
  setCreateGroupEventModal?: (b: boolean) => void;
}): JSX.Element {
  const { sizeClass } = useSizeClass();
  const { t } = useTranslation();
  const { app, user } = useShellContext();
  const dispatch = useDispatch();

  const accessToken = useSelector(accessTokenSelector);
  const navigation = useNavigation();
  const route = useRoute<RouteProps>();
  const event = route?.params?.event;
  const timezone = event?.timezone || app.timezone?.name;

  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(true);

  const channel = useSelector(channelSelector);

  // Refs
  const titleRef = useRef<KitInput>(null);
  const startTimePickerRef = useRef<RefType>(null);
  const endTimePickerRef = useRef<RefType>(null);
  const startDateRef = useRef<RefType>(null);
  const endDateRef = useRef<RefType>(null);

  // Event Image States
  const [eventPhotoId, setEventPhotoId] = useState<string | undefined>(
    undefined
  );
  const [eventPhotoUrlValue, setEventPhotoUrlValue] = useState<
    string | undefined
  >(undefined);

  const [imageTypesData, setImageTypesData] = useState<any[]>([]);

  // Initial states
  const channelData: GroupChannel = route?.params?.channel || channel;
  const initialTitle = event?.title || '';

  // Format utility to get dates from event if they have
  const initialStartDate: Date = useMemo(() => new Date(), []);
  const initialEndDate: Date = useMemo(() => new Date(), []);
  const initialStartTime: string | undefined = String(
    timezone && roundToNextHour(moment().tz(timezone)).format('HHmm')
  ).padStart(4, '0');
  const initialEndTime: string | undefined = String(
    timezone &&
      roundToNextHour(moment().add(1, 'hour').tz(timezone)).format('HHmm')
  ).padStart(4, '0');

  const initialDescription = event?.description || '';

  // Computed States
  const [title, setTitle] = useState<string>(initialTitle);
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(initialEndDate);
  const [startTime, setStartTime] = useState<string | undefined>(
    timezone &&
      String(roundToNextHour(moment().tz(timezone)).format('HHmm')).padStart(
        4,
        '0'
      )
  );

  const [endTime, setEndTime] = useState<string | undefined>(
    timezone &&
      String(
        roundToNextHour(moment().add(1, 'hour').tz(timezone)).format('HHmm')
      ).padStart(4, '0')
  );

  const [allDay, setAllDay] = useState<boolean>(event?.all_day || false);
  const [description, setDescription] = useState<string>(initialDescription);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isEdited = useMemo(() => {
    return (
      title !== initialTitle ||
      startDate !== initialStartDate ||
      endDate !== initialEndDate ||
      startTime !== initialStartTime ||
      endTime !== initialEndTime
    );
  }, [
    title,
    initialTitle,
    startDate,
    initialStartDate,
    endDate,
    initialEndDate,
    startTime,
    initialStartTime,
    endTime,
    initialEndTime,
  ]);

  const handleScroll = (event: {
    nativeEvent: {
      contentOffset: any;
      layoutMeasurement: any;
      contentSize: any;
    };
  }) => {
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent;

    const isAtTop = contentOffset.y <= 0;
    const isAtBottom =
      contentOffset.y + layoutMeasurement.height >= contentSize.height;

    // Show the top shadow when scrolling starts and hide it only at the very top
    setShowTopShadow(!isAtTop);

    // Show the bottom shadow always unless at the very end
    setShowBottomShadow(!isAtBottom);
  };

  const save = useCallback(() => {
    const sendEvent = (event: IGroupEvent | undefined) => {
      if (!event) {
        return;
      }

      navigation.goBack();

      dispatch(
        sendEventMessage(channelData as GroupChannel, {
          groupEvent: event,
        })
      );
    };

    const createGroupEvent = async () => {
      setIsSaving(true);
      const response = await GroupsService.CreateGroupEvent({
        body: {
          all_day: allDay,
          app_key: app.appKey,
          description: description,
          end_at:
            endDate &&
            endTime &&
            timezone &&
            getTimestampUTC(endDate, endTime, timezone),
          start_at:
            startDate &&
            startTime &&
            timezone &&
            getTimestampUTC(startDate, startTime, timezone),
          status: 'published',
          timezone: timezone,
          title: title,
          visibility: 'public',
          _embedded: {
            // @ts-ignore
            images: imageTypesData.map((item) => ({
              id: item.id,
              type: item.type,
            })),
          },
        },
        token: accessToken,
      });

      if (response.status === 201 && response.body) {
        setIsSaving(false);
        sendEvent(response.body);
        KitSnack.show(t('groups:groupEventCreated'), KitSnackDuration.SHORT);
      } else {
        setIsSaving(false);
        KitSnack.show(t('groups:errorMessage'), KitSnackDuration.SHORT);
      }
    };

    if (isEdited) {
      createGroupEvent();
    }
  }, [
    accessToken,
    allDay,
    app.appKey,
    channelData,
    description,
    dispatch,
    endDate,
    endTime,
    imageTypesData,
    isEdited,
    navigation,
    startDate,
    startTime,
    t,
    timezone,
    title,
  ]);

  const onChangeDescription = (text: string) => {
    setDescription(text);
  };

  const onChangeStartDate = (_event?: any, selectedDate?: Date) => {
    const startDate = moment(selectedDate).startOf('day').toDate();
    setStartDate(startDate);
    setEndDate(startDate);
  };

  const onChangeEndDate = (_event?: any, selectedDate?: Date) => {
    const endDate = moment(selectedDate).startOf('day').toDate();
    setEndDate(endDate);
  };

  const handleStartTimeChange = useCallback((time: Date | null) => {
    if (time) {
      const formattedTime = moment(time).format('HHmm');
      setStartTime(formattedTime);
    }
  }, []);

  const handleEndTimeChange = useCallback((time: Date | null) => {
    if (time) {
      const formattedTime = moment(time).format('HHmm');
      setEndTime(formattedTime);
    }
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <KitTouchable
            disabled={!isEdited}
            onPress={save}
            style={{
              paddingLeft: 12,
              paddingRight: Platform.OS === 'ios' ? 12 : 18,
              paddingVertical: 6,
              marginRight: -6,
              borderRadius: 40,
            }}
          >
            <KitText
              color={isEdited ? Colors.brand : Colors.N400}
              fontSize={17}
              testID='group-events-create'
            >
              {t('common:buttonTitleCreate')}
            </KitText>
          </KitTouchable>
        ),
        title: t('groups:newEvent'),
      });
    }
  }, [isEdited, navigation, save, t]);

  return (
    <>
      {Platform.OS === 'web' && (
        <View
          style={[
            {
              padding: Spacing.xl,
              paddingBottom: Spacing.l,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            },
            showTopShadow && {
              backgroundColor: Colors.N0,
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowOffset: { width: 0, height: 3 },
              shadowOpacity: 1,
              shadowRadius: 10,
              zIndex: 999,
            },
          ]}
        >
          <KitText black bold fontSize={20}>
            {t('groups:newGroupEvent')}
          </KitText>
        </View>
      )}
      <ScrollViewWrapper
        onScroll={handleScroll}
        keyboardShouldPersistTaps='handled'
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.N0 }}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            startDateRef?.current?.closePicker?.();
            endDateRef?.current?.closePicker?.();
            startTimePickerRef?.current?.closePicker?.();
            endTimePickerRef?.current?.closePicker?.();
          }}
          accessible={false}
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{
                paddingHorizontal:
                  Platform.OS === 'web' ? Spacing.xl : Spacing.l,
              }}
            >
              <KitInput
                autoCapitalize='none'
                placeholder={t('groups:untitledEvent')}
                inputRef={titleRef}
                marginValue='0 0 18px 0'
                onChangeText={setTitle}
                value={title}
                testID='group-events-title'
              />

              <KitEditableImage
                label={t('groups:eventImage')}
                endUserId={user?.id || ''}
                appKey={app.appKey}
                imageUrl={eventPhotoUrlValue || undefined}
                onPhotoChange={(imageValue: {
                  id: string | undefined;
                  url: string | undefined;
                }) => {
                  setEventPhotoId(imageValue.id);
                  setEventPhotoUrlValue(imageValue.url);
                }}
                token={accessToken}
                setImageTypesData={setImageTypesData}
                testID='group-events-create-upload-section'
              />

              <KitText
                fontSize={14}
                fontWeight={600}
                color={Colors.N600}
                style={{ marginBottom: Spacing.s }}
              >
                {t('common:starts')}
              </KitText>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <View
                  style={[
                    allDay && {
                      flex: 1,
                    },
                    {
                      width: '60%',
                    },
                  ]}
                >
                  <View
                    style={{
                      marginRight: allDay ? 0 : Spacing.m,
                      marginBottom:
                        allDay && Platform.OS === 'web' ? Spacing.l : 0,
                    }}
                  >
                    <KitDatePickerInput
                      ref={startDateRef}
                      date={startDate}
                      onChange={onChangeStartDate}
                      dateFormat='MMMM D, YYYY'
                      label=''
                      placeholder={startDate?.toDateString() || ''}
                      minimumDate={new Date()}
                      onShowPicker={() => {
                        if (!startDate) {
                          onChangeStartDate(undefined, new Date());
                        }
                      }}
                      testID='group-events-start-date'
                    />
                  </View>
                </View>

                {!allDay && (
                  <View style={{ width: '40%' }}>
                    <KitTimePicker
                      ref={startTimePickerRef}
                      onTimeChange={(time) => handleStartTimeChange(time)}
                      initialTime={String(startTime).padStart(4, '0')}
                      style={{ marginBottom: Spacing.l }}
                    />
                  </View>
                )}
              </View>

              <KitText
                fontSize={14}
                fontWeight={600}
                color={Colors.N600}
                style={{ marginBottom: Spacing.s }}
              >
                {t('common:ends')}
              </KitText>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  zIndex: 9999,
                }}
              >
                <View
                  style={[
                    allDay && {
                      flex: 1,
                    },
                    {
                      width: '60%',
                    },
                  ]}
                >
                  <View style={{ marginRight: allDay ? 0 : Spacing.m }}>
                    <KitDatePickerInput
                      ref={endDateRef}
                      date={endDate}
                      onChange={onChangeEndDate}
                      dateFormat='MMMM D, YYYY'
                      label=''
                      placeholder={endDate?.toDateString() || ''}
                      minimumDate={new Date()}
                      onShowPicker={() => {
                        if (!startDate) {
                          onChangeEndDate(undefined, new Date());
                        }
                      }}
                      testID='group-events-end-date'
                    />
                  </View>
                </View>

                {!allDay && (
                  <View style={{ width: '40%' }}>
                    <KitTimePicker
                      ref={startTimePickerRef}
                      onTimeChange={(time) => handleEndTimeChange(time)}
                      initialTime={String(endTime).padStart(4, '0')}
                    />
                  </View>
                )}
              </View>

              <KitListItem
                style={{
                  paddingBottom: Spacing.xl,
                }}
                ImageComponent={
                  <KitIcon
                    name='sun'
                    color={Colors.N600}
                    size={20}
                    style={{ margin: Spacing.s }}
                  />
                }
                rightElement={
                  <KitToggle
                    style={{ marginRight: -Spacing.l }}
                    onToggle={() => setAllDay(!allDay)}
                    isOn={allDay}
                  />
                }
                rightIcon=''
                title={t('groups:allDay')}
                titleFontSize={14}
                isTitleBold={false}
                subtitleFontSize={18}
                subtitleLineHeight={24}
                subtitleLength={0}
                topBorder={false}
                iconColor={Colors.N300}
                minHeight={78}
              />

              <View style={{ marginBottom: Spacing.l }}>
                <KitText
                  semiBold
                  fontSize={14}
                  fontWeight={600}
                  style={{
                    color: Colors.N600,
                    marginBottom: Spacing.s,

                    zIndex: 1,
                    position: 'relative',
                  }}
                  testID='group-events-description'
                >
                  {t('availability:unavailableModal.description')}
                </KitText>
                <KitInput
                  placeholder={t('groups:newGroupEventDescription')}
                  onChangeText={onChangeDescription}
                  value={description}
                  multiline
                  testID='group-events-description-input'
                />
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </ScrollViewWrapper>

      {Platform.OS === 'web' && (
        <View
          style={[
            {
              padding: 24,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              ...(sizeClass === SizeClass.Small && {
                flexDirection: 'column',
              }),
              ...(sizeClass !== SizeClass.Small && {
                flexDirection: 'row-reverse',
              }),
            },
            showBottomShadow && {
              backgroundColor: Colors.N0,
              shadowColor: 'rgba(0, 0, 0, 0.2)',
              shadowOffset: { width: 0, height: -3 },
              shadowOpacity: 1,
              shadowRadius: 10,
              zIndex: 999,
            },
          ]}
        >
          <KitButton
            color={Colors.N900}
            title={t('common:buttonTitleCreate')}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            }}
            small={sizeClass !== SizeClass.Small}
            disabled={!isEdited}
            isLoading={isSaving}
            onPress={save}
            testID='group-events-create'
          />
          <KitButton
            secondary
            color={Colors.N100}
            title={t('common:buttonTitleCancel')}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
              ...(sizeClass !== SizeClass.Small && {
                marginRight: Spacing.m,
              }),
            }}
            small={sizeClass !== SizeClass.Small}
            onPress={() => setCreateGroupEventModal?.(false)}
          />
        </View>
      )}
    </>
  );
}

const ScrollViewWrapper = (
  props: ScrollViewProps & {
    onEndReached?: (isEnd: boolean) => void;
  }
) => {
  const [height, setHeight] = useState(0);

  if (!props.onEndReached) return <ScrollView {...props} />;

  return (
    <ScrollView
      {...props}
      onScroll={({
        nativeEvent: { layoutMeasurement, contentOffset, contentSize },
      }) => {
        // @ts-ignore
        props.onEndReached(
          layoutMeasurement.height + contentOffset.y >= contentSize.height
        );
      }}
      onContentSizeChange={(_, contentHeight) => {
        // @ts-ignore
        props.onEndReached(contentHeight <= height || height === 0);
      }}
      onLayout={({
        nativeEvent: {
          layout: { height },
        },
      }) => {
        setHeight(height);
      }}
      scrollEventThrottle={100}
    >
      {props.children}
    </ScrollView>
  );
};
