import { useNavigation } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as Constants from '../../shared/Constants';
import { channelSelector } from '../../shared/redux/selectors';
import CreateDirectContent from '../../shared/scenes/createChannel/CreateDirectContent';
import { getShortChannelUrl } from '../../utilities/chatUtilities';
import LeftNavButton, { LeftNavType } from '../components/LeftNavButton';

// const debug = require('debug')('tca:chat:screen:CreateChannelModal');

export default function CreateDirectScreen(): JSX.Element {
  const channel = useSelector(channelSelector);
  const navigation = useNavigation();

  navigation.setOptions({
    title: 'New Message ',
    headerLeft: () => (
      <LeftNavButton
        type={LeftNavType.Dismiss}
        title='New Conversation'
        onPress={navigation.goBack}
      />
    ),
  });

  useEffect(() => {
    if (channel && channel.url) {
      navigation.reset({
        index: 1,
        routes: [
          { name: Constants.CHANNEL_LIST_SCREEN },
          {
            name: Constants.CHAT_SCREEN,
            params: {
              channel,
              channelName: channel.name,
              channelId: getShortChannelUrl(channel.url),
            },
          },
        ],
      });
      // @ts-ignore
      setTimeout(navigation.pop, 800);
    }
  }, [channel, navigation]);

  // @ts-ignore
  return <CreateDirectContent />;
}
