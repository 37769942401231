import { KitListItem } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { View } from 'react-native';

interface DetailRowProps {
  leftLabel: string;
  count: number;
  onPress?: () => void;
  children: any;
  expanded: boolean;
}

export default function DetailRow({
  leftLabel = '',
  count,
  onPress,
  children = null,
  expanded = false,
}: DetailRowProps): JSX.Element {
  return (
    <>
      <KitListItem title={leftLabel} count={count} onPress={onPress} />
      {expanded ? children : null}
    </>
  );
}
