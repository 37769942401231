import { KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';

interface DayPickerProps {
  onDaySelect?: (day: string | null) => void;
  selectedDay?: string | null;
  circleStyle?: ViewStyle;
  textStyle?: TextStyle;
  selectedCircleStyle?: ViewStyle;
  selectedTextStyle?: TextStyle;
}

const DayPicker: React.FC<DayPickerProps> = ({
  onDaySelect,
  selectedDay,
  circleStyle,
  textStyle,
  selectedCircleStyle,
  selectedTextStyle,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string | null>(selectedDay || null);
  const { width, height } = useWindowDimensions();
  const isLandscape = width > height;

  const isWeb = Platform.OS === 'web';
  const isIOS = Platform.OS === 'ios';
  const isAndroid = Platform.OS === 'android';
  const applyJustifyContent = (isIOS || isAndroid) && !isWeb && !isLandscape;

  const generateDaysOfWeek = () => {
    return moment.weekdaysMin().map((dayShort, index) => ({
      value: moment().day(index).format('dd').toUpperCase(),
      label: moment().day(index).format('dd'),
    }));
  };

  const daysOfWeek = generateDaysOfWeek();

  const handleSelectDay = useCallback(
    (day: string) => {
      if (selected === day) {
        setSelected(null);
        if (onDaySelect) onDaySelect(null);
      } else {
        setSelected(day);
        if (onDaySelect) onDaySelect(day);
      }
    },
    [selected, onDaySelect]
  );

  return (
    <View style={styles.container}>
      <KitText style={styles.title}> {t('messaging:dayOfWeekLabel')}</KitText>
      <View
        style={[
          styles.circleContainer,
          applyJustifyContent && { justifyContent: 'space-between' },
        ]}
      >
        {daysOfWeek.map(({ value, label }) => (
          <TouchableOpacity
            testID={value}
            key={value}
            style={[
              styles.circle,
              circleStyle,
              selected === value ? styles.selectedCircle : null,
              selected === value ? selectedCircleStyle : null,
              !applyJustifyContent && { marginEnd: Spacing.s },
            ]}
            onPress={() => handleSelectDay(value)}
          >
            <KitText
              style={[
                styles.text,
                textStyle,
                selected === value ? styles.selectedText : null,
                selected === value ? selectedTextStyle : null,
              ]}
            >
              {label}
            </KitText>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    marginBottom: 20,
  },
  circleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  circle: {
    width: 44,
    height: 44,
    borderRadius: 22,
    backgroundColor: Colors.N100,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Spacing.s,
  },
  title: {
    fontSize: 15,
    color: Colors.N500,
    fontWeight: '600',
  },
  text: {
    fontSize: 16,
    color: Colors.N500,
  },
  selectedCircle: {
    backgroundColor: Colors.N1000,
  },
  selectedText: {
    color: Colors.N0,
  },
});

export default DayPicker;
