import { KitText } from '@omni/kit/components';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  message: string;
}

//******************************************************************************
// Component
//******************************************************************************

const Admin = ({ message }: IProps): JSX.Element => {
  return (
    <View style={styles.adminMessageContainer}>
      <KitText fontSize={12} gray style={styles.adminMessage}>
        {message}
      </KitText>
    </View>
  );
};

export { Admin };

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  adminMessageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 14,
    marginRight: 14,
  },
  adminMessage: {
    marginTop: 4,
  },
});
