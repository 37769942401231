import { ScreenContextProvider } from '@omni/kit/contexts/ScreenContext';
import Colors from '@omni/kit/theming/Colors';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';

interface Props {
  children?: React.ReactNode;
}

export default ({ children }: Props): JSX.Element | null => {
  const { colorForScheme } = useContext(ThemeContext);

  return (
    <ScreenContextProvider
      style={{
        backgroundColor: colorForScheme?.({
          light: Colors.N0,
          dark: Colors.N1000,
        }),
        height: '100%',
        width: '100%',
      }}
    >
      {children}
    </ScreenContextProvider>
  );
};
