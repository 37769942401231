import KitImage from '@omni/kit/components/KitImage';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { UserMessage } from '@sendbird/chat/message';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { CustomMessageData } from '../../../../../Types';
import TextMessage from './TextMessage';

export default function AnnouncementMessage({
  message,
}: {
  message: UserMessage;
}): JSX.Element {
  let data: CustomMessageData | null = null;

  if (message.data) {
    try {
      data = JSON.parse(message.data);
    } catch (e) {}
  }

  if (data?.bannerImageUrl) {
    return (
      <>
        <View style={styles.bannerPlaceholder}>
          <KitImage
            source={{ uri: data?.bannerImageUrl }}
            style={styles.bannerImage}
          />
        </View>
        <View style={styles.wrapper}>
          <TextMessage message={message} />
        </View>
      </>
    );
  } else {
    return <TextMessage message={message} />;
  }
}

const styles = StyleSheet.create({
  wrapper: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.N100,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    padding: Spacing.m,
    backgroundColor: Colors.N0,
  },
  bannerPlaceholder: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: 96,
    overflow: 'hidden',
    backgroundColor: Colors.N100,
  },
  bannerImage: {
    height: '100%',
  },
});
