import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { PlatformAgnosticFile } from '../../FileTypes';
import { useImageSelector } from '../../hooks/useImageSelector';
import useImageUpload from '../../hooks/useImageUpload';
import ImageService from '../../services/ImageService';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import { ImageServiceType, parseImageUrl } from '../../utilities/utilities';
import KitActionSheetItem from '../KitActionSheetItem';
import KitImage from '../KitImage';
import KitPopup from '../KitPopup.web';
import { KitSnackDuration } from '../KitSnack';
import {
  KitBadge,
  KitIcon,
  KitListItem,
  KitLoader,
  KitModal,
  KitSnack,
  KitText,
  KitTouchable,
} from '../index';

const debug = require('debug')('tca:component:PhotoUpload');

export interface KitEditableImageProps {
  appKey: string;
  endUserId: string;
  imageUrl?: string;
  onPhotoChange?: any;
  token?: string | undefined;
  setImageTypesData: (data: any) => void;
  label: string;
  testID?: string | undefined;
}

export default function KitEditableImage({
  appKey,
  endUserId,
  imageUrl,
  onPhotoChange,
  token,
  setImageTypesData,
  label,
  testID,
}: KitEditableImageProps): JSX.Element {
  const { uploadImage } = useImageUpload(appKey, endUserId);
  const {
    _onChangePhotoPressWeb,
    _onChangePhotoPressMobile,
    _onCameraPress,
    _onFilePress,
  } = useImageSelector();
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [editImageModalVisible, setEditImageModalVisible] = useState(false);
  const [image, setImage] = useState<string | undefined>(imageUrl);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const _openWebFilePicker = () => {
    fileInputRef.current?.click();
  };

  const _uploadPhoto = (selectedFile: PlatformAgnosticFile) => {
    try {
      setUploading(true);
      setModalVisible(false);
      uploadImage(selectedFile, 'source')
        .then(async ({ id, url, res }) => {
          const imageUrl = parseImageUrl(
            url,
            147,
            84,
            ImageServiceType.ImageJpeg
          );

          // First, we upload the image as the source and then attach that source to the embedded fields of the three types needed for events when creating them, similar to what we do in the dashboard.
          // Adding the source image allows us to crop the images in the future.
          const types = ['wide', 'banner', 'square'];

          for (const type of types) {
            const data = {
              app_key: appKey,
              content_type: res.data.content_type,
              title: res.title,
              type,
              _embedded: {
                source: {
                  id: res.data.id,
                  type: 'source',
                },
              },
            };

            try {
              const response: any = await ImageService.post(
                appKey,
                'image',
                endUserId,
                data,
                '',
                token,
                true
              );

              setImageTypesData((prev: any) => [...prev, response?.data || {}]);
            } catch (error) {
              debug('Error uploading photo types:', error);
            }
          }

          setImage(imageUrl);
          setUploading(false);
          onPhotoChange && onPhotoChange({ id, url: imageUrl });
        })
        .catch(() => {
          debug('Error Uploading Photo');
          setUploading(false);
          KitSnack.show(t('common:photoUploadError'), KitSnackDuration.SHORT);
        });
    } catch (e) {
      debug('Error Uploading Photo');
      setUploading(false);
    }
  };

  const _deletePhoto = async (imageId: string): Promise<number | undefined> => {
    try {
      const response = await ImageService.delete(
        appKey,
        'image',
        imageId,
        token
      );

      return response.status;
    } catch (error) {
      debug(error);
    }
  };

  const onRemoveImage = () => {
    setUploading(true);
    const urlObject = image && new URL(image);
    const imageId = urlObject && urlObject.searchParams.get('id');

    imageId &&
      _deletePhoto(imageId)
        .then((status) => {
          if (status === 204) {
            setUploading(false);
          }
        })
        .catch((error) => {
          debug(error);
        });

    setImage(undefined);
    onPhotoChange && onPhotoChange({ id: undefined, url: undefined });
  };

  const handleOnChangeWeb = async (
    e: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    _onChangePhotoPressWeb(e)
      .then((r) => r && _uploadPhoto(r))
      .catch((e) => debug(e));
  };

  const handleOnChangeMobile = async () => {
    _onChangePhotoPressMobile(false)
      .then((r) => r && _uploadPhoto(r))
      .catch((e) => debug(e));
  };

  return (
    <>
      <View
        style={{
          height: 108,
          marginBottom: 18,
          borderRadius: 8,
        }}
      >
        <TouchableWithoutFeedback
          onPress={() => {
            if (Platform.OS === 'web') {
              if (image === undefined) {
                _openWebFilePicker();
              }
            } else {
              setModalVisible(true);
            }
          }}
        >
          <View style={{ flex: 1 }} testID={testID}>
            <>
              {!uploading && (
                <>
                  {image ? (
                    <KitListItem
                      title={label}
                      titleFontSize={16}
                      titleLineHeight={22}
                      titleColor={Colors.N900}
                      ImageComponent={
                        <KitImage
                          style={styles.image}
                          source={{
                            uri: parseImageUrl(
                              imageUrl || image,
                              147,
                              84,
                              ImageServiceType.ImageJpeg
                            ),
                          }}
                        />
                      }
                      minHeight={108}
                      fullBorder
                      fullBorderStyle={{
                        borderWidth: 1,
                        borderColor: Colors.N100,
                        borderStyle: 'solid',
                        borderRadius: 8,
                      }}
                      rightElement={
                        Platform.OS === 'web' ? (
                          <KitPopup
                            content={
                              <>
                                <KitActionSheetItem
                                  icon='image-library'
                                  label={t('common:replace')}
                                  iconColor='black'
                                  onPress={_openWebFilePicker}
                                />
                                <KitActionSheetItem
                                  icon='minus-circle'
                                  label={t('common:remove')}
                                  iconColor='black'
                                  onPress={onRemoveImage}
                                />
                              </>
                            }
                            contentStyle={{
                              paddingHorizontal: 0,
                              paddingVertical: Spacing.s,
                            }}
                            position='bottom right'
                            triggerType='click'
                            zIndex={9999}
                          >
                            <KitTouchable
                              style={{
                                padding: 12,
                                borderRadius: 22,
                                marginRight: Platform.OS === 'web' ? 6 : 0,
                              }}
                            >
                              <KitIcon
                                size={20}
                                color={Colors.N300}
                                name='action-h'
                              />
                            </KitTouchable>
                          </KitPopup>
                        ) : (
                          <KitTouchable
                            onPress={() => setEditImageModalVisible(true)}
                            style={{
                              padding: 12,
                              borderRadius: 22,
                              marginRight: 0,
                            }}
                          >
                            <KitIcon
                              size={20}
                              color={Colors.N300}
                              name='action-h'
                            />
                          </KitTouchable>
                        )
                      }
                    />
                  ) : (
                    <View
                      style={{
                        height: 108,
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderWidth: 2,
                        borderColor: Colors.N100,
                        borderStyle: 'dashed',
                      }}
                    >
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ margin: Spacing.xxl }}>
                          <KitIcon
                            color={Colors.N300}
                            name='upload'
                            size={36}
                          />
                        </View>
                        <View style={{ marginTop: 23 }}>
                          <KitText
                            fontSize={16}
                            lineHeight={22}
                            color={Colors.N900}
                          >
                            {t('common:uploadArtwork')}
                          </KitText>
                          <KitText
                            fontSize={14}
                            lineHeight={20}
                            color={Colors.N500}
                            style={{ marginBottom: 5 }}
                          >
                            {t('common:maxSizePhoto')}
                          </KitText>
                          <View style={{ width: 'auto', flexDirection: 'row' }}>
                            <KitBadge
                              text='PNG'
                              size='m'
                              textStyle={{ textTransform: 'uppercase' }}
                              style={{ marginRight: 8 }}
                            />
                            <KitBadge
                              text='JPG'
                              size='m'
                              textStyle={{ textTransform: 'uppercase' }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              )}
              {uploading && (
                <View
                  style={{
                    width: '100%',
                    height: 108,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {uploading && <KitLoader />}
                </View>
              )}
            </>
          </View>
        </TouchableWithoutFeedback>

        {Platform.OS === 'web' && (
          // @ts-ignore
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            type='file'
            onChange={
              Platform.OS === 'web'
                ? handleOnChangeWeb
                : () => setModalVisible(true)
            }
            accept='image/*'
          />
        )}
      </View>
      {Platform.OS !== 'web' && (
        <KitModal
          visible={modalVisible}
          style={{ marginHorizontal: Spacing.xl }}
          setVisible={setModalVisible}
        >
          <View style={styles.modalContainer}>
            <KitActionSheetItem
              icon='image-library'
              label={t('common:photoLibrary')}
              onPress={handleOnChangeMobile}
            />
            <KitActionSheetItem
              icon='camera'
              label={t('common:takeAPhoto')}
              onPress={() =>
                _onCameraPress()
                  .then((r) => r && _uploadPhoto(r))
                  .catch((e) => debug(e))
              }
            />
            {Platform.OS === 'ios' && (
              <KitActionSheetItem
                icon='action-h'
                label={t('common:browse')}
                onPress={() =>
                  _onFilePress()
                    .then((r) => r && _uploadPhoto(r))
                    .catch((e) => debug(e))
                }
              />
            )}
          </View>
        </KitModal>
      )}

      {Platform.OS !== 'web' && (
        <KitModal
          visible={editImageModalVisible}
          style={{ marginHorizontal: Spacing.xl }}
          setVisible={setEditImageModalVisible}
        >
          <View style={styles.modalContainer}>
            <KitActionSheetItem
              icon='image-library'
              label={t('common:replace')}
              iconColor='black'
              onPress={() => {
                setEditImageModalVisible(false);
                setTimeout(() => {
                  setModalVisible(true);
                }, 500);
              }}
            />
            <KitActionSheetItem
              icon='minus-circle'
              label={t('common:remove')}
              iconColor='black'
              onPress={() => {
                setEditImageModalVisible(false);
                setTimeout(() => {
                  onRemoveImage();
                }, 500);
              }}
            />
          </View>
        </KitModal>
      )}
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Spacing.s,
  },
  modalContainer: {
    marginHorizontal: Spacing.l,
  },
  popupWrapper: {
    backgroundColor: Colors.N0,
    paddingHorizontal: Spacing.l,
    borderRadius: 14,
    shadowColor: '#000000',
    shadowOpacity: 0.06,
    shadowOffset: { width: 0, height: 3 },
    shadowRadius: 6,
  },
  image: {
    width: 147,
    height: 84,
    borderRadius: 4,
    marginVertical: Platform.OS === 'web' ? 12 : 0,
    marginLeft: Platform.OS === 'web' ? 12 : 6,
  },
});
