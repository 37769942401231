import { Country, CountryWithSubdivisions, Subdivision } from '@omni/kit/Types';
import { KitIcon, KitInput, KitText, KitTouchable } from '@omni/kit/components';
import KitCallout from '@omni/kit/components/KitCallout';
import KitInputMask from '@omni/kit/components/KitInputMask';
import KitSelect, { SelectOption } from '@omni/kit/components/KitSelect';
import ProgressBar from '@omni/kit/components/ProgressBar';
import Show from '@omni/kit/components/Show';
import Stack from '@omni/kit/components/Stack';
import {
  getAllCountries,
  getAllSubdivisions,
} from '@omni/kit/services/LocationService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import AsyncStorage from '@react-native-async-storage/async-storage';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import {
  PERCENT_IN_WHOLE,
  checkPhoneValidation,
  formatPhoneNumber,
  stateIsPresentIfCountryRequiresIt,
} from './utilities';

const debug = require('debug')('tca:blocks:blocks:ExtraFields');
const DEFAULT_CALLOUT_POSITION = { x: 0, y: 0 };

export interface ExtraFieldsProps {
  addressLine1Value: string;
  addressLine2Value: string;
  appTitle?: string;
  cityValue: string;
  countryValue: string | number;
  dateOfBirthValue: string;
  layout2Column?: boolean;
  missingAddressFields?: boolean;
  phoneNumberValue: string;
  postalCodeValue: string;
  progressActivityNumber: number;
  setAddressLine1Value: (address1: string) => void;
  setAddressLine2Value: (address2: string) => void;
  setCityValue: (city: string) => void;
  setCountryValue: (country: string | number) => void;
  setDateOfBirthValue: (dateOfBirth: string) => void;
  setPhoneNumberValue: (phone: string) => void;
  setPostalCodeValue: (postalCode: string) => void;
  setStateValue: (state: string | number) => void;
  stateValue: string | number;
}
const ExtraFields: React.FC<ExtraFieldsProps> = ({
  addressLine1Value,
  addressLine2Value,
  appTitle = '',
  cityValue,
  countryValue,
  dateOfBirthValue,
  layout2Column = false,
  missingAddressFields = false,
  phoneNumberValue,
  postalCodeValue,
  progressActivityNumber,
  setAddressLine1Value,
  setAddressLine2Value,
  setCityValue,
  setCountryValue,
  setDateOfBirthValue,
  setPhoneNumberValue,
  setPostalCodeValue,
  setStateValue,
  stateValue,
}): JSX.Element => {
  // ---------------------------------------------------------------------------
  // Local State

  const [countries, setCountries] = useState<SelectOption[]>([]);
  const [states, setStates] = useState<SelectOption[]>([]);
  const [topCountriesCount, setTopCountriesCount] = useState(0);
  const [isValidBirthday, setIsValidBirthday] = useState(true);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');
  const [calloutDimensions, setCalloutDimensions] = useState<{
    x: number;
    y: number;
  }>({ ...DEFAULT_CALLOUT_POSITION });
  const [showCallout, setShowCallout] = useState(false);
  const [progressBarCallout, setShowProgressBarCallout] = useState(false);

  // ---------------------------------------------------------------------------
  // Callbacks

  const checkBirthdayValidation = (): void => {
    // check if your birthday is less than current year and greater than this date 01/01/1900
    const now = moment().format();
    const currentDate = moment(dateOfBirthValue, 'MM/DD/YYYY').format();
    const lowestDate = moment('01/01/1900', 'MM/DD/YYYY').format();

    if (currentDate < now && currentDate > lowestDate) {
      setIsValidBirthday(
        moment(dateOfBirthValue, 'MM/DD/YYYY', true).isValid()
      );
    } else {
      setIsValidBirthday(false);
    }

    // check if your birthday value is empty set valid birthday to true
    if (!dateOfBirthValue) {
      setIsValidBirthday(true);
    }
  };

  // check if the phone number is between 10 to 15 digits
  const isPhoneValid = (): void => {
    checkPhoneValidation(phoneNumberValue)
      ? setPhoneErrorMessage('')
      : setPhoneErrorMessage('Please enter a valid phone number');
  };

  const dismissProgressBarCallout = (): void => {
    AsyncStorage.setItem('progress-bar-callout-hidden', 'true');
    setShowProgressBarCallout(false);
  };

  useEffect((): void => {
    const fetchCallouts = async (): Promise<void> => {
      if (progressActivityNumber < PERCENT_IN_WHOLE) {
        AsyncStorage.setItem('progress-bar-callout-hidden', 'false');
        setShowProgressBarCallout(true);
      } else {
        const callout = await AsyncStorage.getItem(
          'progress-bar-callout-hidden'
        );

        callout === 'true'
          ? setShowProgressBarCallout(false)
          : setShowProgressBarCallout(true);
      }
    };
    fetchCallouts();
  }, [progressActivityNumber]);

  const onLayout = (event: any): void => {
    const dimensions = event.nativeEvent.layout;
    setCalloutDimensions({
      x: Math.round(dimensions.x + 10),
      y: Math.round(dimensions.y + dimensions.height),
    });
  };

  useEffect((): void => {
    // retrieve a list of countries
    getAllCountries()
      .then(({ data }) => {
        const allCountries = data.countries;
        /** 90+% of users are from these countries, so they're at the top. */
        const topOfListCountries = [
          allCountries.find((c) => c.code === 'US'),
          allCountries.find((c) => c.code === 'CA'),
        ] as Country[];

        setTopCountriesCount(topOfListCountries.length);

        const orderedCountries = topOfListCountries.concat(
          allCountries.filter((c) => !topOfListCountries.includes(c))
        );

        setCountries(
          orderedCountries.map((country) => ({
            label: country.name,
            value: country.code,
          }))
        );
        setStates([]);
      })
      .catch((err) => {
        debug(err);
        setCountries([]);
      });
  }, []);

  useEffect((): void => {
    setStates([]);
    // retrieve a list of states based on what country is selected
    if (countryValue) {
      getAllSubdivisions(countryValue)
        .then(({ data }) => {
          const stateData = data.subdivisions.map(
            (subdivision: Subdivision) => ({
              label: subdivision.name,
              value: subdivision.code,
            })
          );
          setStates(stateData);
        })
        .catch((err) => {
          debug(err);
          setStates([]);
        });
    } else {
      setStateValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  return (
    <>
      {(showCallout || progressBarCallout) && (
        <View
          style={[styles.borderLine, { marginTop: Spacing.xl }]}
          testID='TopBorderline'
        />
      )}
      <View testID='GeneralInfoContainer'>
        {showCallout && (
          <KitCallout
            anchorX={calloutDimensions.x}
            anchorY={calloutDimensions.y}
            body={`Add your information to help ${appTitle} keep in touch.`}
            dismiss={() => setShowCallout(false)}
            title='Complete your profile'
          />
        )}
        {progressBarCallout && (
          <View
            style={styles.progressBarContainer}
            testID='ProgressBarContainer'
          >
            <View
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
              testID='ProgressBarWrapper'
            >
              <ProgressBar percent={progressActivityNumber} />
              <View
                style={{ marginLeft: Spacing.l }}
                testID='CompleteProfileContainer'
              >
                <KitText
                  color={Colors.N900}
                  fontSize={16}
                  semiBold
                  style={{ lineHeight: 24 }}
                  testID='CompleteYourProfile'
                >
                  Complete your profile
                </KitText>
                <KitText gray fontSize={16} testID='ProgressActivityNumber'>
                  {progressActivityNumber}%
                </KitText>
              </View>
            </View>

            {progressActivityNumber === PERCENT_IN_WHOLE ? (
              <KitTouchable
                onPress={() => dismissProgressBarCallout()}
                style={{ borderRadius: 20, marginRight: Spacing.l }}
                ripple={false}
                underlayColor={Colors.N50}
              >
                <KitIcon name='remove' size={20} color={Colors.N300} />
              </KitTouchable>
            ) : (
              <KitTouchable
                onLayout={onLayout}
                onPress={() => setShowCallout(true)}
                ripple={false}
                style={{ borderRadius: 20, marginRight: Spacing.l }}
                underlayColor={Colors.N50}
              >
                <KitIcon name='info' size={20} color={Colors.N300} />
              </KitTouchable>
            )}
          </View>
        )}
      </View>
      <View testID='ExtraFieldsContainer'>
        {/* TODO: turn these into an HOC which wraps a component with a error message popup. */}
        <Show show={!isValidBirthday}>
          <View
            style={[
              styles.errorLabel,
              layout2Column ? { left: '51%', top: 130 } : { top: 218 },
            ]}
            testID='BirthdayErrorMessageContainer'
          >
            <KitText
              fontSize={14}
              bold
              style={{ color: Colors.N0 }}
              testID='BirthdayErrorMessage'
            >
              Please enter a valid date
            </KitText>
          </View>
        </Show>
        <Show show={Boolean(phoneErrorMessage)}>
          <View style={styles.errorLabel} testID='PhoneErrorMessageContainer'>
            <KitText
              fontSize={14}
              bold
              style={{ color: Colors.N0 }}
              testID='PhoneErrorMessage'
            >
              {phoneErrorMessage}
            </KitText>
          </View>
        </Show>

        <KitText
          bold
          fontSize={16}
          color={Colors.N900}
          style={{ marginTop: Spacing.xl }}
          testID='PersonalDetails'
        >
          Personal details
        </KitText>

        <Stack
          spacing={Spacing.l}
          style={{ marginBottom: Spacing.xl }}
          testID='Stack'
        >
          <View
            style={{
              ...(layout2Column && { flexDirection: 'row' }),
              marginTop: Spacing.l,
            }}
            testID='PhoneBirthdayContainer'
          >
            <KitInput
              isValid={!phoneErrorMessage}
              keyboardType='numeric'
              label='Phone number'
              onChangeText={setPhoneNumberValue}
              onBlur={() => {
                isPhoneValid();
                setPhoneNumberValue(formatPhoneNumber(phoneNumberValue));
              }}
              style={{
                ...(layout2Column
                  ? { flex: 1, marginRight: Spacing.l }
                  : { marginBottom: Spacing.l }),
              }}
              testID='PhoneNumber'
              value={phoneNumberValue}
            />
            <View
              style={{ ...(layout2Column && { flex: 1 }) }}
              testID='BirthdayContainer'
            >
              <KitText semiBold style={styles.label} testID='BirthdayLabel'>
                Birthday
              </KitText>
              <KitInputMask
                isValid={isValidBirthday}
                keyboardType='numeric'
                onBlur={() => checkBirthdayValidation()}
                onChangeText={(masked) => {
                  setDateOfBirthValue(masked);
                }}
                placeholder='MM/DD/YYYY'
                value={dateOfBirthValue}
                testID='Birthday'
              />
            </View>
          </View>
          <KitText
            bold
            fontSize={16}
            color={Colors.N900}
            style={{ marginTop: 6 }}
            testID='Label Address'
          >
            Address
          </KitText>
          <KitSelect
            dividerIndex={topCountriesCount}
            isValid={!missingAddressFields || Boolean(countryValue)}
            items={countries}
            label='Country / region'
            onValueChange={(value) => setCountryValue(value.value)}
            placeholder='Country / region'
            value={countryValue}
            testID='Country'
          />
          <KitInput
            isValid={!missingAddressFields || Boolean(addressLine1Value)}
            label='Address'
            onChangeText={setAddressLine1Value}
            testID='Address1'
            value={addressLine1Value}
          />
          <KitInput
            label='Apartment, suite, etc. (optional)'
            onChangeText={setAddressLine2Value}
            testID='Address2'
            value={addressLine2Value}
          />
          <KitInput
            isValid={!missingAddressFields || Boolean(cityValue)}
            label='City'
            onChangeText={setCityValue}
            testID='City'
            value={cityValue}
          />
          <View
            style={{ ...(layout2Column && { flexDirection: 'row' }) }}
            testID='StateAndPostalCodeContainer'
          >
            <View
              style={{
                ...(layout2Column
                  ? { flex: 1, marginRight: Spacing.l }
                  : { marginBottom: Spacing.l }),
              }}
              testID='StateContainer'
            >
              <KitSelect
                isValid={
                  !missingAddressFields ||
                  stateIsPresentIfCountryRequiresIt(countryValue, stateValue)
                }
                items={states}
                label='State / province'
                onValueChange={(value) => setStateValue(value.value)}
                placeholder='State / province'
                value={stateValue}
                testID='State'
              />
            </View>
            <KitInput
              isValid={!missingAddressFields || Boolean(postalCodeValue)}
              label='ZIP / postal code'
              onChangeText={setPostalCodeValue}
              style={{ ...(layout2Column && { flex: 1 }) }}
              testID='PostalCode'
              value={postalCodeValue}
            />
          </View>
        </Stack>
        <View
          style={[styles.borderLine, { marginBottom: Spacing.xl }]}
          testID='BottomBorderLine'
        />
      </View>
    </>
  );
};

export default ExtraFields;

const styles = StyleSheet.create({
  borderLine: {
    borderTopColor: Colors.N100,
    borderTopWidth: 1,
  },
  errorLabel: {
    backgroundColor: Colors.R500,
    borderRadius: Spacing.s,
    marginTop: Spacing.s,
    paddingBottom: Spacing.s,
    paddingLeft: Spacing.l,
    paddingRight: Spacing.l,
    paddingTop: Spacing.s,
    position: 'absolute',
    top: 130,
    zIndex: 2,
  },
  label: {
    color: Colors.N500,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 18,
    marginBottom: Spacing.s,
  },
  progressBarContainer: {
    alignItems: 'center',
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Spacing.xl,
    paddingBottom: Spacing.xl,
  },
});
