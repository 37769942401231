import { KitText } from '@omni/kit/components';
import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import KitAvatar from '@omni/kit/components/KitAvatar';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { Sender } from '@sendbird/chat/message';
import React, { useState } from 'react';
import { View } from 'react-native';

export default function UserActionSheetWeb({
  user,
  onReportUser,
  onBlockUser,
}: {
  user: Sender | null;
  onReportUser: () => void;
  onBlockUser: () => void;
}): JSX.Element | null {
  const [actionItemIsHovered, setActionItemIsHovered] =
    useState<boolean>(false);
  const [blockActionItemIsHovered, setBlockActionItemIsHovered] =
    useState<boolean>(false);

  if (!user) {
    return null;
  }

  const hoverActionItem = () => setActionItemIsHovered(true);
  const unhoverActionItem = () => setActionItemIsHovered(false);

  const blockHoverActionItem = () => setBlockActionItemIsHovered(true);
  const blockUnhoverActionItem = () => setBlockActionItemIsHovered(false);

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: Spacing.l,
          paddingHorizontal: Spacing.l,
        }}
      >
        <KitAvatar
          imageUrl={user.plainProfileUrl}
          nickname={user.nickname}
          size={64}
        />
        <KitText black bold fontSize={22} style={{ marginLeft: Spacing.l }}>
          {user.nickname}
        </KitText>
      </View>
      <div onMouseEnter={hoverActionItem} onMouseLeave={unhoverActionItem}>
        <ActionSheetItem
          icon='flag'
          iconColor={Colors.N900}
          iconSize={20}
          label='Report'
          onPress={onReportUser}
          hovered={actionItemIsHovered}
        />
      </div>
      <div
        onMouseEnter={blockHoverActionItem}
        onMouseLeave={blockUnhoverActionItem}
      >
        <ActionSheetItem
          icon='block'
          iconColor={Colors.R500}
          iconSize={20}
          label='Block'
          onPress={onBlockUser}
          hovered={blockActionItemIsHovered}
        />
      </div>
    </View>
  );
}
