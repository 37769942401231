import { KitIcon, KitText } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';

interface IProps {
  iconName: IconName;
  iconSize: number;
  label: string;
  onPress: () => void;
}

export default ({
  iconName,
  iconSize,
  label,
  onPress,
}: IProps): JSX.Element => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.action}>
      <KitIcon color={Colors.brand} name={iconName} size={iconSize} />
      <KitText
        black
        fontSize={Platform.OS === 'web' ? 16 : 18}
        style={styles.actionText}
      >
        {label}
      </KitText>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  action: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: Platform.OS === 'web' ? Spacing.l : 0,
    paddingVertical: Platform.OS === 'web' ? Spacing.s : Spacing.l,
  },
  actionText: {
    marginLeft: Spacing.m,
  },
});
