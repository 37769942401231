import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IJoinRequest } from '../Types';

const RESOURCE = 'join-request';

interface IUpdateJoinRequest extends Omit<IUri, 'resource'> {
  id: string;
  status: 'approved' | 'denied';
  token: string;
}

interface IUpdateJoinRequestResponse extends IHttpResponse {
  body?: IJoinRequest;
}

export default ({
  id,
  status,
  token,
}: IUpdateJoinRequest): Promise<IUpdateJoinRequestResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
    id,
  });

  const data = {
    status,
  };

  return BaseServiceV2.Patch({ url, token, data });
};
