import { SUBSPLASH_AUTH_PROVIDER_ID, useShellContext } from '@omni/kit';
import { CredentialProps } from '@omni/kit/auth';
import { LeftNavType } from '@omni/kit/components/LeftNavButton';
import {
  IFavoriteApp,
  useFavoriteAppsContext,
} from '@omni/kit/contexts/FavoriteAppsContext';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { IModuleCommand } from '@omni/kit/contexts/types';
import { DOMAIN_GROUPS } from '@omni/kit/services/SearchService/Constants';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { setReactNativeAppIsAtRoot } from '@omni/kit/utilities/NativeHelpers';
import getRouteRootPath from '@omni/kit/utilities/getRouteRootPath.web';
import { capitalizeFirstLetter } from '@omni/kit/utilities/utilities';
import {
  NavigationContainer,
  NavigationContainerRef,
  NavigationState,
  PartialState,
  PathConfigMap,
  RouteProp,
  getPathFromState,
} from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import pluralize from 'pluralize';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { StackPresentationTypes } from 'react-native-screens';
import { NativeStackNavigationOptions } from 'react-native-screens/native-stack';

import {
  COMMAND_NAVIGATE_BROWSE,
  COMMAND_NAVIGATE_RESULT,
  COMMAND_NAVIGATE_SEARCH,
  COMMAND_NAVIGATE_TOUR,
} from './Constants';
import HeaderLeft from './components/HeaderLeft';
import useInitialRouteName from './hooks/useInitialRouteName';
import BrowseScreen from './screens/Browse';
import ResultScreen from './screens/Result';
import SearchTabs from './screens/SearchTabs';
import TourScreen from './screens/Tour';
import { Domain, ISearchRootProps, ResultScreenRouteProp } from './types';
import { Stack } from './utils/stacks';

const debug = require('debug')('tca:search:App.txs');

export default (props: ISearchRootProps): JSX.Element | null => {
  const { app } = useShellContext();
  const rootApp = useRootAppContext();
  const { favoriteApps } = useFavoriteAppsContext();
  const initialRouteName = useInitialRouteName(rootApp);
  const { t } = useTranslation(['search']);

  const navigationRef = useRef<NavigationContainerRef | null>(null);

  const credentialProps: CredentialProps = {
    appKey: props.appKey || '',
    authProviderId: props.authProviderId || SUBSPLASH_AUTH_PROVIDER_ID,
    accessToken: props.accessToken,
    sapToken: props.sapToken,
    tokenUrl: props.tokenUrl,
  };

  /**
   * Get appKey from favorite apps and active app when using
   * `navigate_result` module command
   */
  let moduleCommand = props.moduleCommand;

  if (!moduleCommand?.appFilterValue) {
    moduleCommand = {
      ...(moduleCommand as IModuleCommand),
      appFilterValue: getDefaultAppFilterValue(favoriteApps, props.appKey),
    };
  }

  /**
   * suppress top nav back|dismiss button when user
   * has not yet selected a church/site/campus in a container app
   */
  const rootAppSelected = rootApp?.is_container && rootApp?.id === app?.appKey;
  const headerShown =
    !rootAppSelected &&
    props.headerShown !== false &&
    // DISP-5495: moduleCommand.suppressHeader is used in container app screenshot automation
    !moduleCommand?.suppressHeader;
  const isNativeNavStackAtRoot = props.isNativeNavStackAtRoot;

  /**
   * Presentation
   */
  const stackPresentation: StackPresentationTypes =
    props.headerShown === false || props.presentationStyle === 'modal'
      ? 'fullScreenModal'
      : 'push';

  /**
   * Native app needs to be informed whether react-native app
   * is at the 'root' level of the navigator in order to decide
   * whether to show a header back or dismiss button.
   * The root level screens of the search app are: Tour and Search.
   *
   * In testing, a useEffect with a dependency on'currentRoute' did not work as expected,
   * so we are currently using 'onStateChange' callback method for this behavior.
   * @param state
   */
  const _onStateChange = (state: NavigationState | undefined) => {
    const currentRoute = navigationRef.current?.getCurrentRoute()?.name;
    setReactNativeAppIsAtRoot(
      !currentRoute || currentRoute === 'Tour' || currentRoute === 'Search'
    );
  };

  const getInitialRouteName = () => {
    switch (moduleCommand?.name) {
      case COMMAND_NAVIGATE_BROWSE:
        return 'Browse';
      case COMMAND_NAVIGATE_RESULT:
        return 'Result';
      case COMMAND_NAVIGATE_SEARCH:
        return 'Search';
      case COMMAND_NAVIGATE_TOUR:
        return 'Tour';
      default:
        return initialRouteName || undefined;
    }
  };

  if (initialRouteName === null) {
    return null;
  }

  return (
    <NavigationContainer
      documentTitle={{ enabled: false }}
      ref={navigationRef}
      onStateChange={_onStateChange}
      linking={{
        enabled: Platform.OS === 'web' && props.enableLinking !== false,
        prefixes: [],
        config: {
          screens: {
            Result: `${getRouteRootPath()}/${props.pathPrefix}/:path/:id`,
            Search: `${getRouteRootPath()}/${props.pathPrefix}/:path`,
          },
        },
        // @ts-ignore
        getPathFromState(
          state: NavigationState | Omit<PartialState<NavigationState>, 'stale'>,
          config: {
            initialRouteName?: string;
            screens: PathConfigMap;
          }
        ): string {
          const path = getPathFromState(state, config);

          const rootPath = getRouteRootPath();

          /**
           * By default, react-navigation encodes uri components,
           * convert: rev%3Asome-slug -> rev:some-slug
           */
          if (rootPath.length > 1) {
            const rawString = rootPath.replaceAll('/', '');
            const encodedString = encodeURIComponent(rawString);

            return path.replaceAll(encodedString, rawString);
          }

          return path;
        },
      }}
      independent
    >
      <Stack.Navigator
        initialRouteName={getInitialRouteName()}
        screenOptions={{
          headerShown: Platform.OS !== 'web',
          headerHideShadow: true,
          headerBackTitleVisible: false,
          headerTintColor: Colors.N1000,
          headerTopInsetEnabled: false, // react-native-screens (Android)
          stackPresentation: stackPresentation,
          ...Platform.select({
            web: {
              cardStyle: {
                backgroundColor: Colors.N0,
              },
            },
          }),
          statusBarStyle: 'dark', // react-native-screens
        }}
      >
        <Stack.Screen
          name='Tour'
          options={TourScreenOptions}
          component={TourScreen}
        />
        <Stack.Screen
          name='Search'
          options={SearchScreenOptions(
            headerShown,
            props.headerTitle ?? t('headerTitle'),
            stackPresentation,
            isNativeNavStackAtRoot,
            props.domains
          )}
        >
          {(stackScreenProps) => (
            <SafeAreaView
              style={{
                flex: 1,
                width: '100%',
                backgroundColor: Colors.N0,
              }}
              edges={
                headerShown ? ['left', 'right'] : ['left', 'right', 'top'] // must apply top inset when header is missing
              }
            >
              <SearchTabs
                {...stackScreenProps}
                domains={props.domains}
                {...moduleCommand}
              />
            </SafeAreaView>
          )}
        </Stack.Screen>
        <Stack.Screen
          name='Browse'
          component={BrowseScreen}
          options={BrowseScreenOptions(
            stackPresentation,
            moduleCommand?.name === COMMAND_NAVIGATE_BROWSE
          )}
          initialParams={{ ...moduleCommand }}
        />
        <Stack.Screen
          name='Result'
          options={ResultScreenOptions(
            stackPresentation,
            moduleCommand?.name === COMMAND_NAVIGATE_RESULT
          )}
          initialParams={{ ...moduleCommand }}
        >
          {(stackScreenProps) => (
            <ResultScreen
              {...(stackScreenProps as { route: ResultScreenRouteProp })}
              appKey={props.appKey}
              credentialProps={credentialProps}
            />
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export const ResultScreenOptions =
  (stackPresentation: StackPresentationTypes, dismissApp: boolean) =>
  ({
    navigation,
    route,
  }: {
    navigation: { goBack: () => void };
    route: RouteProp<
      Record<string, { data?: { type: string; title: string } } | undefined>,
      string
    >;
  }): NativeStackNavigationOptions => {
    const data = route.params?.data;

    if (data?.type === 'speaker') {
      return {
        headerShown: false,
      };
    }

    const title =
      data?.type !== 'group' ? capitalizeFirstLetter(data?.title) : '';

    const type =
      stackPresentation === 'push' ? LeftNavType.Back : LeftNavType.Dismiss;

    const style =
      stackPresentation === 'fullScreenModal'
        ? {
            marginLeft: Spacing.s,
          }
        : {};

    return {
      title,
      ...headerTitleStyle(),
      ...HeaderLeft({
        navBack: navigation.goBack,
        dismissApp,
        title,
        type,
        style,
      }),
    };
  };

const BrowseScreenOptions =
  (stackPresentation: StackPresentationTypes, dismissApp: boolean) =>
  ({
    navigation,
    route,
  }: {
    navigation: { goBack: () => void };
    route: RouteProp<Record<string, { tagType?: string } | undefined>, string>;
  }): NativeStackNavigationOptions => {
    const tagType = route.params?.tagType;

    const title = capitalizeFirstLetter(
      tagType && tagType !== 'scripture' ? pluralize(tagType) : tagType
    );

    const type =
      stackPresentation === 'push' ? LeftNavType.Back : LeftNavType.Dismiss;

    const style =
      stackPresentation === 'fullScreenModal'
        ? {
            marginLeft: Spacing.s,
          }
        : {};

    return {
      title,
      ...headerTitleStyle(),
      ...HeaderLeft({
        navBack: navigation.goBack,
        dismissApp,
        title,
        type,
        style,
      }),
    };
  };

const SearchScreenOptions =
  (
    headerShown: boolean,
    headerTitle: string,
    stackPresentation: StackPresentationTypes,
    isNativeNavStackAtRoot?: boolean,
    domains?: Domain[]
  ) =>
  ({ navigation }: { navigation: { goBack: () => void } }) => {
    if (headerShown) {
      const isDismissButton = isNativeNavStackAtRoot === true;

      const title = domains?.includes(DOMAIN_GROUPS) ? 'Groups' : 'Search';

      const style =
        stackPresentation === 'fullScreenModal'
          ? {
              marginLeft: Spacing.s,
            }
          : {};

      const type = isNativeNavStackAtRoot
        ? LeftNavType.Dismiss
        : LeftNavType.Back;

      return {
        title: headerTitle,
        ...headerTitleStyle(),
        ...HeaderLeft({
          dismissApp: isDismissButton,
          navBack: navigation.goBack,
          title,
          isNativeNavStackAtRoot,
          style,
          type,
        }),
      };
    }

    return {
      /** common */
      title: '',
      headerShown: false,
    };
  };

const headerTitleStyle = () => {
  return {
    headerTitleStyle: {
      fontSize: Platform.OS === 'ios' ? 17 : 18,
      fontWeight: 'bold',
      ...(Platform.OS === 'web' ? { fontFamily: 'inherit' } : undefined),
    },
  };
};

const TourScreenOptions = (): NativeStackNavigationOptions &
  StackNavigationOptions => {
  return {
    /** common */
    title: '',
    headerShown: false,

    /* native: NativeStackNavigationOptions */
    stackAnimation: 'none',

    /* web: StackNavigationOptions */
    animationEnabled: false,
    gestureEnabled: false,
  };
};

const getDefaultAppFilterValue = (
  favoriteApps: IFavoriteApp[],
  appKey?: string
) => {
  let appKeys = favoriteApps.map((app: IFavoriteApp) => app.appkey).join(',');

  if (appKey && !appKeys.includes(appKey)) {
    appKeys = appKeys ? `${appKeys},${appKey}` : appKey;
  }

  return appKeys;
};
