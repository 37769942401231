import SendbirdChat from '@sendbird/chat';
import {
  GroupChannelListQuery,
  PublicGroupChannelListQuery,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import { useEffect, useState } from 'react';
import { AppState, NativeEventSubscription, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  createChannelListHandler,
  getGroupChannelListThunk,
  getPublicChannelListThunk,
} from '../shared/redux/actions/SystemActions';
import { userSelector } from '../shared/redux/selectors';
import { sbCreateGroupChannelListQuery } from '../utilities/sendbird/channelFunctions';
import { updateUnread } from '../utilities/sendbird/chatFunctions';
import { sbIsConnected } from '../utilities/sendbird/userFunctions';

const debug = require('debug')('omni:messaging:kit:hooks:useChannelList');

export default function useChannelList(): void {
  const [groupChannelListQuery, setGroupChannelListQuery] = useState<any>(null);
  const [publicChannelListQuery, setPublicChannelListQuery] =
    useState<any>(null);

  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    let listener: NativeEventSubscription | undefined;

    if (Platform.OS !== 'web') {
      listener = AppState.addEventListener('change', _handleAppStateChange);
    }

    (
      SendbirdChat.instance as SendbirdGroupChat
    )?.groupChannel.removeAllGroupChannelHandlers();

    if (
      user &&
      sbIsConnected() &&
      !groupChannelListQuery &&
      !publicChannelListQuery
    ) {
      _getGroupChannelList(true);
      _getPublicChannelList(true);
      dispatch(createChannelListHandler());
    }

    return () => {
      if (Platform.OS !== 'web') {
        listener?.remove();
      }

      (
        SendbirdChat.instance as SendbirdGroupChat
      )?.groupChannel.removeAllGroupChannelHandlers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const _getGroupChannelList = (init = false): void => {
    if (init) {
      const query = sbCreateGroupChannelListQuery() as GroupChannelListQuery;
      setGroupChannelListQuery(query);
      dispatch(getGroupChannelListThunk(query));
    } else {
      dispatch(getGroupChannelListThunk(groupChannelListQuery));
    }
  };

  const _getPublicChannelList = (init = false): void => {
    if (init) {
      const query = sbCreateGroupChannelListQuery(
        true
      ) as PublicGroupChannelListQuery;
      setPublicChannelListQuery(query);
      dispatch(getPublicChannelListThunk(query));
    } else {
      dispatch(getPublicChannelListThunk(publicChannelListQuery));
    }
  };

  const _handleAppStateChange = (nextAppState: string): void => {
    if (nextAppState === 'active') {
      debug('ChannelListScreen is in the foreground');
      _getGroupChannelList(true);
      _getPublicChannelList(true);
    } else if (nextAppState === 'background') {
      debug('ChannelListScreen is in the background');

      /**
       * This is needed to update the iOS app badge number for the following
       * scenarios:
       * - A user sent app to background after receiving new messages on the
       *    channel list screen
       * - A user sent app to background after opening a chat thread and
       *    receiving messages from one of the other chat threads
       * - A user sent app to background quickly after opening a chat thread
       *    with unread messages
       */
      updateUnread();
    }
  };
}
