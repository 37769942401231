import Colors from '@omni/kit/theming/Colors';
import React, { useRef } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Popup from 'reactjs-popup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WebModal({
  button = null,
  // @ts-ignore
  children,
  contentStyle = {},
  clearStyles = false,
  ...props
}) {
  const modalRef = useRef();

  const dynamicProps = {
    ...(button
      ? {
          trigger: (
            <button
              className='button'
              style={{
                borderWidth: 0,
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              {button}
            </button>
          ),
        }
      : {}),
  };

  return (
    <Popup
      // @ts-ignore
      ref={modalRef}
      modal
      nested
      overlayStyle={{ backgroundColor: '#00000040' }}
      {...dynamicProps}
      {...props}
    >
      <View style={[!clearStyles ? styles.modalCard : {}, contentStyle]}>
        {children}
      </View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  modalCard: {
    shadowColor: Colors.N1000,
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 9,
    backgroundColor: Colors.N0,
    borderRadius: 10,
    // @ts-ignore
    width: '90vw',
    // @ts-ignore
    height: '100vh',
    maxWidth: 650,
    maxHeight: 600,
    padding: 10,
    overflow: 'hidden',
  },
});
