import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import React from 'react';
import { NativeModules } from 'react-native';

import { IBackButton } from './types';

export default ({ title = '', onPress, type }: IBackButton): JSX.Element => {
  return (
    <LeftNavButton
      title={title}
      titleStyle={{ fontWeight: '700', fontSize: 18 }}
      onPress={onPress}
      type={type}
    />
  );
};
