import { useShellContext } from '@omni/kit';
import { KitLoader, KitText } from '@omni/kit/components';
import KitNotificationsPrompt from '@omni/kit/components/KitNotificationsPrompt';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { colorForScheme } from '@omni/kit/theming/Theming';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { useTransformGroupEvents } from '../../../hooks/useTransformGroupEvents';
import GroupAllListEvents from './components/GroupAllListEvents';

interface Props {
  navigation: any;
  route: any;
}
export default function GroupEventListScreen({
  route,
  navigation,
}: Props): JSX.Element {
  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user || null, [tokens.user]);
  const [isLoadingGroupEventList, setIsLoadingGroupEventList] =
    useState<boolean>(false);
  const transformedGroupEvents = useTransformGroupEvents(
    route.params.groupEventList ? route.params.groupEventList : []
  );
  const userToken = accessToken?.replace('Bearer ', '');
  const { sizeClassV2 } = useSizeClass();
  const addSpacing = sizeClassV2 >= SizeClassV2.S;
  const { edgeSpacing } = useScreenContext({
    fixedSpacingType: SpacingType.None,
  });

  return (
    <ScrollView
      style={[
        styles.container,
        {
          backgroundColor: colorForScheme({
            default: Colors.N0,
          }),
        },
      ]}
    >
      {isLoadingGroupEventList ? (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <KitLoader />
        </View>
      ) : (
        <GroupAllListEvents
          sections={transformedGroupEvents}
          onRefresh={() => {
            setIsLoadingGroupEventList(true);
          }}
          isRefreshing={isLoadingGroupEventList}
          accessToken={accessToken}
          userToken={userToken}
        />
      )}
    </ScrollView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
});
