import TokensService from '@omni/kit/services/TokensService';

import { IOpenChannelsParticipants, prepareUrl } from '..';
import BaseServiceV2, { IHttpResponse, IUri } from '../../BaseServiceV2';

const RESOURCE = 'open-channel-participants';

interface IGetOpenChannelParticipants extends Omit<IUri, 'resource'> {
  appKey: string;
}

interface IGetOpenChannelParticipantsResponse extends IHttpResponse {
  body?: IOpenChannelsParticipants;
}

export default async ({
  appKey,
  ...buildUrlProps
}: IGetOpenChannelParticipants): Promise<IGetOpenChannelParticipantsResponse> => {
  const url = prepareUrl({
    ...buildUrlProps,
    resource: RESOURCE,
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({ url, appKey, token: guestToken });
};
