import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Platform, TextProps, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styled from 'styled-components/native';

import { KitTouchable } from '../components';
import BorderRadius from '../theming/BorderRadius';
import Colors from '../theming/Colors';
import { ThemeContextType, useThemeContext } from '../theming/ThemeContext';
import { hexToRGBA } from '../utilities/colorTools';

const serifIosFont = 'NewYorkMedium-Regular';
const serifRegularAndroidFont = 'NotoSerif-Regular';
const serifBoldAndroidFont = 'NotoSerif-Bold';
const semiBoldAndroidFont = 'Roboto-Medium';
const boldAndroidFont = 'Roboto-Bold';
const extraBoldAndroidFont = 'Roboto-Black';
const webFont = 'inherit'; // In case of web inherit font-family from root element (html, body)

//******************************************************************************
// Types
//******************************************************************************
export interface IKitTextProps extends TextProps {
  testID?: string;

  // Properties
  bold?: boolean; // 700
  center?: boolean;
  expandable?: boolean;
  extraBold?: boolean; // 800
  fontSize?: number;
  fontFamily?: string;
  fontWeight?: number;
  semiBold?: boolean; // 600
  serif?: boolean;
  lineHeight?: number;
  fadeColor?: string;

  // Colors
  black?: boolean;
  brandColor?: boolean;
  color?: string;
  gray?: boolean;
  white?: boolean;

  // Styles
  body1?: boolean;
  h1?: boolean;
  h2?: boolean;
  subtitle?: boolean;

  // Margin
  marginValue?: string;

  colorForScheme?: any;
}

const androidReadyFont = (
  { fontFamily, h1, h2, semiBold, extraBold, bold }: IKitTextProps,
  theme: ThemeContextType
): string | undefined => {
  if (Platform.OS === 'android') {
    const { androidWeights: weights } = theme;

    if (weights) {
      if (weights?.extraBold && (h1 || extraBold)) return weights.extraBold;
      else if (weights?.bold && (h2 || bold)) return weights.bold;
      else if (weights?.semiBold && semiBold) return weights.semiBold;
      else if (weights?.regular) return weights?.regular;
    }

    if (h1 || extraBold) return extraBoldAndroidFont;
    else if (h2 || semiBold) return semiBoldAndroidFont;
    else if (bold) return boldAndroidFont;
  } else if (Platform.OS === 'web') {
    return fontFamily || webFont;
  } else {
    return fontFamily || theme.fontFamily;
  }
};

const KitText = (props: IKitTextProps): JSX.Element => {
  const theme = useThemeContext();
  const { colorForScheme, fontFamily: themeFontFamily, androidWeights } = theme;
  const [isClipped, setIsClipped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const ref = useRef<HTMLElement>();
  const { fontFamily = themeFontFamily, style = {} } = props;

  useLayoutEffect(() => {
    // Web only
    if (
      Platform.OS === 'web' &&
      props.expandable &&
      ref?.current !== undefined
    ) {
      setIsClipped(
        ref.current.getBoundingClientRect().height < ref.current.scrollHeight
      );
    }
  }, [props.expandable]);

  const textElem = (
    <StyledText
      {...props}
      // @ts-ignore
      ref={ref}
      colorForScheme={colorForScheme}
      fontFamily={androidReadyFont(props, theme)}
      style={style}
      numberOfLines={isExpanded ? 0 : props.numberOfLines}
      // Native only
      onTextLayout={
        props.expandable
          ? (e) =>
              setIsClipped(
                props.numberOfLines !== undefined &&
                  e.nativeEvent.lines.length >= props.numberOfLines
              )
          : undefined
      }
    >
      {props.children}
    </StyledText>
  );

  if (props.expandable) {
    return (
      <View style={{ position: 'relative' }}>
        {textElem}
        <TruncateButton
          isExpanded={isExpanded}
          show={isClipped}
          onPress={() => setIsExpanded(!isExpanded)}
          fontFamily={fontFamily}
          fadeColor={props.fadeColor}
        />
      </View>
    );
  } else {
    return textElem;
  }
};

export default KitText;

/**
 * Button can appear either at the end of a line or at the following line
 */
export const TruncateButton = ({
  isExpanded,
  onPress,
  show,
  fontFamily,
  fadeColor,
  fontSize,
  lineHeight,
}: {
  isExpanded: boolean;
  onPress: () => void;
  show: boolean;
  fontFamily?: string;
  fadeColor?: string;
  fontSize?: number;
  lineHeight?: number;
}): JSX.Element | null => {
  const { colorForScheme } = useThemeContext();
  const { t } = useTranslation('common');
  const [r, g, b] = hexToRGBA(
    fadeColor ||
      colorForScheme?.({
        light: Colors.N0,
        dark: Colors.N1000,
      }) ||
      Colors.N0
  );

  if (!show) return null;

  const customFontFamily =
    Platform.OS === 'android' ? boldAndroidFont : fontFamily;

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 0.4, y: 0 }}
      locations={[0, Platform.OS === 'web' ? 0.35 : 1]}
      // @ts-ignore
      colors={[`rgba(${r}, ${g}, ${b}, 0)`, `rgba(${r}, ${g}, ${b}, 1)`]}
      style={
        !isExpanded
          ? {
              position: 'absolute',
              right: 0,
              bottom: 0,
              paddingLeft: 40,
            }
          : undefined
      }
    >
      <KitTouchable
        onPress={onPress}
        ripple={false}
        underlayColor={
          Platform.OS === 'android'
            ? colorForScheme?.({
                light: Colors.N100,
                dark: Colors.N900,
              })
            : ''
        }
        style={{
          borderRadius: BorderRadius.l,
        }}
      >
        <StyledText
          bold
          fontFamily={customFontFamily}
          colorForScheme={colorForScheme}
          color={colorForScheme?.({
            light: Colors.N900,
            dark: Colors.N0,
          })}
          style={{ textTransform: 'capitalize' }}
          fontSize={fontSize}
          lineHeight={lineHeight}
        >
          {isExpanded ? t('less') : t('more')}
        </StyledText>
      </KitTouchable>
    </LinearGradient>
  );
};

if (!Animated.Text.defaultProps) {
  Animated.Text.defaultProps = {};
}

Animated.Text.defaultProps.allowFontScaling = false;

const StyledText = styled(Animated.Text)`
  /* text-align */
  ${({ center }) => (center ? 'text-align: center;' : '')}

  margin: ${({ marginValue }: IKitTextProps) => {
    return marginValue ? marginValue : '0';
  }};

  font-size: ${({ fontSize, h1, h2, body1, subtitle }) => {
    if (fontSize) return fontSize;
    else if (h1) return 22;
    else if (h2) return 20;
    else if (subtitle) return 18;
    else if (body1) return 16;

    return 16;
  }};

  line-height: ${({ h1, h2, fontSize, lineHeight, body1, subtitle }) => {
    if (lineHeight) return lineHeight;
    else if (h1 || fontSize === 22) return 30;
    else if (h2 || fontSize === 20) return 24;
    else if (subtitle || fontSize === 18) return 22;
    else if (body1 || fontSize === 16) return 22;
    else if (fontSize && fontSize >= 24) {
      return Math.floor(fontSize * 1.2);
    } else return 22;
  }};

  /* Font-family */
  ${({
    fontFamily,
    extraBold,
    semiBold,
    h1,
    h2,
    bold,
    serif,
  }: IKitTextProps) => {
    let family;

    if (serif) {
      if (Platform.OS === 'web') family = webFont;
      else if (Platform.OS === 'ios') family = serifIosFont;
      else if (bold) family = serifBoldAndroidFont;
      else family = serifRegularAndroidFont;
    } else if (fontFamily) {
      family = fontFamily;
    }

    if (family) return `font-family: ${family}`;
  }};

  /* Font-weight */
  ${({ fontWeight, extraBold, bold, semiBold, h1, h2 }: IKitTextProps) => {
    /**
     * Android should not set font-weight since it needs font-family to be set
     * with the proper weight in the family name.
     */
    if (Platform.OS === 'android') return;

    let weight;

    if (fontWeight) weight = fontWeight;
    else if (h1 || extraBold) weight = 800;
    else if (h2 || bold) weight = 700;
    else if (semiBold) weight = 600;
    else weight = 400;

    return `font-weight: ${weight}`;
  }}

  color: ${({
    h1,
    h2,
    subtitle,
    color,
    black,
    white,
    brandColor,
    gray,
    colorForScheme,
  }: IKitTextProps) => {
    if (color) return color;
    else if (black) return Colors.N900;
    else if (white) return Colors.N0;
    else if (brandColor) return Colors.brand;
    else if (gray) return Colors.N500;
    else if (h1 || h2) {
      return (
        colorForScheme({
          light: Colors.N900,
          dark: Colors.N0,
        }) || Colors.N900
      );
    } else if (subtitle) {
      return (
        colorForScheme({ light: Colors.N900, dark: Colors.N100 }) || Colors.N900
      );
    } else {
      return (
        colorForScheme({ light: Colors.N500, dark: Colors.N400 }) || Colors.N500
      );
    }
  }};
`;
