import { HighlightedVersesObj } from '@omni/kit/components/KitScripture/types';
import PassageService from '@omni/kit/services/Bible/PassageService';
import TranslationService from '@omni/kit/services/Bible/TranslationService';
import { ContentReducerState } from '@omni/kit/types/bible';
import {
  BibleBook,
  BibleLocation,
  BibleSettings,
  VersionDetails,
} from '@omni/kit/types/bible';
import {
  retrieveGlobalDataFromDevice,
  saveGlobalDataToDevice,
} from '@omni/kit/utilities/bible';

const debug = require('debug')('tca:bible:api');
const debugBibleTesting = require('debug')('tca:bible:api:bible.js');

async function checkIfAudioAvailableForVersion(
  translation: string
): Promise<boolean | void> {
  try {
    const result = await PassageService.getAll({
      filters: [
        ['translation_code', translation],
        ['osis', 'Gen.1'],
      ],
    });

    return Boolean(result.body?._embedded.passages[0].audio);
  } catch (err) {
    console.warn(
      'message' in (err as { message?: string })
        ? (err as { message: string }).message
        : `Failed to check if audio is available for translation: ${translation}`
    );
  }
}

// TODO: this function should be removed, but it'll require changing a lot of other functions too
async function fetchBibleContent(
  bibleLocation: BibleLocation,
  bibleVersionId: string
): Promise<any> {
  try {
    debug('fetchBibleContent() -- start', bibleLocation);

    const translation = bibleVersionId;
    const location = `${bibleLocation.bookName}.${bibleLocation.chapter}`;

    debug('new translation', translation);

    const result = await PassageService.getAll({
      filters: [
        ['translation_code', translation],
        ['osis', location],
      ],
    });

    const bibleContent = result.body?._embedded.passages[0].content_html;

    return bibleContent;
  } catch (error) {
    debug('bibleLocation: ', bibleLocation);
    debug('bibleVersionId: ', bibleVersionId);
    debug('Error fetching Bible content: ', error);

    return null;
  }
}

export type DetailsAndBooksObj = {
  books: BibleBook[];
  versionDetails: Omit<VersionDetails, 'books' | 'hasAudio'>;
};

async function fetchVersionDetails(
  bibleVersionId: string,
  bibleApi: string
): Promise<DetailsAndBooksObj | null> {
  try {
    debug('fetchVersionDetails() -- start');

    let response = await TranslationService.getAll({
      filters: [['code', bibleVersionId.toLocaleLowerCase()]],
      getFromCache: true,
    });

    if (response.body) {
      debug('Cache is found');
    } else {
      debug('Cache is not found. Fetch request');
      response = await TranslationService.getAll({
        filters: [['code', bibleVersionId.toLocaleLowerCase()]],
      });
    }

    debug('RESPONSE', response);

    const translation = response.body?._embedded.translations[0];
    const {
      abbreviation = '',
      books = [],
      code = '',
      copyright = '',
      info = '',
      name = '',
    } = translation || {};
    const result: DetailsAndBooksObj = {
      versionDetails: { id: code, abbrev: abbreviation, name, copyright, info },
      books: books as unknown as BibleBook[],
    };

    return result;
  } catch (error) {
    debug('bibleVersionId: ', bibleVersionId);
    debug('bibleApi: ', bibleApi);
    debug('Error fetching Bible Version Details: ', error);

    return null;
  }
}

function retrieveBibleLocationAndContentFromDevice(): Promise<ContentReducerState> {
  return retrieveGlobalDataFromDevice(
    'bibleLocationAndContent'
  ) as Promise<ContentReducerState>;
}

function retrieveBibleLocationFromDevice(): Promise<BibleLocation> {
  return retrieveGlobalDataFromDevice(
    'bibleLocation'
  ) as Promise<BibleLocation>;
}

function retrieveHighlightedVersesFromDevice(): Promise<HighlightedVersesObj> {
  debugBibleTesting('retrieveHighlightedVersesFromDevice() - start');

  return retrieveGlobalDataFromDevice(
    'highlightedVerses'
  ) as Promise<HighlightedVersesObj>;
}

function retrieveReadingPlanProgressFromDevice(): Promise<
  Record<string, unknown>
> {
  return retrieveGlobalDataFromDevice('readingPlanProgress') as Promise<
    Record<string, unknown>
  >;
}

function saveBibleLocationAndContentToDevice(content: unknown): Promise<void> {
  return saveGlobalDataToDevice('bibleLocationAndContent', content);
}

function saveBibleSettingsToDevice(
  bibleSettings: Partial<BibleSettings>
): Promise<void> {
  return saveGlobalDataToDevice('bibleSettings', bibleSettings);
}

function saveHighlightedVersesToDevice(
  highlightedVerses: HighlightedVersesObj
): Promise<void> {
  return saveGlobalDataToDevice('highlightedVerses', highlightedVerses);
}

function saveReadingPlanProgressToDevice(progress: unknown): Promise<void> {
  return saveGlobalDataToDevice('readingPlanProgress', progress);
}

export {
  checkIfAudioAvailableForVersion,
  fetchBibleContent,
  fetchVersionDetails,
  // Mobile Storage (below)
  retrieveBibleLocationAndContentFromDevice,
  retrieveBibleLocationFromDevice,
  retrieveHighlightedVersesFromDevice,
  retrieveReadingPlanProgressFromDevice,
  saveBibleLocationAndContentToDevice,
  saveBibleSettingsToDevice,
  saveHighlightedVersesToDevice,
  saveReadingPlanProgressToDevice,
};
