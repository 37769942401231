import '@omni/kit/localization';

import versionData from './version.generated.json';

export {
  ShellContextProvider,
  WebShellContextProvider,
  useShellContext,
} from './contexts/ShellContext';

export { SUBSPLASH_AUTH_PROVIDER_ID } from './constants/identifiers';

export const { version, shortCommitHash } = versionData;

export { default as AuthProviderList } from './auth/AuthProviderList';
export { SourceContextProvider, SOURCE_TYPES } from './contexts/SourceContext';

export { createDeepLink } from './utilities/utilities';
