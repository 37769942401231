import Environment from '../../Environment';
import { buildUrl } from '../BaseServiceV2';
import { DEFAULT_PAGE_SIZE } from './Constants';
import {
  QUERY_TUPLE,
  SEARCH_QUERY_BOOLEAN,
  SEARCH_QUERY_STRING,
} from './Types';

const host = Environment.searchHost;

export interface IPrepareFacetsUrl {
  appFilterValue: string;
  page?: number;
  size?: number;
  tagType: string;
  facet?: string;
  sort?: string;
  include?: string | boolean;
}

export function prepareFacetsUrl({
  appFilterValue,
  page = 1,
  size = DEFAULT_PAGE_SIZE,
  tagType,
  facet = 'tags',
  sort = 'value',
  include,
}: IPrepareFacetsUrl): string {
  const queries: QUERY_TUPLE[] = [
    [SEARCH_QUERY_STRING.SIZE, `${size}`],
    [SEARCH_QUERY_STRING.START, `${(page - 1) * size}`],
    [SEARCH_QUERY_STRING.CHANNEL, 'mobile'],
    [SEARCH_QUERY_STRING.TYPE, tagType],
    [SEARCH_QUERY_STRING.FACET, facet],
    [SEARCH_QUERY_STRING.SORT, sort],
    appFilterValue.split(',').length > 1
      ? [SEARCH_QUERY_STRING.FQ, `{"app_keys":"${appFilterValue}"}`]
      : [SEARCH_QUERY_STRING.APP_KEY, appFilterValue],
  ];

  if (include) {
    if (typeof include === 'string') {
      queries.push([SEARCH_QUERY_STRING.INCLUDE, include]);
    }

    if (typeof include === 'boolean') {
      queries.push([SEARCH_QUERY_BOOLEAN.INCLUDE, include]);
    }
  }

  return buildUrl({
    host,
    resource: 'facets',
    queries,
  });
}
