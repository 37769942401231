import { KitText } from '@omni/kit/components';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import Colors from '@omni/kit/theming/Colors';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { User } from '@sendbird/chat';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBlockedUserList,
  setUnblockedUser,
} from '../../shared/redux/actions/ChatActions';
import { resolveChatUserThunk } from '../../shared/redux/actions/SystemActions';
import {
  blockedUserListSelector,
  unblockedUserSelector,
} from '../../shared/redux/selectors';
import { sbIsConnected } from '../../utilities/sendbird/userFunctions';
import BlockedUserListRow from '../components/BlockedUserListRow';
import MemberActionSheet from '../components/channelDetails/MemberActionSheet';

const debug = require('debug')('tca:chat:screen:ChatSettingsScreen');

//******************************************************************************
// Types
//******************************************************************************

export default function BlockedUserListScreen(): JSX.Element {
  const blockedUsers = useSelector(blockedUserListSelector);
  const unblockedUser = useSelector(unblockedUserSelector);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const connectAndLoad = async () => {
      await dispatch(resolveChatUserThunk());
      dispatch(getBlockedUserList());
    };

    if (sbIsConnected()) {
      dispatch(getBlockedUserList());
    } else {
      connectAndLoad();
    }
  }, [dispatch]);

  const _onUserEditPress = (member: User) => {
    setSelectedUser(member);
    _toggleModal();
  };

  const _toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    if (unblockedUser) {
      showKitSnack(
        `${unblockedUser.nickname} unblocked`,
        KitSnackDuration.SHORT
      );
      dispatch(setUnblockedUser(null));
    }
  }, [dispatch, unblockedUser]);

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  const _renderItem = ({ item: user }: { item: User }) => {
    return (
      <BlockedUserListRow
        key={user.userId}
        user={user}
        onUserEditPress={_onUserEditPress}
      />
    );
  };

  const _keyExtractor = (item: User) => item.userId;

  const _renderBlockedUserList = () => {
    const users: User[] = blockedUsers;

    return users && users.length > 0 ? (
      <FlatList<User>
        testID='blockedUserList'
        scrollEnabled={true}
        horizontal={false}
        renderItem={_renderItem}
        inverted={false}
        data={users}
        keyExtractor={_keyExtractor}
        contentContainerStyle={{ paddingTop: 16 }}
      />
    ) : (
      <View style={styles.emptyStateContainer}>
        <KitText style={styles.emptyStateText}>
          You haven't blocked anyone.
        </KitText>
      </View>
    );
  };

  return (
    <>
      <View style={styles.safeContainer}>
        {_renderBlockedUserList()}

        <MemberActionSheet
          visible={modalVisible}
          setVisible={setModalVisible}
          // @ts-ignore
          selectedUser={selectedUser}
          // @ts-ignore
          setSelectedUser={setSelectedUser}
          isSelectedUserOwner={false}
          isSelectedUserBlocked={true}
          enableChannelActions={false}
          enableBlockUserActions={true}
          showKitSnack={showKitSnack}
        />
      </View>
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  safeContainer: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
  emptyStateContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateText: {
    color: colorForScheme({ default: Colors.N500 }),
    fontSize: 16,
    marginBottom: 130,
  },
});
