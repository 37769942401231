import TokensService from '@omni/kit/services/TokensService';

import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroupTypes } from '../Types';

const RESOURCE = 'group-type';

interface IGetGroupTypes extends Omit<IUri, 'resource'> {
  appKey: string;
}

interface IGetGroupTypesResponse extends IHttpResponse {
  body?: IGroupTypes;
}

export default async ({
  appKey,
}: IGetGroupTypes): Promise<IGetGroupTypesResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    filters: [
      ['app_key', appKey],
      ['assigned', 'true'],
    ],
    resource: RESOURCE,
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    token: guestToken,
    skipTimestamp: true,
  });
};
