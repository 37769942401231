import { KitIcon, KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import { IGroupLocation } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Linking, Platform, StyleSheet, View } from 'react-native';

export default function EventDetailHeader({
  event,
  eventLocation,
}: {
  event: IEvent;
  eventLocation?: IGroupLocation;
}): JSX.Element {
  const { sizeClassV2 } = useSizeClass();
  const { t } = useTranslation(['groups']);

  const isLarge = sizeClassV2 >= SizeClassV2.L;

  const imageUrl = event?._embedded?.images?.filter(
    (image) => image.type === 'banner'
  )[0]._links.related?.href;

  const displayAddress =
    eventLocation &&
    [eventLocation.title, eventLocation.subtitle].filter(Boolean).join(' • ');

  const openMap = (latitude: number, longitude: number) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    const appleMapsUrl = `http://maps.apple.com/?ll=${latitude},${longitude}`;

    if (Platform.OS === 'web') {
      // Open Google Maps in a new tab on web
      window.open(googleMapsUrl, '_blank');
    } else {
      // Open native maps app on mobile (Apple Maps for iOS, Google Maps for Android)
      const url =
        Platform.OS === 'ios' ? appleMapsUrl : `geo:${latitude},${longitude}`;
      Linking.openURL(url).catch(() =>
        console.log('Error', 'Unable to open maps')
      );
    }
  };

  return (
    <>
      <View
        style={{
          flexDirection: Platform.OS === 'web' && isLarge ? 'row' : 'column',
          alignItems:
            Platform.OS === 'web' && isLarge ? 'center' : 'flex-start',
          marginBottom:
            Platform.OS === 'web' && isLarge ? Spacing.xxl : Spacing.m + 3,
        }}
      >
        {imageUrl && (
          <View
            style={[
              styles.banner,
              Platform.OS === 'web' &&
                isLarge && {
                  width: 256,
                  height: 144,
                  marginRight: Spacing.l,
                },
            ]}
          >
            <KitImage
              source={
                event?._embedded?.images
                  ? {
                      uri: imageUrl,
                    }
                  : {}
              }
              style={{
                width: Platform.OS === 'web' && isLarge ? 256 : '100%',
                height: Platform.OS === 'web' && isLarge ? 144 : 191,
                borderRadius: 8,
                backgroundColor: Colors.N200,
              }}
            />
          </View>
        )}
        <View style={[Platform.OS === 'web' && isLarge && { flex: 1 }]}>
          <KitText
            fontSize={24}
            lineHeight={Platform.OS === 'web' && isLarge ? 24 : 30}
            numberOfLines={2}
            color={Colors.N900}
            extraBold
            ellipsizeMode='tail'
            style={[
              {
                marginBottom: Spacing.m,
                marginTop:
                  imageUrl === undefined
                    ? 0
                    : Platform.OS === 'web' && isLarge
                    ? 0
                    : Spacing.m,
              },
            ]}
          >
            {event.title}
          </KitText>

          {event && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                maxWidth: Dimensions.get('window').width - 36,
                marginBottom:
                  Platform.OS === 'web' && isLarge ? Spacing.m : Spacing.s,
              }}
            >
              <KitIcon
                style={{ marginRight: Spacing.xs }}
                size={16}
                name='event'
                color={Colors.N500}
              />
              <KitText
                fontSize={Platform.OS === 'web' && isLarge ? 16 : 14}
                lineHeight={Platform.OS === 'web' && isLarge ? 22 : 20}
                color={Colors.N500}
              >
                {dateFormatRange({
                  startDate: event.start_at,
                  endDate: event.end_at,
                  timezone: event.timezone,
                })}
              </KitText>
            </View>
          )}

          {displayAddress && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                maxWidth: Dimensions.get('window').width - 36,
              }}
            >
              <View>
                <KitIcon
                  style={{
                    marginRight: Spacing.xs,
                    height: 22,
                    marginTop: Platform.OS === 'web' && isLarge ? 0 : 4,
                  }}
                  size={16}
                  name='location'
                  color={Colors.N500}
                />
              </View>
              <View
                style={[
                  {
                    flexDirection: 'column',
                    flexShrink: 1,
                  },
                ]}
              >
                <KitText
                  fontSize={Platform.OS === 'web' && isLarge ? 16 : 14}
                  lineHeight={Platform.OS === 'web' && isLarge ? 22 : 20}
                  color={Colors.N500}
                >
                  {displayAddress}
                </KitText>
                {eventLocation?._embedded?.address && (
                  <KitText
                    onPress={() =>
                      openMap(
                        eventLocation?._embedded?.address.latitude,
                        eventLocation?._embedded?.address.longitude
                      )
                    }
                    style={{
                      textDecorationLine: 'underline',
                      // @ts-ignore
                      cursor: 'pointer',
                      marginTop: Spacing.s,
                    }}
                    fontSize={14}
                    lineHeight={20}
                    color={Colors.N900}
                  >
                    {t('eventsGetDirections')}
                  </KitText>
                )}
              </View>
            </View>
          )}
        </View>
      </View>
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.N200,
    width: '100%',
    height: 191,
    borderRadius: 8,
    marginRight: 0,

    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.06,
        shadowRadius: 10,
      },
    }),
  },
});
