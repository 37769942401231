import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitImage from '@omni/kit/components/KitImage';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import { User } from '@sendbird/chat';
import { differenceBy } from 'lodash';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { blockedUserListSelector } from '../../shared/redux/selectors';

const debug = require('debug')('tca:chat:components:IntroMemberWidget');

interface IProps {
  beginning?: boolean;
  members: User[];
}

export default function IntroMemberWidget({
  beginning,
  members,
}: IProps): JSX.Element | null {
  const blockedUserList = useSelector(blockedUserListSelector);
  members = differenceBy(members, blockedUserList, 'userId');
  debug('Unblocked Members:', members);
  const numMembersToDisplay = 4;

  const _renderMemberAvatars = () => {
    const avatarMembers = members.slice(0, numMembersToDisplay);
    const difference = members.length - avatarMembers.length;

    return (
      <View style={{ flexDirection: 'row', marginTop: Spacing.m }}>
        {avatarMembers.map((member, index) => (
          <View
            key={index}
            style={[
              styles.memberAvatar,
              index !== 0 ? { marginLeft: -8 } : null,
            ]}
          >
            <KitAvatar
              size={44}
              nickname={member.nickname}
              imageUrl={member.profileUrl}
            />
          </View>
        ))}
        {difference > 0 && (
          <View style={[styles.memberAvatar, { marginLeft: -8 }]}>
            <KitText color={Colors.N0} bold>
              {`+${difference}`}
            </KitText>
          </View>
        )}
      </View>
    );
  };
  const names: string[] = [];

  for (let i = 0; i < numMembersToDisplay && i < members.length; i++) {
    const firstName = members[i].nickname.split(' ')[0];
    const lastName = members[i].nickname.split(' ')[1];
    const duplicateName = names.find((name) => name === firstName);

    names.push(duplicateName ? `${firstName} ${lastName[0]}.` : firstName);
  }

  const difference = members.length - names.length;

  if (difference > 0) {
    names.push(`+${difference} ${difference > 1 ? 'others' : 'other'}`);
  }

  const memberNames = names.join(', ');

  return members.length ? (
    <View>
      {_renderMemberAvatars()}
      <KitText fontSize={15} semiBold black>
        {memberNames}
      </KitText>
      {beginning && (
        <KitText fontSize={13}>
          This is the beginning of your message history.
        </KitText>
      )}
    </View>
  ) : null;
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  memberAvatar: {
    borderColor: Colors.N0,
    borderRadius: BorderRadius.xl,
    borderWidth: 2,
  },
});
