import { KitText } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import {
  AdminMessage,
  BaseMessage,
  FileMessage,
  UserMessage,
} from '@sendbird/chat/message';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import TouchableBase from '../../../../../kit/components/TouchableBase';
import { SendbirdMessage } from '../../../../Types';
import { formatDirectChannelName } from '../../../../utilities/chatUtilities';
import { channelTimeSince } from '../../../../utilities/sendbird/channelFunctions';
import {
  blockedUserListSelector,
  userSelector,
} from '../../../redux/selectors';
import { ChannelType } from '../../../redux/types';
import ChannelAvatar from '../components/ChannelAvatar';

const debug = require('debug')('tca:chat:component:ChannelListRow');

//******************************************************************************
// Types
//******************************************************************************

export interface Props {
  channel: GroupChannel;
  onChannelSelection?: (channel: GroupChannel) => void;
  selected: boolean;
}

export default function ChannelListRow({
  channel,
  onChannelSelection,
  selected,
}: Props): JSX.Element {
  const user = useSelector(userSelector);
  const blockedUserList = useSelector(blockedUserListSelector);

  const _renderTitle = () => {
    const { lastMessage, unreadMessageCount, name } = channel;
    const channelType = channel.customType as ChannelType;

    let title = '';
    switch (channelType) {
      case ChannelType.Direct:
        title = formatDirectChannelName(user, channel.members, blockedUserList);
        break;
      default:
        title = name;
        break;
    }

    return (
      <View
        style={styles.renderTitleViewStyle}
        testID={`channel-list-row-${title}`}
      >
        <View style={{ flexDirection: 'row' }}>
          <KitText
            black
            bold={unreadMessageCount !== 0}
            fontSize={16}
            numberOfLines={1}
            style={styles.renderTitleTextStyle}
          >
            {title}
          </KitText>
        </View>
        <View>
          <KitText fontSize={14} gray style={styles.renderTimeTextStyle}>
            {_renderLastMessageTime(lastMessage)}
          </KitText>
        </View>
      </View>
    );
  };

  const _renderSubtitle = () => {
    const { lastMessage, unreadMessageCount } = channel;

    const renderedMessage = _renderMessage(lastMessage as SendbirdMessage);

    if (!renderedMessage) {
      return null;
    }

    return (
      <View style={styles.renderLastMessageViewStyle}>
        <KitText
          color={Colors.N500}
          fontSize={16}
          numberOfLines={1}
          style={styles.renderLastMessageTextStyle}
        >
          {renderedMessage}
        </KitText>
        {unreadMessageCount > 0 && (
          <View style={styles.unreadIndicator}>
            <KitText white fontSize={12} bold style={{ lineHeight: 16 }}>
              {unreadMessageCount}
            </KitText>
          </View>
        )}
      </View>
    );
  };

  const _renderMessage = (message: SendbirdMessage) => {
    if (message) {
      let messagePrefix = '';

      if ((message as UserMessage | FileMessage).sender) {
        messagePrefix =
          (message as UserMessage | FileMessage).sender.nickname + ': ';
      }

      // A threaded message
      if (message.parentMessageId) {
        messagePrefix = `In Thread: ${messagePrefix}`;
      }

      let lastMessage = (message as UserMessage).message
        ? messagePrefix + (message as UserMessage).message
        : messagePrefix + `Sent a ${message.customType.toString()}`;

      if (lastMessage.includes('\n')) {
        lastMessage = lastMessage.split('\n')[0];
      }

      return lastMessage;
    } else {
      return null;
    }
  };

  const _renderLastMessageTime = (message?: BaseMessage | null) => {
    return message ? channelTimeSince(message.createdAt) : null;
  };

  const handleOnPress = (): void => {
    onChannelSelection?.(channel);
  };

  return (
    <TouchableBase
      onPress={handleOnPress}
      style={[
        styles.container,
        { backgroundColor: selected ? Colors.N50 : 'transparent' },
      ]}
    >
      <View style={[styles.imageContainer]}>
        <ChannelAvatar channel={channel} />
      </View>
      <View style={styles.containerText}>
        {_renderTitle()}
        {_renderSubtitle()}
      </View>
    </TouchableBase>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 78,
    alignItems: 'center',
  },
  containerText: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 12,
    justifyContent: 'center',
  },
  imageContainer: {
    height: 36,
    width: 36,
    marginLeft: Spacing.l,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    borderRadius: BorderRadius.xl,
  },
  renderTitleViewStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 4,
  },
  renderTitleTextStyle: {
    width: 200,
  },
  renderTimeTextStyle: {
    marginRight: 9,
  },
  renderLastMessageViewStyle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 4,
  },
  renderLastMessageTextStyle: {
    width: 250,
  },
  unreadIndicator: {
    backgroundColor: Colors.brand,
    height: 20,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 9,
    paddingHorizontal: 6,
  },
  letter: {
    color: Colors.N0,
    fontWeight: '600',
  },
});
