import { useEffect } from 'react';
import { Platform } from 'react-native';

export default function useFavicon(appId: string): void {
  function getFaviconEl(elementId: string): HTMLLinkElement | null {
    return document.getElementById(elementId) as HTMLLinkElement;
  }

  useEffect(() => {
    if (appId && Platform.OS === 'web') {
      const icon = getFaviconEl('icon');
      const shortcutIcon = getFaviconEl('shortcut-icon');
      const appleTouchIcon = getFaviconEl('apple-touch-icon');

      if (icon) {
        icon.href = buildAppIconUrl(appId, 192);
      }

      if (shortcutIcon) {
        shortcutIcon.href = buildAppIconUrl(appId, 32);
      }

      if (appleTouchIcon) {
        appleTouchIcon.href = buildAppIconUrl(appId, 180);
      }
    }
  }, [appId]);
}

// Note: these icons only exist on the production artwork domain
export const buildAppIconUrl = (appId: string, size: number): string =>
  `https://artwork.subsplash.com/images/cdn/${appId}/${size}/${size}/ios/icon.png`;
