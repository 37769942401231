import TokensService from '@omni/kit/services/TokensService';

import { IMediaItem, IPrepareMediaItemUrl, prepareMediaUrl } from '..';
import BaseServiceV2, { IHttpResponse } from '../../BaseServiceV2';

const debug = require('debug')('tca:service:MediaItemService:Core:GetOne');

interface IMediaItemGetOne extends IPrepareMediaItemUrl {
  appKey?: string;
  id: string;
}

interface IMediaItemGetOneResponse extends IHttpResponse {
  body?: IMediaItem;
}

export default async ({
  appKey,
  ...buildUrlProps
}: IMediaItemGetOne): Promise<IMediaItemGetOneResponse> => {
  debug('GetOne');
  const url = prepareMediaUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    token: guestToken,
  });
};
