import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroupEvent } from '../Types';

const RESOURCE = 'events';

interface ICreateGroupEvent extends Omit<IUri, 'resource'> {
  body: Partial<IGroupEvent>;
  token: string;
}

interface ICreateGroupEventsResponse extends IHttpResponse {
  body?: IGroupEvent;
}

export default ({
  body,
  token,
}: ICreateGroupEvent): Promise<ICreateGroupEventsResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
  });

  return BaseServiceV2.Post({ url, token, data: body });
};
