import TokensService from '@omni/kit/services/TokensService';

import {
  IPrepareTranslationUrl,
  ITranslation,
  prepareTranslationUrl,
} from '../';
import BaseServiceV2, { IHttpResponse } from '../../../BaseServiceV2';
import Cache from '../Cache';

const debug = require('debug')('tca:service:TranslationService:Core:GetAll');

interface ITranslationGetAll extends Omit<IPrepareTranslationUrl, 'id'> {
  getFromCache?: boolean;
  appKey?: string;
}

interface ITranslationsGetAllResponse extends IHttpResponse {
  body?: {
    _embedded: {
      translations: ITranslation[];
    };
  };
}

export default async ({
  getFromCache,
  appKey,
  ...buildUrlProps
}: ITranslationGetAll): Promise<ITranslationsGetAllResponse> => {
  debug('GetAll');
  const url = prepareTranslationUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    getFromCache,
    token: guestToken,
    cache: Cache,
  });
};
