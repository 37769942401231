import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

// TODO: Replace these constants after testing
// How often should the timer increment
const IDLE_INTERVAL = 1 * 60 * 1000; // 1 minute

// How long before user is considered inactive
const MAX_IDLE_TIME = 5 * IDLE_INTERVAL; // 5 minutes

interface IdleDetectorWebProps {
  initialIdleState?: boolean;
  rootElementId?: string;
  onUserBecomesIdle?: () => void;
  onUserBecomesActive?: () => void;
}

export default function useIdleDetectorWeb({
  initialIdleState = false,
  rootElementId = 'root',
  onUserBecomesActive,
  onUserBecomesIdle,
}: IdleDetectorWebProps): boolean {
  const [isIdle, setIsIdle] = useState(initialIdleState);
  const idleTimer = useRef(0);
  const idleIntervalRef = useRef<any>();

  useEffect((): (() => void) => {
    if (Platform.OS !== 'web') return () => undefined;

    const handleActivity = (): void => {
      if (isIdle) {
        setIsIdle(false);
        onUserBecomesActive?.();
      }

      idleTimer.current = 0;
    };

    const incrementTimer = (): void => {
      idleTimer.current += IDLE_INTERVAL;

      if (idleTimer.current >= MAX_IDLE_TIME && !isIdle) {
        setIsIdle(true);
        onUserBecomesIdle?.();
      }
    };

    const root = document.getElementById(rootElementId);
    root?.addEventListener('mousemove', handleActivity);
    root?.addEventListener('keypress', handleActivity);

    idleIntervalRef.current = setInterval(incrementTimer, IDLE_INTERVAL);

    return () => {
      root?.removeEventListener('mousemove', handleActivity);
      root?.removeEventListener('keypress', handleActivity);
      clearInterval(idleIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, rootElementId]);

  return isIdle;
}
