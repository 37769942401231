import * as React from 'react';
import {
  ActivityIndicator,
  Animated,
  Easing,
  NativeModules,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import Colors from '../theming/Colors';
import { colorForScheme } from '../theming/Theming';
import NativeActivityIndicator from './NativeActivityIndicator';

//******************************************************************************
// Types
//******************************************************************************
export interface IProps {
  animating?: boolean;
  black?: boolean;
  white?: boolean;
  gray?: boolean;
  color?: string;
  small?: boolean;
  large?: boolean;
  delay?: number;
  hidesWhenStopped?: boolean;
  size?: number;
  style?: StyleProp<ViewStyle>;
}

export interface IState {
  timer: Animated.Value;
  fade: Animated.Value;
}

const DURATION = 3000;

//******************************************************************************
// Component
//******************************************************************************
class KitLoader extends React.Component<IProps, IState> {
  static defaultProps: Partial<IProps> = {
    animating: true,
    hidesWhenStopped: true,
  };

  rotation: Animated.CompositeAnimation | undefined = undefined;

  //****************************************************************************
  // State
  //****************************************************************************

  state = {
    timer: new Animated.Value(0),
    fade: new Animated.Value(0),
  };

  //****************************************************************************
  // Methods
  //****************************************************************************

  private _getColor = () => {
    const props = this.props as IProps;

    if (props.color) return props.color;

    if (props.black) return Colors.N900;

    if (props.white) return Colors.N0;

    if (props.gray) return Colors.N500;

    return colorForScheme({ default: Colors.N300 });
  };

  private _getSize = () => {
    return this.props.size ? this.props.size : this.props.small ? 20 : 48;
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidMount() {
    if (Platform.OS === 'android') return;

    if (
      Platform.OS === 'ios' &&
      'OmniActivityIndicator' in NativeModules.UIManager
    ) {
      return;
    }

    const { animating, delay } = this.props;
    const { timer } = this.state;

    // Circular animation in loop
    this.rotation = Animated.timing(timer, {
      duration: DURATION,
      easing: Easing.linear,
      useNativeDriver: Platform.OS === 'web' ? false : true,
      toValue: 1,
      isInteraction: false,
    });

    if (animating) {
      this.startRotation(delay);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidUpdate(prevProps: IProps) {
    if (Platform.OS === 'android') return;

    if (
      Platform.OS === 'ios' &&
      'OmniActivityIndicator' in NativeModules.UIManager
    ) {
      return;
    }

    const { animating, hidesWhenStopped } = this.props;
    const { fade } = this.state;

    if (animating !== prevProps.animating) {
      if (animating) {
        this.startRotation();
      } else if (hidesWhenStopped) {
        // Hide indicator first and then stop rotation
        Animated.timing(fade, {
          duration: 200,
          toValue: 0,
          useNativeDriver: Platform.OS === 'web' ? false : true,
          isInteraction: false,
        }).start(this.stopRotation.bind(this));
      } else {
        this.stopRotation();
      }
    }
  }

  componentWillUnmount(): void {
    this.stopRotation();
  }

  private startRotation = (delay?: number) => {
    const { fade, timer } = this.state;

    // Show indicator
    Animated.timing(fade, {
      duration: 300,
      delay: delay,
      toValue: 1,
      isInteraction: false,
      useNativeDriver: Platform.OS === 'web' ? false : true,
    }).start();

    // Circular animation in loop
    if (this.rotation) {
      timer.setValue(0);
      Animated.loop(this.rotation).start();
    }
  };

  private stopRotation = () => {
    if (this.rotation) {
      this.rotation.stop();
    }
  };

  render(): JSX.Element {
    const props = this.props as IProps;
    const { animating, hidesWhenStopped, style, ...rest } = props;

    const color = this._getColor();
    const size = this._getSize();

    if (Platform.OS === 'android') {
      return (
        <View style={[styles.container, style]} {...rest}>
          <ActivityIndicator
            size={size}
            color={color}
            animating={animating}
            hidesWhenStopped={hidesWhenStopped}
          />
        </View>
      );
    } else if (
      Platform.OS === 'ios' &&
      'OmniActivityIndicator' in NativeModules.UIManager
    ) {
      return (
        <NativeActivityIndicator
          color={color}
          style={{ height: size, width: size }}
        />
      );
    }

    const { fade, timer } = this.state;

    const frames = (60 * DURATION) / 1000;
    const easing = Easing.bezier(0.4, 0.0, 0.7, 1.0);
    const containerStyle = {
      width: size,
      height: size / 2,
      overflow: 'hidden',
    };

    return (
      <View style={[styles.container, style]} {...rest}>
        <Animated.View style={[{ width: size, height: size, opacity: fade }]}>
          {[0, 1].map((index) => {
            // Thanks to https://github.com/n4kz/react-native-indicators for the great work
            const inputRange = Array.from(
              new Array(frames),
              (_, frameIndex) => frameIndex / (frames - 1)
            );
            const outputRange = Array.from(
              new Array(frames),
              (_, frameIndex) => {
                let progress = (2 * frameIndex) / (frames - 1);
                const rotation = index ? Number(360 - 15) : -(180 - 15);

                if (progress > 1.0) {
                  progress = 2.0 - progress;
                }

                const direction = index ? -1 : +1;

                return `${
                  direction * (180 - 30) * easing(progress) + rotation
                }deg`;
              }
            );

            const layerStyle = {
              width: size,
              height: size,
              transform: [
                {
                  rotate: timer.interpolate({
                    inputRange: [0, 1],
                    outputRange: [
                      `${0 + 30 + 15}deg`,
                      `${2 * 360 + 30 + 15}deg`,
                    ],
                  }),
                },
              ],
            };

            const viewportStyle = {
              width: size,
              height: size,
              transform: [
                {
                  translateY: index ? -size / 2 : 0,
                },
                {
                  rotate: timer.interpolate({ inputRange, outputRange }),
                },
              ],
            };

            const offsetStyle = index ? { top: size / 2 } : null;

            const lineStyle = {
              width: size,
              height: size,
              borderColor: color,
              borderWidth: size / 9,
              borderRadius: size / 2,
            };

            return (
              <Animated.View key={index} style={[styles.layer]}>
                <Animated.View style={layerStyle}>
                  <Animated.View
                    // @ts-ignore
                    style={[containerStyle, offsetStyle]}
                    collapsable={false}
                  >
                    <Animated.View style={viewportStyle}>
                      {/* @ts-ignore */}
                      <Animated.View style={containerStyle} collapsable={false}>
                        <Animated.View style={lineStyle} />
                      </Animated.View>
                    </Animated.View>
                  </Animated.View>
                </Animated.View>
              </Animated.View>
            );
          })}
        </Animated.View>
      </View>
    );
  }
}

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  layer: {
    ...StyleSheet.absoluteFillObject,

    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default KitLoader;
