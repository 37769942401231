import { User } from '@sendbird/chat';
import { Member } from '@sendbird/chat/groupChannel';
import { differenceBy } from 'lodash';

import { ChannelMember, SendbirdMessage } from '../Types';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const findNewChannelIndex = (newChannel, channels) => {
  const newChannelLastMessageUpdated = newChannel.lastMessage
    ? newChannel.lastMessage.createdAt
    : newChannel.createdAt;
  let index = channels.length;

  for (let i = 0; i < channels.length; i++) {
    const comparedChannel = channels[i];
    const comparedChannelLastMessageUpdated = comparedChannel.lastMessage
      ? comparedChannel.lastMessage.createdAt
      : comparedChannel.createdAt;

    if (
      newChannelLastMessageUpdated === comparedChannelLastMessageUpdated &&
      newChannel.url === comparedChannel.url
    ) {
      index = i;
      break;
    } else if (
      newChannelLastMessageUpdated > comparedChannelLastMessageUpdated
    ) {
      index = i;
      break;
    }
  }

  return index;
};

export const formatDirectChannelName = (
  user: User | null,
  members: Member[],
  blockedUserList: User[]
): string => {
  // If only one person is the member of this group, it must be a direct message with ourself
  if (members.length === 1) {
    return user?.nickname ?? '';
  }

  const unblockedMembers = differenceBy(members, blockedUserList, 'userId');
  const otherMembers = unblockedMembers.filter((member) => {
    return member.userId !== user?.userId;
  });

  if (otherMembers.length === 1) {
    return otherMembers[0].nickname;
  }

  let title = '';
  const postfix = otherMembers.length > 3 ? `+${otherMembers.length - 3}` : '';

  for (let i = 0; i < 3; i++) {
    const member = otherMembers[i];

    if (member) {
      const separator = i === otherMembers.length - 1 ? '' : ', ';

      if (member.nickname !== user?.nickname) {
        title = title + member.nickname.split(' ')[0] + separator;
      }
    }
  }

  title = title + postfix;

  return title;
};

/**
 * Removes vendor references from the channel URL
 * @param channelUrl full SendBird channel URL
 */
export const getShortChannelUrl = (channelUrl?: string): string => {
  if (!channelUrl) {
    return '';
  }

  return channelUrl.replace('sendbird_group_channel_', '');
};

/**
 * Returns the full channel URL given a short URL without vendor references
 * @param shortUrl
 */
export const getFullChannelUrl = (shortUrl: string): string => {
  if (shortUrl.includes('sendbird_group_channel_')) {
    return shortUrl;
  }

  return `sendbird_group_channel_${shortUrl}`;
};

export const mapThreads = (
  messages: SendbirdMessage[]
): Array<Array<SendbirdMessage>> => {
  const threads = {};
  messages.forEach((message) => {
    let threadId;

    if (message.parentMessageId) {
      threadId = message.parentMessageId;
    } else if (message?.threadInfo?.replyCount) {
      threadId = message.messageId;
    }

    if (threadId) {
      // @ts-ignore
      if (threads[threadId]) {
        // @ts-ignore
        threads[threadId].unshift(message);
      } else {
        // @ts-ignore
        threads[threadId] = [message];
      }
    }
  });

  return Object.keys(threads)
    .map((key) => {
      // @ts-ignore
      return threads[key];
    })
    .sort((thread1, thread2) => {
      const thread1Date =
        thread1[thread1.length - 1].updatedAt ||
        thread1[thread1.length - 1].createdAt;
      const thread2Date =
        thread2[thread2.length - 1].updatedAt ||
        thread2[thread2.length - 1].createdAt;

      return thread2Date - thread1Date;
    });
};
