import { Platform } from 'react-native';

import getRouteRootPath from './getRouteRootPath.web';

/**
 * Designed to support rev links for absolute urls for Web Messaging and Live Chat
 *
 * Return the scheme, host and root path (if any) without a trailing slash
 * e.g.
 * on iOS & Android: https://messaging.subsplash.com/
 * on Web w/ a revision url: https://messaging.subsplash.com/rev:some-slug/
 */
export default (host: string): string => {
  if (Platform.OS !== 'web') {
    return `${host}`;
  }

  const str = `${host}${getRouteRootPath()}`;

  // remove trailing slash
  return str.endsWith('/') ? str.slice(0, -1) : str;
};
