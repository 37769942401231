import { useShellContext } from '@omni/kit';
import { ENV } from '@omni/kit/Environment';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import { UserMessage } from '@sendbird/chat/message';
import React from 'react';
import { View } from 'react-native';

import { MessageType } from '../../../redux/types';
import AnnouncementMessage from './messageTypes/AnnouncementMessage';
import BibleMessage from './messageTypes/BibleMessage';
import FileMessage from './messageTypes/FileMessage';
import GroupEventMessage from './messageTypes/GroupEventMessage';
import MediaMessage from './messageTypes/MediaMessage';
import PhotoMessage from './messageTypes/PhotoMessage';
import PollMessage from './messageTypes/PollMessage';
import PrayerMessage from './messageTypes/PrayerMessage';
import TextMessage from './messageTypes/TextMessage';
import UnsupportedMessage from './messageTypes/UnsupportedMessage';

export default function MessageContent({
  message,
  disabled,
}: {
  message: UserMessage;
  disabled?: boolean;
}): JSX.Element {
  const isSmall = useSizeClass().sizeClass === SizeClass.Small;
  // Used for checking if is sunny city and dev env
  const { app } = useShellContext();
  const groupEventsEnabled = app.appKey === 'CHURCH' && ENV === 'dev';

  const messageComponent = () => {
    const props = { message, disabled };
    const { customType } = message;

    // If GroupEvent is not enabled, skip processing GroupEvent case entirely
    if (customType === MessageType.GroupEvent && !groupEventsEnabled) {
      return <UnsupportedMessage />; // Skip rendering GroupEvent if it's not enabled
    }

    switch (message.customType) {
      case MessageType.Text:
        return <TextMessage {...props} />;
      case MessageType.Prayer:
        return <PrayerMessage {...props} />;
      case MessageType.Bible:
        return <BibleMessage {...props} />;
      case MessageType.Media:
        return <MediaMessage {...props} />;
      case MessageType.File:
        return <FileMessage {...props} />;
      case MessageType.Gif:
      case MessageType.Photo:
        return <PhotoMessage {...props} />;
      case MessageType.Poll:
        return <PollMessage {...props} />;
      case MessageType.Announcement:
        return <AnnouncementMessage {...props} />;
      // To be done when 5250 will be ready
      case MessageType.GroupEvent:
        return <GroupEventMessage {...props} />;
      default:
        return <UnsupportedMessage />;
    }
  };

  return (
    <View style={{ maxWidth: isSmall ? SCREEN_WIDTH * 0.8 : 470 }}>
      {messageComponent()}
    </View>
  );
}
