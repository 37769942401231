import {
  NavigationContainer,
  NavigationContainerRef,
  NavigationState,
} from '@react-navigation/native';
import React, { useState } from 'react';

import { MainChatTabs } from '../../../../mobile/navigation/largeScreens/MainScreen';
import ChannelHeader from './ChannelHeader';

const navRef = React.createRef<NavigationContainerRef>();

export default function ChannelContent(): JSX.Element {
  const [navState, setNavState] = useState<NavigationState>();

  return (
    <>
      <ChannelHeader
        navigation={navRef}
        // @ts-ignore
        currentRoute={navState?.routes[navState.index].name}
      />
      <NavigationContainer
        ref={navRef}
        onStateChange={setNavState}
        documentTitle={{ enabled: false }}
      >
        <MainChatTabs />
      </NavigationContainer>
    </>
  );
}
