import { Platform } from 'react-native';

import { IRootProps } from '../../contexts/types';
import EventService from '../../services/EventService';
import {
  EVENT_SERVICE_EVENTS,
  IListenerCallbackData,
} from '../../services/EventService/types';
import AddIosMediaPlaybackListener from './AddIosMediaPlaybackListener';
import ResetPatchTimer from './ResetPatchTimer';
import UploadAllLocalProgress from './UploadAllLocalProgress';
import { CURRENT_EVENT_SOURCE } from './constants';

const debug = require('debug')(
  'tca:packages:TrackMediaProgress:LaunchTrackMediaProgress'
);

/**
 * Initialize media tracking on app launch
 * @param props {IRootProps}
 */
export default async (props: IRootProps): Promise<void> => {
  debug('Init LaunchTrackMediaProgress', props);
  const { moduleCommand, appKey } = props;
  const isAppLaunch = !moduleCommand;

  if (!isAppLaunch || !appKey) return;

  await UploadAllLocalProgress(appKey);

  EventService.addListener(
    EVENT_SERVICE_EVENTS.UPDATED_MEDIA_PLAY_RESOURCE,
    (props?: IListenerCallbackData) => {
      if (props?.source !== CURRENT_EVENT_SOURCE) {
        ResetPatchTimer();
      }
    }
  );

  /**
   * IOS can run react native code in the background
   *
   * Note: Android has to run react native code using a headless task runner.
   * https://reactnative.dev/docs/headless-js-android
   *
   * This is attached in the root index.ts
   */
  if (Platform.OS === 'ios') {
    AddIosMediaPlaybackListener();
  }
};
