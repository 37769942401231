/**
 * https://subsplash.io/go/feeds/-/blob/master/internal/handlers/list/item_converter.go
 * Porting over the above logic from backend
 */
import { getMediaDetailAction } from '@omni/kit/utilities/getMediaDetailAction';
import { getGroupTypeDisplayName } from '@omni/kit/utilities/groupUtilities';
// @ts-ignore
import osisToEn from 'bible-reference-formatter/es6/en';
import moment from 'moment';

import Environment from '../Environment';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../constants/identifiers';
import i18n from '../localization';
import { IFeaturedApp } from '../services/FeedsService';
import { IGroup } from '../services/GroupsService/Types';
import { getAudioPlaybackUrl } from '../services/MediaItemService';
import { IMediaItem } from '../services/MediaItemService/Types';
import { IAppSearchHit } from '../services/SearchService/AppSearchHitTypes';
import { ITag } from '../services/TagService/Types';
import { ContainerAppMembership } from '../services/Types';
import { createImageUrl } from '../utilities/utilities';
import { EActionHandlers } from './ActionHandlerTypes';
import getAppStates from './getAppStates';
import { SearchResultItemProps } from './searchListItemTypes';

export function convertToListItem(
  item:
    | IAppSearchHit
    | IMediaItem
    | ITag
    | ContainerAppMembership
    | IFeaturedApp
    | IGroup,
  type?: string,
  useBlockPage?: boolean
): SearchResultItemProps {
  switch (type) {
    case 'app':
      return convertAppItem(item as IAppSearchHit);
    case 'container-app-membership':
      return convertContainerAppMembership(item as ContainerAppMembership);
    case 'featured-app':
      return convertFeaturedApp(item as IFeaturedApp);
    case 'audio-item':
      return convertAudioItem(item as IMediaItem, 'default');
    case 'audio-item-background':
      return convertAudioItem(item as IMediaItem, 'background');
    case 'media-item':
      return convertMediaItem(item as IMediaItem, useBlockPage);
    case 'live-media-item':
      return convertMediaItem(item as IMediaItem, useBlockPage);
    case 'speaker':
    case 'topic':
      return convertTag(item as ITag);
    case 'group':
      return convertGroup(item as IGroup);
    default:
      return {};
  }
}

export function convertAppItem(item: IAppSearchHit): SearchResultItemProps {
  if (!item?.fields?.title || !item?.fields?.app_key) {
    return {};
  }

  return {
    title: item?.fields?.title,
    subtitle: item?.fields?.distance
      ? i18n.t('common:locationDistanceAway', {
          distance: item?.fields?.distance,
        })
      : undefined,
    action: {
      handler: EActionHandlers.App,
      appkey: item?.fields?.app_key,
      states: getAppStates(item?.fields?.app_key, item?.fields?.title),
    },
    image: `${Environment.imagesHost}/images/cdn/${item?.fields?.app_key}/{width}/{height}/ios/circle.png`,
    type: 'app',
  };
}

function convertContainerAppMembership(
  item: ContainerAppMembership
): SearchResultItemProps {
  if (!item?._embedded?.app?.title || !item?.app_key) {
    return {};
  }

  return {
    title: item?._embedded?.app?.title,
    action: {
      handler: EActionHandlers.App,
      appkey: item?.app_key,
    },
    image: `${Environment.imagesHost}/images/cdn/${item?.app_key}/{width}/{height}/ios/circle.png`,
    type: 'app',
  };
}

function convertFeaturedApp(item: IFeaturedApp): SearchResultItemProps {
  if (!item?.title || !item?.app_key) {
    return {};
  }

  return {
    title: item?.title,
    action: {
      handler: EActionHandlers.App,
      appkey: item?.app_key,
      states: getAppStates(item?.app_key, item?.title),
    },
    image: `${Environment.imagesHost}/images/cdn/${item?.app_key}/{width}/{height}/ios/circle.png`,
    type: 'app',
  };
}

function convertTag(item: ITag): SearchResultItemProps {
  const image = item._embedded?.['square-image'];
  const tag = {
    averageHexColor: image?.average_color_hex,
    count: item.published_tagging_count || item.tagging_count,
    title: item.title,
    type: item.type,
    subtitle: item.type === 'speaker' ? item.short_description : 'Topic',
    value: item.title,
  };

  if (tag.type === 'speaker') {
    return {
      ...tag,
      description: item.description,
      image: image?._links?.related?.href,
      showAvatar: true,
    };
  }

  return tag;
}

function convertAudioItem(
  item: IMediaItem,
  mode: 'default' | 'background'
): SearchResultItemProps {
  const host = Environment.feedsService;
  const resource = 'media-playlist';
  const images = item._embedded?.images || [];
  const image = images[0]?._links?.related?.href;
  const averageHexColor = images[0]?.average_color_hex;

  const metadata: string[] = [];

  if (item.date) metadata.push(moment.utc(item.date).format('MMMM D, YYYY'));

  if (item.speaker) metadata.push(item.speaker);

  return {
    id: item.id,
    shortCode: item.short_code,
    title: item.title,
    subtitle: metadata.join(' • '),
    scriptures: item.scriptures
      ?.map((scripture) => osisToEn('niv-long', scripture))
      .join(', '),
    averageHexColor,
    image,
    type: 'audio-item',
    action: {
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      handler: EActionHandlers.Audio,
      url: `${host}/${resource}/${item.id}`,
      mode,
    },
    duration:
      item._embedded?.audio?._embedded?.['audio-outputs']?.[0]?.duration ??
      item._embedded?.video?._embedded?.['video-outputs']?.[0]?.duration,
    savedStateKey: getAudioPlaybackUrl(item) ?? item.short_code,
  };
}

function convertMediaItem(
  item: IMediaItem,
  useBlockPage = false
): SearchResultItemProps {
  const images = item._embedded?.images || [];
  const image = images[0]?._links?.dynamic?.href;
  const averageHexColor = images[0]?.average_color_hex;
  const vibrantHexColor = images[0]?.vibrant_color_hex;

  const isBroadcastScheduledOrLive =
    item._embedded?.broadcast?.status === 'scheduled' ||
    item._embedded?.broadcast?.status === 'live';
  const metadata: string[] = [];

  if (item.date) metadata.push(moment.utc(item.date).format('MMMM D, YYYY'));

  if (item.speaker) metadata.push(item.speaker);

  return {
    id: item.id,
    shortCode: item.short_code,
    title: item.title,
    subtitle: isBroadcastScheduledOrLive //Added the change for Live in NMT
      ? item?.subtitle
      : metadata.join(' • '),
    scriptures: item.scriptures
      ?.map((scripture) => osisToEn('niv-long', scripture))
      .join(', '),
    averageHexColor,
    vibrantHexColor,
    image,
    type: 'media-item',
    action: getMediaDetailAction({
      id: item.id,
      shortCode: item.short_code,
      useBlockPage,
    }),
    duration:
      item._embedded?.audio?._embedded?.['audio-outputs']?.[0]?.duration ??
      item._embedded?.video?._embedded?.['video-outputs']?.[0]?.duration,
    broadcast: item._embedded?.broadcast,
    savedStateKey: getAudioPlaybackUrl(item) ?? item.short_code,
  };
}

function convertGroup(item: IGroup): SearchResultItemProps {
  const image = item._embedded?.image;
  const averageHexColor = image?.average_color_hex;
  const metadata: string[] = [];

  if (item._embedded?.type?.name) {
    metadata.push(getGroupTypeDisplayName(item._embedded.type.name));
  }

  const memberCount = item.member_count ? item.member_count : 0;
  metadata.push(i18n.t('groups:membersWithCount', { count: memberCount }));

  return {
    title: item.name,
    subtitle: metadata.join(' • '),
    id: item.id,
    averageHexColor,
    image: createImageUrl(image?.id),
    type: 'group',
    scheduleTitle: item.scheduleTitle,
    locationId: item._embedded?.location?.id,
  };
}
