import { useEffect, useState } from 'react';

import TokensService from '../services/TokensService/TokensService';

export default function useGuestToken(): {
  guestToken: string | undefined;
  guestTokenLoading: boolean;
} {
  const [guestToken, setGuestToken] = useState<string>();
  const [guestTokenLoading, setGuestTokenLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await TokensService.getGuestToken();
        setGuestToken(token);
      } finally {
        setGuestTokenLoading(false);
      }
    };

    fetchToken();
  });

  return { guestToken, guestTokenLoading };
}
