import { ENV } from '@omni/kit/Environment';
import { KitEmojiPicker, KitText } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import Tooltip from '@omni/kit/components/Tooltip';
import { useShellContext } from '@omni/kit/contexts/ShellContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { useNavigation } from '@react-navigation/native';
import React, { useRef, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Popup from 'reactjs-popup';

import { MessageInputIcon } from '../../../../mobile/components/chat/MessageInputIcon';
import { BibleSelectionModalWithHeader } from '../../../../mobile/navigation/SmallScreenNavigator';
import GifModal from '../../../../mobile/screens/GifModal';
import MediaSelectionScreen from '../../../../mobile/screens/MediaSelectionScreen';
import PollScreen from '../../../../mobile/screens/PollScreen';
import PrayerRequestScreen from '../../../../mobile/screens/PrayerRequestScreen';
import { CHANNEL_DETAILS_SCREEN } from '../../../../shared/Constants';
import CreateEditGroupEventScreen from '../../../../shared/scenes/channelDetails/CreateEditGroupEventScreen';
import WebFileAction from './WebFileAction';
import WebImageAction from './WebImageAction';

enum PageContent {
  image,
  gif,
  prayer,
  file,
  media,
  poll,
  passage,
  emoji,
  more,
  groupevent,
}
type IconProps = {
  tooltip: string;
  icon: IconName;
  content: PageContent;
};
// Used for checking if is  dev env
const groupEventsEnabled = ENV === 'dev';
const nestedIcons: IconProps[] = [
  { tooltip: 'Attachment', icon: 'file', content: PageContent.file },
  { tooltip: 'Media', icon: 'media', content: PageContent.media },
  { tooltip: 'Poll', icon: 'poll', content: PageContent.poll },
  { tooltip: 'Bible Passage', icon: 'bible', content: PageContent.passage },
  // this shows the event button only if we are in dev
  ...(groupEventsEnabled
    ? [
        {
          tooltip: 'Group Event',
          icon: 'event' as IconName,
          content: PageContent.groupevent,
        },
      ]
    : []),
];

type NestedIconProps = {
  icon: IconName;
  tooltip: string;
  nest?: boolean;
  color?: string;
  children?: React.ReactNode | Array<React.ReactNode>;
  navTo?: () => void;
};
type ActionIconProps = IconProps &
  NestedIconProps & {
    currentContent?: PageContent;
    setContent: (c?: PageContent) => void;
    closePopup: () => void;
    onEmojiSelected: (e: string) => void;
    setCreateModal?: (b: boolean) => void;
  };

const NestedActionIcon: React.FC<NestedIconProps> = ({ navTo, ...props }) =>
  props.nest ? (
    <Button
      {...props}
      tooltip={undefined}
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        margin: Spacing.s,
      }}
    >
      {props.children}
      <KitText color={props.color}>{props.tooltip}</KitText>
    </Button>
  ) : navTo ? (
    <Button
      {...props}
      tooltip={undefined}
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        margin: Spacing.s,
      }}
      onPress={navTo}
    >
      {props.children}
      <KitText onPress={navTo} color={props.color}>
        {props.tooltip}
      </KitText>
    </Button>
  ) : (
    <Button {...props} />
  );

const CreateGroupEventsModal = ({
  createGroupEventModal,
  setCreateGroupEventModal,
}: {
  createGroupEventModal: boolean;
  setCreateGroupEventModal: (b: boolean) => void;
}) => {
  return (
    <>
      <KitModalV2
        modalStyle={{
          width: '100%',
          maxWidth: 620,
          height: '100%',
          maxHeight: 700,
          padding: 0,
        }}
        coverScreen={true}
        isVisible={createGroupEventModal}
        onClose={() => setCreateGroupEventModal(false)}
        enableScroll
        noScrollView
      >
        <CreateEditGroupEventScreen
          setCreateGroupEventModal={setCreateGroupEventModal}
        />
      </KitModalV2>
    </>
  );
};

const ActionIcon = React.forwardRef((props: ActionIconProps, ref) => {
  const {
    closePopup,
    color,
    content,
    currentContent,
    icon,
    nest = false,
    onEmojiSelected,
    setContent,
    tooltip,
    setCreateModal,
  } = props;

  const navigation = useNavigation();
  const { app } = useShellContext();

  const groupEventsEnabled = app.appKey === 'CHURCH';

  const filteredNestedIcons = groupEventsEnabled
    ? nestedIcons
    : nestedIcons.filter((i) => i.icon !== 'event');

  const isActive = content === currentContent;
  const isNestedActive =
    content === PageContent.more &&
    (isActive ||
      Boolean(filteredNestedIcons.find((i) => i.content === currentContent)));

  return content === PageContent.image ? (
    <NestedActionIcon tooltip={tooltip} icon={icon} color={color} nest={nest}>
      <WebImageAction />
    </NestedActionIcon>
  ) : content === PageContent.file ? (
    <NestedActionIcon tooltip={tooltip} icon={icon} color={color} nest={nest}>
      <WebFileAction />
    </NestedActionIcon>
  ) : content === PageContent.groupevent ? (
    <NestedActionIcon
      tooltip={tooltip}
      icon={icon}
      color={color}
      navTo={() =>
        Platform.OS === 'web'
          ? setCreateModal && setCreateModal(true)
          : navigation.navigate(CHANNEL_DETAILS_SCREEN, {
              screen: 'create_event',
            })
      }
    />
  ) : nest ? (
    <TouchableOpacity onPress={() => setContent(content)}>
      <NestedActionIcon tooltip={tooltip} icon={icon} color={color} nest />
    </TouchableOpacity>
  ) : (
    <Popup
      // @ts-ignore
      ref={ref}
      key={icon}
      open={isActive || isNestedActive}
      onClose={() => setContent(undefined)}
      contentStyle={{ zIndex: 3 }}
      trigger={
        <Button
          onPress={() => setContent(content)}
          icon={icon}
          tooltip={tooltip}
          active={isActive || isNestedActive}
          border={content === PageContent.more}
        />
      }
      position='top right'
    >
      <View
        style={
          currentContent === PageContent.more
            ? styles.popupWrapper
            : styles.popupWrapperFull
        }
      >
        {currentContent === PageContent.gif ? (
          // @ts-ignore
          <GifModal togglePopup={closePopup} />
        ) : currentContent === PageContent.prayer ? (
          // @ts-ignore
          <PrayerRequestScreen togglePopup={closePopup} />
        ) : currentContent === PageContent.media ? (
          // @ts-ignore
          <MediaSelectionScreen togglePopup={closePopup} />
        ) : currentContent === PageContent.poll ? (
          // @ts-ignore
          <PollScreen togglePopup={closePopup} />
        ) : currentContent === PageContent.passage ? (
          // @ts-ignore
          <BibleSelectionModalWithHeader togglePopup={closePopup} />
        ) : currentContent === PageContent.groupevent ? (
          <></>
        ) : currentContent === PageContent.emoji ? (
          <KitEmojiPicker
            onEmojiSelected={({ emoji }) => {
              onEmojiSelected(emoji);
              closePopup();
            }}
          />
        ) : currentContent === PageContent.more ? (
          <View style={styles.popupContentMore}>
            {filteredNestedIcons.map((icon) => (
              <ActionIcon
                {...props}
                {...icon}
                key={icon.content}
                color={Colors.N900}
                nest
              />
            ))}
          </View>
        ) : null}
      </View>
    </Popup>
  );
});

export default function WebActionBar({
  showNonText = false,
  onEmojiSelected = (_s: string) => null,
}: {
  showNonText: boolean;
  onEmojiSelected?: (s: string) => void;
}): JSX.Element {
  const [currentContent, setCurrentContent] = useState<PageContent | undefined>(
    undefined
  );

  const [createGroupEventModal, setCreateGroupEventModal] =
    useState<boolean>(false);

  const textIcons: IconProps[] = [
    { tooltip: 'Emoji', icon: 'emoji', content: PageContent.emoji },
  ];

  const nonTextBasic: IconProps[] = [
    ...textIcons,
    { tooltip: 'Image', icon: 'image', content: PageContent.image },
    { tooltip: 'GIF', icon: 'gif', content: PageContent.gif },
    { tooltip: 'Prayer', icon: 'prayer', content: PageContent.prayer },
    { tooltip: 'More', icon: 'add-s', content: PageContent.more },
  ];

  const icons = showNonText ? nonTextBasic : textIcons;

  const refs = useRef(
    Array.from({ length: icons.length }).map(() => React.createRef())
  );

  const closePopup = () =>
    // @ts-ignore
    refs.current.forEach((ref) => ref.current?.close?.());

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'flex-end',
          position: 'relative',
        }}
      >
        {icons.map((icon, i) => (
          <ActionIcon
            key={i}
            ref={refs.current[i]}
            {...icon}
            closePopup={closePopup}
            setContent={setCurrentContent}
            currentContent={currentContent}
            onEmojiSelected={onEmojiSelected}
            setCreateModal={setCreateGroupEventModal}
          />
        ))}
      </View>

      <CreateGroupEventsModal
        createGroupEventModal={createGroupEventModal}
        setCreateGroupEventModal={setCreateGroupEventModal}
      />
    </>
  );
}

interface IActionButton {
  icon: IconName;
  tooltip?: string;
  as?: 'button' | 'label';
  onPress?: () => void;
  children?: React.ReactNode;
  border?: boolean;
  style?: any;
  active?: boolean;
}

const Button = React.forwardRef(
  (
    {
      active,
      icon,
      tooltip,
      children,
      border,
      style,
      onPress,
      ...rest
    }: IActionButton,
    ref: any
  ) => {
    const [iProps, setIProps] = useState({});
    const child = (
      <MessageInputIcon
        icon={icon}
        color={active ? Colors.N900 : undefined}
        backgroundColor={active ? Colors.N200 : undefined}
        border={border}
        onPress={onPress}
        {...iProps}
      />
    );

    return (
      <label
        ref={ref}
        style={{ ...style, cursor: 'pointer' }}
        onPointerEnter={() =>
          tooltip &&
          setIProps({ color: Colors.N900, backgroundColor: Colors.N150 })
        }
        onPointerLeave={() => setIProps({})}
        {...rest}
      >
        {children}
        {tooltip ? <Tooltip label={tooltip}>{child}</Tooltip> : child}
      </label>
    );
  }
);

const styles = StyleSheet.create({
  popupWrapper: {
    borderRadius: 14,
    height: 500,
    paddingHorizontal: Spacing.s,
    position: 'relative',
    width: 400,
  },

  popupWrapperFull: {
    borderRadius: 14,
    height: 500,
    overflow: 'hidden',
    paddingHorizontal: Spacing.s,
    position: 'relative',
    width: 400,

    backgroundColor: Colors.N0,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 10,
  },

  popupContentMore: {
    backgroundColor: Colors.N0,
    borderRadius: 14,
    display: 'flex',
    marginLeft: 'auto',
    marginRight: -2 * Spacing.m,
    marginTop: 'auto',
    padding: Spacing.m,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.35,
    shadowRadius: 10,
  },
});
