import { FlexAlignType } from 'react-native';

import { ItemAlignment } from '../Types';

export const getFlexForAlignment = (
  alignment: ItemAlignment
): 'flex-start' | 'flex-end' | 'center' => {
  let flex: FlexAlignType = 'flex-start'; // default

  switch (alignment) {
    case ItemAlignment.End:
      flex = 'flex-end';
      break;
    case ItemAlignment.Center:
      flex = 'center';
      break;
  }

  return flex;
};
