import { KitListItem } from '@omni/kit/components';
import KitIcon from '@omni/kit/components/KitIcon';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

export default function NoUpcomingEvents(): JSX.Element {
  const { t } = useTranslation();

  return (
    <KitListItem
      bottomBorder={false}
      ImageComponent={
        <View style={styles.icon}>
          <KitIcon color={Colors.N300} name='event' size={20} />
        </View>
      }
      rightIcon=''
      minHeight={80}
      style={styles.emptyListItem}
      title={t('common:textNoUpcomingEvents')}
      titleColor={Colors.N500}
      titleFontSize={16}
      onPressUnderlayColor={Colors.N50}
    />
  );
}

const styles = StyleSheet.create({
  emptyListItem: {
    borderColor: Colors.N100,
    borderBottomWidth: 1,
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N50,
    borderRadius: BorderRadius.m,
    display: 'flex',
    padding: Spacing.s,
  },
});
