/**
 * https://subsplash.atlassian.net/wiki/spaces/NATIVE/pages/2025455796/Native+Handlers+Actions
 */

export enum EActionHandlers {
  App = 'app',
  Audio = 'audio',
  BlockPage = 'blockPage',
  InternalBrowser = 'internalBrowser',
  List = 'list',
  NativeShell = 'nativeShell',
  Video = 'video',
}
