import React, { useCallback, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import useChannelList from '../../../hooks/useChannelList';
import { setChannel } from '../../../shared/redux/actions/ChatActions';
import {
  channelListSelector,
  channelSelector,
  viewIsReadySelector,
} from '../../../shared/redux/selectors';
import JoinRequestsScreen from '../../../shared/scenes/joinRequests/JoinRequestsScreen';
import {
  getFullChannelUrl,
  getShortChannelUrl,
} from '../../../utilities/chatUtilities';
import { sbGetGroupChannel } from '../../../utilities/sendbird/channelFunctions';
import Routes from '../../navigation/Routes';
import ChannelContent from '../channel/components/ChannelContent';
import Sidebar from './components/Sidebar';
import WebLoadingView from './components/WebLoadingView';

const debug = require('debug')('omni:messaging:web:scenes:split');

interface ChannelParams {
  channelId?: string;
  appKey?: string;
}

export default function SplitScreen(): JSX.Element {
  const channel = useSelector(channelSelector);
  const channelList = useSelector(channelListSelector);
  const viewIsReady = useSelector(viewIsReadySelector);

  const isJoinRequestsScreen = useRouteMatch(Routes.joinRequests())?.isExact;

  const history = useHistory();
  const params = useParams<ChannelParams>();
  const dispatch = useDispatch();

  const previousChannel = useRef(channel);
  const previousParams = useRef(params);

  useChannelList();

  const setNewChannelView = useCallback(
    (channelId: string) => {
      debug('setNewChannelView()');
      sbGetGroupChannel(getFullChannelUrl(channelId))
        .then((ch) => {
          dispatch(setChannel(ch));
          // @ts-ignore
          if (ch && params.channelId !== getShortChannelUrl(ch.url)) {
            history.push(
              // @ts-ignore
              Routes.channelScreen(params.appKey, getShortChannelUrl(ch.url))
            );
          }
        })
        .catch((err) => {
          debug('Could not get channel with ID: ', channelId, ', Error: ', err);
        });
    },
    [dispatch, history, params.appKey, params.channelId]
  );

  // Watch for a change of channel
  useEffect(() => {
    // Channel ID changed in URL
    if (
      previousParams &&
      previousParams.current.channelId !== params.channelId
    ) {
      previousParams.current = params;
      if (params.channelId) {
        setNewChannelView(getShortChannelUrl(params.channelId));
      } else if (isJoinRequestsScreen) {
        dispatch(setChannel(null));
      }
    }
    // Channel changed in Redux
    else if (channel && channel !== previousChannel.current) {
      previousChannel.current = channel;
      setNewChannelView(getShortChannelUrl(channel.url));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params,
    previousParams,
    channel,
    previousChannel,
    setNewChannelView,
    isJoinRequestsScreen,
  ]);

  useEffect(() => {
    if (
      !channel &&
      !isJoinRequestsScreen &&
      channelList &&
      channelList?.length > 0
    ) {
      if (!params.channelId) {
        // If there's no channel id in the URL open the first channel
        setNewChannelView(getShortChannelUrl(channelList[0]?.url));
      } else {
        setNewChannelView(getShortChannelUrl(params.channelId));
      }
    }
  }, [
    channel,
    channelList,
    isJoinRequestsScreen,
    params.channelId,
    setNewChannelView,
  ]);

  return !viewIsReady ? (
    <WebLoadingView />
  ) : (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        {isJoinRequestsScreen ? (
          <JoinRequestsScreen />
        ) : channel ? (
          <ChannelContent />
        ) : null}
      </View>
    </View>
  );
}
