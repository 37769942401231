import { KitButton, KitText, KitTouchable } from '@omni/kit/components';
import KitEventIcon from '@omni/kit/components/KitEventIcon';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import { getGroupEventFrequencyFromRRule } from '@omni/kit/utilities/groupUtilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface Option {
  value: string;
  label: string;
}
export default function DeleteEventConfirmModal({
  isVisible,
  onClose,
  confirmAction,
  title,
  subtitle,
  event,
}: {
  isVisible: boolean;
  onClose: (isVisible: boolean) => void;
  confirmAction: () => void;
  title: string;
  subtitle?: string;
  event: IEvent;
}): JSX.Element | null {
  const { sizeClass } = useSizeClass();
  const { t } = useTranslation(['common', 'groups']);

  const delOptions: Option[] = [
    { value: 'only', label: t('groups:thisEventOnly') },
    { value: 'this_and_following', label: t('groups:thisAndFollowingEvents') },
    { value: 'all', label: t('groups:allEvents') },
  ];
  // @ts-ignore
  const repetitionRules = event?.repetition_rules;

  const [selOption, setSelOption] = useState<string | undefined>();

  const onCloseModal = () => {
    setSelOption(undefined);
    onClose(false);
  };

  const onConfirmModal = () => {
    confirmAction();
    onClose(false);
  };

  return (
    <KitModalV2
      isVisible={isVisible}
      onClose={() => onCloseModal()}
      anchorBottom={sizeClass === SizeClass.Small}
      coverScreen
    >
      <View
        style={{
          width: '100%',
        }}
      >
        <KitText
          black
          extraBold
          fontSize={22}
          style={{ marginBottom: Spacing.s }}
        >
          {title}
        </KitText>
        <KitText gray fontSize={16} style={{ marginBottom: Spacing.l }}>
          {subtitle}
        </KitText>

        <View
          style={{
            width: '100%',
            height: 94,
            borderTopColor: Colors.N100,
            borderTopWidth: 1,
            borderBottomColor: Colors.N100,
            borderBottomWidth: 1,
            marginBottom: Spacing.l,
            justifyContent: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <KitEventIcon event={event as IEvent} />
            <View style={{ marginLeft: Spacing.m }}>
              <KitText fontSize={16} lineHeight={22} color={Colors.N900}>
                {event.title}
              </KitText>
              <KitText fontSize={14} color={Colors.N500}>
                {dateFormatRange({
                  startDate: event.start_at,
                  endDate: event.end_at,
                  timezone: event.timezone,
                })}
              </KitText>
              {repetitionRules && (
                <KitText fontSize={14} color={Colors.N500}>
                  {getGroupEventFrequencyFromRRule(repetitionRules)}
                </KitText>
              )}
            </View>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: -Spacing.m,
          }}
        >
          {delOptions.map((i) => (
            <KitTouchable
              key={i.value}
              style={{
                width: '100%',
                padding: i.value !== selOption ? Spacing.l : 17,
                height: 58,
                borderWidth: i.value === selOption ? 2 : 1,
                borderColor: i.value === selOption ? Colors.N900 : Colors.N100,
                borderRadius: BorderRadius.m,
                marginBottom: Spacing.m,
              }}
              onPress={() => setSelOption(i.value)}
              borderRadius={BorderRadius.m}
            >
              <KitText black style={{ textTransform: 'capitalize' }}>
                {i.label}
              </KitText>
            </KitTouchable>
          ))}
        </View>

        <View
          style={{
            ...(sizeClass === SizeClass.Small && {
              flexDirection: 'column',
            }),
            ...(sizeClass !== SizeClass.Small && {
              flexDirection: 'row-reverse',
              marginTop: Spacing.l,
            }),
          }}
        >
          <KitButton
            color={Colors.R500}
            title={t('buttonTitleYesDelete')}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            }}
            onPress={() => onConfirmModal()}
            small={sizeClass !== SizeClass.Small}
          />
          <KitButton
            secondary
            color={Colors.N100}
            title={t('buttonTitleNoCancel')}
            onPress={() => onCloseModal()}
            style={{
              ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
              ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
            }}
            small={sizeClass !== SizeClass.Small}
          />
        </View>
      </View>
    </KitModalV2>
  );
}
