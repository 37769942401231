'use client';

import type { DispatchEvent, ShellContextData } from '@omni/kit/Types';
import { AuthModal, AuthService } from '@omni/kit/auth';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { AuthContextProvider, useAuth } from '@omni/kit/contexts/AuthContext';
import { ShellContextProvider } from '@omni/kit/contexts/ShellContext';
import { SizeClassContextProvider } from '@omni/kit/contexts/SizeClassContext';
import {
  SnackbarContextProvider,
  useSnackbarContext,
} from '@omni/kit/contexts/Snackbar';
import { useSourceContext } from '@omni/kit/contexts/SourceContext';
import { IRootProps } from '@omni/kit/contexts/types';
import useAppData from '@omni/kit/hooks/useAppData';
import useAuthPrompt from '@omni/kit/hooks/useAuthPrompt';
import { App } from '@omni/kit/services/Types';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useApplicationContextData from './hooks/useApplicationContextData';

const debug = require('debug')('omni:nativeShell:context:application:context:');

export interface ApplicationContextData
  extends Omit<ShellContextData, 'dispatchEvent'> {
  global: {
    isAuthModalVisible: boolean;
  };
}

export const ApplicationContextProvider = (
  props: IRootProps & { children: React.ReactNode }
): JSX.Element | null => {
  const { app, appLoading } = useAppData(props.appKey);

  const { t } = useTranslation(['common']);
  const { showSnackbar } = useSnackbarContext();

  if (!app) {
    if (!appLoading) {
      showSnackbar(t('common:textErrorOccurred', { code: 550 }));
    }

    return null;
  }

  return (
    <SizeClassContextProvider>
      <SnackbarContextProvider>
        <AuthContextProvider {...props} app={app}>
          <ShellContextProviderWrapper props={{ ...props, app }}>
            {props.children}
          </ShellContextProviderWrapper>
        </AuthContextProvider>
      </SnackbarContextProvider>
    </SizeClassContextProvider>
  );
};

/**
 * TODO:
 * Once determine direction on a global store, this will need to be refactored.
 * 1. move all states into a global store
 * 2. decouple AuthService from the context (all auth states should be stored in the global store)
 */
const ShellContextProviderWrapper = ({
  children,
  props,
}: {
  children: React.ReactNode;
  props: IRootProps & { app: App };
}): JSX.Element | null => {
  const { t } = useTranslation(['common']);

  const { contextData, contextDataLoading } = useApplicationContextData(
    props.app
  );

  const isAuthenticated = Boolean(contextData?.tokens.user);

  const { targetProviderIds } = useAuth();

  const [showAuthModal, setShowAuthModal] = useState<boolean>(false);

  const { showSnackbar } = useSnackbarContext();

  const { dispatchAuthPrompt } = useAuthPrompt({
    showLoginOptions: () => {
      setShowAuthModal(true);
    },
  });

  const source = useSourceContext();

  const dispatch = useCallback(
    (event: DispatchEvent): void => {
      switch (event.type) {
        case 'REFRESH_TOKEN':
          AuthService.refreshAccessTokens(
            contextData?.app.appKey ?? '',
            targetProviderIds,
            source ?? 'app'
          );

          break;
        case 'AUTHENTICATE_USER':
          dispatchAuthPrompt({
            appKey: event.appKey ?? contextData?.app.appKey ?? '',
            orgKey: event.orgKey ?? contextData?.app.orgKey ?? '',
            source: event.source ?? 'app',
            targetAuthProviderId:
              event.targetAuthProviderId ?? SUBSPLASH_AUTH_PROVIDER_ID,
            customReturnUrl: event.customReturnUrl,
            openInNewTab: event.openInNewTab,
          });
          break;
        case 'SET_SNACKBAR_MESSAGE':
          showSnackbar(event.payload);
          break;
        default:
          debug('dispatchEvent not implemented in native shell', event);
          break;
      }
    },
    [
      contextData?.app.appKey,
      contextData?.app.orgKey,
      dispatchAuthPrompt,
      showSnackbar,
      source,
      targetProviderIds,
    ]
  );

  const onHideAuthModal = useCallback(() => {
    setShowAuthModal(false);
  }, []);

  useEffect(() => {
    /** TODO: do something with initialData */
  }, [contextData]);

  useEffect(() => {
    if (isAuthenticated && showAuthModal) {
      setShowAuthModal(false);
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!contextData) {
    // guestToken is missing
    if (!contextDataLoading) {
      showSnackbar(t('common:textErrorOccurred', { code: 553 }));
    }

    return null;
  }

  return (
    <ShellContextProvider
      shellData={{
        ...contextData,
        dispatchEvent: dispatch,
      }}
    >
      <>
        {children}
        <AuthModal
          appKey={contextData.app.appKey}
          orgKey={contextData.app.orgKey}
          onClose={onHideAuthModal}
          isVisible={showAuthModal}
          targetAuthProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
        />
      </>
    </ShellContextProvider>
  );
};
