import { KitLoader } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { User as SendbirdUser } from '@sendbird/chat';
import { differenceBy } from 'lodash';
import React, { RefObject, useContext, useEffect, useState } from 'react';
import { FlatList, StyleSheet, TextInput, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { IInvite, InviteType } from '../../../Types';
import IntroMemberWidget from '../../../mobile/components/IntroMemberWidget';
import PlatformKeyboardSpacer from '../../../mobile/components/PlatformKeyboardSpacer';
import UserListRow from '../../../mobile/components/UserListRow';
import KitChipInput from '../../../mobile/components/channelDetails/KitChipInput';
import MessageInput from '../../../mobile/components/chat/MessageInput';
import { getBlockedUserList } from '../../redux/actions/ChatActions';
import {
  createNewChannel,
  getUserList,
} from '../../redux/actions/SystemActions';
import {
  blockedUserListSelector,
  userListSelector,
  userSelector,
} from '../../redux/selectors';
import { ChannelType } from '../../redux/types';

export default function CreateDirectContent({
  header = undefined,
  onSubmit = () => undefined,
}: {
  header?: JSX.Element;
  onSubmit: () => void;
}): JSX.Element {
  const [filteredList, setFilteredList] = useState<IInvite[]>([]);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pendingMembers, setPendingMembers] = useState<IInvite[]>([]);
  const [textMessage, setTextMessage] = useState('');

  const userList = useSelector(userListSelector);
  const blockedUserList = useSelector(blockedUserListSelector);
  const user: SendbirdUser | null = useSelector(userSelector);

  const dispatch = useDispatch();
  const { colorForScheme } = useContext(ThemeContext);

  const inputField = React.useRef<TextInput>();

  const _onSend = () => {
    const memberList = pendingMembers.map(
      (member) => member.data as SendbirdUser
    );

    if (memberList.length === 0) {
      // prevent channel creation if no members have been selected
      return;
    }

    if (user) {
      // Add current user as a member
      memberList.push(user);
    }

    setIsLoading(true);
    const name = 'Message';
    dispatch(
      createNewChannel(
        ChannelType.Direct,
        true,
        false,
        name,
        memberList,
        textMessage
      )
    );
    onSubmit();
  };

  const _onBackspace = () => {
    if (!inputText.length) _removePendingMember(pendingMembers.length - 1);
  };

  const _onDeleteChipPress = (invite: IInvite) => {
    const index = pendingMembers.indexOf(invite as never);
    _removePendingMember(index);
  };

  const _removePendingMember = (index: number) => {
    const updatedMembers = pendingMembers;
    updatedMembers.splice(index, 1);

    setInputText('');
    setPendingMembers(updatedMembers);
    setFilteredList([]);

    inputField.current?.focus();
  };

  const _addPendingMember = (item: IInvite) => {
    const updatedMembers: IInvite[] = pendingMembers;
    updatedMembers.push(item);
    setInputText('');
    setPendingMembers(updatedMembers);
    setFilteredList([]);
    inputField.current?.focus();
  };

  const _onInputFieldChanged = (fieldText: string) => {
    if (fieldText !== '') {
      // Filter list each time a new character is typed
      const filteredCombinedList: IInvite[] = [];

      const filteredUserList = differenceBy(userList, blockedUserList, 'userId')
        .filter((item) => {
          const filter = inputText.toUpperCase();

          return item.nickname.toUpperCase().includes(filter);
        })
        .slice(0, 25); // Limit user suggestions to to keep the list from being too long

      filteredUserList.forEach((user: SendbirdUser) => {
        if (
          !pendingMembers.find((member) => member.data.userId === user.userId)
        ) {
          filteredCombinedList.push({
            data: user,
            title: user.nickname,
            type: InviteType.User,
          });
        }
      });
      setFilteredList(filteredCombinedList);
    } else {
      setFilteredList([]);
    }

    setInputText(fieldText);
  };

  const _renderList = ({ item }: { item: IInvite }) => {
    return (
      <UserListRow invite={item} onPress={() => _addPendingMember(item)} />
    );
  };

  const _renderInviteList = () => {
    if (inputText || pendingMembers.length > 0) {
      return (
        <FlatList<IInvite>
          contentContainerStyle={{ paddingBottom: 30 }}
          data={filteredList}
          inverted={false}
          keyboardDismissMode='on-drag'
          keyboardShouldPersistTaps='always'
          keyExtractor={(item) => item.title + Math.random()}
          renderItem={_renderList}
          scrollEnabled={true}
          style={{ flex: 1 }}
        />
      );
    }
  };

  useEffect(() => {
    dispatch(getUserList());
    dispatch(getBlockedUserList());
    inputField.current?.focus();
  }, [dispatch]);

  const spinner = isLoading ? (
    <View style={styles.loadingStyle}>
      <KitLoader />
    </View>
  ) : null;

  return (
    <View
      style={{
        backgroundColor: colorForScheme?.({ default: Colors.N0 }),
        flex: 1,
      }}
    >
      <View style={{ flex: 1 }}>
        {header}
        <View style={styles.inputContainer}>
          <KitChipInput
            autoCapitalize='none'
            autoCorrect={false}
            chips={pendingMembers}
            editable={true}
            inputRef={inputField as RefObject<TextInput>}
            onBackspace={_onBackspace}
            onChangeText={_onInputFieldChanged}
            onDeleteChipPress={_onDeleteChipPress}
            placeholder='Type a name'
            value={inputText}
          />
        </View>
        <View
          style={{
            backgroundColor: Colors.N0,
            bottom: 0,
            padding: Spacing.m,
            position: 'absolute',
          }}
        >
          <IntroMemberWidget
            members={pendingMembers.map(
              (member) => member.data as SendbirdUser
            )}
          />
        </View>
        {_renderInviteList()}
      </View>
      <MessageInput
        disabled={!textMessage || !pendingMembers.length}
        hideDrawer={true}
        isEditing={false}
        onCancelEditingPress={() => undefined}
        onChangeText={setTextMessage}
        onConfirmEditingPress={() => undefined}
        onSendPress={_onSend}
        textChanged={true}
        value={textMessage}
      />
      <PlatformKeyboardSpacer />
      {spinner}
    </View>
  );
}

const styles = StyleSheet.create({
  inputContainer: { margin: Spacing.l },
  loadingStyle: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
