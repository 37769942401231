import { KitListItem, KitText } from '@omni/kit/components';
import { EventImage } from '@omni/kit/components/EventImage';
import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import {
  ImageServiceType,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pressable, StyleSheet } from 'react-native';

import * as Constants from '../../../Constants';

interface EventsListItemsProps {
  accessToken: string | null | undefined;
  events: IGroupEvent[];
  openListEventModal: () => void;
}

export default function GroupEventListItems({
  accessToken,
  events,
  openListEventModal,
}: EventsListItemsProps): JSX.Element {
  const { t } = useTranslation();
  const navigation = useNavigation();
  // Get current date for filtering upcoming events
  const now = new Date();

  // Filter and sort events
  // const upcomingEvents = events
  //   .filter((event) => event.start_at && new Date(event.start_at) > now)
  //   .sort(
  //     (a, b) =>
  //       new Date(a.start_at || 0).getTime() -
  //       new Date(b.start_at || 0).getTime()
  //   );

  // TODO remove after api ready with real data
  const upcomingEvents = events.filter((event) => event.title === 'K Event');
  const displayedEvents = upcomingEvents.slice(0, 2); // Display the nearest two upcoming events

  return (
    <>
      {displayedEvents?.map((event, index) => {
        const imgUrl = parseImageUrl(
          createImageUrl(event?._embedded?.images?.[0].id),
          36,
          36,
          ImageServiceType.ImageJpeg
        );

        return (
          <KitListItem
            key={`${event.id}-${index}`}
            data-testid={event.title}
            title={event.title}
            subtitle={dateFormatRange({
              startDate: event.start_at ? event.start_at : '',
              endDate: event.end_at,
            })}
            titleColor={Colors.N900}
            titleFontSize={16}
            ImageComponent={
              <EventImage
                accessToken={accessToken}
                redirectUrl={imgUrl}
                date={event.start_at}
              />
            }
            minHeight={80}
            rightActionIcon='arrow-right'
            iconColor={Colors.N300}
            onRightActionPress={() =>
              navigation.navigate(Constants.EVENT_DETAILS_SCREEN, { event })
            }
          />
        );
      })}
      <Pressable
        onPress={openListEventModal}
        style={({ pressed }) => [
          styles.viewGroupEvents,
          {
            backgroundColor: pressed ? Colors.N50 : Colors.N0,
          },
        ]}
      >
        <KitText semiBold black>
          {t('groups:viewAllGroupEvents')}
        </KitText>
      </Pressable>
    </>
  );
}
const styles = StyleSheet.create({
  viewGroupEvents: {
    paddingVertical: Spacing.l,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
