import { SUBSPLASH_AUTH_PROVIDER_ID, useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import { KitIcon, KitListItem, KitText } from '@omni/kit/components';
import ImageIndicator from '@omni/kit/components/ImageIndicator';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitImage from '@omni/kit/components/KitImage';
import Show from '@omni/kit/components/Show';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from '@omni/kit/constants/ImageSizeDefault';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import {
  SizeClass,
  WindowSizeMax,
  useSizeClass,
} from '@omni/kit/contexts/SizeClassContext';
import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import { SearchResultItemProps } from '@omni/kit/feeds/searchListItemTypes';
import { Place } from '@omni/kit/placeTypes';
import GroupsService from '@omni/kit/services/GroupsService';
import {
  IGroupLocation,
  IGroupMember,
} from '@omni/kit/services/GroupsService/Types';
import { ILogoImage } from '@omni/kit/services/Types';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Depth from '@omni/kit/theming/Depth';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { dispatchAction } from '@omni/kit/utilities/NativeHelpers';
import {
  ImageServiceType,
  logItemPressEvent,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/core';
import pluralize from 'pluralize';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';

import { VALUE_CURRENT_LOCATION } from '../../Constants';
import { formatLocation } from '../../utils/filterOptions';

const APP_ICON_SIZE = 36;
const AVATAR_SIZE = 48;
const SILHOUETTE_IMAGE_SIZE = 20;
const LOCATION_ICON_SIZE = 20;
const LARGE_IMAGE_HEIGHT = 131;
const LARGE_IMAGE_WIDTH = 232;
const RECT_ASPECT_RATIO = LARGE_IMAGE_WIDTH / LARGE_IMAGE_HEIGHT;
const SMALL_IMAGE_WIDTH = WindowSizeMax.Small;

interface Props {
  appFilterValue: string;
  item: SearchResultItemProps;
  index: number;
  horizontalSpacing?: SpacingType;
  setSelectedPlace?: (value: Place | undefined) => void;
}

export default ({
  appFilterValue,
  item,
  index,
  setSelectedPlace,
}: Props): JSX.Element => {
  const { sizeClass, windowWidth } = useSizeClass();
  const isLarge = sizeClass >= SizeClass.Large;
  const stackImage = windowWidth <= 600;
  const navigation = useNavigation();
  const debug = require('debug')('groups:detail');

  const { tokens, user } = useShellContext();

  const [groupLocation, setGroupLocation] = useState<
    IGroupLocation | undefined
  >();
  const [members, setMembers] = useState<IGroupMember[]>([]);

  const accessToken = useMemo(() => tokens.user || '', [tokens.user]);
  const guestToken = useMemo(() => tokens.guest || '', [tokens.guest]);

  const endUserId = user?.id;
  const myselfAsMember = useMemo(() => {
    return members.filter(
      (member) => member._embedded?.['end-user']?.id === endUserId
    )?.[0];
  }, [members, endUserId]);

  const key = 'id' in item ? item.id : JSON.stringify(item);

  const onPress = () => {
    if ('place' in item && Boolean(item?.place)) {
      const place: Place = {
        description: item.place?.description ?? '',
        place_id: item.place?.place_id ?? '',
      };
      setSelectedPlace?.(place);
    } else if (
      'action' in item &&
      Boolean(item.action) &&
      Platform.OS !== 'web'
    ) {
      if (item.type === 'app') {
        logItemPressEvent('app_search_result');
      } else if (item.type === 'featured-app') {
        logItemPressEvent('featured_app');
      }

      dispatch(item.action);
    } else {
      Platform.OS === 'android'
        ? dispatchAction({
            handler: 'search',
            authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
            presentationStyle: 'modal',
            moduleCommand: {
              name: 'navigate_result',
              id: (item as IListItemData).id,
              path: item.type ? pluralize(item.type) : undefined,
              data: item,
              appFilterValue: appFilterValue,
            },
          })
        : navigation.navigate('Result', {
            id: (item as IListItemData).id,
            path: item.type ? pluralize(item.type) : undefined,
            ...(Platform.OS !== 'web'
              ? {
                  data: item,
                  appFilterValue: appFilterValue,
                }
              : {}),
          });
    }
  };

  const titleColor =
    'place' in item && item.place?.place_id === VALUE_CURRENT_LOCATION
      ? Colors.brand
      : undefined;

  const fetchGroupLocation = useCallback(() => {
    const _fetchGroupLocation = async (id: string) => {
      try {
        const response = await GroupsService.GetGroupLocation({
          id,
          token: accessToken || guestToken,
        });

        if (response.body) {
          setGroupLocation(response.body);
        } else {
          debug('Could not fetch group location');
        }
      } catch {}
    };

    if ('locationId' in item) {
      item.locationId
        ? _fetchGroupLocation(item.locationId)
        : setGroupLocation(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, guestToken, navigation, item]);

  // Hooks
  useEffect(() => {
    fetchGroupLocation();
  }, [fetchGroupLocation]);

  const fetchGroupMembers = useCallback(() => {
    const _fetchGroupMembers = async (id: string) => {
      try {
        const response = await GroupsService.GetGroup({
          id,
          token: accessToken,
        });

        if (response.body && response.body._embedded?.['group-members']) {
          setMembers(response.body._embedded?.['group-members']);
        }
      } catch {}
    };

    if ('locationId' in item) {
      if (item.locationId && item.id) {
        _fetchGroupMembers(item.id as string);
      } else {
        setMembers([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, guestToken, navigation, item]);

  // Hooks
  useEffect(() => {
    fetchGroupMembers();
  }, [fetchGroupMembers]);

  const displayAddress = formatLocation(groupLocation, myselfAsMember);

  return (
    <>
      <View
        style={{
          marginHorizontal: Spacing.xl,
        }}
        key={key}
      >
        {item.type === 'group' ? (
          <KitListItem
            ImageComponent={<LeftItem item={item} stackImage={stackImage} />}
            onPress={onPress}
            title={item?.title ?? ''}
            titleColor={titleColor}
            titleFontSize={sizeClass !== SizeClass.Small ? 24 : 20}
            subtitleFontSize={sizeClass !== SizeClass.Small ? 16 : 14}
            isTitleBold
            topBorder={false}
            bottomBorder={false}
            rightIcon=''
            stackImage={stackImage}
            style={{
              marginBottom: sizeClass === SizeClass.Large ? Spacing.xl : 0,
              borderRadius: Spacing.s,
            }}
            hoverStyle={{
              paddingHorizontal: Spacing.m,
              marginHorizontal: -Spacing.m,
              borderRadius: Spacing.s,
            }}
            scaleOnTouch
            sectionUnderTitle={
              <>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: Spacing.xs,
                  }}
                >
                  <KitIcon
                    style={{ marginRight: Spacing.xs }}
                    size={16}
                    name='people'
                    color={Colors.N500}
                  />
                  <KitText fontSize={16} lineHeight={22} color={Colors.N500}>
                    {item?.subtitle}
                  </KitText>
                </View>
                {item.scheduleTitle && (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: Spacing.xs,
                    }}
                  >
                    <KitIcon
                      style={{ marginRight: Spacing.xs }}
                      size={16}
                      name='calendar'
                      color={Colors.N500}
                    />
                    <KitText fontSize={16} lineHeight={22} color={Colors.N500}>
                      {item?.scheduleTitle}
                    </KitText>
                  </View>
                )}
                {displayAddress && (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <KitIcon
                      style={{ marginRight: Spacing.xs }}
                      size={16}
                      name='location'
                      color={Colors.N500}
                    />
                    <KitText fontSize={16} lineHeight={22} color={Colors.N500}>
                      {displayAddress}
                    </KitText>
                  </View>
                )}
              </>
            }
          />
        ) : (
          <KitListItem
            ImageComponent={<LeftItem item={item} />}
            onPress={onPress}
            subtitle={item?.subtitle}
            title={item?.title ?? ''}
            titleColor={titleColor}
            topBorder={index === 0}
            scaleOnTouch
          />
        )}
      </View>
    </>
  );
};

const LeftItem = ({
  item,
  stackImage,
}: {
  item: any;
  stackImage?: boolean;
}) => {
  const { colorForScheme } = useContext(ThemeContext);
  const appContext = useRootAppContext();
  const { sizeClass } = useSizeClass();
  const brand = appContext?._embedded?.branding;
  const silhouetteLogoImage: ILogoImage | undefined =
    brand?._embedded?.['silhouette-logo-image'];

  let borderRadius: number = BorderRadius.s;
  switch (item.type) {
    case 'speaker':
      borderRadius = BorderRadius.full;
      break;
    case 'group':
      borderRadius = BorderRadius.m;
      break;
  }
  const logoImage = silhouetteLogoImage?._links?.dynamic?.href || '';

  return (
    <View style={[styles.flex, { alignItems: 'stretch' }]}>
      <View
        style={[
          styles.flex,
          {
            backgroundColor: item.averageHexColor || Colors.N150,
            borderRadius: borderRadius,
            height: 48,
            width: 85,
          },
          item.type === 'speaker' ? { width: AVATAR_SIZE } : {},
          item.type === 'app'
            ? {
                width: APP_ICON_SIZE,
                height: APP_ICON_SIZE,
                backgroundColor: undefined,
              }
            : {},
          item.type === 'location'
            ? { backgroundColor: undefined, width: LOCATION_ICON_SIZE }
            : {},
          item.type === 'group'
            ? {
                width: LARGE_IMAGE_WIDTH,
                height: LARGE_IMAGE_HEIGHT,
                marginVertical: Spacing.m,
                alignItems: 'stretch',
                marginRight: Spacing.l,
                ...(stackImage && {
                  width: '100%',
                  height: 'auto',
                  paddingTop: '56%', // creates 16:9 aspect ratio
                  marginRight: 0,
                }),
              }
            : {},
        ]}
      >
        <Show
          show={
            Boolean(item.type === 'topic') ||
            Boolean(item.type === 'speaker') ||
            Boolean(item.type === 'media-item')
          }
        >
          <Image
            source={{
              uri: parseImageUrl(
                logoImage,
                SILHOUETTE_IMAGE_SIZE,
                SILHOUETTE_IMAGE_SIZE,
                ImageServiceType.ImagePng,
                'fit-white'
              ),
              height: SILHOUETTE_IMAGE_SIZE,
              width: SILHOUETTE_IMAGE_SIZE,
            }}
            style={styles.silhouetteLogoImage}
            resizeMode='cover'
          />
        </Show>
        <Show show={Boolean(item.type === 'topic')}>
          <View
            style={[
              styles.flex,
              styles.position,
              {
                backgroundColor: item.averageHexColor || Colors.N100,
                borderRadius: borderRadius,
              },
            ]}
          >
            <KitIcon color={Colors.N900} name='thread' size={20} />
          </View>
        </Show>
        <Show show={Boolean(item.type === 'speaker')}>
          <View style={[styles.position]}>
            <KitAvatar
              size={AVATAR_SIZE}
              imageUrl={item.image}
              nickname={item.title}
              border={false}
            />
          </View>
        </Show>
        <Show show={Boolean(item.type === 'media-item')}>
          <ImageIndicator
            image={item.image}
            imageWidth={IMAGE_WIDTH}
            imageHeight={IMAGE_HEIGHT}
            imageRadius={borderRadius}
            style={[styles.position, { borderRadius: borderRadius }]}
          />
        </Show>
        <Show show={Boolean(item.type === 'group')}>
          <KitImage
            source={{
              uri: parseImageUrl(
                item.image,
                sizeClass === SizeClass.Small
                  ? SMALL_IMAGE_WIDTH
                  : LARGE_IMAGE_WIDTH,
                sizeClass === SizeClass.Small
                  ? SMALL_IMAGE_WIDTH / RECT_ASPECT_RATIO
                  : LARGE_IMAGE_HEIGHT,
                ImageServiceType.ImageJpeg
              ),
            }}
            resizeMode='cover'
            style={[
              styles.position,
              Depth.m,
              {
                borderRadius: borderRadius,
              },
            ]}
          />
        </Show>
        <Show show={Boolean(item.type === 'app')}>
          <ImageIndicator
            image={item.image}
            imageWidth={APP_ICON_SIZE}
            imageHeight={APP_ICON_SIZE}
            style={[
              styles.position,
              Platform.select({
                android: {
                  elevation: 3,
                  overflow: 'hidden',
                },
                default: {
                  shadowColor: '#000000',
                  shadowOpacity: 0.1,
                  shadowOffset: { width: 0, height: 3 },
                  shadowRadius: 5,
                },
              }),
            ]}
          />
        </Show>
        <Show show={Boolean(item.type === 'location')}>
          <KitIcon
            color={colorForScheme?.({ light: Colors.N300, dark: Colors.N500 })}
            name='map-pin'
            size={LOCATION_ICON_SIZE}
          />
        </Show>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  position: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  silhouetteLogoImage: {
    opacity: 0.3,
  },
});
