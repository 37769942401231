import { KitIconCircle } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import { User } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { userSelector } from '../../../redux/selectors';
import { ChannelType } from '../../../redux/types';

const DirectChannelAvatar = ({ channel }: { channel: GroupChannel }) => {
  const user = useSelector(userSelector);
  const otherMembers = channel.members.filter((member) => {
    return member.userId !== user?.userId;
  });

  if (channel.members.length === 1) {
    return (
      <View
        style={{
          backgroundColor: Colors.N200,
          borderRadius: BorderRadius.xl,
          width: 36,
          height: 36,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {_renderDirectAvatar(channel.members[0], 36)}
      </View>
    );
  }

  if (otherMembers.length === 1) {
    return (
      <View
        style={{
          backgroundColor: Colors.N200,
          borderRadius: BorderRadius.xl,
          width: 36,
          height: 36,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {_renderDirectAvatar(otherMembers[0], 36)}
      </View>
    );
  } else if (otherMembers.length === 2) {
    return (
      <View style={{ borderRadius: BorderRadius.xl, width: 36, height: 36 }}>
        <View>{_renderDirectAvatar(otherMembers[0], 24)}</View>
        <View style={{ position: 'absolute', top: 12, left: 12 }}>
          {_renderDirectAvatar(otherMembers[1], 24)}
        </View>
      </View>
    );
  } else if (otherMembers.length === 3) {
    return (
      <View style={{ borderRadius: BorderRadius.xl, width: 36, height: 36 }}>
        <View
          style={{
            position: 'absolute',
            top: 2,
            left: 0,
            right: 0,
            alignItems: 'center',
          }}
        >
          {_renderDirectAvatar(otherMembers[0], 16)}
        </View>
        <View style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {_renderDirectAvatar(otherMembers[1], 16)}
        </View>
        <View style={{ position: 'absolute', bottom: 0, left: 0 }}>
          {_renderDirectAvatar(otherMembers[2], 16)}
        </View>
      </View>
    );
  } else {
    return (
      <View style={{ borderRadius: BorderRadius.xl, width: 36, height: 36 }}>
        <View style={{ position: 'absolute', top: 0, left: 0 }}>
          {_renderDirectAvatar(otherMembers[0], 16)}
        </View>
        <View style={{ position: 'absolute', top: 0, right: 0 }}>
          {_renderDirectAvatar(otherMembers[1], 16)}
        </View>
        <View style={{ position: 'absolute', bottom: 0, right: 0 }}>
          {_renderDirectAvatar(otherMembers[2], 16)}
        </View>
        <View style={{ position: 'absolute', bottom: 0, left: 0 }}>
          {_renderDirectAvatar(otherMembers[3], 16)}
        </View>
      </View>
    );
  }
};

const _renderDirectAvatar = (user: User, size: number) => {
  return (
    <KitAvatar
      size={size}
      imageUrl={user?.profileUrl}
      nickname={user?.nickname}
      border={size === 24}
    />
  );
};

interface ChannelAvatarProps {
  channel: GroupChannel;
  customSize?: number;
}
const ChannelAvatar: React.FC<ChannelAvatarProps> = ({
  channel,
  customSize,
}) => {
  if (channel.customType === ChannelType.Direct) {
    return <DirectChannelAvatar channel={channel} />;
  }

  if (
    (channel.coverUrl &&
      channel.coverUrl.startsWith('https://images.subsplash.com')) ||
    channel.coverUrl.startsWith('https://images.stage.subsplash.net')
  ) {
    return (
      <KitAvatar
        size={customSize ? customSize : 36}
        imageUrl={channel.coverUrl}
        nickname=''
        border={false}
      />
    );
  } else {
    // No group image, show fallback icon
    return (
      <KitIconCircle
        name='channel'
        size={20}
        color={Colors.N0}
        circleSize={customSize ? customSize : 36}
        backgroundColor={Colors.N200}
      />
    );
  }
};

export default ChannelAvatar;
