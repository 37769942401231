import { MenuItem } from '@waves/menu';
import { Popover } from '@waves/popover';
import { TimePicker } from '@waves/time-picker';
import moment from 'moment';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';

import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import KitInputValidationMessage from '../KitInputValidationMessage';
import { KitTimePickerProps, RefType } from './types';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'waves-time-picker': {
        value: string;
        ref: React.RefObject<unknown>;
      };
    }
  }
}

const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined';

if (isBrowser && !window.customElements.get('waves-time-picker')) {
  window.customElements.define('waves-time-picker', TimePicker);
}

if (isBrowser && !window.customElements.get('waves-popover')) {
  window.customElements.define('waves-popover', Popover);
}

if (isBrowser && !window.customElements.get('waves-menu-item')) {
  window.customElements.define('waves-menu-item', MenuItem);
}

const KitTimePicker = forwardRef<RefType, KitTimePickerProps>(
  (props, ref): JSX.Element => {
    const {
      initialTime,
      onTimeChange,
      placeholder,
      dateFormat = 'h:mma',
      style,
    } = props;
    const { t } = useTranslation();
    const wavesPickerRef = useRef<HTMLElement>(null);
    const [time, setTime] = useState<Date | null>(
      initialTime ? moment(initialTime, 'Hmm').toDate() : new Date()
    );
    const [error, setError] = useState<string | null>(null);
    const [inputFocused, setInputFocused] = useState<boolean>(false);

    const onChange = useCallback(
      (event: Event) => {
        const isValidTimeFormat = (time: Date) => {
          if (isNaN(time.getTime())) {
            return false;
          }

          const date = moment(
            time,
            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)',
            true
          );

          return date.isValid();
        };
        const time = (event as CustomEvent)?.detail?.time;

        if (time) {
          const selectedTime = moment(time, dateFormat).toDate();
          setTime(selectedTime);
          if (onTimeChange) {
            onTimeChange(selectedTime);
          }

          if (isValidTimeFormat(selectedTime)) {
            setError(null);
          } else {
            setError(t('error:timePickerValidationMessageError'));
          }
        }
      },
      [onTimeChange, dateFormat, t]
    );

    useEffect(() => {
      const wavesPicker = wavesPickerRef.current;

      if (wavesPicker) {
        // Attach the event listener to the HTML element
        wavesPicker.addEventListener('change', onChange);
        const inputElement = wavesPicker.shadowRoot?.querySelector('input');

        if (inputElement) {
          inputElement.style.border = error ? 'none' : '';
        }
      }

      // Cleanup function to remove the event listener
      return () => {
        if (wavesPicker) {
          wavesPicker.removeEventListener('change', onChange);
        }
      };
    }, [wavesPickerRef, onChange, error]);

    return (
      <>
        <View style={style} testID='kit_time_picker_web'>
          <KitInputValidationMessage
            message={error}
            testID='time-picker-validation-message'
          >
            <View style={error ? styles.errorBorder : null}>
              <waves-time-picker
                ref={wavesPickerRef}
                value={moment(time || new Date()).format(dateFormat)}
              />
            </View>
          </KitInputValidationMessage>
        </View>
      </>
    );
  }
);
const styles = StyleSheet.create({
  errorBorder: {
    borderColor: Colors.R500,
    borderWidth: 2,
    borderRadius: 4,
  },
});

export default KitTimePicker;
