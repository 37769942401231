import i18n from '@omni/kit/localization';
import moment from 'moment-timezone';

import { IGroup } from '../services/GroupsService/Types';

const defaultGroupTypes = ['activity', 'community', 'small_group', 'team'];
const defaultFrequencies = ['daily', 'weekly', 'every_other_week', 'monthly'];
const defaultTimeOfDay = ['morning', 'evening', 'afternoon'];

export const getGroupTypeDisplayName = (name?: string): string => {
  if (!name) {
    return '';
  }

  return defaultGroupTypes.includes(name)
    ? (i18n.t(`groups:groupType_${name}`) as string)
    : name;
};

export const getLocalizedFrequence = (name?: string): string => {
  if (!name) {
    return '';
  }

  return defaultFrequencies.includes(name)
    ? i18n.t('groups:frequency', { context: name })
    : name;
};

export const getFullDayName = (abbreviation: string): string => {
  const dayMap: Record<string, number> = {
    MO: 1,
    TU: 2,
    WE: 3,
    TH: 4,
    FR: 5,
    SA: 6,
    SU: 7,
  };

  return moment().day(dayMap[abbreviation]).format('dddd');
};

export const getLocalizedTimeOfDay = (name?: string): string => {
  if (!name) {
    return '';
  }

  return defaultTimeOfDay.includes(name)
    ? i18n.t('groups:timeOfDay', { context: name })
    : name;
};

export const formatTime = (inputTime: number, timezone: string): string => {
  const time = moment(inputTime.toString().padStart(4, '0'), 'HHmm');

  const tzAbbreviation = moment.tz(timezone).format('z');

  return `${time.format('h:mm A')} ${tzAbbreviation}`;
};

export const constructGroupSchedule = (group: IGroup): string => {
  const scheduleParts: string[] = [];

  if (group.frequency) {
    scheduleParts.push(getLocalizedFrequence(group.frequency));
  }

  if (group.day_of_week) {
    const dayName = getFullDayName(group.day_of_week);

    if (dayName) {
      scheduleParts.push(dayName);
    }
  }

  if (group.time_of_day && group.timezone) {
    scheduleParts.push(formatTime(group.time_of_day, group.timezone));
  }

  return scheduleParts.join(' • ');
};

export function getGroupEventFrequencyFromRRule(rrule: string[]): string {
  const rruleObj: { [key: string]: string } = {};
  rrule.forEach((rule) => {
    const [key, value] = rule.split(':');
    rruleObj[key] = value;
  });

  const rruleParts =
    rruleObj.RRULE?.split(';').reduce((acc, part) => {
      const [key, value] = part.split('=');
      acc[key] = value;

      return acc;
    }, {} as { [key: string]: string }) || {};

  const dayMap: { [key: string]: string } = {
    MO: getFullDayName('MO'),
    TU: getFullDayName('TU'),
    WE: getFullDayName('WE'),
    TH: getFullDayName('TH'),
    FR: getFullDayName('FR'),
    SA: getFullDayName('SA'),
    SU: getFullDayName('SU'),
  };

  const getOrdinalSuffix = (day: number): string => {
    return i18n.t('common:key', { ordinal: true, count: day });
  };

  let result = i18n.t('groups:frequency.every');

  switch (rruleParts.FREQ) {
    case 'DAILY':
      result += i18n.t('groups:frequency.day');
      break;

    case 'WEEKLY':
      const interval = rruleParts.INTERVAL
        ? i18n.t('groups:frequency.weeks_interval', {
            count: Number(rruleParts.INTERVAL),
          })
        : i18n.t('groups:frequency.week');
      const days = rruleParts.BYDAY?.split(',')
        .map((day) => dayMap[day])
        .join(', ');
      result += i18n.t('groups:frequency.weekly_on', { interval, days });
      break;

    case 'MONTHLY':
      const byDay = rruleParts.BYDAY;
      const byMonthDay = rruleParts.BYMONTHDAY;

      if (byDay) {
        const dayNumber = byDay.match(/\d+/);
        const day = dayNumber
          ? `${getOrdinalSuffix(parseInt(dayNumber[0], 10))} `
          : '';
        result += i18n.t('groups:frequency.monthly_on', {
          day,
          weekday: dayMap[byDay.slice(-2)],
        });
      } else if (byMonthDay) {
        result += i18n.t('groups.monthly_on_day', {
          day: getOrdinalSuffix(parseInt(byMonthDay, 10)),
        });
      }

      break;

    case 'YEARLY':
      const byMonth = rruleParts.BYMONTH;
      const byMonthDayYearly = rruleParts.BYMONTHDAY;
      const month = new Date(0, parseInt(byMonth, 10) - 1).toLocaleString(
        'default',
        { month: 'long' }
      );
      result += i18n.t('groups:frequency.yearly_on', {
        month,
        day: getOrdinalSuffix(parseInt(byMonthDayYearly, 10)),
      });
      break;

    default:
      result = '';
  }

  return result;
}
