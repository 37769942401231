import React, { useCallback, useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import Environment from '../../Environment';
import Colors from '../../theming/Colors';
import Spacing from '../../theming/Spacing';
import KitIcon from '../KitIcon';
import KitInput from '../KitInput';
import KitList from '../KitList';
import KitListItem from '../KitListItem';
import { PlacesAutocompleteProps } from './types';

// Debounce function with explicit return type
const debounce = (
  func: (...args: any[]) => void,
  delay: number
): ((...args: any[]) => void) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const PlacesAutocomplete: React.FC<PlacesAutocompleteProps> = ({
  placeholder,
  onPlaceSelected,
  testID = '',
}) => {
  const [input, setInput] = useState<string>('');
  const [debouncedValue, setDebouncedValue] = useState<string>(input);

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: Environment.googleMapsKey,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputChange = useCallback(
    debounce((value: string) => {
      setDebouncedValue(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (debouncedValue) {
      getPlacePredictions({ input: debouncedValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleOnPress = (
    item: google.maps.places.AutocompletePrediction
  ): void => {
    placesService?.getDetails({ placeId: item.place_id }, (placeDetails) => {
      onPlaceSelected(item, placeDetails); // Send place details to parent component
    });
  };

  const renderItem = (
    item: google.maps.places.AutocompletePrediction
  ): JSX.Element => (
    <div
      key={item.place_id}
      style={{ cursor: 'pointer' }}
      onClick={() => handleOnPress(item)}
    >
      <KitListItem
        title={item.description.split(' ')[0]}
        subtitle={item.description.split(' ').slice(1).join(' ')}
        topBorder={false}
        bottomBorder={true}
        customHeight={79}
        style={{ flex: 1 }}
        ImageComponent={
          <KitIcon
            name='not-filled-location'
            size={20}
            color={Colors.N600}
            style={{ marginLeft: Spacing.s }}
          />
        }
      />
    </div>
  );

  return (
    <>
      <KitInput
        placeholder={placeholder}
        value={input}
        onChangeText={(text) => {
          setInput(text);
          handleInputChange(text);
        }}
        testID={testID}
      />

      <KitList
        showsVerticalScrollIndicator={false}
        data={placePredictions}
        alwaysBounceVertical={false}
        renderItem={(item) => renderItem(item.item)}
        scrollEnabled={true}
      />
    </>
  );
};

export default PlacesAutocomplete;
