import { KitIcon, KitText } from '@omni/kit/components';
import {
  IOS_KEYBOARD_EASE,
  useKeyboardContext,
} from '@omni/kit/contexts/KeyboardContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useState } from 'react';
import { Animated, Platform, View } from 'react-native';

import { IProp } from './types';

export default ({ icon, title = '', description = '' }: IProp): JSX.Element => {
  const { keyboardEvent } = useKeyboardContext();

  const [animateHeight] = useState<Animated.Value>(
    new Animated.Value(
      keyboardEvent.show ? keyboardEvent.endCoordinates.height : 0
    )
  );

  useEffect(() => {
    Animated.timing(animateHeight, {
      duration: keyboardEvent.duration || 250,
      toValue: keyboardEvent.show ? keyboardEvent.endCoordinates.height : 0,
      useNativeDriver: false,
      easing: IOS_KEYBOARD_EASE,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardEvent.show]);

  /**
   * @Note
   * Cannot use <KeyboardAvoidingView /> because the height is
   * not adjusted if the keyboard is already visible before
   * this component is mounted.
   */
  return (
    <View
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        paddingHorizontal: Spacing.l,
      }}
    >
      <Animated.View
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: Platform.OS === 'ios' ? animateHeight : 0,
        }}
      >
        {Boolean(icon) && <KitIcon name={icon} size={36} color={Colors.N300} />}
        <KitText black bold fontSize={16} style={{ marginTop: 18 }}>
          {title}
        </KitText>
        <KitText gray center fontSize={16}>
          {description}
        </KitText>
      </Animated.View>
    </View>
  );
};
