import { KitLoader } from '@omni/kit/components';
import { IModuleCommand } from '@omni/kit/contexts/types';
import {
  NavigationProp,
  ParamListBase,
  useIsFocused,
  useNavigation,
} from '@react-navigation/native';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import * as Constants from '../../shared/Constants';
import {
  resolveChatUserThunk,
  sendbirdLoginFailed,
} from '../../shared/redux/actions/SystemActions';
import { systemErrorSelector } from '../../shared/redux/selectors';
import { isNewOmniVersion } from '../../utilities/mobileStorage';
import ErrorView from './ErrorView';

const debug = require('debug')('omni:chat:AppLoadingView');

const whatsNewData = require('@omni/blocks/templates/examples/whatsNew.json');

type Props = {
  screenProps: {
    appKey?: string;
    brandColor?: string;
    moduleCommand?: IModuleCommand;
  };
  showIntroModal?: () => void;
};

export default function AppLoadingView({
  screenProps,
  showIntroModal = undefined,
}: Props): JSX.Element {
  let isFocused = true;
  let navigation: NavigationProp<ParamListBase>;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    isFocused = useIsFocused();
  } catch {}

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigation = useNavigation();
  } catch {}

  const dispatch = useDispatch();

  const systemError = useSelector(systemErrorSelector);
  const isError = Boolean(systemError);

  const _onRetryButtonPress = async () => {
    // clear the error to show loading state
    dispatch(sendbirdLoginFailed(''));

    dispatch(resolveChatUserThunk());
  };

  useEffect(() => {
    loadChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadChat = async () => {
    await dispatch(resolveChatUserThunk());

    presentIntroModalIfNeeded();
  };

  const presentIntroModalIfNeeded = () => {
    isNewOmniVersion().then((isNewVersion) => {
      if (isNewVersion && (whatsNewData?.blocks?.length ?? 0) > 0) {
        if (showIntroModal) {
          showIntroModal();
        } else {
          navigation.navigate(Constants.INTRO_SCREEN);
        }
      }
    });
  };

  return (
    <View
      style={{
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
      }}
    >
      {isError ? (
        <ErrorView onRetryButtonPress={_onRetryButtonPress} />
      ) : isFocused ? (
        <KitLoader />
      ) : null}
    </View>
  );
}
