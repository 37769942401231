import { useShellContext } from '@omni/kit';
import { ENV } from '@omni/kit/Environment';
import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import KitPopup from '@omni/kit/components/KitPopup.web';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import AddMeetingLocationScreen from '@omni/messaging/shared/scenes/channelDetails/AddMeetingLocationScreen';
import MoreActionSheet from '@omni/messaging/shared/scenes/channelDetails/components/details/MoreActionSheet';
import { createStackNavigator } from '@react-navigation/stack';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';
import { StyleSheet } from 'react-native';
import { RootSiblingParent } from 'react-native-root-siblings';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { useSelector } from 'react-redux';

import { LeftNavType } from '../../../mobile/components/LeftNavButton';
import { getNavOptions } from '../../../mobile/navigation/AppNavigator';
import {
  ADD_MEETING_LOCATION,
  ADD_MEETING_SCHEDULE,
  CHANNEL_DETAILS_SCREEN,
  EDIT_CHANNEL_SCREEN,
  EVENT_DETAILS_SCREEN,
  GROUP_EVENTS_LIST_SCREEN,
  INVITE_SCREEN,
  MEMBER_LIST_SCREEN,
  VISIBILITY_AND_ENROLLMENT_SCREEN,
} from '../../Constants';
import { channelSelector } from '../../redux/selectors';
import { ChannelType } from '../../redux/types';
import EventDetailsScreen from '../events/EventDetailsScreen';
import EventMoreActionSheet from '../events/components/EventMoreActionsSheet';
import AddMeetingScheduleScreen from './AddMeetingScheduleScreen';
import ChannelDetailsScreen from './ChannelDetailsScreen';
import CreateEditGroupEventScreen from './CreateEditGroupEventScreen';
import EditChannelScreen from './EditChannelScreen';
import GroupEventListScreen from './GroupEventListScreen';
import InviteScreen from './InviteScreen';
import MemberListScreen from './MemberListScreen';
import VisibilityAndEnrollmentScreen from './VisibilityAndEnrollmentScreen';

const debug = require('debug')('tca:chat:screen:ChannelDetailStack');

// @ts-ignore
const Stack = Platform.select({
  default: createNativeStackNavigator(),
  web: createStackNavigator(),
});

export const ChannelDetailsStack = ({
  closeModal,
}: {
  closeModal?: () => void;
}): JSX.Element => {
  const channel = useSelector(channelSelector) as GroupChannel;
  const isManager = channel?.myRole === 'operator';
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const { app } = useShellContext();
  const groupEventsEnabled = app.appKey === 'CHURCH' && ENV === 'dev';
  const { t } = useTranslation();
  const parseChannelData = useCallback(() => {
    try {
      const data = JSON.parse(channel.data);
      debug('channel data ', data);

      return data;
    } catch {
      debug('Could not parse channel data');
    }
  }, [channel]);

  return (
    <RootSiblingParent>
      <Stack.Navigator
        screenOptions={{
          // @ts-ignore
          headerHideShadow: true,
          headerStyle: {
            elevation: 0,
            shadowColor: 'transparent',
            height: 78,
            borderBottomColor: Colors.N100,
            borderBottomWidth: 1,
          },
          headerTopInsetEnabled: false, // react-native-screens (Android)
        }}
      >
        <Stack.Screen
          name={CHANNEL_DETAILS_SCREEN}
          component={ChannelDetailsScreen}
          options={({ route, navigation }) => {
            return {
              ...getNavOptions(
                channel?.customType === ChannelType.Direct
                  ? 'Conversation details'
                  : '',
                { navigation },
                LeftNavType.Back,
                closeModal
              ),
              headerRight: () =>
                channel?.customType !== ChannelType.Direct && (
                  <View style={{ flexDirection: 'row' }}>
                    {channel?.myRole === 'operator' && (
                      <KitTouchable
                        onPress={() => {
                          navigation.navigate(EDIT_CHANNEL_SCREEN, {
                            channelData: parseChannelData(),
                          });
                        }}
                        style={{
                          padding: 12,
                          marginRight: 0,
                          borderRadius: 22,
                        }}
                      >
                        <KitIcon size={20} name='edit' />
                      </KitTouchable>
                    )}

                    {Platform.OS === 'web' ? (
                      <KitPopup
                        content={
                          <MoreActionSheet
                            // @ts-ignore
                            isManager={route.params?.isManager}
                            // @ts-ignore
                            canLeave={route.params?.canLeave}
                            // @ts-ignore
                            isMuted={route.params?.isMuted}
                            // @ts-ignore
                            onLeaveGroup={() => route.params?.onLeaveGroup()}
                            // @ts-ignore
                            onDeleteGroup={() => route.params?.onDeleteGroup()}
                            // @ts-ignore
                            onMuteGroup={() => route.params?.onMuteGroup()}
                          />
                        }
                        contentStyle={{
                          paddingHorizontal: 0,
                          paddingVertical: Spacing.l,
                        }}
                        position='bottom right'
                        triggerType='click'
                      >
                        <KitTouchable
                          style={{
                            padding: 12,
                            borderRadius: 22,
                            marginRight: Platform.OS === 'web' ? 6 : 0,
                          }}
                        >
                          <KitIcon size={20} name='action-h' />
                        </KitTouchable>
                      </KitPopup>
                    ) : (
                      <KitTouchable
                        // @ts-ignore
                        onPress={() => route.params?.openMoreActionModal()}
                        style={{
                          padding: 12,
                          borderRadius: 22,
                        }}
                      >
                        <KitIcon size={20} name='action-h' />
                      </KitTouchable>
                    )}
                  </View>
                ),
            };
          }}
        />
        {groupEventsEnabled && (
          <Stack.Screen
            name={GROUP_EVENTS_LIST_SCREEN}
            component={GroupEventListScreen}
            options={(props) => ({
              ...getNavOptions('Calendar', props),
              headerTitle:
                Platform.OS === 'web'
                  ? () => (
                      <View style={{ alignItems: 'flex-start' }}>
                        <KitText fontSize={16} bold color={Colors.N900}>
                          {t('groups:calendar')}
                        </KitText>
                        <KitText fontSize={16}>{channel.name}</KitText>
                      </View>
                    )
                  : undefined,
              headerRight: isManager
                ? () => (
                    <KitTouchable
                      onPress={null}
                      style={{
                        paddingLeft: 12,
                        paddingRight: Platform.OS === 'ios' ? 12 : 18,
                        paddingVertical: 6,
                        marginRight: -6,
                        borderRadius: 40,
                      }}
                    >
                      <KitIcon name='square-plus' size={20} />
                    </KitTouchable>
                  )
                : undefined,
            })}
          />
        )}
        <Stack.Screen
          name={EDIT_CHANNEL_SCREEN}
          component={EditChannelScreen}
          options={(props) => ({
            ...getNavOptions('Edit Group', props),
            headerRight: () => (
              <KitTouchable
                onPress={null}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText color={Colors.N400} fontSize={17}>
                  Save
                </KitText>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={VISIBILITY_AND_ENROLLMENT_SCREEN}
          component={VisibilityAndEnrollmentScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('Visibility and enrollment', { navigation }),
            headerRight: () => (
              <KitTouchable
                onPress={null}
                style={{
                  paddingLeft: 12,
                  paddingRight: Platform.OS === 'ios' ? 12 : 18,
                  paddingVertical: 6,
                  marginRight: -6,
                  borderRadius: 40,
                }}
              >
                <KitText color={Colors.N400} fontSize={17}>
                  Save
                </KitText>
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={ADD_MEETING_LOCATION}
          component={AddMeetingLocationScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('', { navigation }),
            stackPresentation: isLandscape ? 'modal' : 'push',
            headerShown: !isLandscape,
            headerLeft: () => (
              <KitTouchable
                onPress={navigation.goBack}
                style={{
                  padding: 12,
                  borderRadius: 22,
                  marginLeft: Platform.OS === 'ios' ? -10 : 0,
                }}
              >
                <KitIcon name='remove' size={20} />
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={ADD_MEETING_SCHEDULE}
          component={AddMeetingScheduleScreen}
          // @ts-ignore
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions('', { navigation }),
            stackPresentation: isLandscape ? 'modal' : 'push',
            headerShown: !isLandscape,
            headerLeft: () => (
              <KitTouchable
                onPress={navigation.goBack}
                style={{
                  padding: 12,
                  borderRadius: 22,
                  marginLeft: Platform.OS === 'ios' ? -10 : 0,
                }}
              >
                <KitIcon name='remove' size={20} />
              </KitTouchable>
            ),
          })}
        />
        <Stack.Screen
          name={MEMBER_LIST_SCREEN}
          component={MemberListScreen}
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions(route.params?.title || 'Members', { navigation }),
            stackPresentation: 'modal', // This will present the screen as a modal
          })}
        />

        <Stack.Screen
          name={INVITE_SCREEN}
          component={InviteScreen}
          // options={(props) => getNavOptions('Add people', props)}
          options={({ navigation, route }) => ({
            ...getNavOptions('Add people', { navigation }),
            stackPresentation: 'modal', // This will present the screen as a modal
          })}
        />
        <Stack.Screen
          name={EVENT_DETAILS_SCREEN}
          component={EventDetailsScreen}
          options={({ navigation, route }) => ({
            ...getNavOptions('', { navigation }),
            headerRight: () =>
              channel?.customType !== ChannelType.Direct && (
                <View style={{ flexDirection: 'row' }}>
                  {Platform.OS === 'web'
                    ? // @ts-ignore
                      route.params?.isManager && (
                        <KitPopup
                          content={
                            <EventMoreActionSheet
                              // @ts-ignore
                              isManager={route.params?.isManager}
                              // @ts-ignore
                              onEditEvent={() => route.params?.onEditEvent()}
                              onDeleteEvent={() =>
                                // @ts-ignore
                                route.params?.onDeleteEvent()
                              }
                            />
                          }
                          contentStyle={{
                            paddingHorizontal: 0,
                            paddingVertical: Spacing.l,
                          }}
                          position='bottom right'
                          triggerType='click'
                        >
                          <KitTouchable
                            style={{
                              padding: 12,
                              borderRadius: 22,
                              marginRight: Platform.OS === 'web' ? 6 : 0,
                            }}
                          >
                            <KitIcon size={20} name='action-h' />
                          </KitTouchable>
                        </KitPopup>
                      )
                    : // @ts-ignore
                      route.params?.isManager && (
                        <KitTouchable
                          // @ts-ignore
                          onPress={() => route.params?.openMoreActionModal()}
                          style={{
                            padding: 12,
                            borderRadius: 22,
                          }}
                        >
                          <KitIcon size={20} name='action-h' />
                        </KitTouchable>
                      )}
                </View>
              ),
          })}
        />
        <Stack.Screen
          name='create_event'
          component={CreateEditGroupEventScreen}
          options={({ navigation, route }) => ({
            // @ts-ignore
            ...getNavOptions(route.params?.title || t('groups:createEvent'), {
              navigation,
            }),
            stackPresentation: 'modal', // This will present the screen as a modal
          })}
        />
      </Stack.Navigator>
    </RootSiblingParent>
  );
};
