import { IndicatorSize, RadiusStyle } from '@omni/kit/Types';
import { KitIcon, KitText, KitTouchable } from '@omni/kit/components';
import ImageIndicator from '@omni/kit/components/ImageIndicator';
import Show from '@omni/kit/components/Show';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { dateFormatRange } from '@omni/kit/utilities/dateFormatRange';
import React, { useContext } from 'react';
import { View, ViewStyle } from 'react-native';

import { getRadiusForStyle } from '../../blocks/utilities';
import DateIndicator from './DateIndicator';
import PositionIndicator from './PositionIndicator';

interface IRowItemProps {
  chevronEnabled?: boolean;
  data?: IRowItemData;
  imageHeight?: number;
  imageRadius?: RadiusStyle;
  imageWidth?: number;
  marginHorizontal?: number;
  marginVertical?: number;
  onPress?: (data: any) => void;
  renderLeft?: (data: any) => JSX.Element;
  style?: ViewStyle;
  titleColor?: string;
}

interface IRowItemData {
  action?: any;
  allDay?: boolean;
  date?: string;
  endDate?: string;
  image?: string;
  position?: string;
  startDate?: string;
  subtitle?: string;
  timeZone?: string;
  title?: string;
}

export default function RowItem(props: IRowItemProps): JSX.Element {
  const {
    chevronEnabled = false,
    data = {},
    imageHeight = 50,
    imageRadius = RadiusStyle.Small,
    imageWidth = 50,
    marginHorizontal = Spacing.l,
    marginVertical = Spacing.l,
    onPress = () => null,
    renderLeft = null,
    style = {},
  } = props;

  const { colorForScheme } = useContext(ThemeContext);
  const titleColor =
    props.titleColor ??
    colorForScheme?.({ dark: Colors.N0, light: Colors.N900 });
  const subtitleColor = colorForScheme?.({
    dark: Colors.N400,
    light: Colors.N500,
  });

  const resolvedImageRadius = getRadiusForStyle(
    imageRadius,
    imageWidth,
    imageHeight
  );

  const imageIndicatorVisible = data.image || data.startDate;
  const indicatorVisible = renderLeft
    ? false
    : data.image || data.startDate || data.position;

  const _onItemPress = () => {
    if (onPress) {
      onPress(data);
    }
  };

  const _renderImageIndicator = () => {
    return (
      data.image && (
        <ImageIndicator
          style={{
            bottom: 0,
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          image={data.image}
          imageWidth={imageWidth}
          imageHeight={imageHeight}
          imageRadius={resolvedImageRadius}
        />
      )
    );
  };

  const _renderDateIndicator = () => {
    const indicatorWidth = imageHeight * 0.889;

    return (
      data.startDate && (
        <DateIndicator
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.56)',
            borderRadius: resolvedImageRadius,
            bottom: 0,
            height: indicatorWidth,
            position: 'absolute',
            right: 0,
            width: indicatorWidth,
          }}
          date={data.startDate}
          timeZone={data.timeZone}
          size={IndicatorSize.Small}
          dayColor={Colors.N0}
          monthColor='rgba(255, 255, 255, 0.7)'
        />
      )
    );
  };

  const _renderPositionIndicator = () => {
    return (
      data.position && (
        <PositionIndicator
          style={{ bottom: 0, left: 0, position: 'absolute', right: 0, top: 0 }}
          color={colorForScheme?.({ dark: Colors.N500, light: Colors.N200 })}
          position={data.position}
          size={IndicatorSize.Small}
        />
      )
    );
  };

  const formattedDateString = data.startDate
    ? dateFormatRange({
        allDay: data.allDay,
        endDate: data.endDate,
        startDate: data.startDate,
        timezone: data.timeZone,
      })
    : undefined;

  return (
    <KitTouchable
      onPress={() => _onItemPress()}
      borderRadius={resolvedImageRadius}
      underlayColor='#00000011'
      style={style}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          marginHorizontal,
        }}
      >
        {renderLeft ? (
          renderLeft(data)
        ) : (
          <View
            style={[
              { marginVertical },
              imageIndicatorVisible
                ? {
                    backgroundColor: Colors.N300,
                    borderRadius: resolvedImageRadius,
                    height: imageHeight,
                    width: imageWidth,
                  }
                : indicatorVisible
                ? {
                    height: imageHeight,
                    width: imageWidth,
                  }
                : {
                    /**
                     * Collapse the indicator, but keep the height for visual
                     * consistency with other rows.
                     */
                    height: imageHeight,
                    width: 0,
                  },
            ]}
          >
            {_renderImageIndicator()}
            {_renderDateIndicator()}
            {!data.image && !data.startDate && _renderPositionIndicator()}
          </View>
        )}

        <View
          style={{
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
            marginLeft: !indicatorVisible ? 0 : Spacing.m,
          }}
        >
          <Show show={Boolean(data.title && data.title.length > 0)}>
            <KitText bold color={titleColor} numberOfLines={1}>
              {data.title}
            </KitText>
          </Show>
          <Show show={Boolean(data.subtitle && data.subtitle.length > 0)}>
            <KitText
              style={{ lineHeight: 20 }}
              fontSize={14}
              color={subtitleColor}
              numberOfLines={1}
            >
              {data.subtitle}
            </KitText>
          </Show>
          <Show
            show={Boolean(
              formattedDateString && formattedDateString.length > 0
            )}
          >
            <KitText
              style={{ lineHeight: 20 }}
              fontSize={14}
              color={subtitleColor}
              numberOfLines={1}
            >
              {formattedDateString}
            </KitText>
          </Show>
        </View>
        <Show show={chevronEnabled}>
          <View
            style={{
              alignItems: 'flex-end',
              justifyContent: 'center',
              marginVertical,
            }}
          >
            <KitIcon
              name='arrow-right'
              size={16}
              color={colorForScheme?.({
                dark: Colors.N500,
                light: Colors.N200,
              })}
            />
          </View>
        </Show>
      </View>
    </KitTouchable>
  );
}
