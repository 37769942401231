import { KitText } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import { User } from '@sendbird/chat';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import RoundImageButton from './RoundImageButton';

const debug = require('debug')('tca:chat:component:BlockedUserListRow');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  key: string;
  onUserEditPress?: (user: User) => void;
  user: User;
}

export default function BlockedUserListRow({
  user,
  onUserEditPress,
}: IProps): JSX.Element {
  const _onActionButtonPress = () => {
    onUserEditPress ? onUserEditPress(user) : null;
  };

  const _renderAvatar = () => {
    const { profileUrl, nickname } = user;

    return <KitAvatar nickname={nickname} imageUrl={profileUrl} size={36} />;
  };

  const _renderNickname = () => (
    <KitText black fontSize={16}>
      {user.nickname}
    </KitText>
  );

  return (
    <View style={styles.container}>
      {_renderAvatar()}
      <View style={styles.containerText}>{_renderNickname()}</View>
      {onUserEditPress && (
        <RoundImageButton
          onPress={_onActionButtonPress}
          color='#999b9e'
          size={22}
          buttonSize={35}
          name='action-h'
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 18,
    paddingVertical: 6,
  },
  containerText: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 12,
  },
});
