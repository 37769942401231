import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import RootReducer from './reducers';

const composeEnhancers =
  // @ts-ignore
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStore = () => store;
