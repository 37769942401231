import { ReactNode, ReactPortal, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Platform } from 'react-native';

const mount =
  Platform.OS === 'web' ? document.getElementById('portal-root') : null;
const el = Platform.OS === 'web' ? document.createElement('div') : null;

const Portal = ({ children }: { children?: ReactNode }): ReactPortal => {
  useEffect(() => {
    if (!el) return;

    mount?.appendChild(el);

    return () => {
      mount?.removeChild(el);
    };
  }, []);

  return createPortal(children, el as HTMLDivElement);
};

export default Portal;
