import { KitIcon } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';

export default function ScrollButtons({
  scrollOffset,
  scrollToEnd,
}: {
  scrollOffset: number;
  scrollToEnd: (...args: unknown[]) => void;
}): JSX.Element {
  return (
    <>
      {scrollOffset > 700 && Platform.OS !== 'web' && (
        <Pressable
          onPress={scrollToEnd}
          style={({ pressed }) => ({
            ...styles.scrollToBottom,
            backgroundColor: pressed ? Colors.N100 : Colors.N0,
          })}
        >
          <KitIcon name='arrow-down-android' size={22} color={Colors.brand} />
        </Pressable>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  scrollToBottom: {
    alignItems: 'center',
    alignSelf: 'center',
    borderColor: 'rgba(0,0,0,.04)',
    borderRadius: 80,
    borderWidth: 2,
    bottom: 110,
    flexDirection: 'row',
    height: 45,
    justifyContent: 'center',
    position: 'absolute',
    shadowColor: Colors.N1000,
    shadowOffset: {
      height: 1,
      width: 0,
    },
    shadowOpacity: 0.06,
    shadowRadius: 12,
    width: 45,
    zIndex: 1,
  },
  viewUnread: {
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 80,
    flexDirection: 'row',
    height: 40,
    justifyContent: 'center',
    paddingHorizontal: Spacing.xl,
    position: 'absolute',
    top: Spacing.l,
    zIndex: 1,
  },
});
