import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroupAddress } from '../Types';

const RESOURCE = 'addresses';

interface ICreateAddress extends Omit<IUri, 'resource'> {
  body: Partial<IGroupAddress>;
  token: string;
}

interface ICreateAddressResponse extends IHttpResponse {
  body?: IGroupAddress;
}

export default ({
  body,
  token,
}: ICreateAddress): Promise<ICreateAddressResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
  });

  return BaseServiceV2.Post({ url, token, data: body });
};
