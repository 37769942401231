import { KitSnack } from '@omni/kit/components';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import {
  bytesPerMb,
  fileTooLargeMessage,
  maxFileSizeInMb,
} from '@omni/kit/utilities/utilities';
import { logEvent } from '@omni/kit/utilities/utilities';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendFileMessage } from '../../../../shared/redux/actions/ChatActions';
import { channelSelector } from '../../../../shared/redux/selectors';
import { IFile, MessageType } from '../../../../shared/redux/types';
import { processImageFile } from '../../../../utilities/imageUtilities';

const debug = require('debug')('omni:chat:components:ActionBar');

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WebFileAction() {
  const channel = useSelector(channelSelector);
  const dispatch = useDispatch();

  const _onPhotoSelection = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageObject = event.target.files?.[0];

    if (!imageObject) {
      return;
    }

    const imageProcessed = await processImageFile(imageObject);
    // @ts-ignore
    dispatch(sendFileMessage(channel as GroupChannel, imageProcessed));
  };

  const _onFileSelecton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObject = event.target.files?.[0];

    if (!fileObject) {
      return;
    }

    const fileSize = (fileObject.size ?? 0) / bytesPerMb; // in megabytes

    if (fileSize > maxFileSizeInMb) {
      KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);
      logEvent('chat_file_exceeds_limit');

      return;
    }

    if (fileObject.type.includes('image/')) {
      _onPhotoSelection(event);

      return;
    }

    const file: IFile = {
      file: fileObject,
      customType: MessageType.File,
      data: {},
      name: fileObject.name,
      type: fileObject.type,
    };

    dispatch(sendFileMessage(channel as GroupChannel, file));
  };

  return (
    <input style={{ display: 'none' }} type='file' onChange={_onFileSelecton} />
  );
}
