import { IGroupEvent } from '@omni/kit/services/GroupsService/Types';
import { getDateRangeParts } from '@omni/kit/utilities/dateFormatRange';
import {
  ImageServiceType,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Event extends IGroupEvent {
  image: string | undefined;
  eventSubtitle: string;
  formatedDate: string;
  sectionHeaderDate: string;
}

export interface GroupedEventSection {
  sectionHeader: string;
  data: Event[];
}

export const useTransformGroupEvents = (
  events: Event[]
): GroupedEventSection[] => {
  const [data, setData] = useState<GroupedEventSection[]>([]);
  const { t } = useTranslation(['schedule']);

  const transformData = useCallback(
    (events: Event[]): GroupedEventSection[] => {
      const today = moment().startOf('day');

      const formatSectionHeader = (startDate: string): string => {
        const date = moment(startDate);

        if (date.isSame(moment(), 'day')) {
          return t('schedule:today');
        } else if (date.isSame(moment().add(1, 'day'), 'day')) {
          return t('schedule:tomorrow');
        } else if (!date.isSame(moment(), 'year')) {
          return date.format('dddd, MMMM D, YYYY');
        } else {
          return date.format('dddd, MMMM D');
        }
      };

      const upcomingEvents = events
        .filter((event) => event.id)
        .filter((event) => moment(event.start_at).isSameOrAfter(today))
        .filter((event) => event.status === 'published')
        .sort((a, b) =>
          moment(a.start_at).isBefore(moment(b.start_at)) ? -1 : 1
        ); // Sort by start date

      // Group the events by date
      return upcomingEvents.reduce(
        (accumulator: GroupedEventSection[], currentValue) => {
          const { dateString, timeString, allDay, timezone } =
            getDateRangeParts({
              startDate: currentValue.start_at ? currentValue.start_at : '',
              endDate: currentValue.end_at,
            });

          let formatedDate = dateString;

          if (timeString) {
            formatedDate += ` \u2022 ${timeString}`;
          }

          /** Add timezone code if not in same timezone */
          if (allDay === false && timezone?.isLocal === false) {
            formatedDate += ` ${timezone?.abbreviated}`;
          }

          const imgUrl = parseImageUrl(
            createImageUrl(currentValue?._embedded?.images?.[0].id),
            36,
            36,
            ImageServiceType.ImageJpeg
          );

          const event: Event = {
            ...currentValue,
            image: imgUrl,
            eventSubtitle: timeString ? timeString : dateString,
            formatedDate,
            sectionHeaderDate: formatSectionHeader(
              currentValue.start_at ? currentValue.start_at : ''
            ),
          };

          const sectionHeader = accumulator.findIndex(
            (section) => section.sectionHeader === event.sectionHeaderDate
          );

          if (sectionHeader === -1) {
            accumulator.push({
              sectionHeader: event.sectionHeaderDate,
              data: [event],
            });
          } else {
            accumulator[sectionHeader].data.push(event);
          }

          return accumulator;
        },
        []
      ); // Return an array of grouped event sections
    },
    [t]
  );

  useEffect(() => {
    setData(transformData(events));
  }, [events, transformData]);

  return data;
};
