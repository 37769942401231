import Environment from '../Environment';
import { Country, CountryWithSubdivisions, ILocation } from '../Types';

const debug = require('debug')('tca:kit:service:Location');

interface BaseResponse {
  meta: {
    count: number;
    total: number;
  };
  status: number;
}

interface CountriesResponse extends BaseResponse {
  data: {
    countries: Country[];
  };
}

interface LocationsResponse extends BaseResponse {
  data: {
    locations: ILocation[];
  };
}

interface SubdivisionsResponse extends BaseResponse {
  data: CountryWithSubdivisions & Country;
}

export const getAllCountries = async (): Promise<CountriesResponse> => {
  const url = _buildUrl('countries');

  return _getAll(url) as Promise<CountriesResponse>;
};

export const getAllSubdivisions = async (
  resource: string | number
): Promise<SubdivisionsResponse> => {
  const url = _buildUrl(`countries/${resource}`);

  return _getAll(url) as Promise<SubdivisionsResponse>;
};

export const getAllLocations = async (
  resource: string
): Promise<LocationsResponse> => {
  const url = _buildUrl(resource);

  return _getAll(url) as Promise<LocationsResponse>;
};

const _getAll = async (url: string): Promise<unknown> => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'X-Sap-Service': 'omni-app' },
    });

    return _handleAllResponse(response);
  } catch (response_1) {
    // @ts-ignore
    return _handleErrorResponse(response_1);
  }
};

const _buildUrl = (resource: string) => {
  return `${Environment.locationService}/${resource}`;
};

const _handleAllResponse = (response: Response) => {
  if (!response.ok) return Promise.reject(response);

  return response.json().then((data) => {
    return {
      meta: {
        count: data.count,
        total: data.total,
      },
      status: response.status,
      data: data._embedded ? data._embedded : data,
    };
  });
};

const _handleErrorResponse = (response: Response) => {
  return response.text().then((text) => {
    try {
      const json = JSON.parse(text);

      return Promise.reject({
        status: response.status,
        message: Array.isArray(json.errors) ? json.errors[0].detail : json,
      });
    } catch (e) {
      return Promise.reject({
        status: response.status,
        message: text,
      });
    }
  });
};
