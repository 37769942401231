import Environment from '../../Environment';
import { IFilter, IInclude, buildUrl } from '../BaseServiceV2';

const host = Environment.builderService;

export interface IPrepareWebAppUrl {
  filters?: IFilter[];
  id?: string;
  includes?: IInclude[];
}

export function prepareWebApprUrl({
  filters,
  id,
  includes,
}: IPrepareWebAppUrl): string {
  return buildUrl({
    host,
    resource: 'web-apps',
    filters,
    includes,
    id,
  });
}
