import { KitButton, KitIcon, KitText } from '@omni/kit/components';
import { SizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

export function RTJConfirmationModal({
  onConfirm,
  sizeClass,
}: {
  onConfirm: () => void;
  sizeClass: SizeClass;
}): JSX.Element | null {
  const { t } = useTranslation(['messaging']);

  return (
    <View>
      <View style={{ alignItems: 'center' }}>
        <KitIcon
          name='completed-circle'
          size={78}
          color={Colors.N300}
          style={{ marginBottom: Spacing.s }}
        />
      </View>
      <KitText
        bold
        fontSize={20}
        center={true}
        black
        style={{ marginTop: Spacing.xxs, marginBottom: Spacing.s }}
      >
        {t('messaging:textRequestWasSent')}
      </KitText>
      <KitText
        fontSize={14}
        center={true}
        gray
        style={{ marginBottom: Spacing.xl }}
      >
        {t('messaging:textKeepAnEyeOnEmail')}
      </KitText>
      <View
        style={{
          ...(sizeClass === SizeClass.Small && {
            flexDirection: 'column',
          }),
          ...(sizeClass !== SizeClass.Small && {
            flexDirection: 'row',
            alignSelf: 'center',
          }),
        }}
      >
        <KitButton
          title={t('messaging:buttonOkayGotIt')}
          color={Colors.N100}
          secondary
          onPress={onConfirm}
          style={{
            ...(sizeClass === SizeClass.Small && { marginTop: Spacing.l }),
            ...(sizeClass !== SizeClass.Small && { marginRight: Spacing.m }),
          }}
          small={sizeClass !== SizeClass.Small}
        />
      </View>
    </View>
  );
}
