import { KitText } from '@omni/kit/components';
import KitCheckmark from '@omni/kit/components/KitCheckmark';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

type KitMultiSelectProps = {
  options: any[];
  selOptions: any[];
  setSelOptions: (sel: any[]) => void;
  style?: ViewStyle;
};

const KitMultiSelect: React.FC<KitMultiSelectProps> = ({
  options,
  selOptions,
  setSelOptions,
  style,
}) => {
  const toggleOption = (option: any) => {
    const isSelected = selOptions.some((item) => item.value === option.value);
    const newSelectedOptions = isSelected
      ? selOptions.filter((item) => item.value !== option.value)
      : [...selOptions, option];

    setSelOptions(newSelectedOptions);
  };

  return (
    <View style={[{ flex: 1, maxWidth: 300 }, style]}>
      {options.map((option, i) => (
        <TouchableOpacity
          key={i}
          onPress={() => toggleOption(option)}
          style={styles.optionContainer}
        >
          <KitCheckmark
            checked={selOptions.some((s) => s.label === option.label)}
            onToggle={() => toggleOption(option)}
          />
          <KitText
            ellipsizeMode='tail'
            numberOfLines={1}
            fontSize={16}
            lineHeight={22}
            color={Colors.N900}
          >
            {option.label}
          </KitText>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
  },
});

export default KitMultiSelect;
