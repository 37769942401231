import { version } from '@omni/kit';
import { KitListItem, KitText } from '@omni/kit/components';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Linking,
  ScrollView,
  SectionList,
  StyleSheet,
  View,
} from 'react-native';

import { BLOCKED_USER_LIST_SCREEN } from '../../shared/Constants';

const debug = require('debug')('tca:chat:screen:ChatSettingsScreen');

//******************************************************************************
// Types
//******************************************************************************

export interface IProps {
  navigation: any;
}

export default function ChatSettingsScreen({
  navigation,
}: IProps): JSX.Element {
  const { t } = useTranslation(['common']);

  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.LONG,
  });

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  const _navigateBlockedUserScreen = () => {
    navigation.navigate(BLOCKED_USER_LIST_SCREEN);
  };

  const _onFeedbackPressed = () => {
    const url = 'https://subsplash.wufoo.com/forms/product-feedback/';
    Linking.openURL(url);
  };

  const _onClearData = () => {
    AsyncStorage.clear()
      .catch(() => undefined) // suppress sentry error
      .finally(() => {
        debug('Cache cleared');
        showKitSnack(t('common:cacheCleared'), KitSnackDuration.LONG);
      });
  };

  const sections = [
    {
      title: 'Privacy',
      data: [{ title: 'Blocked people', onPress: _navigateBlockedUserScreen }],
    },
    {
      title: 'Product feedback',
      data: [{ title: 'Send feedback', onPress: _onFeedbackPressed }],
    },
    {
      title: 'More',
      data: [
        { title: `Version ${version}` },
        { title: 'Clear all app data', onPress: _onClearData },
      ],
    },
  ];

  // @ts-ignore
  const _keyExtractor = (item, index) => item.title + index;

  // @ts-ignore
  const _renderItem = ({ item }) => (
    <KitListItem title={item.title} onPress={item.onPress} />
  );

  // @ts-ignore
  const _renderSectionHeader = ({ section }) => (
    <View style={styles.sectionTitleView}>
      <KitText semiBold black style={styles.sectionTitle}>
        {section.title}
      </KitText>
    </View>
  );

  return (
    <>
      <ScrollView style={styles.safeContainer}>
        <SectionList
          sections={sections}
          keyExtractor={_keyExtractor}
          renderItem={_renderItem}
          renderSectionHeader={_renderSectionHeader}
          stickySectionHeadersEnabled={false}
        />
      </ScrollView>
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  safeContainer: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
    paddingHorizontal: Spacing.l,
  },
  sectionTitleView: {
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  },
  sectionTitle: {
    marginTop: Spacing.xxl,
    marginBottom: Spacing.l,
  },
});
