import { KitText, KitTouchable } from '@omni/kit/components';
import KitAvatar from '@omni/kit/components/KitAvatar';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { userSelector } from '../../../shared/redux/selectors';
import { ChannelMember } from '../../Types';

const debug = require('debug')('tca:chat:component:MemberListRow');

//******************************************************************************
// Types
//******************************************************************************

export interface MentionListRowProps {
  key: string;
  member: ChannelMember;
  onPress: (member: ChannelMember) => void;
}

export default function MentionListRow({
  member,
  onPress,
}: MentionListRowProps): JSX.Element {
  const currentUser = useSelector(userSelector);

  const onMemberPress = () => {
    onPress(member);
  };

  const _renderMemberAvatar = () => {
    const { profileUrl, nickname } = member;

    return <KitAvatar nickname={nickname} imageUrl={profileUrl} size={36} />;
  };

  const _renderNickname = () => {
    const { nickname, userId } = member;
    const currentId = currentUser?.userId;

    return (
      <View style={{ flexDirection: 'row' }}>
        <KitText black fontSize={16} numberOfLines={1}>
          {`${nickname}${userId === currentId ? ' (you)' : ''}`}
        </KitText>
      </View>
    );
  };

  return (
    <KitTouchable
      borderRadius={0}
      onPress={onMemberPress}
      style={styles.container}
    >
      <>
        {_renderMemberAvatar()}
        <View style={styles.containerText}>{_renderNickname()}</View>
      </>
    </KitTouchable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 18,
    paddingVertical: 6,
  },
  containerText: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 12,
  },
});
