import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { useRef } from 'react';
import { Animated, StyleSheet, ViewStyle } from 'react-native';
import Popup from 'reactjs-popup';

import BorderRadius from '../theming/BorderRadius';
import Depth from '../theming/Depth';

interface IKitPopupProps {
  children: React.ReactNode;
  contentStyle?: ViewStyle;
  content: React.ReactNode;
  position?:
    | 'top left'
    | 'top center'
    | 'top right'
    | 'right top'
    | 'right center'
    | 'right bottom'
    | 'bottom left'
    | 'bottom center'
    | 'bottom right'
    | 'left top'
    | 'left center'
    | 'left bottom'
    | 'center center';
  triggerType: 'hover' | 'click' | 'focus' | 'right-click';

  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  setIsOpen?: (b: boolean) => void;
  zIndex?: number;
}

export default function KitPopup({
  children,
  contentStyle,
  content,
  position = 'bottom left',
  triggerType = 'hover',
  isOpen,
  setIsOpen,
  zIndex,
}: IKitPopupProps): JSX.Element {
  const anim = { duration: 150, useNativeDriver: true };
  const values = { opacity: { init: 0, end: 1 }, scale: { init: 0.8, end: 1 } };

  const opacity = useRef(new Animated.Value(values.opacity.init)).current;
  const scale = useRef(new Animated.Value(values.scale.init)).current;

  const fadeIn = () => {
    Animated.timing(opacity, { ...anim, toValue: values.opacity.end }).start();
    Animated.timing(scale, { ...anim, toValue: values.scale.end }).start();
  };

  const fadeOut = () => {
    setIsOpen && setIsOpen(false);
    opacity.setValue(values.opacity.init);
    scale.setValue(values.scale.init);
  };

  return (
    <Popup
      open={isOpen}
      on={triggerType}
      onOpen={fadeIn}
      onClose={fadeOut}
      trigger={
        <div
          className='button'
          role='button'
          style={{
            borderWidth: 0,
            backgroundColor: 'transparent',
            cursor: 'pointer',
            padding: 0,
            display: 'inline-block',
          }}
        >
          {children}
        </div>
      }
      arrowStyle={{ display: 'none' }}
      contentStyle={{ zIndex: zIndex ? zIndex : 2 }}
      position={position}
    >
      <Animated.View
        style={[
          styles.popup,
          Depth.xl,
          contentStyle,
          { opacity, transform: [{ scale }] },
        ]}
      >
        {content}
      </Animated.View>
    </Popup>
  );
}

const styles = StyleSheet.create({
  popup: {
    backgroundColor: Colors.N0,
    borderRadius: BorderRadius.l,
    padding: Spacing.l,
  },
});
