export const SystemActionTypes = {
  // Startup
  INIT_SENDBIRD: 'INIT_SENDBIRD',
  INIT_STATE: 'INIT_STATE',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  RESET: 'RESET',
  LOGOUT: 'LOGOUT',
  RESOLVE_NATIVE_AUTH_ERROR: 'RESOLVE_NATIVE_AUTH_ERROR',
  RESOLVE_NATIVE_AUTH_SUCCESS: 'RESOLVE_NATIVE_AUTH_SUCCESS',
  RESOLVE_SYSTEM_ERROR: 'RESOLVE_SYSTEM_ERROR',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_APP_ID: 'SET_APP_ID',
  SET_APP_TITLE: 'SET_APP_TITLE',

  // Load Channel List
  CLEAR_SELECTED_GROUP_CHANNEL: 'CLEAR_SELECTED_GROUP_CHANNEL',
  GROUP_CHANNEL_LIST_FAIL: 'GROUP_CHANNEL_LIST_FAIL',
  GROUP_CHANNEL_PROGRESS_END: 'GROUP_CHANNEL_PROGRESS_END',
  GROUP_CHANNEL_PROGRESS_START: 'GROUP_CHANNEL_PROGRESS_START',
  PUBLIC_CHANNEL_LIST_FAIL: 'PUBLIC_CHANNEL_LIST_FAIL',
  SET_APP_BRANDING: 'SET_APP_BRANDING',
  SET_APP_KEY: 'SET_APP_KEY',
  SET_ORG_KEY: 'SET_ORG_KEY',
  SET_CURRENT_CHANNEL: 'SET_CURRENT_CHANNEL',
  SET_DELETED_CHANNEL: 'SET_DELETED_CHANNEL',
  SET_USER_ID: 'SET_USER_ID',
  SET_VIEW_READY: 'SET_VIEW_READY',
  SET_WEB_DETAILS_EXPANDED: 'SET_WEB_DETAILS_EXPANDED',
  UPDATE_CHANNEL_LIST: 'UPDATE_CHANNEL_LIST',
  UPDATE_PUBLIC_CHANNEL_LIST: 'UPDATE_PUBLIC_CHANNEL_LIST',
  UPDATE_PUSH_PREF: 'UPDATE_PUSH_PREF',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  UPDATE_USER: 'UPDATE_USER',
  SET_PROFILE_IMAGE_URL: 'SET_PROFILE_IMAGE_URL',
} as const;
