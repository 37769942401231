import { KitText, KitTouchable } from '@omni/kit/components';
import ImageIndicator from '@omni/kit/components/ImageIndicator';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitMediaDurationIndicator from '@omni/kit/components/KitMediaDurationIndicator';
import KitProgress from '@omni/kit/components/KitProgress';
import Show from '@omni/kit/components/Show';
import { animatePressIn, animatePressOut } from '@omni/kit/theming/Animating';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { isEqual } from 'lodash';
import React, { useContext, useState } from 'react';
import {
  Animated,
  DimensionValue,
  Image,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import LinearGradient, {
  LinearGradientProps,
} from 'react-native-linear-gradient';

import {
  getFlexForAlignment,
  getRadiusForStyle,
} from '../../blocks/blocks/utilities';
import DateIndicator from '../../blocks/components/List/DateIndicator';
import PositionIndicator from '../../blocks/components/List/PositionIndicator';
import {
  IGridItemData,
  IndicatorSize,
  ItemAlignment,
  ItemTextPosition,
  RadiusStyle,
} from '../Types';

interface IGridItemProps {
  aspectRatio?: number;
  averageColor?: string;
  data?: IGridItemData;
  fixedWidthEnabled?: boolean;
  height?: number;
  ignoreImageLoad?: boolean;
  imageHeight?: number;
  imageRadius?: RadiusStyle;
  imageWidth?: number;
  mediaDurationStyle?: ViewStyle;
  textWrap?: boolean;
  onPress?: () => void;
  percentageStyle?: ViewStyle;
  progressContainerStyle?: ViewStyle;
  progressWrapperStyle?: ViewStyle;
  shadowEnabled?: boolean;
  silhouetteLogoImage?: string;
  silhouetteLogoImageSize?: number;
  style?: ViewStyle;
  subtitleMarginTop?: number;
  subtitleTextColor?: string;
  subtitleLineHeight?: number;
  textAlignmentHorizontal?: ItemAlignment;
  textAlignmentVertical?: ItemAlignment;
  textCenter?: boolean;
  textPosition?: ItemTextPosition;
  titleIndicator?: JSX.Element | null;
  titleTextColor?: string;
  titleTextSize?: number;
  titleLineHeight?: number;
  width?: DimensionValue;
  noImage?: boolean;
  noRadius?: boolean;
  isAvatar?: boolean;
  linearGradient?: LinearGradientProps;
}

function GridItem(props: IGridItemProps): JSX.Element {
  const [hasImageLoadEnded, setHasImageLoadEnded] = useState<boolean>(false);
  const itemScale = new Animated.Value(1);
  const {
    aspectRatio,
    averageColor,
    data = {},
    fixedWidthEnabled = false,
    height = 136,
    ignoreImageLoad = false,
    imageHeight = 136,
    imageRadius = RadiusStyle.None,
    imageWidth = 136,
    mediaDurationStyle,
    noRadius,
    onPress,
    percentageStyle,
    progressContainerStyle,
    progressWrapperStyle,
    shadowEnabled = true,
    silhouetteLogoImage,
    silhouetteLogoImageSize,
    style = {},
    subtitleMarginTop = Spacing.xs,
    subtitleTextColor,
    subtitleLineHeight = 16,
    textAlignmentVertical = ItemAlignment.Center,
    textPosition = ItemTextPosition.Below,
    titleIndicator = null,
    titleTextColor,
    titleTextSize = 14,
    titleLineHeight = 19,
    width = 136,
    // depends on textPosition
    textAlignmentHorizontal = textPosition === ItemTextPosition.Below
      ? ItemAlignment.Start
      : ItemAlignment.Center,
    isAvatar = false,
    linearGradient,
  } = props;

  const { colorForScheme } = useContext(ThemeContext);

  const resolvedImageRadius = getRadiusForStyle(imageRadius, width, height);

  const titleColor = titleTextColor
    ? titleTextColor
    : colorForScheme?.({ light: Colors.N900, dark: Colors.N0 });
  const subtitleColor = subtitleTextColor
    ? subtitleTextColor
    : colorForScheme?.({ light: Colors.N500, dark: Colors.N300 });

  const textAlignmentHorizontalFlex = getFlexForAlignment(
    textAlignmentHorizontal
  );

  const _onImageLoadEnd = (): void => {
    setHasImageLoadEnded(true);
  };

  const RenderBrandingIndicator = (): JSX.Element | null => {
    if (!silhouetteLogoImage) return null;

    return (
      <View
        style={[
          styles.brandingContainer,
          {
            backgroundColor: averageColor || Colors.N150,
            borderRadius: resolvedImageRadius,
          },
        ]}
      >
        <Image
          source={{
            uri: silhouetteLogoImage,
            width: silhouetteLogoImageSize,
            height: silhouetteLogoImageSize,
          }}
          style={styles.silhouetteLogoImage}
          resizeMode='cover'
        />
      </View>
    );
  };

  const RenderImageIndicator = (): JSX.Element | null => {
    if (!data.image) return null;

    return (
      <ImageIndicator
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        image={data.image}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageRadius={resolvedImageRadius}
        overlayTitle={
          textPosition === ItemTextPosition.Overlay ? data.title : undefined
        }
        overlaySubtitle={
          textPosition === ItemTextPosition.Overlay ? data.subtitle : undefined
        }
        titleTextColor={titleTextColor}
        subtitleTextColor={subtitleTextColor}
        textAlignmentHorizontal={textAlignmentHorizontal}
        textAlignmentVertical={textAlignmentVertical}
        onLoadEnd={_onImageLoadEnd}
      />
    );
  };

  const RenderDateIndicator = (): JSX.Element | null => {
    if (!data.startDate) return null;

    const indicatorWidth = height * 0.889;

    return (
      <DateIndicator
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.56)',
          borderRadius: resolvedImageRadius,
          bottom: 0,
          height: indicatorWidth,
          position: 'absolute',
          right: 0,
          width: indicatorWidth,
        }}
        date={data.startDate}
        timeZone={data.timeZone}
        size={IndicatorSize.Medium}
        dayColor={Colors.N0}
        monthColor='rgba(255, 255, 255, 0.7)'
      />
    );
  };

  const RenderProgress = (): JSX.Element | null => {
    if (!data.percentage) return null;

    return (
      <LinearGradient
        colors={['#00000000', '#00000026']}
        end={{ x: 0, y: 1 }}
        start={{ x: 0, y: 0.01 }}
        style={{ ...styles.progressWrapper, ...progressWrapperStyle }}
      >
        <Show show={Boolean(data.duration)}>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
            }}
          >
            <KitMediaDurationIndicator
              duration={data.duration}
              wrapperStyle={mediaDurationStyle}
            />
          </View>
        </Show>
        <KitProgress
          percentage={data.percentage}
          percentageStyle={{
            ...styles.percentage,
            ...percentageStyle,
          }}
          progressContainerStyle={{
            ...styles.progressContainer,
            ...progressContainerStyle,
          }}
        />
      </LinearGradient>
    );
  };

  const RenderPositionIndicator = (): JSX.Element | null => {
    if ((!data.image && !data.startDate) || !data.position) return null;

    return (
      <PositionIndicator
        style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
        color={colorForScheme?.({ light: Colors.N500, dark: Colors.N400 })}
        position={data.position}
        size={IndicatorSize.Medium}
      />
    );
  };

  const _onPressIn = (): void => {
    animatePressIn(itemScale);
  };

  const _onPressOut = (): void => {
    animatePressOut(itemScale);
  };

  const RenderImage = () => {
    return (
      <View
        style={[
          fixedWidthEnabled
            ? {
                /**
                 * Occurs in horizontal lists in which numColumns is
                 * undefined, so each item needs a fixed width and height.
                 */
                alignItems: 'center',
                display: 'flex',
                height: height,
                justifyContent: 'center',
                width: width,
              }
            : aspectRatio
            ? {
                /**
                 * Occurs in vertical lists where each item's width is already
                 * determined by column count + effective margins.
                 * In order to support aspect ratio on the web, we must use
                 * paddingTop percentage + absolute position on children,
                 * ref: https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
                 * react-native-web does not currently support the aspectRatio
                 * prop: https://github.com/necolas/react-native-web/issues/427
                 */
                paddingTop: ((1 / aspectRatio) * 100 + '%') as `${number}%`,
              }
            : {},
          shadowEnabled && (hasImageLoadEnded || ignoreImageLoad)
            ? Platform.select({
                android: {
                  elevation: 5,
                },
                default: {
                  shadowColor: '#000000',
                  shadowOpacity: 0.15,
                  shadowOffset: { width: 0, height: 4 },
                },
              })
            : {},
          {
            backgroundColor: averageColor
              ? averageColor
              : colorForScheme?.({ light: Colors.N100, dark: Colors.N900 }),
            borderRadius: resolvedImageRadius,
            // Workaround for: https://github.com/facebook/react-native/issues/23090
            opacity: 0.99,
            overflow: 'hidden', // Ensure content is clipped by the container's borders
          },
        ]}
      >
        <RenderBrandingIndicator />
        <RenderImageIndicator />
        <RenderProgress />
        <RenderDateIndicator />
        <RenderPositionIndicator />
      </View>
    );
  };

  return (
    <KitTouchable
      style={style}
      onPress={onPress}
      onPressIn={_onPressIn}
      onPressOut={_onPressOut}
      borderRadius={resolvedImageRadius}
      underlayColor='transparent'
      noRadius={noRadius}
    >
      <Animated.View
        style={{
          transform: [{ scale: itemScale }],
        }}
      >
        <Show show={!props.noImage}>
          {isAvatar ? (
            <KitAvatar
              nickname={data.title}
              size={100}
              imageUrl={data.image}
              style={{ backgroundColor: Colors.N800 }}
              color='#999b9e'
            />
          ) : (
            <RenderImage />
          )}
        </Show>
        <Show show={textPosition === ItemTextPosition.Below}>
          <View
            style={[
              {
                alignItems: textAlignmentHorizontalFlex,
                flexBasis: 'auto',
                flexGrow: 1,
                flexShrink: 1,
                marginVertical: Spacing.s,
                minHeight: 1, // height of 0 will crash the app MED-8823
                width,
              },
              props.noImage && {
                marginVertical: 0,
                marginBottom: 10,
                paddingVertical: 11,
                paddingHorizontal: 18,
                backgroundColor: Colors.N800,
                width: 160,
                height: 40,
                borderRadius: BorderRadius.m,
              },
            ]}
          >
            <Show show={Boolean(data.title)}>
              <KitText
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  lineHeight: titleLineHeight,
                }}
                center={props.textCenter}
                fontSize={titleTextSize}
                bold
                color={titleColor}
                numberOfLines={props.textWrap ? 0 : 1}
                ellipsizeMode='clip'
              >
                {data.title}
              </KitText>
            </Show>
            <Show show={Boolean(titleIndicator)}>
              {titleIndicator as JSX.Element}
            </Show>
            <Show show={Boolean(data.subtitle)}>
              <KitText
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 'auto',
                  lineHeight: subtitleLineHeight,
                  marginTop: subtitleMarginTop,
                }}
                center={props.textCenter}
                fontSize={14}
                color={subtitleColor}
                numberOfLines={props.textWrap ? 0 : 1}
                ellipsizeMode='clip'
              >
                {data.subtitle}
              </KitText>
            </Show>
            <Show show={!props.textWrap}>
              <LinearGradient
                start={{ x: 0.7, y: 0 }}
                end={{ x: 1, y: 0 }}
                // @ts-ignore
                colors={
                  colorForScheme?.({
                    // @ts-ignore
                    dark: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)'],
                    // @ts-ignore
                    light: ['rgba(255, 255, 255, 0)', 'rgba(255,255, 255, 1)'],
                  }) || []
                }
                style={{
                  height: '100%',
                  position: 'absolute',
                  right: -1,
                  width: '100%',
                }}
              />
            </Show>
          </View>
        </Show>
        <Show show={Boolean(linearGradient)}>
          <LinearGradient {...(linearGradient as LinearGradientProps)} />
        </Show>
      </Animated.View>
    </KitTouchable>
  );
}

/**
 * Compare GridItem data and style props to determine if it should rerender or
 * not. For our use cases only this two properties need to be checked for
 * now. Later more properties can be added or a better solution can be
 * implemented.
 */
const arePropsEqual = (
  prevProps: IGridItemProps,
  nextProps: IGridItemProps
) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.style, nextProps.style)
  );
};

export default React.memo(GridItem, arePropsEqual);

const styles = StyleSheet.create({
  brandingContainer: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  button: {
    alignItems: 'center',
    backgroundColor: Colors.N1000,
    borderRadius: 32,
    height: 29,
    justifyContent: 'center',
    opacity: 0.8,
    width: 29,
  },
  silhouetteLogoImage: {
    borderRadius: 3,
    opacity: 0.3,
  },
  progressWrapper: {
    borderBottomEndRadius: BorderRadius.m,
    borderBottomStartRadius: BorderRadius.m,
    bottom: 0,
    display: 'flex',
    height: 44,
    justifyContent: 'flex-end',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  progressContainer: {
    backgroundColor: '#ffffff66',
    borderBottomEndRadius: BorderRadius.m,
    borderBottomStartRadius: BorderRadius.m,
    height: 3,
  },
  percentage: {
    backgroundColor: Colors.N0,
    borderBottomStartRadius: BorderRadius.m,
    height: 3,
  },
});
