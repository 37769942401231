import { KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import {
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
} from '../../../../shared/redux/selectors';
import CreateMessageOrGroup from './CreateMessageOrGroup';

export default function SidebarHeader(): JSX.Element {
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const canCreateChannels = useSelector(canCreateChannelsSelector);

  return (
    <View style={styles.wrapper}>
      <KitText black bold fontSize={24}>
        Messaging
      </KitText>
      {(canCreateChannels || canCreateDirectMessage) && (
        <CreateMessageOrGroup />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    height: 78,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Spacing.l,
    borderBottomWidth: 1,
    borderColor: Colors.N100,
  },
  createIcon: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 2,
  },
});
