import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import Colors from '../theming/Colors';

/**
 * Function that calculates rotation of the semicircle
 **/
const rotateByStyle = (
  percent: number,
  baseDegrees: number,
  clockwise: boolean
) => {
  let rotateBy = baseDegrees;

  if (clockwise) {
    rotateBy = baseDegrees + percent * 3.6;
  } else {
    //anti clockwise progress
    rotateBy = baseDegrees - percent * 3.6;
  }

  return {
    transform: [{ rotateZ: `${rotateBy}deg` }],
  };
};
const renderThirdLayer = (
  percent: number,
  commonStyles: ViewStyle,
  ringColorStyle: ViewStyle,
  ringBgColorStyle: ViewStyle,
  clockwise: boolean,
  innerRingStyle: ViewStyle,
  startDegrees: number
) => {
  let rotation = 45 + startDegrees;
  let offsetLayerRotation = -135 + startDegrees;

  if (!clockwise) {
    rotation += 180;
    offsetLayerRotation += 180;
  }

  if (percent > 50) {
    /**
     * Third layer circles default rotation is kept 45 degrees for clockwise rotation, so by default it occupies the right half semicircle.
     * Since first 50 percent is already taken care  by second layer circle, hence we subtract it
     * before passing to the rotateByStyle function
     **/

    return (
      <View
        style={[
          styles.secondProgressLayer,
          rotateByStyle(percent - 50, rotation, clockwise),
          commonStyles,
          ringColorStyle,
        ]}
      />
    );
  } else {
    return (
      <View
        style={[
          styles.offsetLayer,
          innerRingStyle,
          ringBgColorStyle,
          { transform: [{ rotateZ: `${offsetLayerRotation}deg` }] },
        ]}
      />
    );
  }
};

export interface ProgressBarProps {
  percent: number;
  radius?: number;
  bgRingWidth?: number;
  progressRingWidth?: number;
  ringColor?: string;
  ringBgColor?: string;
  clockwise?: boolean;
  bgColor?: string;
  startDegrees?: number;
}

const CircularProgress: React.FC<ProgressBarProps> = ({
  percent = 0,
  radius = 18,
  bgRingWidth = 6,
  progressRingWidth = 4,
  ringColor = Colors.brand,
  ringBgColor = Colors.N50,
  clockwise = true,
  bgColor = Colors.N0,
  startDegrees = 0,
}) => {
  const commonStyles = {
    width: radius * 2,
    height: radius * 2,
    borderRadius: radius,
    borderTopWidth: progressRingWidth,
    borderLeftWidth: progressRingWidth,
    borderBottomWidth: progressRingWidth,
    borderRightWidth: progressRingWidth,
  };

  /**
   * Calculate radius for base layer and offset layer.
   * If progressRingWidth == bgRingWidth, innerRadius is equal to radius
   **/
  const widthDiff = progressRingWidth - bgRingWidth;
  const innerRadius = radius - progressRingWidth + bgRingWidth + widthDiff / 2;

  const innerRingStyle = {
    width: innerRadius * 2,
    height: innerRadius * 2,
    borderRadius: innerRadius,
    borderTopWidth: bgRingWidth,
    borderLeftWidth: bgRingWidth,
    borderBottomWidth: bgRingWidth,
    borderRightWidth: bgRingWidth,
  };

  const ringColorStyle = {
    borderRightColor: ringColor,
    borderTopColor: ringColor,
  };

  const ringBgColorStyle = {
    borderRightColor: ringBgColor,
    borderTopColor: ringBgColor,
  };

  const thickOffsetRingStyle = {
    borderRightColor: bgColor,
    borderTopColor: bgColor,
  };

  let rotation = -135 + startDegrees;

  /**
   * If we want our ring progress to be displayed in anti-clockwise direction
   **/
  if (!clockwise) {
    rotation += 180;
  }

  let firstProgressLayerStyle;
  /* when ther ring's border widths are different and percent is less than 50, then we need an offsetLayer
   * before the original offser layer to avoid ring color of the thick portion to be visible in the background.
   */
  let displayThickOffsetLayer = false;

  if (percent > 50) {
    firstProgressLayerStyle = rotateByStyle(50, rotation, clockwise);
  } else {
    firstProgressLayerStyle = rotateByStyle(percent, rotation, clockwise);
    if (progressRingWidth > bgRingWidth) {
      displayThickOffsetLayer = true;
    }
  }

  let offsetLayerRotation = -135 + startDegrees;

  if (!clockwise) {
    offsetLayerRotation += 180;
  }

  return (
    <View style={[styles.container, { width: radius, height: radius }]}>
      <View
        style={[
          styles.baselayer,
          innerRingStyle,
          {
            borderColor: ringBgColor,
            borderWidth: percent > 0 ? bgRingWidth : 0,
          },
        ]}
      />
      <View
        style={[
          styles.firstProgressLayer,
          firstProgressLayerStyle,
          commonStyles,
          ringColorStyle,
          {
            borderTopWidth: progressRingWidth,
            borderRightWidth: progressRingWidth,
          },
        ]}
      />
      {displayThickOffsetLayer && (
        <View
          style={[
            styles.offsetLayer,
            commonStyles,
            thickOffsetRingStyle,
            {
              transform: [{ rotateZ: `${offsetLayerRotation}deg` }],
              borderWidth: percent > 0 ? bgRingWidth : 0,
            },
          ]}
        />
      )}
      {renderThirdLayer(
        percent,
        commonStyles,
        ringColorStyle,
        ringBgColorStyle,
        clockwise,
        innerRingStyle,
        startDegrees
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  baselayer: {
    position: 'absolute',
  },
  /**
   * Using `#00000001` here instead of `transparent` to work around an Android issue
   * where if a View with borderRadius has a transparent border, none of the borders display.
   * See: https://github.com/facebook/react-native/issues/34722
   */
  firstProgressLayer: {
    position: 'absolute',
    borderLeftColor: '#00000001',
    borderBottomColor: '#00000001',
  },
  secondProgressLayer: {
    position: 'absolute',
    borderLeftColor: '#00000001',
    borderBottomColor: '#00000001',
  },
  offsetLayer: {
    position: 'absolute',
    borderLeftColor: '#00000001',
    borderBottomColor: '#00000001',
  },
  display: {
    position: 'absolute',
  },
});

export default CircularProgress;
