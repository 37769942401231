import React, { useContext } from 'react';

import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';
import FileMessageBubble from '../../../../../mobile/scenes/channel/components/messageTypes/FileMessageBubble';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function FileMessage({ message, disabled }): JSX.Element {
  const { handleSelectingMessage } = useContext(ChatContext);

  return (
    <FileMessageBubble
      message={message}
      onLongPress={handleSelectingMessage}
      disabled={disabled}
    />
  );
}
