import { KitButton, KitLoader, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { useIsFocused } from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { PreviousMessageListQuery } from '@sendbird/chat/message';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';

import { SendbirdMessage } from '../../../Types';
import { CHAT_THREAD_SCREEN } from '../../../shared/Constants';
import {
  getThreadsList,
  setIsLoading,
} from '../../../shared/redux/actions/ChatActions';
import { channelSelector } from '../../../shared/redux/selectors';
import Message from '../../../shared/scenes/channel/components/Message';
import { getShortChannelUrl } from '../../../utilities/chatUtilities';
import { sbCreatePreviousMessageListQuery } from '../../../utilities/sendbird/chatFunctions';

const debug = require('debug')('tca:chat:screen:ThreadListScreen');

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function ThreadListScreen({ navigation }): JSX.Element {
  // Redux state
  const channel = useSelector(channelSelector);

  // Local state
  const [threads, setThreads] = useState<SendbirdMessage[][]>([]);
  const [prevQuery, setPrevQuery] = useState<PreviousMessageListQuery | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const isFocused = useIsFocused();

  const initQuery = useCallback(() => {
    const query = sbCreatePreviousMessageListQuery(
      channel as GroupChannel,
      100
    );

    if (query) {
      setPrevQuery(query);
    } else {
      debug('Error creating previous thread list query');
    }
  }, [channel]);

  const runQuery = useCallback(() => {
    if (!prevQuery) return;

    setIsLoading(true);
    getThreadsList(prevQuery)
      .then((newThreads) => {
        if (newThreads && newThreads.length) {
          try {
            setThreads(threads.concat(newThreads));
          } catch (e) {
            debug('Error on setThreads: ', e);
          }
        }
      })
      .catch((e) => {
        debug('Error on getThreadsList: ', e);
      })
      .finally(() => {
        setLoading(false);
        setLoadMore(false);
      });
  }, [prevQuery, threads]);

  // Effects

  useEffect(() => {
    if (!prevQuery) {
      debug('Initializing query...');
      initQuery();

      return;
    }

    debug('Running query...');
    runQuery();
  }, [initQuery, prevQuery, runQuery]);

  useEffect(() => {
    if (loadMore) {
      debug('loadMore requested. Running query...');
      runQuery();
    }
  }, [runQuery, loadMore]);

  useEffect(() => {
    if (isFocused) {
      setPrevQuery(null);
      setThreads([]);
      setLoading(true);
    }
  }, [isFocused]);

  return (
    <View style={styles.list}>
      {loading ? (
        <Loading />
      ) : threads.length ? (
        <FlatList
          data={threads}
          renderItem={({ item, index }) => (
            <ThreadGroup
              thread={item}
              onOpenThread={() =>
                navigation.navigate(CHAT_THREAD_SCREEN, {
                  // @ts-ignore
                  parentMessageId: item[0].messageId,
                  channelId: getShortChannelUrl(channel?.url),
                })
              }
              lastItem={index === threads.length - 1}
            />
          )}
          // @ts-ignore
          keyExtractor={(item) => item.messageId}
          onEndReached={() => {
            if (prevQuery?.hasNext && !loadMore && !loading) {
              debug('FlatList:onEndReached');
              setLoadMore(true);
            }
          }}
          ListFooterComponent={
            loadMore ? (
              <View style={styles.loadMore}>
                <Loading />
              </View>
            ) : null
          }
        />
      ) : (
        <Empty />
      )}
    </View>
  );
}

// @ts-ignore
function ThreadGroup({ thread, lastItem, onOpenThread }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <View style={[styles.threadGroup, lastItem && { marginBottom: 0 }]}>
      <ThreadMessage message={thread[0]} />
      {thread.length > 3 &&
        (expanded ? (
          thread
            .slice(1, thread.length - 2)
            // @ts-ignore
            .map((message) => (
              <ThreadMessage message={message} key={message.messageId} />
            ))
        ) : (
          <TouchableOpacity
            onPress={() => setExpanded(true)}
            style={{ marginLeft: 60, marginVertical: Spacing.s }}
          >
            <KitText brandColor fontSize={16}>
              {thread.length === 4
                ? '1 more reply'
                : `${thread.length - 3} more replies`}
            </KitText>
          </TouchableOpacity>
        ))}
      {thread.length > 2 && (
        <ThreadMessage message={thread[thread.length - 2]} />
      )}
      {thread.length > 1 && (
        <ThreadMessage message={thread[thread.length - 1]} />
      )}
      <KitButton
        title='Reply in thread'
        white
        style={{ marginLeft: 60, marginTop: Spacing.m, marginRight: 18 }}
        secondary
        small
        titleStyle={{ fontWeight: 'normal', fontSize: 16 }}
        onPress={onOpenThread}
      />
    </View>
  );
}

// @ts-ignore
function ThreadMessage({ message }) {
  return (
    <Message
      message={message}
      showReactions={false}
      showThreads={false}
      showDate
      disabled
      onReport={undefined}
      onBlock={undefined}
    />
  );
}

function Loading() {
  return (
    <View style={styles.empty}>
      <KitLoader />
    </View>
  );
}

function Empty() {
  return (
    <View style={styles.empty}>
      <KitText center style={{ marginBottom: Spacing.xxl }}>
        There are no threads in this conversation.
        {Platform.OS !== 'web' &&
          ' You can start a thread by pressing and holding on a message.'}
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
    backgroundColor: Colors.N0,
    marginBottom: Spacing.l,
    borderTopWidth: 1,
    borderTopColor: Colors.N100,
  },
  threadGroup: {
    backgroundColor: Colors.N0,
    paddingTop: 10,
    paddingBottom: Spacing.l,
    marginBottom: Spacing.m,
  },
  loadMore: {
    paddingTop: Spacing.xl,
    paddingBottom: Spacing.xxl,
    backgroundColor: Colors.N0,
  },
  empty: {
    flex: 1,
    backgroundColor: Colors.N0,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Spacing.xxl,
  },
});
