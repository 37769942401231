import { dispatch as actionDispatch } from '@omni/kit/ActionHandler';
import Environment from '@omni/kit/Environment';
import HealthMonitor from '@omni/kit/HealthMonitor';
import { ActionTypeAndPayloadObj } from '@omni/kit/Types';
import { AuthService } from '@omni/kit/auth';
import { KitSnack } from '@omni/kit/components';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import removeFromCache from '@omni/kit/services/CacheService/RemoveCachedResponse';
import { Branding, ILogoImage } from '@omni/kit/services/Types';
import SendbirdChat, {
  BaseChannel,
  PushTriggerOption,
  User,
} from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelHandler,
  GroupChannelListQuery,
  PublicGroupChannelListQuery,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import { Platform } from 'react-native';
import { Action, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import ChatService from '../../../services/ChatService';
import { ChatUserInfoObject, userService } from '../../../services/userService';
import {
  removeAppIdFromDevice,
  removeEndUserIdFromDevice,
  removeUserIdFromDevice,
  retrieveUserIdFromDevice,
  saveAppIdToDevice,
  saveEndUserIdToDevice,
  saveUserIdToDevice,
} from '../../../utilities/mobileStorage';
import {
  sbGetGroupChannel,
  sbGetGroupChannelList,
  sbGetPushPreference,
  sbHideGroupChannel,
  sbSetPushPreference,
} from '../../../utilities/sendbird/channelFunctions';
import {
  sbConnect,
  sbCreateApplicationUserListQuery,
  sbDisconnect,
  sbGetAppUsers,
  sbIsConnected,
  sbRegisterPushToken,
  sbUnregisterPushToken,
} from '../../../utilities/sendbird/userFunctions';
import * as Constants from '../../Constants';
import {
  accessTokenSelector,
  appKeySelector,
  channelListSelector,
  environmentSelector,
  publicChannelListSelector,
  userSelector,
} from '../selectors';
import { ChannelType, IAppState } from '../types';
import { sendTextMessage, setChannel, setChannelName } from './ChatActions';
import { SystemActionTypes } from './SystemActionTypes';

const debug = require('debug')('omni:messaging:redux:actions:SystemActions');

const RETRY_AFTER = 1000;
const RETRY_LIMIT = 3;

type DispatchMethod = (arg: ActionTypeAndPayloadObj) => void;

//****************************************************************************
// Simple Actions
//****************************************************************************

const updateSystemState = (initialState: unknown): ActionTypeAndPayloadObj => ({
  payload: initialState,
  type: SystemActionTypes.INIT_STATE,
});

export const resetState = (): Action => ({
  type: SystemActionTypes.RESET,
});

export const logoutState = (): Action => ({
  type: SystemActionTypes.LOGOUT,
});

export const setAccessToken = (
  accessToken: string
): ActionTypeAndPayloadObj => ({
  payload: accessToken,
  type: SystemActionTypes.SET_ACCESS_TOKEN,
});

const sendbirdLoginSuccess = (user: unknown): ActionTypeAndPayloadObj => ({
  payload: user,
  type: SystemActionTypes.LOGIN_SUCCESS,
});

export const sendbirdLoginFailed = (
  error: unknown
): ActionTypeAndPayloadObj => ({
  payload: error,
  type: SystemActionTypes.LOGIN_FAIL,
});

export const resolveNativeAuthError = (
  error: unknown
): ActionTypeAndPayloadObj => ({
  payload: error,
  type: SystemActionTypes.RESOLVE_NATIVE_AUTH_ERROR,
});

export const resolveSystemError = (
  error: unknown
): ActionTypeAndPayloadObj => ({
  payload: error,
  type: SystemActionTypes.RESOLVE_SYSTEM_ERROR,
});

export const updatePushPref = (value: unknown): ActionTypeAndPayloadObj => {
  debug('update push pref: ', value);

  return {
    payload: value,
    type: SystemActionTypes.UPDATE_PUSH_PREF,
  };
};

export const updateUserList = (userList: unknown): ActionTypeAndPayloadObj => ({
  payload: userList,
  type: SystemActionTypes.UPDATE_USER_LIST,
});

export const setAppId = (appId: string): ActionTypeAndPayloadObj => ({
  payload: appId,
  type: SystemActionTypes.SET_APP_ID,
});

export const setAppTitle = (title: string): ActionTypeAndPayloadObj => ({
  payload: title,
  type: SystemActionTypes.SET_APP_TITLE,
});

export const setDeletedChannel = (
  channelUrl: string | null
): ActionTypeAndPayloadObj => ({
  payload: channelUrl,
  type: SystemActionTypes.SET_DELETED_CHANNEL,
});

export const setUserId = (userId?: string): ActionTypeAndPayloadObj => ({
  payload: userId,
  type: SystemActionTypes.SET_USER_ID,
});

export const setViewReady = (ready: boolean): ActionTypeAndPayloadObj => ({
  payload: ready,
  type: SystemActionTypes.SET_VIEW_READY,
});

const setUser = (user: unknown): ActionTypeAndPayloadObj => ({
  payload: user,
  type: SystemActionTypes.UPDATE_USER,
});

export const setAppBranding = (payload: {
  branding: Branding | undefined;
  logo: ILogoImage | undefined;
}): ActionTypeAndPayloadObj => ({
  payload,
  type: SystemActionTypes.SET_APP_BRANDING,
});

export const setAppKey = (key: string): ActionTypeAndPayloadObj => ({
  payload: key,
  type: SystemActionTypes.SET_APP_KEY,
});

export const setOrgKey = (key: string): ActionTypeAndPayloadObj => ({
  payload: key,
  type: SystemActionTypes.SET_ORG_KEY,
});

export const setProfileImageUrl = (
  profileImageUrl: string
): ActionTypeAndPayloadObj => ({
  payload: profileImageUrl,
  type: SystemActionTypes.SET_PROFILE_IMAGE_URL,
});

//****************************************************************************
// Thunks
//****************************************************************************

export const initState = (initialState: {
  appKey?: string;
  brandColor?: string;
}): ((dispatch: DispatchMethod) => void) => {
  debug('initState()');

  return (dispatch) => {
    const stateValues = {
      appKey: initialState.appKey,
      brandColor: initialState.brandColor || Constants.DEFAULT_BRAND_COLOR,
    };
    debug('updated state values', stateValues);
    dispatch(updateSystemState(stateValues));
  };
};

let sendbirdConnectRetryCount = 0;
const chatSendbirdLoginThunk =
  (
    userId: string,
    token: string,
    appId: string
  ): ThunkAction<
    Promise<void | ActionTypeAndPayloadObj>,
    IAppState,
    unknown,
    AnyAction
  > =>
  (dispatch) =>
    sbConnect({ userId, token, appId })
      .then((user) => {
        dispatch(sendbirdLoginSuccess(user));
      })
      .then(() => {
        if (Platform.OS !== 'web') {
          sbRegisterPushToken();
        }

        sendbirdConnectRetryCount = 0;
      })
      .catch((error) => {
        if (sendbirdConnectRetryCount < RETRY_LIMIT) {
          debug(
            `Failed to connect to Sendbird: ${JSON.stringify(error)}`,
            'Trying again...'
          );
          sendbirdConnectRetryCount++;
          setTimeout(() => {
            dispatch(chatSendbirdLoginThunk(userId, token, appId));
          }, RETRY_AFTER * sendbirdConnectRetryCount);
        } else {
          debug(`Failed to connect to Sendbird: ${error}}`);

          if (error instanceof Error) {
            error.message = `Failed to connect to Sendbird: ${error.message}`;
            HealthMonitor.logError(error);
          } else {
            HealthMonitor.logError(
              new Error(`Failed to connect to Sendbird: ${error}`)
            );
          }

          dispatch(sendbirdLoginFailed(error));
        }
      });

let fetchChatUserRetryCount = 0;

export const resolveChatUserThunk =
  (): ThunkAction<
    Promise<ChatUserInfoObject | void>,
    IAppState,
    unknown,
    AnyAction
  > =>
  (dispatch, getState) =>
    getChatUserInfo(getState())
      .then(
        ({
          appId,
          canCreateChannels,
          canCreateDirectMessage,
          endUserId,
          userId,
          userToken,
        }: ChatUserInfoObject) => {
          saveAppIdToDevice(appId);
          saveUserIdToDevice(userId);
          saveEndUserIdToDevice(endUserId);
          dispatch({
            payload: {
              canCreateChannels,
              canCreateDirectMessage,
              userId,
              userToken,
            },
            type: SystemActionTypes.RESOLVE_NATIVE_AUTH_SUCCESS,
          });
          dispatch(setAppId(appId));

          return { userId, userToken, appId };
        }
      )
      .then(({ userId, userToken, appId }) => {
        // @ts-ignore
        dispatch(chatSendbirdLoginThunk(userId, userToken, appId)).then(() => {
          dispatch(setViewReady(true));
          fetchChatUserRetryCount = 0;
        });
      })
      .catch((error) => {
        if (fetchChatUserRetryCount < RETRY_LIMIT) {
          debug('Could not get chat user info: ', error, 'Trying again...');
          fetchChatUserRetryCount++;
          setTimeout(() => {
            dispatch(resolveChatUserThunk());
          }, RETRY_AFTER * fetchChatUserRetryCount);
        } else {
          debug('Could not get chat user info: ', error);

          if (error instanceof Error) {
            error.message = `Could not get user info from chat-sessions: ${error.message}`;
            HealthMonitor.logError(error);
          } else {
            HealthMonitor.logError(
              new Error(`Could not get user info from chat-sessions: ${error}`)
            );
          }

          dispatch(sendbirdLoginFailed(error));
        }
      });

// @ts-ignore
const getChatUserInfo = (state) => {
  const sessionEndpoint = `${environmentSelector(state)}/chat-sessions`;

  return userService.getChatUserInfo(
    appKeySelector(state),
    accessTokenSelector(state),
    sessionEndpoint
  );
};

export const logout = (
  silent = false
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const appKey: string = appKeySelector(getState());

    const context = 'Logout';
    debug(context, 'started');
    handleLogoutUnregisterPushToken(getState())
      .catch((error) => {
        debug(`Unregister push token error: ${error}`);
      })
      .finally(() => {
        debug(context, 'Removing stored ids');
        removeStoredIds();

        removeFromCache(
          `${Environment.feedsService}/profile/${appKey.toUpperCase()}/user`
        );

        /** State should be reset even if the API calls fail */
        debug(context, 'Resetting app state');
        dispatch(logoutState());

        debug(context, 'AuthService logout started');
        AuthService.logout();

        /**
         * This KitSnack is vital to Detox E2E tests in moduleCommand.spec.js.
         * We show the snack for an arbitrarily long period of time (e.g. 10 seconds)
         * so that the E2E test will succeed in the slow headless emulator in CI.
         * The test failed with shorter durations such as 3.5 seconds.
         */
        !silent && KitSnack.show('Logged Out', KitSnackDuration.SUPER_LONG);
      });
  };
};

// A connection with Sendbird is required in order to unregister a push token
// If a failure occurs, we log the error, but always resolve the promise to allow
// other aspects of logout to run as expected.
// @ts-ignore
const handleLogoutUnregisterPushToken = (state): Promise<void> => {
  return new Promise<void>((resolve) => {
    const context = 'LogoutUnregisterPushToken';

    if (Platform.OS === 'web') {
      debug(context, 'Skipping unregister push token process for web platform');
      sbDisconnect();
      resolve();
    } else {
      /**
       * The stored userId is a hint that the user is logged-in.
       * We must check this first to avoid inadvertently creating chat users on logout.
       */
      retrieveUserIdFromDevice().then((storedUserId) => {
        if (storedUserId) {
          debug(
            context,
            'Proceeding with unregistering push token since there is a stored userId. We are dealing with an existing chat user and will not inadvertently create a new one.'
          );
          getChatUserInfo(state)
            // @ts-ignore // todo: refactor this promise return type to be cleaner
            .then(({ userId, _, userToken, appId }) => {
              debug(
                context,
                'Request to chat sessions finished',
                JSON.stringify({ userId, userToken, appId })
              );
              if (userId && appId && userToken) {
                sbConnect({ userId, token: userToken, appId })
                  .then(() => {
                    debug(context, 'sbConnect finished');
                    sbUnregisterPushToken()
                      .then(() => {
                        debug('Unregistering push token succeeded');
                      })
                      .catch((err) => {
                        debug(err);
                      })
                      .finally(() => {
                        debug('Finished unregister push token step');
                        sbDisconnect()
                          .catch((error) => {
                            debug(`Error on sbDisconnect: ${error}`);
                          })
                          .finally(() => {
                            resolve();
                          });
                      });
                  })
                  .catch((err) => {
                    debug(
                      context,
                      `sbConnect failed, unable to unregister push token: ${err}`
                    );
                    resolve();
                  });
              } else {
                debug(
                  context,
                  'Unable to unregister push token during logout. Missing one of the following: userId, appId, or userToken'
                );
                resolve();
              }
            })
            .catch((err) => {
              debug('Error for getChatUserInfo:', err);
              resolve();
            });
        } else {
          debug(
            context,
            'Skipping unregister push token process during logout. There was not a stored userId, so if we proceed, this will create a new chat user.'
          );
          resolve();
        }
      });
    }
  });
};

export const removeStoredIds = async (): Promise<void> => {
  try {
    await removeAppIdFromDevice();
    debug('Removed stored appId');
  } catch (err) {
    debug('Error removing stored appId: ', err);
  }

  try {
    await removeUserIdFromDevice();
    debug('Removed stored userId');
  } catch (err) {
    debug('Error removing stored userId', err);
  }

  try {
    await removeEndUserIdFromDevice();
    debug('Removed stored endUserId');
  } catch (err) {
    debug('Error removing stored endUserId', err);
  }
};

/** Platform agnostic way to log out the user */
export const universalLogout = (
  silent = false
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    if (Platform.OS === 'web') {
      dispatch(logout(silent));
    } else {
      actionDispatch({ handler: 'appUser', command: 'logOut' });
    }
  };
};

//****************************************************************************
// Channel Actions
//****************************************************************************

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clearSelectedGroupChannel = () => ({
  type: SystemActionTypes.CLEAR_SELECTED_GROUP_CHANNEL,
});

const updateGroupChannelProgress = (start: boolean) => {
  return {
    type: start
      ? SystemActionTypes.GROUP_CHANNEL_PROGRESS_START
      : SystemActionTypes.GROUP_CHANNEL_PROGRESS_END,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updateChannelList = (
  channels: GroupChannel[],
  append?: boolean
) => ({
  type: SystemActionTypes.UPDATE_CHANNEL_LIST,
  payload: { append, channelsList: channels },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const updatePublicChannelList = (channels: GroupChannel[]) => ({
  type: SystemActionTypes.UPDATE_PUBLIC_CHANNEL_LIST,
  payload: channels,
});

export const getGroupChannelListThunk = (
  groupChannelListQuery: GroupChannelListQuery,
  append?: boolean
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  debug('getGroupChannelListThunk()');

  return (dispatch) => {
    dispatch(updateGroupChannelProgress(true));

    if (groupChannelListQuery && groupChannelListQuery.hasNext) {
      sbGetGroupChannelList(groupChannelListQuery)
        .then((channels) => {
          dispatch(updateChannelList(channels as GroupChannel[], append));
        })
        .catch((error) => {
          dispatch({ type: SystemActionTypes.GROUP_CHANNEL_LIST_FAIL });
        });
    } else {
      dispatch({ type: SystemActionTypes.GROUP_CHANNEL_LIST_FAIL });
    }
  };
};

export const getPublicChannelListThunk = (
  groupChannelListQuery: PublicGroupChannelListQuery
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  debug('getPublicChannelListThunk()');

  return (dispatch) => {
    if (groupChannelListQuery && groupChannelListQuery.hasNext) {
      sbGetGroupChannelList(groupChannelListQuery)
        .then((channels) => {
          dispatch(updatePublicChannelList(channels as GroupChannel[]));
        })
        .catch((error) => {
          dispatch({ type: SystemActionTypes.PUBLIC_CHANNEL_LIST_FAIL });
        });
    } else {
      dispatch({ type: SystemActionTypes.PUBLIC_CHANNEL_LIST_FAIL });
    }
  };
};

export const createChannelListHandler = (): ThunkAction<
  void,
  IAppState,
  unknown,
  AnyAction
> => {
  return (dispatch, getState) => {
    if (!sbIsConnected()) {
      debug('Skipping createChannelListHandler. Not connected.');

      return;
    }

    const channelHandler = new GroupChannelHandler({
      onChannelChanged: (channel: BaseChannel) => {
        if (!sbIsConnected()) {
          debug('Skipping onChannelChanged. Not connected.');

          return;
        }

        debug('SendbirdChat.instance.ChannelHandler.onChannelChanged');

        const newChannelList = [...(channelListSelector(getState()) ?? [])];
        const channelIndex = newChannelList.findIndex(
          (ch) => ch.url === channel.url
        );
        newChannelList[channelIndex] = channel as GroupChannel;
        newChannelList.sort((a, b) => {
          if (!a.lastMessage || !b.lastMessage) {
            return 1;
          }

          return b.lastMessage.createdAt - a.lastMessage.createdAt;
        });
        dispatch(updateChannelList(newChannelList));
      },
      onChannelDeleted: (channelUrl: string) => {
        debug('channel DELETED', channelUrl);
        dispatch(setDeletedChannel(channelUrl));
        dispatch(setChannel(null));
        dispatch(setChannelName(''));
        const channelList = [...(channelListSelector(getState()) ?? [])];
        const deletedChannel = channelList.find((ch) => ch.url === channelUrl);
        const newChannelList = channelList.filter(
          (ch) => ch.url !== channelUrl
        );

        if (deletedChannel) {
          KitSnack.show(
            `${deletedChannel.name} was deleted`,
            KitSnack.duration.SHORT
          );
        }

        dispatch(updateChannelList(newChannelList));
        dispatch(setDeletedChannel(null));
      },
      onUserReceivedInvitation: (channel: GroupChannel) => {
        debug('invited to a channel!');
        dispatch(addChannelToChannelList(channel));
      },
    });

    debug('SendbirdChat.instance.addChannelHandler');

    (
      SendbirdChat.instance as SendbirdGroupChat
    )?.groupChannel.addGroupChannelHandler(
      'GROUP_CHANNEL_LIST_HANDLER',
      channelHandler
    );
  };
};

export const deleteChannel = (
  channelUrl: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState): void => {
    ChatService.delete('channels', channelUrl)
      .then(() => {
        const channelList = [...channelListSelector(getState())];
        const newChannelList = channelList.filter(
          (ch) => ch.url !== channelUrl
        );
        dispatch(updateChannelList(newChannelList));
      })
      .catch((error) =>
        KitSnack.show('Error deleting channel', KitSnack.duration.SHORT)
      );
  };
};

// export const renameChannel = (channelName: string, channelUrl: string) => {
//   // @ts-ignore
//   return (dispatch) => {
//     ChatService.patch('channels', channelUrl, { name: channelName })
//       .then((response: any) => {
//         debug('Successfully renamed channel: ', response);
//         dispatch(setChannelName(response.data.name));
//         KitSnack.show('Channel has been renamed', KitSnack.duration.SHORT);
//       })
//       .catch((error) => {
//         debug('Could not rename channel: ', error);
//         KitSnack.show('Error renaming channel', KitSnack.duration.SHORT);
//       });
//   };
// };

export const updateChannel = (
  channel: GroupChannel,
  channelName: string,
  channelImageUrl: string,
  channelIsPublic: boolean,
  channelData: Record<string, any>,
  channelMetadata: Record<string, any>,
  callback: () => void
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return () => {
    debug('updateChannel()');
    ChatService.patch('channels', channel.url, {
      name: channelName,
      cover_url: channelImageUrl,
      is_public: channelIsPublic,
      description: channelData?.description,
      restricted: channelMetadata?.restricted === 'true',
    })
      .then(() => {
        callback();
      })
      .catch((error: Error) => {
        if (error) {
          KitSnack.show('Unable to save', KitSnackDuration.SHORT);
          debug('Unable to save:', error);
        } else {
          callback();
        }
      });
  };
};

// export const updateUser = (
//   nickname: string,
//   profileUrl: string,
//   callback: () => void
// ) => {
//   // @ts-ignore
//   return (dispatch) => {
//     sbUpdateProfile(nickname, profileUrl)
//       .then((response) => {
//         dispatch(setUser(response));
//         callback();
//       })
//       .catch(() => callback());
//   };
// };

export const createNewChannel = (
  type: ChannelType,
  isDistinct: boolean,
  isPublic: boolean,
  channelName: string,
  members: { userId: string }[],
  message?: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const appKey: string = appKeySelector(getState());
    const user: User | null = userSelector(getState());
    debug('createNewChannel()');
    ChatService.post('channels', {
      custom_type: type,
      is_public: isPublic,
      name: channelName,
      app_key: appKey,
      operators: [{ user_id: user?.userId }],
      members: members.map((member) => ({
        user_id: member.userId,
      })),
    })
      .then((chatServiceChannel) =>
        // @ts-ignore
        sbGetGroupChannel(chatServiceChannel.data.id)
      )
      .then((channel) => {
        if (message) {
          dispatch(sendTextMessage(channel, message, []));
        }

        const channelList = channelListSelector(getState());
        const ch = channelList?.find((c) => c.url === channel.url);

        if (!ch) {
          dispatch(addChannelToChannelList(channel as GroupChannel));
        }

        dispatch(setChannel(channel));
      })
      .catch((error) => debug('Could not create channel: ', error));
  };
};

export const joinChannel = (
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (channel.isPublic) {
      const appKey: string = appKeySelector(getState());
      const user: User | null = userSelector(getState());
      debug('joinChannel()');
      ChatService.post('channel-members', {
        app_key: appKey,
        nickname: user?.nickname,
        role: 'member',
        user_id: user?.userId,
        _embedded: {
          channel: {
            id: channel.url,
          },
        },
      }).catch((error: Error) => {
        if (error) {
          KitSnack.show('Unable to join', KitSnackDuration.SHORT);
          debug('Unable to join:', error);
          dispatch(setChannel(null));
        }
      });
    } else {
      KitSnack.show('Unable to join', KitSnackDuration.SHORT);
      debug('Unable to join:', 'Channel is not public');
      dispatch(setChannel(null));
    }
  };
};

export const addChannelToChannelList = (
  newChannel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  debug('addChannelToChannelList()');

  return (dispatch, getState) => {
    const currentChannelList = channelListSelector(getState());
    // @ts-ignore
    const findChannelIndex = currentChannelList.findIndex(
      (channel) => channel.url === newChannel.url
    );

    if (findChannelIndex < 0) {
      // @ts-ignore
      const newChannelList = [newChannel, ...currentChannelList];
      dispatch(updateChannelList(newChannelList));
    }
  };
};

export const setPushPreference = (
  pushPreference: PushTriggerOption,
  channelUrl: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    debug('setPushPreference()', pushPreference);

    return sbSetPushPreference(pushPreference, channelUrl)
      .then(() => {
        debug('Successfully set push preference');
        dispatch(updatePushPref(pushPreference));
      })
      .catch((error) => debug('Error setting push pref: ', error));
  };
};

export const getPushPreference = (
  channelUrl: string
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch) => {
    return sbGetPushPreference(channelUrl)
      .then((response) => dispatch(updatePushPref(response)))
      .catch((error) => debug('Error getting push pref: ', error));
  };
};

export const hideChannel = (
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    sbHideGroupChannel(channel.url)
      .then((response) => {
        const channelList = channelListSelector(getState());

        if (channelList !== null) {
          const newMyChannelList = channelList.filter(
            (ch) => ch.url !== channel.url
          );

          if (channel.isPublic) {
            const newPublicChannelList = publicChannelListSelector(getState());

            if (newPublicChannelList !== null) {
              dispatch(updatePublicChannelList(newPublicChannelList));
            }
          }

          dispatch(updateChannelList(newMyChannelList));
        }
      })
      .catch((error) => debug('Error leaving channel: ', error));
  };
};

export const onLeaveChannelPress = (
  channel: GroupChannel
): ThunkAction<void, IAppState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const user: User | null = userSelector(getState());
    debug('onLeaveChannelPress()', `${channel.url}_${user?.userId}`);
    ChatService.delete('channel-members', `${channel.url}_${user?.userId}`)
      .then(() => {
        const channelList = channelListSelector(getState());

        if (channelList !== null) {
          const newMyChannelList = channelList.filter(
            (ch) => ch.url !== channel.url
          );

          if (channel.isPublic) {
            const newPublicChannelList = publicChannelListSelector(getState());

            if (newPublicChannelList !== null) {
              dispatch(updatePublicChannelList(newPublicChannelList));
            }
          }

          dispatch(updateChannelList(newMyChannelList));
        }

        dispatch(setChannel(null));
      })
      .catch((error: Error) => debug('Error leaving channel: ', error));
  };
};

//****************************************************************************
// Users
//****************************************************************************
export const getUserList = (): ThunkAction<
  void,
  IAppState,
  unknown,
  AnyAction
> => {
  return (dispatch) => {
    const query = sbCreateApplicationUserListQuery();

    if (query !== null) {
      sbGetAppUsers(query).then((users: User[]) => {
        dispatch(updateUserList(users));
      });
    }
  };
};
