import { useShellContext } from '@omni/kit';
import { Optional } from '@omni/kit/Types';
import {
  KitButton,
  KitIcon,
  KitInput,
  KitLoader,
  KitModal,
  KitSnack,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import KitConfirmModal from '@omni/kit/components/KitConfirmModal';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import useShellAuthProviders from '@omni/kit/contexts/ShellContext/hooks/useShellAuthProviders';
import BaseServiceV2, { IHttpResponse } from '@omni/kit/services/BaseServiceV2';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { notifyAppUserSettingsUpdated } from '@omni/kit/utilities/NativeHelpers';
import {
  capitalizeFirstLetter,
  handleHttpErrorResponse,
} from '@omni/kit/utilities/utilities';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import Modal from 'react-native-modal';

import { useBlockPageContext } from '../../../../BlockPageContext';
import { UpdateActionInterface } from '../../../types';

const CELL_COUNT = 6;

export interface ChangeEmailModalProps {
  appKey: string;
  clientBrandColor?: string;
  email: string;
  profileID?: string;
  setRefreshing?: (refreshing: boolean) => void;
  setVisible: (visible: boolean) => void;
  /** The action to perform when a user changes their email address. */
  updateEmailAction?: UpdateActionInterface;
  visible: boolean;
}

const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
  appKey,
  clientBrandColor,
  email,
  profileID,
  setRefreshing,
  setVisible,
  updateEmailAction,
  visible,
}): JSX.Element => {
  // ---------------------------------------------------------------------------
  // Global State

  const { tokens } = useShellContext();
  const { targetProviders } = useShellAuthProviders();
  const { authProviderId } = useBlockPageContext();

  const targetProviderToken = useMemo(
    () =>
      authProviderId
        ? tokens.accessTokens[authProviderId] || undefined
        : undefined,
    [authProviderId, tokens.accessTokens]
  );

  // ---------------------------------------------------------------------------
  // Local State
  const newEmailRef = useRef<TextInput>(null);

  const [value, setValue] = useState<string>('');
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT + 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
    marginBottom: Spacing.l,
  });
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmActionKey] = useState('');

  const [resendVerificationCodeButton, setResendVerificationCodeButton] =
    useState(false);
  const [newEmail, setNewEmail] = useState<string>('');
  const [verificationModalVisible, setVerificationModalVisible] =
    useState(false);

  // ---------------------------------------------------------------------------
  // Computed State
  const isValidEmail = useMemo((): boolean => {
    /** Check if the email is valid */
    const emailRegex = /^.+@.+\..+/g;

    return Boolean(
      newEmail.match(emailRegex) &&
        newEmail?.charAt(0) !== '!' &&
        newEmail !== email
    );
  }, [newEmail, email]);

  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  const handleVerificationCode = (
    success = true,
    error: Optional<string> = null
  ): void => {
    /**
     *  Based on email-account-verification-code request we decide what interactions should
     *  happen for the user regarding the error type (verificationCodeInvalid, codeExpired or tooManyAttempts)
     */
    setIsLoading(false);
    setValue('');

    if (success) {
      setRefreshing?.(true);
      setNewEmail('');
      setVerificationModalVisible(false);
      KitSnack.show('Email address updated');
    } else {
      if (error === 'verification_code_invalid') {
        showKitSnack('Verification code invalid', KitSnackDuration.SHORT);
      } else if (error === 'verification_code_expired') {
        setResendVerificationCodeButton(true);
        showKitSnack('Code expired', KitSnackDuration.SHORT);
      } else if (error === 'verification_code_too_many_attempts') {
        setVerificationModalVisible(false);
        KitSnack.show('Verification failed. Too many attempts');
      } else if (error === 'email_already_exists') {
        setVerificationModalVisible(false);
        KitSnack.show('Verification failed. Email already exists');
      } else {
        showKitSnack(
          'Unable to verify. Please try again.',
          KitSnackDuration.SHORT
        );
      }
    }
  };

  const handleEmailUpdated = (
    success = true,
    message: Optional<string> = null
  ): void => {
    if (success) {
      setVisible(false);
      // Server takes a while before returning the updated data
      setTimeout(() => {
        setRefreshing?.(true); // auto-refresh the entire screen to display new email if provided by backend
        notifyAppUserSettingsUpdated();
        setVerificationModalVisible(true);
      }, 500);
    } else {
      showKitSnack(
        message?.length
          ? capitalizeFirstLetter(message)
          : 'Unable to save. Please try again.',
        KitSnackDuration.SHORT
      );
    }
  };

  const makeRequestForChangeEmail = async (): Promise<IHttpResponse> => {
    // make a request with feeds endpoint to update email
    const originalAction = updateEmailAction;

    if (originalAction?.updateUrl && originalAction?.settings?.[0]) {
      const settings = [...(originalAction?.settings ?? [{}])];
      settings[0] = {
        ...settings[0],
        value: newEmail.trim(),
        old_email: email,
      };

      const url = originalAction.updateUrl;

      return await BaseServiceV2.Put({
        url: url as string,
        appKey,
        authProviderId,
        targetProviders,
        token: targetProviderToken,
        data: { profileID, settings },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      throw new Error();
    }
  };

  const saveNewEmail = async (): Promise<void> => {
    const res = await makeRequestForChangeEmail();

    const ok = res.status === 201 || res.status === 200;

    if (ok) {
      handleEmailUpdated();
    } else {
      const { message } = await handleHttpErrorResponse(res);
      handleEmailUpdated(false, message);
    }
  };

  const resendCode = async (): Promise<void> => {
    const res = await makeRequestForChangeEmail();

    const ok = res.status === 201 || res.status === 200;

    if (ok) {
      setResendVerificationCodeButton(false);
      showKitSnack('Verification email resent', KitSnackDuration.SHORT);
    } else {
      const { message } = await handleHttpErrorResponse(res);
      showKitSnack(
        message ?? 'Unable to save. Please try again.',
        KitSnackDuration.SHORT
      );
    }
  };

  const showKitSnack = (msg: string, dur: number): void => {
    setSnackOptions({
      duration: dur,
      marginBottom: Spacing.l,
      message: msg,
      visible: true,
    });
  };

  const closeVerificationModal = (): void => {
    if (Platform.OS === 'web') {
      setConfirmModalVisible(true);
    } else {
      Alert.alert(
        'Cancel email address change',
        'The verification code sent to the new email address will no longer be valid.',
        [
          {
            text: 'No',
            onPress: () => setVerificationModalVisible(true),
            style: 'cancel',
          },
          {
            text: 'Yes',
            onPress: () => {
              setVerificationModalVisible(false);
              setResendVerificationCodeButton(false);
              setIsLoading(false);
              setValue('');
              setNewEmail('');
            },
          },
        ]
      );
    }
  };

  const closeVerificationModalWeb = () => {
    setConfirmModalVisible(false);
    setVerificationModalVisible(false);
    setResendVerificationCodeButton(false);
    setIsLoading(false);
    setValue('');
    setNewEmail('');
  };

  useEffect((): void => {
    // make a call to confirm activation code only if all the numbers are completed
    if (value?.length === 6) {
      setIsLoading(true);

      async function confirmVerificationCode(): Promise<void> {
        const originalAction = updateEmailAction;

        if (originalAction?.confirmEmailAccountVerificationCodeUrl) {
          const url = originalAction.confirmEmailAccountVerificationCodeUrl;

          const res = await BaseServiceV2.Put({
            url: url as string,
            appKey,
            authProviderId,
            targetProviders,
            token: targetProviderToken,
            data: { code: value, email: email },
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const ok = res.status === 201 || res.status === 200;

          if (ok) {
            handleVerificationCode();
          } else {
            const { data } = await handleHttpErrorResponse(res);
            handleVerificationCode(false, data?.error);
          }
        }
      }
      confirmVerificationCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect((): void => {
    // Fix for android to auto-focus the input on open
    visible
      ? Platform.OS === 'android'
        ? setTimeout(() => newEmailRef.current?.focus(), 150)
        : newEmailRef.current?.focus()
      : newEmailRef.current?.blur();
  }, [visible]);

  return (
    <>
      <KitModal visible={visible} setVisible={setVisible}>
        <View style={{ margin: Spacing.xl }}>
          <KitText h1 testID='modalEmailAddressTitle'>
            Change email address
          </KitText>
          <KitInput
            autoCapitalize='none'
            inputRef={newEmailRef}
            label='New email address'
            marginValue='18px 0 18px 0'
            onChangeText={setNewEmail}
            testID='NewEmailAddress'
            value={newEmail}
          />
          <KitButton
            color={clientBrandColor}
            disabled={!isValidEmail}
            onPress={saveNewEmail}
            testID='SetEmailButton'
            title='Set email address'
          />
        </View>
        <KitSnackRender
          {...snackOptions}
          setVisible={(value) =>
            setSnackOptions({ ...snackOptions, visible: value })
          }
        />
      </KitModal>

      <KitModal
        setVisible={closeVerificationModal}
        visible={verificationModalVisible}
      >
        <View style={styles.verificationModalContainer}>
          <View style={{ display: 'flex', alignItems: 'center' }}>
            <KitIcon
              color={Colors.N300}
              name='email'
              size={40}
              style={{ marginBottom: Spacing.s }}
            />
            <KitText h1 testID='modalVerificationEmailTitle'>
              Verification email sent
            </KitText>
            <KitText
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: Spacing.m,
                maxWidth: '80%',
                textAlign: 'center',
              }}
            >
              Check your email ({newEmail}) to find the code and enter it below.
            </KitText>
          </View>
          <View
            style={{
              alignItems: 'center',
              display: isLoading ? 'flex' : 'none',
              marginBottom: Spacing.xl,
              marginTop: Spacing.xl,
              opacity: isLoading ? 1 : 0,
            }}
          >
            {isLoading && <KitLoader large />}
          </View>
          <View
            style={{
              display:
                resendVerificationCodeButton || isLoading ? 'none' : 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: Spacing.xl,
              marginLeft: Spacing.m,
              marginTop: Spacing.xl,
              opacity: resendVerificationCodeButton || isLoading ? 0 : 1,
            }}
          >
            <CodeField
              {...codeFieldProps}
              caretHidden={false}
              cellCount={CELL_COUNT}
              keyboardType='number-pad'
              onChangeText={setValue}
              ref={ref}
              renderCell={({ index, symbol, isFocused }) => (
                <Text
                  key={index}
                  onLayout={getCellOnLayoutHandler(index)}
                  style={[styles.cell, isFocused && styles.focusCell]}
                >
                  {symbol || (isFocused ? <Cursor /> : null)}
                </Text>
              )}
              testID='ConfirmationCodeField'
              textContentType='oneTimeCode'
              value={value}
            />
          </View>
          <View
            style={{
              display: resendVerificationCodeButton ? 'flex' : 'none',
              marginBottom: Spacing.xl,
              marginTop: Spacing.xl,
              opacity: resendVerificationCodeButton ? 1 : 0,
            }}
          >
            <KitButton
              onPress={resendCode}
              secondary
              title='Resend verification code'
            />
          </View>

          <View
            pointerEvents={isLoading ? 'none' : 'auto'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            <KitText style={{ marginRight: Spacing.xs }}>
              Didn't receive an email?
            </KitText>
            <KitTouchable onPress={resendCode}>
              <KitText style={{ color: '#4f64ff' }} testID='ResendCode'>
                Resend code
              </KitText>
            </KitTouchable>
          </View>
        </View>
        <KitSnackRender
          {...snackOptions}
          setVisible={(value) =>
            setSnackOptions({ ...snackOptions, visible: value })
          }
        />
      </KitModal>
      <Modal
        animationIn='fadeIn'
        animationOut='fadeOut'
        isVisible={confirmModalVisible}
      >
        <KitConfirmModal
          action={confirmActionKey}
          cancelButtonText='No'
          confirmButtonText='Yes'
          message='The verification code sent to the new email address will no longer be valid.'
          onConfirm={closeVerificationModalWeb}
          setConfirmModalVisible={setConfirmModalVisible}
          title='Cancel email address change'
        />
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  cell: {
    backgroundColor: Colors.N75,
    borderRadius: BorderRadius.m,
    color: Colors.N900,
    fontSize: 32,
    height: 54,
    marginRight: Spacing.m,
    overflow: 'hidden',
    paddingTop: Platform.OS === 'ios' ? 8 : 5,
    textAlign: 'center',
    width: 44,
  },
  focusCell: { color: Colors.brand },
  verificationModalContainer: {
    marginBottom: Spacing.xxl,
    marginLeft: Spacing.xl,
    marginRight: Spacing.xl,
    marginTop: Spacing.xxl,
  },
});

export default ChangeEmailModal;
