import ActionSheetItem from '@omni/kit/components/KitActionSheetItem';
import Colors from '@omni/kit/theming/Colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';

export default function EventMoreActionSheet({
  isManager,
  onDeleteEvent,
  onEditEvent,
}: {
  isManager: boolean;
  onDeleteEvent: () => void;
  onEditEvent: () => void;
}): JSX.Element | null {
  const { t } = useTranslation(['groups']);

  return (
    <>
      {isManager && (
        <>
          <ActionSheetItem
            icon='edit'
            iconColor={Colors.N900}
            label={t('labelEditEvent')}
            onPress={onEditEvent}
          />
          <ActionSheetItem
            icon='delete'
            iconColor={Colors.R500}
            label={t('labelDeleteEvent')}
            onPress={onDeleteEvent}
          />
        </>
      )}
    </>
  );
}
//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({});
