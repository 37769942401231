import BaseServiceV2, { IHttpResponse } from '../../../BaseServiceV2';
import TokensService from '../../../TokensService/TokensService';
import {
  IMediaPlays,
  IPrepareMediaPlaysUrl,
  prepareMediaPlaysUrl,
} from '../index';

const debug = require('debug')(
  'tca:service:MediaPersonalizationService:MediaPlayService:Core:GetOne'
);

interface IMediaPlaysGetOne extends IPrepareMediaPlaysUrl {
  appKey?: string;
  id: string;
}

interface IMediaPlaysGetOneResponse extends IHttpResponse {
  body?: IMediaPlays;
}

export default async function getOne({
  appKey,
  ...buildUrlProps
}: IMediaPlaysGetOne): Promise<IMediaPlaysGetOneResponse> {
  debug('GetOne');
  const token = await TokensService.getGuestToken();
  const url = prepareMediaPlaysUrl(buildUrlProps);

  return await BaseServiceV2.Get({
    url,
    appKey,
    token,
  });
}
