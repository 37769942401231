import Environment from '../../../Environment';
import { IFilter, buildUrl } from '../../BaseServiceV2';

const host = Environment.bibleService;

export interface IPreparePassageUrl {
  filters?: IFilter[];
}

export function preparePassageUrl({ filters }: IPreparePassageUrl): string {
  return buildUrl({
    host,
    resource: 'passage',
    filters,
  });
}
