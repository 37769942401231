import { SelectOption } from '@omni/kit/components/KitSelect';
import { IFavoriteApp } from '@omni/kit/contexts/FavoriteAppsContext';
import i18n from '@omni/kit/localization';
import { IGroupLocation } from '@omni/kit/services/GroupsService/Types';
import { DOMAIN_LIBRARY } from '@omni/kit/services/SearchService/Constants';
import { App, AppFeature, AppFeatureName } from '@omni/kit/services/Types';
import memberAppLabelContextKey from '@omni/kit/utilities/memberAppLabelContextKey';

import { Domain } from '../types';

export interface SearchableState {
  searchable: boolean;
}

export type AppFilterOption = SelectOption & SearchableState;

export const initAppFilterOptions = (
  activeApp:
    | { appKey: string; title: string; features: AppFeature[] }
    | undefined,
  rootApp: App | undefined,
  favoriteApps: IFavoriteApp[]
): AppFilterOption[] => {
  let options: AppFilterOption[] = [];

  // 1. active app
  if (activeApp?.appKey) {
    options = [
      ...options,
      {
        label: activeApp?.title ?? '',
        value: activeApp?.appKey ?? '',
        searchable:
          (activeApp?.features ?? []).filter(
            (item) => item.name === AppFeatureName.SEARCH_MEDIA && item.enabled
          ).length > 0,
      },
    ];
  }

  const favoriteAppOptions: AppFilterOption[] = favoriteApps
    .map((item) => {
      const option: AppFilterOption = {
        label: item.title ?? '',
        value: item.appkey,
        searchable: Boolean(item.is_media_search_enabled),
      };

      return option;
    })
    .filter((item) => {
      // excluding active app to avoid redundant option
      return item.value !== activeApp?.appKey;
    });

  if (favoriteAppOptions.length > 0) {
    // 2. 'All favorited churches|campuses|sites'
    options = [
      ...options,
      {
        label: i18n.t('search:filterOptionAllFavorited', {
          context: memberAppLabelContextKey(rootApp),
        }),
        value: favoriteApps.map((item) => item.appkey).join(','),
        searchable:
          favoriteAppOptions.filter((item) => item.searchable).length > 0,
      },
    ];

    // 3+. List of all favorites
    options = [...options, ...favoriteAppOptions];
  }

  return options;
};

export const isAppFilterValueSearchable = (
  appFilterValue: string,
  appFilterOptions: AppFilterOption[],
  domain: Domain
): boolean => {
  if (domain === DOMAIN_LIBRARY) {
    return (
      appFilterOptions.filter(
        (item) => item.value === appFilterValue && item.searchable
      ).length > 0
    );
  }

  return true;
};

export const countNonEmptyArrays = (arrays: any[][]): number => {
  return arrays.filter((array) => array.length !== 0).length;
};

// A utility method to support filter queries where the value must either be a
// string to filter on a single value or an array of strings to filter on multiple values
// ref: https://subsplash.io/go/search/-/blob/2c97681b005443aba5f64ee0580b1e7db3b0ed01/filter_query.go#L37-58
export const extractValuesFromArray = (options: any[]): string | string[] => {
  if (options.length === 1) {
    return options[0].value;
  } else {
    return options.map((option) => option.value);
  }
};

// A utility method to extract labels from an array
export const extractLabelsFromArray = (array: any[]): string => {
  return array.map((item) => item.label).join(', ');
};

export const getTimeRange = (selectedTimeOfDay: any[]): any[] | null => {
  const ranges: any = {
    morning: {
      range_start: '0000',
      range_end: '1200',
    },
    afternoon: {
      range_start: '1200',
      range_end: '1700',
    },
    evening: {
      range_start: '1700',
      range_end: '2359',
    },
  };

  if (selectedTimeOfDay.length === 0) {
    return null;
  }

  const selectedRanges = selectedTimeOfDay
    .map((timeOfDay) => ranges[timeOfDay.value])
    .filter((range) => range);

  return selectedRanges;
};

export const extractLabelAndValue = (
  arr: any[]
): { label: string; value: any }[] => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const cityCountMap: any = arr.reduce((acc: any, item: any) => {
    const city = item._embedded?.address?.city;
    const state = item._embedded?.address?.state;
    const hasGroup = item._embedded?.group;

    if (city && hasGroup) {
      const cityStateKey = state ? `${city}, ${state}` : city;

      if (!acc[cityStateKey]) {
        acc[cityStateKey] = { count: 0, address: item._embedded.address };
      }

      acc[cityStateKey].count += 1;
    }

    return acc;
  }, {});

  return Object.entries(cityCountMap).map(
    ([cityState, { count, address }]: any) => ({
      label: `${cityState} (${count})`,
      value: address,
    })
  );
};

export const formatLocation = (
  data: IGroupLocation | undefined,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  isMember: any
): string | undefined => {
  if (!data) {
    return;
  }

  const {
    title,
    _embedded: {
      address: { city, state },
    },
  } = data;

  if (isMember) {
    // If `isMember` is true and `title` is defined, return 'title • city, state'
    // If `title` is not defined, return 'city, state'
    return title ? `${title} • ${city}, ${state}` : `${city}, ${state}`;
  } else {
    // If `isMember` is false, return 'city, state'
    return `${city}, ${state}`;
  }
};
