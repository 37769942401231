import { TextBlock } from '@omni/blocks';
import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import { RadiusStyle } from '@omni/kit/Types';
import { KitText } from '@omni/kit/components';
import GridItem from '@omni/kit/components/GridItem';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitInfiniteScroll from '@omni/kit/components/KitInfiniteScroll';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import Show from '@omni/kit/components/Show';
import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
} from '@omni/kit/constants/ImageSizeDefault';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { IBlockProps } from '@omni/kit/feeds/blockTypes';
import { IListItemData } from '@omni/kit/feeds/listItemTypes';
import { IMediaItemData } from '@omni/kit/feeds/mediaItemTypes';
import { useFeatureFlag } from '@omni/kit/hooks/useFeatureFlag';
import SearchService, {
  IPrepareHitsUrl,
  TAG_TYPES,
} from '@omni/kit/services/SearchService';
import { buildMediaItemSubtitles } from '@omni/kit/services/SearchService/buildMediaItemSubtitle';
import { parseSearchResults } from '@omni/kit/services/SearchService/parseSearchResults';
import TagService from '@omni/kit/services/TagService';
import { AppFeatureName, ILogoImage } from '@omni/kit/services/Types';
import { UNLEASH_TOGGLES } from '@omni/kit/services/UnleashService';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import { minDelay } from '@omni/kit/utilities/minDelay';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import FullPageLoader from '@omni/search/components/FullPageLoader';
import NoResults from '@omni/search/components/NoResult/NoResults';
import { useNavigation } from '@react-navigation/native';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Animated,
  Dimensions,
  NativeModules,
  Platform,
  StyleSheet,
  View,
} from 'react-native';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';

import { COMMAND_NAVIGATE_RESULT } from '../../Constants';
import ScreenContainer from '../../components/ScreenContainer';
import { ResultScreenRouteProp } from '../../types';
import calculateTotalPages from '../../utils/calculateTotalPages';
import ParseTagResults from '../Browse/ParseTagResults';

const debug = require('debug')('tca:search:screens:TagResult');

const DEFAULT_IMAGE_RATIO = 1.78;
/** width / height */
const SILHOUETTE_IMAGE_SIZE = 76;
const NAVBAR_HEIGHT = 44;

let timeoutId: NodeJS.Timeout | undefined;

export default ({
  route,
  horizontalSpacing,
}: {
  route: ResultScreenRouteProp;
  horizontalSpacing?: SpacingType;
}): JSX.Element => {
  const { colorForScheme } = useContext(ThemeContext);
  const { app } = useShellContext();
  const gatedContentEnabled = Boolean(
    app.features.find(
      (f) => f.name === AppFeatureName.GATED_CONTENT_V1 && f.enabled
    )
  );
  const useBlockPageForMediaItem = gatedContentEnabled;

  const { appFilterValue } = route.params;
  const commandName = route.params.name;
  const routeData = useMemo(() => route.params.data || {}, [route.params.data]);

  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });
  const safeAreaInsets = useSafeAreaInsets();

  const { t } = useTranslation(['search']);

  const navigation = useNavigation();
  const brand = useRootAppContext()?._embedded?.branding;

  const silhouetteLogoImage: ILogoImage | undefined =
    brand?._embedded?.['silhouette-logo-image'];

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [results, setResults] = useState<IBlockProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scrollPosition] = useState(new Animated.Value(0));
  const [parsedTag, setParsedTag] = useState<IListItemData>();
  const numColumns = viewPortWidth <= 640 ? 1 : 2;

  const opacity = scrollPosition.interpolate({
    inputRange: [95, 120],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  const renderGridItem = ({
    item,
    index,
  }: {
    item: IBlockProps;
    index: number;
  }) => {
    const isOdd = index % 2;
    const style =
      numColumns === 1
        ? {
            marginHorizontal: edgeSpacing,
          }
        : {
            marginLeft: isOdd ? 10 : edgeSpacing,
            marginRight: isOdd ? edgeSpacing : 10,
          };

    return (
      <GridItem
        style={{
          marginBottom: 16,
          ...style,
        }}
        imageWidth={IMAGE_WIDTH}
        imageHeight={IMAGE_HEIGHT}
        width='100%'
        data={item}
        onPress={() => {
          if (item.action) dispatch(item.action);
        }}
        silhouetteLogoImage={parseImageUrl(
          silhouetteLogoImage?._links?.dynamic?.href || '',
          SILHOUETTE_IMAGE_SIZE,
          SILHOUETTE_IMAGE_SIZE,
          ImageServiceType.WhitePng
        )}
        silhouetteLogoImageSize={SILHOUETTE_IMAGE_SIZE}
        averageColor={item.averageHexColor}
        aspectRatio={DEFAULT_IMAGE_RATIO}
        imageRadius={RadiusStyle.Medium}
        titleTextSize={16}
      />
    );
  };

  const renderHeader = () => {
    return (
      <View
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <KitAvatar
          imageUrl={parsedTag?.image}
          nickname={parsedTag?.title || routeData.value}
          size={80}
        />
        <KitText
          h1
          style={{
            marginBottom: parsedTag?.subtitle ? Spacing.xs : Spacing.xl,
            marginTop: Spacing.m,
          }}
        >
          {parsedTag?.title || routeData.value}
        </KitText>
        <Show show={Boolean(parsedTag?.subtitle)}>
          <KitText
            body1
            style={{
              marginBottom: parsedTag?.description ? Spacing.m : Spacing.xl,
            }}
          >
            {parsedTag?.subtitle}
          </KitText>
        </Show>
        <Show show={Boolean(parsedTag?.description)}>
          <TextBlock
            align='center'
            bottomSpacing={SpacingType.ExtraLarge}
            content={parsedTag?.description || ''}
            insetStyle={{ padding: 0 }}
            numberOfLines={3}
            topSpacing={SpacingType.None}
          />
        </Show>
      </View>
    );
  };

  const onSearch = useCallback(
    async (page = 1) => {
      try {
        const config: IPrepareHitsUrl = {
          appFilterValue,
          page,
          type: ['media-item'],
        };

        if (routeData.type === TAG_TYPES.SCRIPTURE) {
          config.scripture = routeData.value;
        } else {
          config.tag = `${routeData.type}:${routeData.value}`;
        }

        const data = await SearchService.search(config);

        const nextPage = page + 1;
        const totalPages = calculateTotalPages(data?.body?.total || 0);
        setHasNextPage(nextPage <= totalPages);
        setCurrentPage(nextPage);

        const parsedData = parseSearchResults(
          data,
          useBlockPageForMediaItem
        ) as IMediaItemData[];

        if (routeData.type === TAG_TYPES.SCRIPTURE) {
          return buildMediaItemSubtitles(parsedData, routeData.title as string);
        }

        return parsedData;
      } catch (e) {
        debug('search request failed', e);
      }
    },
    [appFilterValue, useBlockPageForMediaItem, routeData]
  );

  const onFetchData = () => {
    return onSearch(currentPage);
  };

  const onFetch = useCallback(async () => {
    const start = new Date().getTime();
    setIsLoading(true);

    const data = await onSearch(1);
    setResults(data ?? []);

    timeoutId = minDelay({
      start,
      callback: () => {
        setIsLoading(false);
      },
    });
  }, [onSearch]);

  const fetchOneTag = useCallback(
    async ({
      type,
      value,
      appKey,
      getFromCache,
    }: {
      type: string;
      value: string;
      appKey: string;
      getFromCache: boolean;
    }) => {
      const data = await TagService.getAll({
        appKey,
        filters: [
          ['app_key', appFilterValue],
          ['type', type],
          ['title', value],
        ],
        getFromCache,
        includes: ['image'],
        pages: [
          ['size', 1],
          ['number', 1],
        ],
      });

      const tags = ParseTagResults(data.body?._embedded.tags || []);

      setParsedTag(tags[0]);
    },
    [appFilterValue]
  );

  useEffect(() => {
    onFetch();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [onFetch]);

  useEffect(() => {
    if (routeData.type === 'speaker' && routeData.value) {
      const props = {
        type: routeData.type,
        value: routeData.value,
        appKey: appFilterValue,
        getFromCache: false,
      };
      fetchOneTag({
        ...props,
        getFromCache: true,
      });
      fetchOneTag(props);
    }
  }, [routeData, appFilterValue, fetchOneTag]);

  const onPress = () => {
    if (commandName === COMMAND_NAVIGATE_RESULT) {
      NativeModules.ReactPlatformBridge.dismiss();
    } else {
      navigation.goBack();
    }
  };

  const _renderIsLoading = () => {
    if (!isLoading) return null;

    return (
      <ScreenContainer>
        <FullPageLoader />
      </ScreenContainer>
    );
  };

  const _renderNoResults = () => {
    if (results.length !== 0) return null;

    let context: 'default' | 'scripture' | 'speaker' | 'topic' = 'default';

    if (
      routeData.type === 'scripture' ||
      routeData.type === 'speaker' ||
      routeData.type === 'topic'
    ) {
      context = routeData.type;
    }

    return (
      <ScreenContainer>
        <NoResults
          icon='chat'
          title={t('search:noResultsTitle', { context })}
          description={t('search:noResultsSubtitle', { context: 'checkLater' })}
        />
      </ScreenContainer>
    );
  };

  const _renderResults = () => {
    if (results.length === 0) return null;

    return (
      <KitInfiniteScroll<IBlockProps>
        onFetchData={onFetchData}
        renderItem={renderGridItem}
        scrollEventThrottle={16}
        headerComponent={
          routeData.type === 'speaker' ? renderHeader : undefined
        }
        initialData={results}
        hasMoreItems={hasNextPage}
        numColumns={numColumns}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: { y: scrollPosition },
              },
            },
          ],
          { useNativeDriver: true }
        )}
      />
    );
  };

  return (
    <SafeAreaView
      style={{
        backgroundColor: colorForScheme?.({
          light: Colors.N0,
          dark: Colors.N1000,
        }),
      }}
      edges={
        routeData.type === 'speaker'
          ? ['top', 'left', 'right']
          : ['left', 'right']
      }
    >
      <Show show={Boolean(routeData.type === 'speaker')}>
        <View style={styles.navbar}>
          <View style={styles.backButton}>
            <LeftNavButton
              iconSize={20}
              onPress={onPress}
              title={Platform.OS === 'ios' ? '' : (routeData.title as string)}
              // @ts-ignore
              titleStyle={{ fontWeight: '700', fontSize: 18, opacity: opacity }}
              type={LeftNavType.Back}
            />
          </View>
          <Show show={Boolean(Platform.OS === 'ios')}>
            <Animated.View style={{ opacity: opacity }}>
              <KitText bold black fontSize={17}>
                {routeData.title}
              </KitText>
            </Animated.View>
          </Show>
        </View>
      </Show>
      <ScreenContainer
        style={{
          paddingTop: routeData.type === 'speaker' ? 0 : 10,
          height:
            routeData.type === 'speaker'
              ? Dimensions.get('screen').height -
                NAVBAR_HEIGHT -
                safeAreaInsets.top
              : '100%',
        }}
      >
        {_renderIsLoading()}
        {_renderNoResults()}
        {_renderResults()}
      </ScreenContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  navbar: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    height: NAVBAR_HEIGHT,
    justifyContent: 'center',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: Platform.OS === 'ios' ? 18 : 24,
  },
});
