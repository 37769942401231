import KitSnack, { KitSnackDuration } from '@omni/kit/components/KitSnack';
import {
  bytesPerMb,
  fileTooLargeMessage,
  maxFileSizeInMb,
} from '@omni/kit/utilities/utilities';
import { logEvent } from '@omni/kit/utilities/utilities';
import React from 'react';
import { Platform, Image as RNImage } from 'react-native';
import DocumentPicker from 'react-native-document-picker';

import { IFile, MessageType } from '../../../../shared/redux/types';
import ActionButton from './ActionButton';
import ActionButtonWeb from './ActionButtonWeb';

const debug = require('debug')('omni:chat:components:ActionBar');

interface FileActionButtonProps {
  sendFileMessage: (file: IFile) => void;
  onMessageSent?: () => void;
}

export default function FileActionButton({
  sendFileMessage,
  onMessageSent,
}: FileActionButtonProps): JSX.Element {
  const _onPhotoSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageObject = event.target.files?.[0];

    if (!imageObject) return;

    const img = document.createElement('img');
    const Url = window.URL || window.webkitURL;

    img.src = Url.createObjectURL(imageObject);
    img.onload = () => {
      if (img.width !== 0 && img.height !== 0) {
        const ratio = img.width / img.height;
        const file: IFile = {
          customType: MessageType.Photo,
          data: { ratio: `${ratio}` },
          file: imageObject,
          name: imageObject.name,
          type: imageObject.type,
        };
        sendFileMessage(file);
      }
    };
  };

  const _onFileSelecton = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObject = event.target.files?.[0];

    if (!fileObject) return;

    const fileSize = (fileObject.size ?? 0) / bytesPerMb; // in megabytes

    if (fileSize > maxFileSizeInMb) {
      KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);
      logEvent('chat_file_exceeds_limit');

      return;
    }

    if (fileObject.type.includes('image/')) {
      _onPhotoSelection(event);

      return;
    }

    const file: IFile = {
      customType: MessageType.File,
      data: {},
      file: fileObject,
      name: fileObject.name,
      type: fileObject.type,
    };

    sendFileMessage(file);
  };

  const _onFilePress = async () => {
    try {
      const fileObject = await DocumentPicker.pickSingle({
        type: [DocumentPicker.types.allFiles, DocumentPicker.types.zip],
      });
      const fileSize = (fileObject.size ?? 0) / bytesPerMb; // in megabytes

      if (fileSize > maxFileSizeInMb) {
        KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);
        logEvent('chat_file_exceeds_limit');

        return;
      }

      if (fileObject.type?.startsWith('image/')) {
        try {
          await RNImage.getSize(fileObject.uri, (width, height) => {
            if (width !== 0 && height !== 0) {
              const ratio = width / height;
              const file: IFile = {
                customType: MessageType.Photo,
                data: { ratio: `${ratio}` },
                name: fileObject.name || 'file',
                type: fileObject.type || 'unknown',
                uri: fileObject.uri,
              };
              sendFileMessage(file);
              onMessageSent?.();
            }
          });
        } catch (error) {
          debug(error);
        }
      } else {
        const file: IFile = {
          customType: MessageType.File,
          data: {},
          name: fileObject.name || 'file',
          type: fileObject.type || 'unknown',
          uri: fileObject.uri,
        };

        sendFileMessage(file);
        onMessageSent?.();
      }
    } catch (err) {
      // If user cancelled the picker, exit any dialogs or menus and move on
      if (DocumentPicker.isCancel(err)) return;

      throw err;
    }
  };

  return Platform.OS === 'web' ? (
    <ActionButtonWeb icon='file' label='File' as='label'>
      <input
        onChange={_onFileSelecton}
        style={{ display: 'none' }}
        type='file'
      />
    </ActionButtonWeb>
  ) : (
    <ActionButton icon='file' label='File' onPress={_onFilePress} />
  );
}
