import { PlatformAgnosticFile } from '../../FileTypes';
import { IImage } from '../../services/ImageService/Types';

export interface ImageUploadProps {
  uploadImage: (
    imageObject: PlatformAgnosticFile,
    imageType: string
  ) => Promise<{
    id: string;
    url: string;
    res: { title: string; data: Partial<IImage> };
  }>; // the id url and res of the uploaded image from the Image Service
  progress: number;
  status: ImageUploadStatus;
}

export enum ImageUploadStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  Success = 'success',
  Error = 'error',
}
