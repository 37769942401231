import TokensService from '@omni/kit/services/TokensService';

import { IChannels, prepareUrl } from '..';
import BaseServiceV2, { IHttpResponse, IUri } from '../../BaseServiceV2';

const RESOURCE = 'channels';

interface IGetChannel extends Omit<IUri, 'resource'> {
  appKey: string;
}

interface IGetChannelResponse extends IHttpResponse {
  body?: IChannels;
}

export default async ({
  appKey,
  ...buildUrlProps
}: IGetChannel): Promise<IGetChannelResponse> => {
  const url = prepareUrl({
    ...buildUrlProps,
    resource: RESOURCE,
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({ url, appKey, token: guestToken });
};
