import { GroupChannel } from '@sendbird/chat/groupChannel';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendFileMessage } from '../../../../shared/redux/actions/ChatActions';
import { channelSelector } from '../../../../shared/redux/selectors';
import { processImageFile } from '../../../../utilities/imageUtilities';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function WebImageAction() {
  const channel = useSelector(channelSelector);
  const dispatch = useDispatch();

  const _onPhotoSelection = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const imageObject = event.target.files?.[0];

    if (!imageObject) {
      return;
    }

    const imageProcessed = await processImageFile(imageObject);
    // @ts-ignore
    dispatch(sendFileMessage(channel as GroupChannel, imageProcessed));
  };

  return (
    <input
      style={{ display: 'none' }}
      type='file'
      onChange={_onPhotoSelection}
      accept='image/*'
    />
  );
}
