import Groups from '@omni/groups/Groups';
import useDocumentTitle from '@omni/kit/hooks/useDocumentTitle';
import useFavicon from '@omni/kit/hooks/useFavicon';
import React from 'react';
import { View } from 'react-native';

import useAppInfo from '../../../hooks/useAppInfo';

export default function GroupsScreen({
  appKey,
}: {
  appKey: string;
}): JSX.Element {
  const { app } = useAppInfo(appKey);
  useDocumentTitle(app?.title || '', 'Groups');
  useFavicon(app?.id || '');

  return (
    <View style={{ flex: 1 }}>
      <Groups appKey={appKey} pathPrefix={appKey} />
    </View>
  );
}
