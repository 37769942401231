import { createStackNavigator } from '@react-navigation/stack';
import { Platform } from 'react-native';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';

export const Stack = Platform.select({
  ios: createNativeStackNavigator(),
  android: createNativeStackNavigator(),
  // @ts-ignore
  web: createStackNavigator(),
});
