import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit';
import Environment from '@omni/kit/Environment';
import BaseServiceV2, { IHttpResponse } from '@omni/kit/services/BaseServiceV2';
import pluralize from 'pluralize';

import { getStore } from '../shared/redux/store';

const debug = require('debug')('omni:messaging:service:ChatService');

export default class MediaService {
  private static get host() {
    return Environment.mediaService;
  }

  private static get appKey() {
    const store = getStore();

    return store.getState().system.appKey;
  }

  private static get token() {
    const store = getStore();

    return store.getState().system.accessToken;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getAll(resource: string) {
    const url = this._buildUrl(resource);

    return BaseServiceV2.Get({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
    })
      .then(this._handleAllResponse)
      .catch(this._handleErrorResponse);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getFiltered(
    resource: string,
    filters: [string, string][],
    queryParams = ''
  ) {
    const url = this._buildUrl(resource, undefined, filters, queryParams);

    return BaseServiceV2.Get({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
    })
      .then(this._handleAllResponse)
      .catch(this._handleErrorResponse);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getOne(resource: string, id: string) {
    const url = this._buildUrl(resource, id);

    return BaseServiceV2.Get({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static patch(resource: string, id: string, data: object) {
    const url = this._buildUrl(resource, id);
    debug('url', url);

    return BaseServiceV2.Patch({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static post(resource: string, data: object, id = '') {
    const url = this._buildUrl(resource, id);

    return BaseServiceV2.Post({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
      data: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static delete(resource: string, id: string) {
    const url = this._buildUrl(resource, id);

    return BaseServiceV2.Delete({
      url: url,
      appKey: this.appKey,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
      token: this.token,
    })
      .then(this._handleOneResponse)
      .catch(this._handleErrorResponse);
  }

  private static _buildUrl(
    resource: string,
    id?: string,
    filters?: [string, string][],
    params?: string
  ) {
    id = id ? `/${id}` : '';

    let filtersString = '';

    if (filters) {
      let prefix = '?';
      filters.forEach((filter) => {
        filtersString =
          filtersString + `${prefix}filter[${filter[0]}]=${filter[1]}`;
        prefix = '&';
      });
    }

    const paramString = `${filters ? '&' : '?'}${params}`;

    return `${this.host}/${pluralize(
      resource
    )}${id}${filtersString}${paramString}`;
  }

  private static _handleOneResponse(response: IHttpResponse): {
    meta: { count?: number; total?: number };
    status: number;
    data?: any;
  } {
    const ok =
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204;

    if (!ok) throw response;

    return {
      meta: {},
      status: response.status || 0,
      data: response.body,
    };
  }

  private static _handleAllResponse(response: IHttpResponse): {
    meta: { count?: number; total?: number };
    status: number;
    data?: any;
  } {
    const ok =
      response.status === 200 ||
      response.status === 201 ||
      response.status === 204;

    if (!ok) throw response;

    return {
      meta: {
        count: response.body.count,
        total: response.body.total,
      },
      status: response.status || 0,
      data: response.body._embedded,
    };
  }

  private static _handleErrorResponse(response: IHttpResponse): {
    status: number;
    message: string;
    data?: any;
  } {
    return {
      status: response.status || 0,
      message: Array.isArray(response.body?.errors)
        ? response.body.errors[0].detail
        : JSON.stringify(response.body),
    };
  }
}
