import { KitIcon } from '@omni/kit/components';
import { IconName } from '@omni/kit/components/KitIcon';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useState } from 'react';
import { StyleSheet, TextStyle, TouchableOpacity, View } from 'react-native';

interface MessageInputIconProps {
  backgroundColor?: string;
  border?: boolean;
  color?: string;
  icon: IconName;
  onPress?: (...args: unknown[]) => void;
  padding?: number;
  size?: number;
  style?: TextStyle;
}

export const MessageInputIcon = ({
  icon,
  border = false,
  backgroundColor,
  padding = Spacing.s,
  color = Colors.N500,
  size = 20,
  style,
  ...rest
}: MessageInputIconProps): JSX.Element => {
  const borderWidth = border ? 2 : 0;
  const inset = 2;

  const [activeStyle, setActiveStyle] = useState<any>(undefined);

  const _size = border ? size - 2 * (inset + borderWidth) : size;
  const _color = activeStyle ? Colors.N900 : color;

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPressIn={() => setActiveStyle(styles.touchablePressIn)}
      onPressOut={() => setActiveStyle(undefined)}
      {...rest}
      style={[
        {
          background: activeStyle ? undefined : backgroundColor,
          borderRadius: size,
          marginVertical: -padding,
          padding,
        },
        activeStyle,
      ]}
      testID={`message-input-icon-${icon}`}
    >
      <View
        style={
          border
            ? {
                borderColor: _color,
                borderRadius: size,
                borderWidth,
                padding: inset,
              }
            : {}
        }
      >
        <KitIcon name={icon} color={_color} size={_size} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  touchablePressIn: {
    backgroundColor: Colors.N200,
    transform: [{ scale: 0.9 }],
  },
});
