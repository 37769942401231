import { useShellContext } from '@omni/kit';
import { PlatformAgnosticFile } from '@omni/kit/FileTypes';
import { KitIcon, KitListItem } from '@omni/kit/components';
import KitInput from '@omni/kit/components/KitInput';
import KitModal from '@omni/kit/components/KitModalV2/KitModalV2';
import KitProgress from '@omni/kit/components/KitProgress';
import KitText from '@omni/kit/components/KitText';
import KitTouchable from '@omni/kit/components/KitTouchable';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import Show from '@omni/kit/components/Show';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import { IGroup, IGroupLocation } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import AddMeetingLocationScreen from '@omni/messaging/shared/scenes/channelDetails/AddMeetingLocationScreen';
import { useIsFocused } from '@react-navigation/native';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import { useChannelGroup } from '../../../hooks/useChannelGroup';
import useChatImageUpload from '../../../hooks/useChatImageUpload';
import Label from '../../../mobile/components/Label';
import PlatformKeyboardSpacer from '../../../mobile/components/PlatformKeyboardSpacer';
import ConfirmModal from '../../../mobile/components/web/ConfirmModal';
import ChatService from '../../../services/ChatService';
import { isLocalFileUrl } from '../../../utilities/imageUtilities';
import {
  ADD_MEETING_LOCATION,
  ADD_MEETING_SCHEDULE,
  VISIBILITY_AND_ENROLLMENT_SCREEN,
} from '../../Constants';
import { updateChannel } from '../../redux/actions/SystemActions';
import {
  accessTokenSelector,
  appKeySelector,
  channelSelector,
} from '../../redux/selectors';
import AddMeetingScheduleScreen from './AddMeetingScheduleScreen';
import { ChannelAvatarList, LoadingOverlay } from './components/edit';

const debug = require('debug')('tca:chat:screen:EditChannelScreen');

interface Props {
  navigation: any;
  route: any;
  renameChannel: (channelName: string, channelUrl: string) => void;
}

export default function EditChannelScreen({
  route,
  navigation,
}: Props): JSX.Element {
  const { channelData } = route.params;

  // Channel Data
  const channel = useSelector(channelSelector);
  const [channelMetadata, setChannelMetadata] = useState<any | null>(null);
  const [channelName, setChannelName] = useState(channel?.name || '');
  const [description, setDescription] = useState(
    channelData?.description || ''
  );
  const [channelImage, setChannelImage] = useState(channel?.coverUrl || '');
  const [groupLocation, setGroupLocation] = useState<
    IGroupLocation | undefined
  >();
  // Group Data
  const group = useChannelGroup(channel?.url);
  const isFocused = useIsFocused();

  // Editing
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [userImage, setUserImage] = useState<PlatformAgnosticFile | null>(null);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmActionButtonText, setConfirmActionButtonText] = useState('');
  const [confirmActionTitle, setConfirmActionTitle] = useState('');
  const [confirmActionMessage, setConfirmActionMessage] = useState('');
  const [confirmActionKey, setConfirmActionKey] = useState('');
  const [routeScheduleMeetingWeb, setRouteScheduleMeetingWeb] =
    useState<IGroup | null>();
  const { windowWidth, windowHeight } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false);
  const { sizeClass } = useSizeClass();
  const dispatch = useDispatch();
  const { tokens } = useShellContext();
  const accessToken = useMemo(() => tokens.user || '', [tokens.user]);

  const { t } = useTranslation(['messaging', 'groups']);

  const { uploadImage, progress } = useChatImageUpload();

  const _saveChannelDetails = useCallback(() => {
    if (isEdited && channel) {
      setIsSaving(true);
      if (isLocalFileUrl(channelImage) && userImage) {
        uploadImage(userImage, 'channel banner')
          .then(({ url }: { url: string }) => {
            dispatch(
              updateChannel(
                channel,
                channelName,
                url,
                channel?.isPublic,
                { description: description.slice(0, 190) },
                channelMetadata,
                _onSaveDetailsComplete
              )
            );
          })
          .catch(() => {
            _onSaveDetailsComplete();
          });
      } else {
        dispatch(
          updateChannel(
            channel,
            channelName,
            channelImage,
            channel?.isPublic,
            { description: description.slice(0, 190) },
            channelMetadata,
            _onSaveDetailsComplete
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channel,
    channelImage,
    channelName,
    channelMetadata,
    description,
    dispatch,
    isEdited,
    channel?.isPublic,
    navigation,
  ]);

  const _onSaveDetailsComplete = () => {
    setIsSaving(false);

    // TODO: fix the snack
    // route.params?.showKitSnack('Channel updated', KitSnackDuration.SHORT);

    navigation.goBack();
  };

  const _handleCustomImageSelected = (image: PlatformAgnosticFile) => {
    setUserImage(image);
    setChannelImage(image.uri);
  };

  const navigateBack = useCallback(() => {
    if (isEdited) {
      if (Platform.OS === 'web') {
        setConfirmActionTitle('Unsaved changes');
        setConfirmActionMessage('You have unsaved changes to your channel.');
        setConfirmActionButtonText('Discard changes');
        setConfirmModalVisible(true);

        return;
      }

      Alert.alert(
        'Unsaved changes',
        'You have unsaved changes to your channel.',
        [
          {
            text: 'Discard',
            onPress: navigation.goBack,
            style: 'destructive',
          },
          { text: 'Save', onPress: _saveChannelDetails, style: 'cancel' },
        ],
        { cancelable: false }
      );
    } else {
      navigation.goBack();
    }
  }, [_saveChannelDetails, isEdited, navigation]);

  const _onConfirm = () => {
    navigation.goBack();
  };

  useEffect(() => {
    const { channelData } = route.params;

    if (
      channelName !== channel?.name ||
      (description !== channelData?.description &&
        !(description === '' && channelData?.description === undefined)) ||
      channelImage !== channel?.coverUrl
    ) {
      setIsEdited(true);
    }
  }, [channelName, description, channelImage, channel, route.params]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <KitTouchable
          disabled={isLoading}
          onPress={isEdited ? _saveChannelDetails : null}
          style={{
            paddingLeft: 12,
            paddingRight: Platform.OS === 'ios' ? 12 : 18,
            paddingVertical: 6,
            marginRight: -6,
            borderRadius: 40,
          }}
        >
          <KitText color={isEdited ? Colors.brand : Colors.N400} fontSize={17}>
            Save
          </KitText>
        </KitTouchable>
      ),
      ...Platform.select({
        default: {
          headerLeft: () => (
            <LeftNavButton
              title='Edit group'
              type={LeftNavType.Back}
              onPress={navigateBack}
            />
          ),
        },
        android: null,
      }),
    });
  }, [
    isEdited,
    navigation,
    channelName,
    description,
    isLoading,
    _saveChannelDetails,
    navigateBack,
  ]);

  useEffect(() => {
    setRouteScheduleMeetingWeb(group);
  }, [group]);

  useEffect(() => {
    if (isLoading) {
      navigation.setOptions({
        headerStyle: {
          backgroundColor: '#bbb',
        },
        ...Platform.select({
          default: {
            headerLeft: () => (
              <LeftNavButton
                disabled
                title='Edit group'
                type={LeftNavType.Back}
              />
            ),
          },
          android: null,
        }),
      });
    } else {
      navigation.setOptions({
        ...Platform.select({
          default: {
            headerLeft: () => (
              <LeftNavButton
                title='Edit group'
                type={LeftNavType.Back}
                onPress={navigation.goBack}
              />
            ),
          },
          android: null,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fetchGroupLocation = useCallback(() => {
    const _fetchGroupLocation = async (id: string) => {
      try {
        const response = await GroupsService.GetGroupLocation({
          id,
          token: accessToken,
        });

        if (response.body) {
          setGroupLocation(response.body);
        } else {
          debug('Could not fetch group location');
        }
      } catch {}
    };

    group?._embedded?.location?.id
      ? _fetchGroupLocation(group._embedded.location.id)
      : setGroupLocation(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, navigation, group?._embedded?.location?.id]);

  // Hooks
  useEffect(() => {
    if (isFocused) {
      fetchGroupLocation();
    }
  }, [fetchGroupLocation, isFocused]);

  return (
    <>
      {(isLoading || isSaving) && <LoadingOverlay />}
      <KeyboardAwareScrollView
        contentInset={{ bottom: Spacing.l }}
        contentContainerStyle={{
          marginTop: Platform.OS !== 'web' && isLandscape ? 14 : 0,
          borderTopWidth: Platform.OS !== 'web' && isLandscape ? 1 : 0,
          borderTopColor:
            Platform.OS !== 'web' && isLandscape ? Colors.N100 : Colors.N0,
          paddingTop: 10,
          paddingHorizontal: Spacing.l,
        }}
        style={{
          backgroundColor: colorForScheme({
            default: Colors.N0,
          }),
        }}
        keyboardShouldPersistTaps='always'
      >
        <View
          style={[
            Platform.OS === 'web' && { width: 800, marginHorizontal: 'auto' },
          ]}
        >
          <View style={styles.avatarListWrapper}>
            <ChannelAvatarList
              channel={channel}
              channelImage={channelImage}
              setChannelImage={setChannelImage}
              onCustomImageSelected={_handleCustomImageSelected}
              userImage={userImage}
            />
          </View>

          <KitInput
            label='Name'
            placeholder='Enter channel name'
            value={channelName}
            onChangeText={setChannelName}
          />
          <KitInput
            marginValue='18px 0 0 0'
            label='Description'
            rightLabel={
              description.length === 0
                ? '190 character max'
                : `${description.length} / 190`
            }
            placeholder='Type a description...'
            multiline
            value={description}
            onChangeText={setDescription}
            rightLabelStyle={description.length > 190 ? { color: 'red' } : {}}
          />
          <Show show={group !== null}>
            <View style={{ marginBottom: Spacing.l }}>
              <KitListItem
                title={t('messaging:buttonTitleAddMeetingLocation')}
                subtitle={
                  groupLocation
                    ? groupLocation.title
                      ? `${groupLocation.title} • ${groupLocation.subtitle}`
                      : groupLocation.subtitle
                    : undefined
                }
                ImageComponent={
                  <KitIcon
                    name='not-filled-location'
                    size={20}
                    color={Colors.N600}
                    style={{ marginLeft: Spacing.s }}
                  />
                }
                style={{ marginTop: Spacing.s }}
                onPress={
                  Platform.OS === 'web'
                    ? () => setOpenLocationModal(true)
                    : () =>
                        navigation.navigate(ADD_MEETING_LOCATION, {
                          group,
                          groupLocation,
                        })
                }
              />
              {Platform.OS === 'web' && (
                <KitModal
                  isVisible={openLocationModal}
                  onModalHide={() => setOpenLocationModal(false)}
                  onClose={() => setOpenLocationModal(false)}
                  coverScreen
                  modalStyle={{
                    minHeight: 515,
                    padding: 10,
                    width: sizeClass === SizeClass.Small ? '100%' : 620,
                    height: sizeClass === SizeClass.Small ? '100%' : undefined,
                    maxWidth: sizeClass === SizeClass.Small ? '100%' : '50%',
                  }}
                >
                  <AddMeetingLocationScreen
                    route={route}
                    navigation={navigation}
                    isOpenLocationModal={openLocationModal}
                    setIsOpenLocationModal={setOpenLocationModal}
                    group={group}
                    locationData={groupLocation}
                  />
                </KitModal>
              )}
              <KitListItem
                title={
                  group?.frequency || group?.day_of_week
                    ? t('messaging:meetingSchedule')
                    : t('messaging:buttonTitleAddMeetingSchedule')
                }
                subtitle={
                  group !== null
                    ? [
                        group.frequency
                          ? t('groups:frequency', { context: group.frequency })
                          : null,
                        group.day_of_week !== undefined
                          ? moment().day(group.day_of_week).format('dddd')
                          : null,
                        group.time_of_day || group.time_of_day === 0
                          ? `${moment(
                              String(group.time_of_day).padStart(4, '0'),
                              'Hmm'
                            ).format('h:mm A')}
                          ${
                            group.timezone
                              ? ` ${moment().tz(group.timezone).zoneAbbr()}`
                              : ''
                          }`
                          : null,
                      ]
                        .filter(Boolean)
                        .join(' • ')
                    : ''
                }
                customHeight={80}
                ImageComponent={
                  <KitIcon
                    name='calendar'
                    size={18}
                    color={Colors.N600}
                    style={{ marginLeft: Spacing.s }}
                  />
                }
                onPress={
                  Platform.OS === 'web'
                    ? () => setOpenScheduleModal(true)
                    : () => navigation.navigate(ADD_MEETING_SCHEDULE, { group })
                }
              />
              {Platform.OS === 'web' && (
                <KitModal
                  isVisible={openScheduleModal}
                  onModalHide={() => {
                    setOpenScheduleModal;
                  }}
                  coverScreen
                  modalStyle={{
                    width: sizeClass === SizeClass.Small ? '100%' : '50%',
                    height: sizeClass === SizeClass.Small ? '100%' : undefined,
                  }}
                  noScrollView
                >
                  <AddMeetingScheduleScreen
                    route={{ params: { group: routeScheduleMeetingWeb } }}
                    navigation={navigation}
                    isOpenScheduleModal={openScheduleModal}
                    setIsOpenScheduleModal={setOpenScheduleModal}
                  />
                </KitModal>
              )}
              <KitListItem
                title={t('messaging:buttonTitleVisibilityAndEnrollment')}
                subtitle={
                  group !== null
                    ? `${t(
                        `messaging:${group.public ? 'public' : 'private'}`
                      )} • ${t(`messaging:${group.enrollment_type}`)}`
                    : ''
                }
                customHeight={80}
                ImageComponent={
                  <KitIcon
                    name={group?.public ? 'publish' : 'eye-slash'}
                    size={18}
                    color={Colors.N600}
                    style={{ marginLeft: Spacing.s }}
                  />
                }
                onPress={() =>
                  navigation.navigate(VISIBILITY_AND_ENROLLMENT_SCREEN, {
                    group,
                  })
                }
              />
            </View>
          </Show>
        </View>
      </KeyboardAwareScrollView>
      <PlatformKeyboardSpacer />
      <Modal isVisible={isSaving && userImage != null && Platform.OS !== 'web'}>
        <View style={styles.saveModalContainer}>
          <View style={styles.saveModalBox}>
            <KitText style={{ marginBottom: Spacing.l }} semiBold subtitle>
              Uploading photo...
            </KitText>
            <KitProgress percentage={progress * 100} />
          </View>
        </View>
      </Modal>
      <Modal
        isVisible={confirmModalVisible}
        animationIn='fadeIn'
        animationOut='fadeOut'
      >
        <ConfirmModal
          title={confirmActionTitle}
          message={confirmActionMessage}
          cancelButtonText='Cancel'
          confirmButtonText={confirmActionButtonText}
          setConfirmModalVisible={setConfirmModalVisible}
          onConfirm={_onConfirm}
          action={confirmActionKey}
        />
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  avatarListWrapper: {
    marginBottom: Spacing.l,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  touchable: {
    width: 54,
    height: 54,
    ...(Platform.OS === 'android'
      ? {
          marginRight: Spacing.s,
          marginBottom: Spacing.m,
        }
      : {}),
  },
  avatarButton: {
    backgroundColor: Colors.N100,
    width: 57,
    height: 57,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    ...(Platform.OS === 'ios'
      ? {
          marginRight: Spacing.s,
          marginBottom: Spacing.m,
        }
      : {}),
  },
  saveModalContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000066',
  },
  saveModalBox: {
    padding: Spacing.xl,
    width: 240,
    height: 115,
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.N0,
    overflow: 'hidden',
    borderRadius: 10,
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 25,
      },
    }),
  },
  saveModalProgressContainer: {
    margin: Spacing.l,
  },
});
