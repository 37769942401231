import { KitBadge } from '@omni/kit/components';
import Spacing from '@omni/kit/theming/Spacing';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function LiveTag(): JSX.Element {
  return (
    <View style={[styles.tag]}>
      <KitBadge text='LIVE' color='live' size='l' />
    </View>
  );
}

const styles = StyleSheet.create({
  tag: {
    alignItems: 'center',
    flexDirection: 'row',
    position: 'absolute',
    right: Spacing.l,
    top: Spacing.l,
  },
});
