import { BlockPageApp } from '@omni/blocks';
import Environment from '@omni/kit/Environment';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit/constants/identifiers';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import React from 'react';
import { View } from 'react-native';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function BlockPageScreen({ navigation, route }): JSX.Element {
  const { appKey, mediaId } = route.params;

  return (
    <View style={{ flex: 1 }}>
      <BlockPageApp
        appKey={appKey}
        authProviderId={SUBSPLASH_AUTH_PROVIDER_ID}
        url={`${Environment.feedsService}/media-detail/${mediaId}`}
        topBarStyle={NativeTopBarStyle.Transparent}
      />
    </View>
  );
}
