import { ItemAlignment, ItemTextPosition, RadiusStyle } from '@omni/kit/Types';
import GridItem from '@omni/kit/components/GridItem';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SpacingType, getSpacing } from '@omni/kit/theming/SpacingType';
import { useCoalescedSize } from '@omni/kit/utilities/utilities';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:ImageBlock');

export interface IImageBlockProps extends IBlockProps {
  action?: Record<string, unknown>;
  aspectRatio?: number;
  averageColor: string;

  /** Supported in release 6.2.0+ */
  align?: 'start' | 'end' | 'center';

  imageUrl: string;
  subtitle?: string;
  subtitleTextColor?: string;
  title?: string;
  titleTextColor?: string;

  /** Controls how much margin to use on the left and right sides of the image. */
  horizontalSpacing?: SpacingType;

  imageRadius: RadiusStyle;

  /**
   * Controls whether shadows are rendered behind the image.
   * This will be suppressed when there is no horizontal margin
   * since rendering shadows does not make sense if there is no affordance for it.
   */
  shadowEnabled: boolean;

  textAlignmentHorizontal: ItemAlignment;
  textAlignmentVertical: ItemAlignment;

  /** Supported in release 6.2.0+ */
  width?: number;

  /** Supported in release 6.2.0+ */
  height?: number;
}

/** Supported in release 5.5.0+ */
export default function ImageBlock({
  action,
  align,
  averageColor = '#FFFFFF',
  bottomSpacing = SpacingType.None,
  horizontalSpacing,
  imageRadius = RadiusStyle.None,
  imageUrl = '',
  insetStyle = {},
  shadowEnabled = true,
  subtitle,
  subtitleTextColor,
  textAlignmentHorizontal = ItemAlignment.Center,
  textAlignmentVertical = ItemAlignment.Center,
  title,
  titleTextColor,
  topSpacing = SpacingType.None,
  width = 320, // default to banner size
  height = 113,
  aspectRatio = width / height,
}: IImageBlockProps): JSX.Element {
  const { dispatchAction } = useBlockPageContext();

  const [containerWidth, setContainerWidth] = useState(align ? width : 0);
  const [containerHeight, setContainerHeight] = useState(align ? height : 0);
  const [imageWidth, setImageWidth] = useState(width);
  const [imageHeight, setImageHeight] = useState(height);

  const marginHorizontal = getSpacing(horizontalSpacing);

  const viewStyle = {
    ...insetStyle,
    marginTop: getSpacing(topSpacing),
    marginBottom: getSpacing(bottomSpacing),
  };

  const { edgeSpacing, viewPortWidth } = useScreenContext({
    fixedSpacingType: horizontalSpacing,
  });

  const {
    coalescedWidth: coalescedImageWidth,
    coalescedHeight: coalescedImageHeight,
  } = useCoalescedSize(imageWidth, imageHeight);

  useEffect((): void => {
    if (viewPortWidth > 0 && !align) {
      const curContainerWidth = viewPortWidth - edgeSpacing * 2;
      const curContainerHeight = curContainerWidth / aspectRatio;
      setContainerWidth(curContainerWidth);
      setContainerHeight(curContainerHeight);

      setImageWidth(containerWidth);
      setImageHeight(containerHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewPortWidth, edgeSpacing, align]);

  const _onPress = action
    ? () => {
        // @ts-ignore
        dispatchAction(action);
      }
    : undefined;

  return (
    <View style={viewStyle}>
      <GridItem
        style={{
          marginHorizontal: edgeSpacing,
          ...(Boolean(align) && {
            width,
            height,
          }),
          ...(align === 'center' && { alignSelf: 'center' }),
          ...(align === 'start' && { alignSelf: 'flex-start' }),
          ...(align === 'end' && { alignSelf: 'flex-end' }),
        }}
        data={{
          title: title,
          subtitle: subtitle,
          action: action,
          image: imageUrl,
        }}
        onPress={_onPress}
        aspectRatio={aspectRatio}
        averageColor={averageColor}
        fixedWidthEnabled={false}
        width={containerWidth}
        height={containerHeight}
        imageWidth={coalescedImageWidth}
        imageHeight={coalescedImageHeight}
        imageRadius={imageRadius}
        shadowEnabled={shadowEnabled && Boolean(marginHorizontal)}
        titleTextColor={titleTextColor}
        subtitleTextColor={subtitleTextColor}
        textPosition={ItemTextPosition.Overlay}
        textAlignmentHorizontal={textAlignmentHorizontal}
        textAlignmentVertical={textAlignmentVertical}
      />
    </View>
  );
}
