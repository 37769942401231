import { useShellContext } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import KitAvatar from '@omni/kit/components/KitAvatar';
import KitIcon from '@omni/kit/components/KitIcon';
import KitText from '@omni/kit/components/KitText';
import Colors from '@omni/kit/theming/Colors';
import Depth from '@omni/kit/theming/Depth';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useEffect, useRef } from 'react';
import {
  Animated,
  Easing,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native';

import { useBlockPageContext } from '../BlockPageContext';
import { IBlockProps } from './types';

const debug = require('debug')('tca:blocks:AvatarRowBlock');

export interface IAvatarRowBlockProps extends IBlockProps {
  /** An action that will occur when a user taps/clicks on the block.*/
  action?: any;
  subtitle: string;
  appKey?: string;
}

export default function AvatarRowBlock(
  props: IAvatarRowBlockProps
): JSX.Element {
  const { dispatchAction } = useBlockPageContext();

  const { action = null, subtitle = '', appKey } = props;

  const { user } = useShellContext();

  const userInfo = user?.profile;
  const name =
    userInfo && userInfo.fullName.length > 0
      ? userInfo.fullName
      : `${userInfo?.firstName} ${userInfo?.lastName}`;

  const minHeightAnim = useRef(new Animated.Value(78)).current;
  const opacityAnim = useRef(new Animated.Value(0)).current;

  const viewStyle = {
    minHeight: minHeightAnim,
    opacity: opacityAnim,
  };

  useEffect(() => {
    if (userInfo) {
      Animated.timing(minHeightAnim, {
        toValue: 78,
        duration: 500,
        easing: Easing.ease,
        useNativeDriver: false,
      }).start();

      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 500,
        easing: Easing.ease,
        useNativeDriver: false,
      }).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return userInfo ? (
    <Animated.View style={viewStyle}>
      <TouchableHighlight
        underlayColor={Colors.N50}
        onPress={action ? () => dispatchAction(action) : undefined}
      >
        <View style={styles.rowContainer}>
          <View style={{ justifyContent: 'center' }}>
            <View style={Depth.m}>
              <KitAvatar
                imageUrl={userInfo?.photoUrl}
                initials={userInfo?.initials}
                size={36}
              />
            </View>
          </View>
          <View style={styles.textContainer}>
            <KitText style={{ flex: 1, flexWrap: 'wrap' }} color={Colors.N1000}>
              {name}
            </KitText>
            <KitText style={{ flex: 1, flexWrap: 'wrap', fontSize: 14 }}>
              {subtitle}
            </KitText>
          </View>
          <View style={{ justifyContent: 'center' }}>
            <KitIcon name='edit' size={20} color={Colors.N300} />
          </View>
        </View>
      </TouchableHighlight>
    </Animated.View>
  ) : (
    <View />
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: 'row',
    paddingVertical: Spacing.l,
    marginHorizontal: Spacing.l,
    minHeight: 78,
    borderBottomColor: Colors.N100,
    borderBottomWidth: 1,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingLeft: Spacing.m,
  },
});
