import { KitListItem, KitText } from '@omni/kit/components';
import { EventImage } from '@omni/kit/components/EventImage';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, SectionList, View } from 'react-native';
import { SafeAreaInsetsContext } from 'react-native-safe-area-context';
import { useSelector } from 'react-redux';

import { IGroupEventAllListProps } from '../../../../Types';
import { GroupedEventSection } from '../../../../hooks/useTransformGroupEvents';
import { channelSelector } from '../../../redux/selectors';
import GroupEventsEmptyState from './GroupEventsEmptyState';

export default function GroupAllListEvents({
  sections,
  onRefresh,
  isRefreshing,
  accessToken,
}: IGroupEventAllListProps): JSX.Element {
  const insets = useContext(SafeAreaInsetsContext);
  const { t } = useTranslation();
  const channel = useSelector(channelSelector) as GroupChannel;
  const isManager = channel?.myRole === 'operator';
  const { windowHeight, windowWidth } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;

  return (
    <View
      style={[
        Platform.OS === 'web' && { width: 800, marginHorizontal: 'auto' },
      ]}
    >
      <SectionList
        renderItem={({ item }) => (
          <KitListItem
            ImageComponent={
              <EventImage
                accessToken={accessToken}
                redirectUrl={item?.image}
                date={item?.startAt}
              />
            }
            title={item.title}
            subtitleLength={2}
            secondarySubtitle={item.eventSubtitle}
            rightActionIcon='action-h'
            minHeight={78}
            style={{
              paddingHorizontal: Spacing.l,
            }}
            // To be done
            // onPress={() => console.log('group event detail')}
          />
        )}
        sections={sections}
        keyExtractor={(item, index) => item.title + index}
        ListEmptyComponent={<GroupEventsEmptyState isManager={isManager} />}
        ListFooterComponent={<View style={{ height: insets?.bottom }} />}
        onRefresh={onRefresh}
        refreshing={isRefreshing}
        renderSectionHeader={({ section }) => {
          // Get the index of the section
          const sectionIndex = sections?.findIndex(
            (sec: GroupedEventSection) =>
              sec.sectionHeader === section.sectionHeader
          );

          return (
            <View
              style={{
                marginTop: sectionIndex === 0 ? Spacing.m : Spacing.xxl, // No marginTop for the first section
                paddingHorizontal: Spacing.l,
              }}
            >
              <KitText bold fontSize={20} color={Colors.N900}>
                {section.sectionHeader}
              </KitText>
            </View>
          );
        }}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled={false}
      />
    </View>
  );
}
