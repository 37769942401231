import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { GroupRoles, IGroupMember } from '../Types';

const RESOURCE = 'group-member';

interface ICreateGroupMember extends Omit<IUri, 'resource'> {
  endUserId: string;
  groupId: string;
  token: string;
}

interface ICreateGroupMemberResponse extends IHttpResponse {
  body?: IGroupMember;
}

export default ({
  endUserId,
  groupId,
  token,
}: ICreateGroupMember): Promise<ICreateGroupMemberResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    resource: RESOURCE,
  });

  const data = {
    role: GroupRoles.Member,
    _embedded: { group: { id: groupId }, 'end-user': { id: endUserId } },
  };

  return BaseServiceV2.Post({ url, token, data });
};
