import { IndicatorSize } from '@omni/kit/Types';
import { KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import { dateFormatter } from '@omni/kit/utilities/utilities';
import React from 'react';
import { View, ViewStyle } from 'react-native';

interface IDateIndicatorProps {
  date: string;
  dayColor?: string;
  monthColor?: string;
  size: IndicatorSize;
  style?: ViewStyle;
  timeZone?: string;
}

export default function DateIndicator(props: IDateIndicatorProps): JSX.Element {
  const {
    date,
    dayColor = Colors.N0,
    monthColor = Colors.N0,
    size,
    style = {},
    timeZone,
  } = props;

  let fontSize = 10;

  switch (size) {
    case IndicatorSize.Medium:
      fontSize = 16;
      break;
    case IndicatorSize.Large:
      fontSize = 22;
      break;
    case IndicatorSize.ExtraLarge:
      fontSize = 32;
      break;
    default:
      break;
  }

  const monthFontSize = fontSize;
  const dayFontSize = fontSize * 2.4;

  return (
    <View
      style={[
        style,
        {
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}
    >
      <KitText
        style={{
          letterSpacing: 1.67,
          lineHeight: monthFontSize * 1.2,
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
        fontSize={monthFontSize}
        numberOfLines={1}
        ellipsizeMode='clip'
        color={monthColor}
        bold
      >
        {dateFormatter(date, 'MMM', timeZone)}
      </KitText>
      <KitText
        style={{
          letterSpacing: -0.56,
          lineHeight: dayFontSize * 1.2,
          marginTop: -1,
          textAlign: 'center',
        }}
        fontSize={dayFontSize}
        numberOfLines={1}
        ellipsizeMode='clip'
        color={dayColor}
      >
        {dateFormatter(date, 'DD', timeZone)}
      </KitText>
    </View>
  );
}
