import { KitText } from '@omni/kit/components';
import LeftNavButton, { LeftNavType } from '@omni/kit/components/LeftNavButton';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { User } from '@sendbird/chat';
import { Reaction } from '@sendbird/chat/message';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { useSelector } from 'react-redux';

import { channelSelector } from '../../shared/redux/selectors';
import { sbGetUsersById } from '../../utilities/sendbird/channelFunctions';
import { SendbirdUserMessage } from '../Types';
import MemberListRow from '../components/channelDetails/MemberListRow';

const debug = require('debug')('omni:chat:screen:PollResultModal');

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PollResultModal({ navigation, route }): JSX.Element {
  const options = route.params?.options as string[];
  const message = route.params?.message as SendbirdUserMessage;
  const resultCount = route.params?.resultCount as number;

  const { sizeClass } = useSizeClass();

  const pageTitle = `Poll responses (${resultCount})`;

  navigation.setOptions({
    title: pageTitle,
    headerLeft: () => (
      <LeftNavButton
        type={LeftNavType.Dismiss}
        title={`Poll responses (${resultCount})`}
        onPress={navigation.goBack}
      />
    ),
  });

  // @ts-ignore
  const renderOption = (option, index: number) => {
    const keyRegex = /\{([^{}]*)\}/g;
    const reaction = message.reactions?.filter((reaction) => {
      const result = Array.from(reaction.key.matchAll(keyRegex), ([_, g]) => g);

      if (result && result?.length >= 2) {
        // we know this is a validly formatted reaction data block
        return result[1] === index.toString();
      }
    })[0];

    if (!reaction) return null;

    return (
      <View key={index} style={{ marginTop: Spacing.xl }}>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 3 }}>
            <KitText semiBold fontSize={14}>
              {`${option} - ${Math.round(
                (reaction?.userIds.length / resultCount) * 100
              )}%`}
            </KitText>
          </View>
          <View style={{ flex: 1 }}>
            {reaction && (
              <KitText style={{ textAlign: 'right' }} semiBold fontSize={14}>
                {reaction?.userIds.length} votes
              </KitText>
            )}
          </View>
        </View>
        {reaction && <UserList userIds={reaction?.userIds} />}
      </View>
    );
  };

  return (
    <ScrollView
      style={{ backgroundColor: Colors.N0 }}
      contentContainerStyle={{
        paddingHorizontal: Spacing.l,
        paddingBottom: Spacing.l,
        paddingTop: Spacing.m,
      }}
    >
      {Platform.OS === 'web' && sizeClass !== SizeClass.Small && (
        <View
          style={{
            marginBottom: Spacing.s,
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <LeftNavButton
            type={LeftNavType.Dismiss}
            title=''
            onPress={navigation.goBack}
          />
        </View>
      )}
      <KitText semiBold black>
        {message.message}
      </KitText>
      {options.map(renderOption)}
      <View style={{ height: 100 }} />
    </ScrollView>
  );
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function UserList({ userIds }): JSX.Element {
  const [users, setUsers] = useState<User[] | null>(null);

  const channel = useSelector(channelSelector);

  useEffect(() => {
    sbGetUsersById(userIds)
      .then((userList) => {
        setUsers(userList as User[]);
      })
      .catch((e) => {
        debug('Could not get users by ID: ', e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View>
      {users &&
        users.map((user, index) => (
          <MemberListRow
            key={user.userId}
            // @ts-ignore
            member={user}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            channel={channel!}
            style={{ paddingHorizontal: 0 }}
          />
        ))}
    </View>
  );
}
