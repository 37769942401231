import { BaseMessage } from '@sendbird/chat/message';

export const findMessageIndex = (
  newMessage: BaseMessage,
  messages: BaseMessage[]
): number => {
  let index = messages.length;

  for (let i = 0; i < messages.length; i++) {
    if (messages[i].createdAt < newMessage.createdAt) {
      index = i;
      break;
    }
  }

  return index;
};

export const getMessageIndex = (
  newMessage: BaseMessage,
  messages: BaseMessage[]
): number => {
  for (let i = 0; i < messages.length; i++) {
    if (newMessage.isIdentical(messages[i])) {
      return i;
    }
  }

  return -1;
};
