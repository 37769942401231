import { useShellContext } from '@omni/kit';
import {
  KitButton,
  KitIcon,
  KitInput,
  KitListItem,
  KitText,
  KitTouchable,
  KitTouchableIcon,
} from '@omni/kit/components';
import KitMapView from '@omni/kit/components/KitMapView';
import KitSnack, { KitSnackDuration } from '@omni/kit/components/KitSnack';
import PlacesAutocomplete from '@omni/kit/components/PlacesAutocomplete';
import { useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import {
  GroupRoles,
  IGroupAddress,
  IGroupLocation,
} from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';

interface Props {
  navigation: any;
  route: any;
  isOpenLocationModal: boolean;
  setIsOpenLocationModal: (open: boolean) => void;
  group: any;
  locationData: IGroupLocation | undefined;
}
interface SelectedLocation {
  latitude: number;
  longitude: number;
  address: string;
  streetNumber: string | undefined;
  restOfAddress: string | undefined;
  city: string;
  country: string;
  country_obj: {
    code: string;
    name: string;
  };
  postal_code: string;
  state: string;
  street: string;
}
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}
interface GooglePlaceDetails {
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  address_components: AddressComponent[];
  formatted_address: string;
}

export default function AddMeetingLocationScreen({
  route,
  navigation,
  isOpenLocationModal,
  setIsOpenLocationModal,
  group,
  locationData,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] =
    useState<SelectedLocation | null>();

  const { tokens, app, user } = useShellContext();
  const accessToken = useMemo(() => tokens.user || '', [tokens.user]);
  const isWeb = Platform.OS === 'web';
  const { windowWidth, windowHeight } = useSizeClass();
  const isLandscape = windowHeight < windowWidth;
  const groupLocation: IGroupLocation | undefined = isWeb
    ? locationData
    : route.params.groupLocation;
  const calculateGroupId = isWeb ? group.id : route.params.group.id;

  const [locationNameValue, setLocationNameValue] = useState(
    groupLocation?.title || ''
  );

  const endUserId = user?.id;
  const members = isWeb
    ? group._embedded?.['group-members']
    : route.params?.group?._embedded?.['group-members'] || [];

  // Compute managers directly
  const managers = Array.isArray(members)
    ? members.filter(
        (member: { role: string }) => member.role === GroupRoles.Manager
      )
    : [];

  // Compute myselfAsMember directly
  const myselfAsMember = Array.isArray(managers)
    ? managers.find(
        (manager: { _embedded: { [x: string]: { id: string | undefined } } }) =>
          manager._embedded?.['end-user']?.id === endUserId
      )
    : undefined;

  const processAddressComponents = (
    addressComponents: any[],
    latitude: number,
    longitude: number,
    description: string
  ) => {
    const streetNumberComponent = addressComponents.find((component) =>
      component.types.includes('street_number')
    );
    const streetNumber = streetNumberComponent
      ? streetNumberComponent.long_name
      : undefined;

    const city = addressComponents.find((component) =>
      component.types.includes('locality')
    ).long_name;

    const countryComponent = addressComponents.find((component) =>
      component.types.includes('country')
    );

    const country = countryComponent.long_name;

    const country_obj = {
      code: countryComponent.short_name,
      name: countryComponent.long_name,
    };

    const postalCodeComponent = addressComponents.find((component) =>
      component.types.includes('postal_code')
    );
    const postal_code = postalCodeComponent
      ? postalCodeComponent.long_name
      : null;

    const state = addressComponents.find((component) =>
      component.types.includes('administrative_area_level_1')
    ).short_name;

    const streetNameComponent = addressComponents.find((component) =>
      component.types.includes('route')
    );

    const streetName = streetNameComponent ? streetNameComponent.long_name : '';

    const street = `${streetNumber} ${streetName}`.trim();

    const restOfAddress = addressComponents
      .filter((component) => !component.types.includes('street_number'))
      .map((component) => component.long_name)
      .join(', ');

    setSelectedLocation({
      latitude: latitude,
      longitude: longitude,
      address: description,
      streetNumber: streetNumber,
      restOfAddress: restOfAddress,
      city: city,
      country: country,
      country_obj: country_obj,
      postal_code: postal_code,
      state: state,
      street: street,
    });
  };

  const handleSelectLocation = (
    data: any,
    details: GooglePlaceDetails | null
  ) => {
    if (Platform.OS !== 'web' && details) {
      processAddressComponents(
        details.address_components,
        details.geometry.location.lat,
        details.geometry.location.lng,
        details.formatted_address
      );
    }

    if (Platform.OS === 'web' && details) {
      const latitude =
        typeof details.geometry.location.lat === 'function'
          ? // @ts-ignore
            details.geometry.location.lat()
          : details.geometry.location.lat;
      const longitude =
        typeof details?.geometry.location.lat === 'function'
          ? // @ts-ignore
            details.geometry.location.lng()
          : details.geometry.location.lng;
      processAddressComponents(
        details.address_components,
        latitude,
        longitude,
        details.formatted_address
      );
    }
  };

  const isEdited = useMemo(() => {
    const titleName = locationNameValue || undefined;

    if (selectedLocation !== undefined) {
      return true;
    }

    if (selectedLocation === undefined && groupLocation?.title !== titleName) {
      return true;
    }

    return false;
  }, [selectedLocation, groupLocation?.title, locationNameValue]);

  const handleSave = useCallback(async () => {
    const updateGroupLocation = async (locationId: string) => {
      const response = await GroupsService.UpdateGroupLocation({
        body: {
          title: locationNameValue,
          _embedded: groupLocation?._embedded,
        },
        id: locationId,
        token: accessToken,
      });

      if (response.status === 200 && response.body) {
        navigation.goBack();
        KitSnack.show(
          t('messaging:successMeetingLocationMessage'),
          KitSnackDuration.SHORT
        );
      } else {
        KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
      }
    };

    if (isEdited && groupLocation?.id) {
      updateGroupLocation(groupLocation.id);
    }

    try {
      if (accessToken && myselfAsMember.id && selectedLocation) {
        const addressData: Partial<IGroupAddress> = {
          app_key: app.appKey,
          city: selectedLocation.city,
          country: selectedLocation.country,
          country_obj: selectedLocation?.country_obj,
          latitude: selectedLocation?.latitude,
          longitude: selectedLocation?.longitude,
          postal_code: selectedLocation?.postal_code,
          state: selectedLocation?.state,
          street: selectedLocation?.street,
          _embedded: {
            manager: {
              id: myselfAsMember.id,
            },
          },
        };

        const addressResponse = await GroupsService.CreateGroupAddress({
          body: addressData,
          token: accessToken,
        });

        if (addressResponse.body) {
          const locationData: Partial<any> = {
            app_key: app.appKey,
            title: locationNameValue,
            subtitle: selectedLocation.address,
            type: ['group'],
            _embedded: {
              group: {
                id: calculateGroupId,
              },
              address: {
                id: addressResponse.body.id,
              },
            },
          };

          const locationResponse = await GroupsService.CreateGroupLocation({
            body: locationData,
            token: accessToken,
          });

          if (locationResponse.status === 201 && locationResponse.body) {
            navigation.goBack();
            isOpenLocationModal && setIsOpenLocationModal(false);
            KitSnack.show(
              t('messaging:successMeetingLocationMessage'),
              KitSnackDuration.SHORT
            );
          } else {
            KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
          }
        } else {
          KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
        }
      }
    } catch (error) {
      KitSnack.show(t('messaging:errorMessage'), KitSnackDuration.SHORT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdited,
    accessToken,
    calculateGroupId,
    selectedLocation,
    locationNameValue,
    myselfAsMember,
    isOpenLocationModal,
    navigation,
  ]);

  const handleRemoveLocation = useCallback(async () => {
    if (isWeb) {
      if (groupLocation !== undefined) {
        const response = await GroupsService.DeleteGroupLocation({
          id: groupLocation.id,
          token: accessToken,
        });

        if (response.status === 204) {
          navigation.goBack();
          setIsOpenLocationModal(false);
        }

        setSelectedLocation(undefined);
      }
    } else {
      if (groupLocation !== undefined) {
        const response = await GroupsService.DeleteGroupLocation({
          id: groupLocation.id,
          token: accessToken,
        });

        response.status === 204 && navigation.goBack();
        setSelectedLocation(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, selectedLocation, groupLocation]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        headerRight: () => (
          <KitTouchable
            onPress={handleSave}
            disabled={!isEdited}
            style={{
              paddingLeft: 12,
              paddingRight: Platform.OS === 'ios' ? 12 : 18,
              paddingVertical: 6,
              marginRight: -6,
              borderRadius: 40,
            }}
          >
            <KitText
              color={isEdited ? Colors.brand : Colors.N400}
              fontSize={16}
            >
              {t('common:buttonTitleSave')}
            </KitText>
          </KitTouchable>
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdited, navigation, handleSave, selectedLocation, groupLocation]);

  return (
    <>
      <View
        style={[
          styles.container,
          isLandscape &&
            !isWeb && { paddingTop: 24, paddingLeft: 24, paddingRight: 24 },
        ]}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <KitText
            color={Colors.N900}
            style={[
              styles.title,
              (groupLocation !== undefined ||
                selectedLocation !== undefined) && { marginBottom: 0 },
            ]}
          >
            {t('messaging:meetingLocation')}
          </KitText>

          {isOpenLocationModal && (
            <KitTouchableIcon
              name='remove'
              buttonSize={44}
              size={20}
              onPress={() => {
                if (setIsOpenLocationModal) {
                  setIsOpenLocationModal(false);
                }
              }}
              color={Colors.N300}
              style={{
                marginTop: -12,
                marginRight: -12,
                position: 'absolute',
                right: 0,
              }}
            />
          )}
        </View>

        {(groupLocation !== undefined || selectedLocation !== undefined) && (
          <KitText
            color={Colors.N500}
            fontSize={14}
            style={{ marginBottom: Spacing.l }}
          >
            {t('messaging:exactMeetingLocation')}
          </KitText>
        )}

        {groupLocation || selectedLocation ? (
          (() => {
            const extractStreetNumber = (address: any) => {
              if (typeof address !== 'string' || address.trim() === '') {
                return undefined;
              }

              // Use a regular expression to check if the address starts with a number
              const match = address.match(/^(\d+)/);

              return match ? match[0] : undefined; // Return the matched number or undefined
            };

            const extractStreetName = (address: any) => {
              if (typeof address !== 'string' || address.trim() === '') {
                return undefined;
              }

              // Use a regular expression to remove the leading street number
              return address.replace(/^\d+\s*,?\s*/, '').trim();
            };

            const streetNumber = extractStreetNumber(
              groupLocation?._embedded?.address?.street
            );

            return (
              <>
                <KeyboardAvoidingView
                  behavior={Platform.OS === 'ios' ? 'position' : 'height'}
                  keyboardVerticalOffset={isLandscape ? 40 : 80}
                >
                  <ScrollView>
                    <View
                      style={{ overflow: 'hidden', borderRadius: 8 }}
                      testID='locationMap'
                    >
                      <KitMapView
                        latitude={
                          groupLocation?._embedded.address.latitude ||
                          selectedLocation?.latitude
                        }
                        longitude={
                          groupLocation?._embedded.address.longitude ||
                          selectedLocation?.longitude
                        }
                        circle={{
                          radius: 3218.68,
                          strokeColor: Colors.N1000,
                          fillColor: 'rgba(0, 0, 0, 0.2)',
                          strokeOpacity: 0.8,
                          fillOpacity: 0.8,
                          strokeWeight: 0.6,
                        }}
                        webStyle={{ height: 176, borderRadius: 8 }}
                        nativeStyle={{ height: 176, borderRadius: 8 }}
                      />
                    </View>
                    <KitListItem
                      title={streetNumber || selectedLocation?.streetNumber}
                      subtitle={
                        groupLocation?.subtitle ||
                        extractStreetName(selectedLocation?.address)
                      }
                      topBorder={false}
                      style={{ marginTop: 0 }}
                      ImageComponent={
                        <KitIcon
                          name='not-filled-location'
                          size={20}
                          color={Colors.N600}
                          style={{ marginLeft: Spacing.s }}
                        />
                      }
                      testID='locationAddress'
                    />

                    <KitInput
                      label={t('messaging:labelLocationNameOptional')}
                      onChangeText={(e) => setLocationNameValue(e)}
                      style={{
                        marginTop: Spacing.xl,
                        marginBottom: isWeb ? 0 : Spacing.l,
                      }}
                      testID='locationName'
                      value={locationNameValue}
                    />
                    {Platform.OS !== 'web' &&
                      groupLocation !== undefined &&
                      !isLandscape && (
                        <KitButton
                          secondary
                          icon='minus-circle'
                          iconSize={14}
                          title={t('messaging:removeLocationButtonLabel')}
                          onPress={handleRemoveLocation}
                        />
                      )}

                    {(groupLocation !== undefined ||
                      selectedLocation !== undefined) &&
                      (isWeb || isLandscape) && (
                        <View
                          style={[
                            styles.buttonContainer,
                            groupLocation === undefined && {
                              justifyContent: 'flex-end',
                            },
                          ]}
                        >
                          {groupLocation !== undefined && (
                            <KitButton
                              secondary
                              icon='minus-circle'
                              iconSize={14}
                              title={t('messaging:removeLocationButtonLabel')}
                              onPress={handleRemoveLocation}
                              style={styles.leftButton}
                              testID='removeLocation'
                            />
                          )}
                          <View style={styles.rightButtonsContainer}>
                            <KitButton
                              secondary
                              title={t('messaging:buttonTitleCancel')}
                              onPress={
                                Platform.OS === 'web'
                                  ? () => setIsOpenLocationModal(false)
                                  : navigation.goBack
                              }
                              style={styles.rightButton}
                            />
                            <KitButton
                              title={t('common:buttonTitleSave')}
                              color={Colors.N900}
                              onPress={handleSave}
                              style={styles.rightButton}
                              disabled={!isEdited}
                            />
                          </View>
                        </View>
                      )}
                  </ScrollView>
                </KeyboardAvoidingView>
              </>
            );
          })()
        ) : (
          <PlacesAutocomplete
            onPlaceSelected={handleSelectLocation}
            placeholder={t('messaging:placeholderAddress')}
            testID='locationInput'
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    paddingTop: Platform.OS === 'web' ? 14 : 12,
    paddingLeft:
      Platform.OS === 'web' ? 14 : Platform.OS === 'android' ? 24 : 18,
    paddingRight:
      Platform.OS === 'web' ? 14 : Platform.OS === 'android' ? 24 : 18,
    paddingBottom: 0,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingTop: Spacing.xl,
    paddingBottom: Platform.OS === 'web' ? 14 : Spacing.xl,
    paddingHorizontal: Platform.OS === 'web' ? 14 : Spacing.xl,
  },
  leftButton: {
    marginRight: Spacing.s,
  },
  rightButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  rightButton: {
    marginLeft: Spacing.s,
  },
});
