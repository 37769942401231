import { useEffect, useState } from 'react';

import AccountsService from '../services/AccountsService';
import { App } from '../services/Types';

export default function useAppData(appKey?: string): {
  app: App | undefined;
  appLoading: boolean;
} {
  const [app, setApp] = useState<App | undefined>();
  const [appLoading, setAppLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetch = async (appKey: string) => {
      try {
        const app = await AccountsService.getApp(appKey, {
          includeAppStoreInfo: true,
          includeBranding: true,
          includeFeatures: true,
          includeContainerAppInfo: true,
          includeWebApp: true,
        });
        setApp(app);
      } catch (e) {
        console.warn(e);
      } finally {
        setAppLoading(false);
      }
    };

    if (appKey) {
      fetch(appKey);
    } else {
      setAppLoading(false);
    }
  }, [appKey]);

  return { app, appLoading };
}
