import { KitText } from '@omni/kit/components';
import KitSnack, {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import { SizeClassV2, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import GroupsService from '@omni/kit/services/GroupsService';
import { IGroupLocation } from '@omni/kit/services/GroupsService/Types';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { accessTokenSelector, channelSelector } from '../../redux/selectors';
import DeleteEventConfirmModal from './components/DeleteEventConfirmModal';
import EventDetailHeader from './components/EventDetailHeader';
import EventDetailLocation from './components/EventDetailLocation';
import MoreActionModal from './components/MoreActionModal';

const debug = require('debug')('tca:chat:screen:EventDetailsScreen');

export default function EventDetailsScreen(): JSX.Element {
  const navigation = useNavigation();
  const { t } = useTranslation(['groups']);
  const route = useRoute();
  const { sizeClassV2, windowHeight, windowWidth } = useSizeClass();
  const accessToken = useSelector(accessTokenSelector);

  // @ts-ignore
  const { event } = route.params;

  const isLarge = sizeClassV2 >= SizeClassV2.L;
  const isLandscape = windowHeight < windowWidth;
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });

  const [moreActionModal, setMoreActionModal] = useState<boolean>(false);
  const [eventToDelete, setEventToDelete] = useState<boolean>(false);
  const [eventLocation, setEventLocation] = useState<
    IGroupLocation | undefined
  >();

  const channel = useSelector(channelSelector) as GroupChannel;
  const isManager = channel?.myRole === 'operator';

  //****************************************************************************
  // Methods
  //****************************************************************************

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  const openMoreActionModal = () => {
    setMoreActionModal(true);
  };

  const closeMoreActionModal = () => {
    setMoreActionModal(false);
  };

  const _onEditEvent = () => {
    setMoreActionModal(false);
    // TODO go to create/edit event screen
  };
  const _onDeleteEvent = () => {
    setMoreActionModal(false);
    setTimeout(() => {
      setEventToDelete(true);
    }, 500);
  };

  const _confirmDeleteEvent = () => {
    debug('Confirm Delete Event');
    // TODO dispatch delete event action

    // navigate to all events calendar screen
    showKitSnack(t('groups:eventDeleted'), KitSnackDuration.SHORT);
  };

  //****************************************************************************
  // Lifecycle
  //****************************************************************************

  const fetchGroupLocation = useCallback(() => {
    const _fetchGroupLocation = async (id: string) => {
      try {
        const response = await GroupsService.GetGroupLocation({
          id,
          token: accessToken,
        });

        if (response.body) {
          setEventLocation(response.body);
        } else {
          debug('Could not fetch group location');
        }
      } catch {}
    };

    event?._embedded?.location?.id
      ? _fetchGroupLocation(event._embedded.location.id)
      : setEventLocation(undefined);
  }, [accessToken, event?._embedded?.location?.id]);

  // Hooks
  useEffect(() => {
    fetchGroupLocation();
  }, [fetchGroupLocation]);

  useEffect(() => {
    navigation.setParams({ openMoreActionModal });
    navigation.setParams({ isManager: isManager });
    navigation.setParams({ onEditEvent: _onEditEvent });
    navigation.setParams({ onDeleteEvent: _onDeleteEvent });
  }, [navigation, isManager]);

  return (
    <>
      <ScrollView style={{ backgroundColor: 'white' }}>
        <View
          style={[
            {
              maxWidth: '100%',
              marginTop: Platform.OS !== 'web' && isLandscape ? 14 : 0,
              borderTopWidth: Platform.OS !== 'web' && isLandscape ? 1 : 0,
              borderTopColor:
                Platform.OS !== 'web' && isLandscape ? Colors.N100 : Colors.N0,
            },
            styles.safeContainer,
          ]}
        >
          <View
            style={{
              paddingHorizontal: isLarge ? Spacing.xxxxl + 8 : Spacing.l,
              paddingTop: Platform.OS !== 'web' ? Spacing.m : Spacing.xl,
              paddingBottom: Spacing.xxl,
            }}
          >
            {/* Event Header */}
            <EventDetailHeader
              event={event as any}
              eventLocation={eventLocation}
            />

            {/* Event Location */}
            <EventDetailLocation
              eventLocation={eventLocation}
              // @ts-ignore
              marker={eventLocation?.title}
            />

            {/* Event Description */}
            {event.description_text !== undefined && (
              <View
                style={{
                  position: 'relative',
                  marginTop: Spacing.xxl,
                }}
              >
                <KitText
                  fontSize={20}
                  bold
                  color={Colors.N900}
                  style={{ marginBottom: Spacing.xs }}
                >
                  {t('eventsAbout')}
                </KitText>
                <KitText expandable numberOfLines={3}>
                  {event.description_text}
                </KitText>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />

      {Platform.OS !== 'web' && (
        <>
          {/* More Action Modal */}
          <MoreActionModal
            isVisible={moreActionModal}
            isManager={isManager}
            onClose={closeMoreActionModal}
            onEditEvent={_onEditEvent}
            onDeleteEvent={_onDeleteEvent}
          />
        </>
      )}

      <DeleteEventConfirmModal
        isVisible={eventToDelete}
        onClose={() => setEventToDelete(false)}
        title={t('deleteRecurringEvent')}
        subtitle={t('groups:deleteRecurringEventDesc')}
        confirmAction={_confirmDeleteEvent}
        event={event}
      />
    </>
  );
}

//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    paddingHorizontal: Spacing.l,
  },
  safeContainer: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
});
