import { KitIcon, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import ChannelAvatar from '@omni/messaging/shared/scenes/channelList/components/ChannelAvatar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

interface HeaderLeftComponentProps {
  onPress: () => void;
  channel: any;
  channelName: any;
  width: number;
}

const HeaderTitleLeftComponent: React.FC<HeaderLeftComponentProps> = ({
  onPress,
  channel,
  channelName,
  width,
}) => {
  const { t } = useTranslation(['groups']);

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.5}
      style={styles.touchableOpacity}
    >
      {channel !== null && <ChannelAvatar channel={channel} customSize={35} />}
      <View style={styles.innerContainer}>
        <View style={styles.textContainer}>
          <KitText
            black
            fontSize={16}
            bold
            numberOfLines={1}
            ellipsizeMode='tail'
            style={{ maxWidth: width / 2.3 }}
          >
            {channelName}
          </KitText>
          <KitText
            color={Colors.N500}
            fontSize={14}
            numberOfLines={1}
            ellipsizeMode='tail'
            style={{ marginTop: -5 }}
          >
            {channel?.memberCount !== undefined &&
              t('membersWithCount', {
                count: channel?.memberCount,
              })}
          </KitText>
        </View>
        <KitIcon
          color={Colors.N1000}
          name='arrow-right'
          size={20}
          style={styles.icon}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  touchableOpacity: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    maxWidth: '100%',
  },
  textContainer: {
    marginLeft: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  icon: {
    alignItems: 'flex-end',
    marginTop: 2,
  },
});

export default HeaderTitleLeftComponent;
