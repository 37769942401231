import { KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import { ImageServiceType, parseImageUrl } from '@omni/kit/utilities/utilities';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';

const EVENT_IMAGE_SIZE_MEDIUM = 36;
const EVENT_IMAGE_SIZE_LARGE = 64;

export const EventImage = ({
  redirectUrl,
  date,
  size = 'm',
  accessToken,
  customWidth,
  customHeight,
  customBorderRadius,
}: {
  redirectUrl?: string;
  date?: string;
  size?: 'm' | 'l';
  accessToken: string | null | undefined;
  customWidth?: number | undefined;
  customHeight?: number | undefined;
  customBorderRadius?: number | undefined;
}): JSX.Element => {
  const [finalImageUrl, setFinalImageUrl] = useState('');
  const width = customWidth
    ? customWidth
    : size === 'm'
    ? EVENT_IMAGE_SIZE_MEDIUM
    : EVENT_IMAGE_SIZE_LARGE;
  const height = customHeight
    ? customHeight
    : size === 'm'
    ? EVENT_IMAGE_SIZE_MEDIUM
    : EVENT_IMAGE_SIZE_LARGE;

  const headerFontStyles = useMemo(() => {
    switch (size) {
      case 'm':
        return { fontSize: 8, lineHeight: 10 };
      case 'l':
        return { fontSize: 12, lineHeight: 18 };
      default:
        break;
    }
  }, [size]);

  const bodyFontStyles = useMemo(() => {
    switch (size) {
      case 'm':
        return { fontSize: 14, lineHeight: 18 };
      case 'l':
        return { fontSize: 24, lineHeight: 26 };
      default:
        break;
    }
  }, [size]);

  // Fetch final image url to be passed to KitImage to be used as the cache key
  const getFinalImageUrl = useCallback(async () => {
    if (redirectUrl && accessToken) {
      try {
        const response = await fetch(redirectUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setFinalImageUrl(response.url);
      } catch (e) {
        console.error('Failed to fetch image URL:', e);
      }
    }
  }, [redirectUrl, accessToken]);

  useEffect(() => {
    getFinalImageUrl();
  }, [getFinalImageUrl]);

  return redirectUrl ? (
    <View
      style={{
        width: width,
        height: height,
        backgroundColor: Colors.N100,
        borderRadius: customBorderRadius ? customBorderRadius : BorderRadius.m,
      }}
    >
      <KitImage
        style={{
          width: width,
          height: height,
          borderRadius: customBorderRadius
            ? customBorderRadius
            : BorderRadius.m,
        }}
        source={{
          uri: parseImageUrl(
            finalImageUrl,
            width,
            height,
            ImageServiceType.ImagePng
          ),
        }}
      />
    </View>
  ) : (
    <View style={[styles.calendarContainer, { width: width, height: height }]}>
      <View style={styles.calendarHeader}>
        <KitText
          bold
          white
          style={{ ...headerFontStyles, textTransform: 'uppercase' }}
        >
          {moment(date).format('MMM')}
        </KitText>
      </View>
      <View style={styles.calendarBody}>
        <KitText
          extraBold={size === 'l'}
          bold={size === 'm'}
          black
          style={bodyFontStyles}
        >
          {moment(date).format('D')}
        </KitText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  calendarContainer: {
    borderRadius: BorderRadius.m,
    borderWidth: 1,
    borderColor: Colors.N100,
    overflow: 'hidden',
  },
  calendarHeader: {
    alignItems: 'center',
    paddingVertical: 1,
    backgroundColor: Colors.N900,
  },
  calendarBody: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N0,
  },
});
