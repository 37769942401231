import { Cache } from 'react-native-cache';

import { IDownloaderResponse } from '../../Downloader';
import defaultCache from './DefaultCache';

const debug = require('debug')(
  'tca:kit:services:CacheService:getCachedResponse'
);

export default async function getCachedResponse({
  url,
  authorization,
  cache,
}: {
  url: string;
  authorization?: string | null;
  cache?: Cache;
}): Promise<IDownloaderResponse> {
  try {
    const cachedResult = (await getFromCache(url, cache)) as
      | IDownloaderResponse
      | undefined;

    if (cachedResult) {
      // do not return response from cache if Cache-Control contains 'private' and the cached Authorization Bearer does not match the current value
      const cacheControl = cachedResult?.headers?.['Cache-Control'];
      const isCacheControlPublic = !cacheControl || cacheControl === 'public';
      debug(`Cache-Control is public: ${isCacheControlPublic}`);
      if (!isCacheControlPublic) {
        const cachedAuthorization = cachedResult?.authorization;
        debug(`Cached authorization: ${Boolean(cachedAuthorization)}`);
        debug(`Request authorization: ${Boolean(authorization)}`);

        const authorizationUnused = !cachedAuthorization && !authorization;
        const authorizationMatches = cachedAuthorization === authorization;
        const allowCachedResponse = authorizationUnused || authorizationMatches;

        let reason = '';

        if (authorizationUnused) {
          reason = 'Authorization not used';
        } else if (authorizationMatches) {
          reason = 'Authorization matches';
        } else if (!authorization) {
          reason = 'Request missing Authorization';
        } else if (!authorizationMatches) {
          reason = 'Authorization mismatch';
        }

        debug(
          `Cached response allowed: ${allowCachedResponse}, reason: ${reason}`
        );
        if (!allowCachedResponse) {
          return;
        }
      }

      const cachedResultJson = JSON.stringify(cachedResult);
      const cachedResultJsonTruncated =
        cachedResultJson.length > 1000
          ? cachedResultJson.substring(0, 1000) + '...'
          : cachedResultJson;

      debug(`Cached result: ${cachedResultJsonTruncated}`);

      return cachedResult;
    }
  } catch (ex) {
    debug(`Error getting data = ${url} from the cache`);
  }

  return;
}

async function getFromCache(id: string, cache?: Cache) {
  return new Promise((resolve, reject) => {
    cache = cache || defaultCache;
    cache
      .get(id)
      .then((value) => {
        if (value) {
          debug(`Data for ${id} retrieved from cache`);

          try {
            const cachedResponse =
              typeof value === 'string'
                ? JSON.parse(value)
                : (value as IDownloaderResponse | undefined);
            resolve(cachedResponse);
          } catch (error) {
            debug(`Failed to parse cached response: ${error}`);
            resolve(undefined);
          }
          resolve(value);
        } else {
          debug(`Data for ${id} not found in cache`);
          resolve(undefined);
        }
      })
      .catch((err) => {
        if (err) {
          debug(err);
          reject();
        }
      });
  });
}
