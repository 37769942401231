import { SOURCE_TYPES, SourceContextProvider } from '@omni/kit';
import store from '@omni/messaging/shared/redux/store';
import { ReactNode } from 'react';
import React from 'react';
import { Provider } from 'react-redux';

export const GroupsWrapper = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  // using source 'messaging' to track group finder together with messaging for the time being
  return (
    <SourceContextProvider source={SOURCE_TYPES.MESSAGING}>
      <Provider store={store}>{children}</Provider>
    </SourceContextProvider>
  );
};
