import BaseServiceV2, { IHttpResponse } from '../../../BaseServiceV2';
import { cache } from '../../../SearchService/Cache';
import TokensService from '../../../TokensService/TokensService';
import {
  IMediaPlays,
  IPrepareMediaPlaysUrl,
  prepareMediaPlaysUrl,
} from '../index';

const debug = require('debug')(
  'tca:service:MediaPersonalizationService:MediaPlayService:Core:GetAll'
);

interface IMediaPlaysGetAll extends Omit<IPrepareMediaPlaysUrl, 'id'> {
  appKey?: string;
  getFromCache?: boolean;
  headers?: { [key: string]: string };
  signal?: AbortSignal;
}

interface IMediaPlaysGetAllResponse extends IHttpResponse {
  body?: {
    _embedded: {
      'media-plays': IMediaPlays[];
    };
    total?: number;
    count: number;
  };
}

export default async function getAll({
  appKey,
  getFromCache,
  headers,
  signal,
  ...buildUrlProps
}: IMediaPlaysGetAll): Promise<IMediaPlaysGetAllResponse> {
  debug('GetAll');
  const token = await TokensService.getGuestToken();
  const url = prepareMediaPlaysUrl(buildUrlProps);

  return BaseServiceV2.Get({
    appKey,
    cache,
    getFromCache,
    headers,
    skipTimestamp: true,
    token,
    url,
    signal,
  });
}
