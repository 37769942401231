import { KitIcon, KitText } from '@omni/kit/components';
import KitScripture from '@omni/kit/components/KitScripture';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useState } from 'react';
import { Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native';

import { CustomMessageData } from '../../../../../Types';

const debug = require('debug')(
  'omni:messaging:kit:scenes:channel:BibleMessage'
);

const MESSAGE_HEIGHT = Platform.OS === 'web' ? 150 : 162;
const HEADER_HEIGHT = 40;

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function BibleMessage({
  // @ts-ignore
  message,
  // @ts-ignore
  disabled,
}): JSX.Element | null {
  const [isLongPassage, setIsLongPassage] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const data = message.data;
  let osis, versionId, displayReference;

  if (data) {
    try {
      const parsedData: CustomMessageData = JSON.parse(data);
      osis = parsedData.osis;
      versionId = parsedData.versionId;
      displayReference = parsedData.displayReference || osis;
    } catch (e) {
      debug('Error parsing osis/version in bible message');
    }
  }

  if (!osis || !versionId) {
    return null;
  }

  const toggleIsExpanded = () => setIsExpanded(!isExpanded);

  return (
    <Pressable
      onPress={toggleIsExpanded}
      disabled={disabled}
      style={[
        styles.wrapper,
        !isExpanded && {
          maxHeight: MESSAGE_HEIGHT,
        },
      ]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <KitIcon name='bible' size={16} />
        <KitText black semiBold fontSize={14} style={{ marginLeft: Spacing.s }}>
          {displayReference}
        </KitText>
      </View>
      <KitScripture
        osis={`${osis}`}
        version={versionId}
        style={
          [
            styles.scripture,
            isLongPassage && { marginBottom: OVERFLOW_HEIGHT },
          ] as ViewStyle
        }
        showTitles={false}
        onContentSizeChange={(_contentWidth, contentHeight) => {
          setIsLongPassage(contentHeight > MESSAGE_HEIGHT - HEADER_HEIGHT);
        }}
        baseFontStyle={{
          fontSize: 16,
          lineHeight: 26,
        }}
      />
      {isLongPassage && (
        <View style={styles.overflowContainer}>
          <View style={styles.translucent} />
          <KitText fontSize={13} style={styles.expand}>
            {isExpanded ? 'Show Less' : 'Read More'}
          </KitText>
        </View>
      )}
    </Pressable>
  );
}

const OVERFLOW_HEIGHT = 25;
const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.N100,
    borderRadius: 20,
    padding: Spacing.m,
    backgroundColor: Colors.N0,
    overflow: 'hidden',
  },
  scripture: {
    paddingHorizontal: Platform.OS === 'web' ? Spacing.m : Spacing.s,
    overflow: 'hidden',
  },
  overflowContainer: {
    position: 'absolute',
    height: OVERFLOW_HEIGHT,
    bottom: 0,
    left: 0,
    width: '100%',
    alignItems: 'center',
  },
  translucent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.N0,
  },
  expand: {
    zIndex: 1,
  },
});
