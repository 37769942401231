import { SUBSPLASH_AUTH_PROVIDER_ID } from '@omni/kit';
import { dispatch } from '@omni/kit/ActionHandler';
import Environment from '@omni/kit/Environment';
import { KitIcon, KitLoader, KitText } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import useMediaStatus from '@omni/kit/hooks/useMediaStatus';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import {
  ImageServiceType,
  SCREEN_WIDTH,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import React, { useContext } from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';

import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';
import MediaService from '../../../../../services/MediaService';
import { appKeySelector } from '../../../../../shared/redux/selectors';
import { MEDIA_DETAIL_SCREEN } from '../../../../Constants';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function MediaMessage({ message, disabled }): JSX.Element {
  const data = JSON.parse(message.data);
  const { title, speaker, image, id } = data;
  const mediaWidth = Platform.OS === 'web' ? 400 : SCREEN_WIDTH * 0.72;
  const mediaHeight = mediaWidth / 1.6;
  const imageBanner = parseImageUrl(
    image,
    mediaWidth,
    mediaHeight,
    ImageServiceType.ImageJpeg
  );

  const appKey = useSelector(appKeySelector);

  const playlistUrl = `${Environment.feedsService}/media-playlist/${id}`;

  const { playlistData, broadcastData, hasAudio, hasVideo, error } =
    useMediaStatus({
      playlistUrl,
      enablePolling: false,
      authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
    });

  const { handleSelectingMessage } = useContext(ChatContext);

  // @ts-ignore
  let navigation;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigation = useNavigation();
  } catch {}

  const onPlayPress = () => {
    if (Platform.OS === 'web') {
      _openMediaInBrowser({ mediaId: id, title });
    } else if (!hasVideo && !hasAudio) {
      _onMessagePress();
    } else {
      // Launch player with video
      const playAction = {
        handler: hasVideo ? 'video' : 'audio',
        url: playlistUrl,
      };
      dispatch(playAction);
    }
  };

  const _onMessagePress = () => {
    if (Platform.OS === 'web') {
      _openMediaInBrowser({ mediaId: id, title });

      return;
    }

    // @ts-ignore
    navigation.navigate(MEDIA_DETAIL_SCREEN, {
      appKey: appKey,
      mediaId: id,
      title,
    });
  };

  // @ts-ignore
  const _openMediaInBrowser = (media) => {
    MediaService.getOne('media-items', media.mediaId).then((response) => {
      // @ts-ignore
      const item = response.data;

      if (item?._links?.share?.href) {
        const win = window.open(item._links.share.href, '_blank');
        win?.focus();
      }
    });
  };

  return (
    <Pressable
      style={({ pressed }) => ({
        borderRadius: 20,
        backgroundColor: pressed ? Colors.N50 : Colors.N0,
      })}
      onPress={_onMessagePress}
      disabled={disabled}
    >
      <View style={styles.wrapper}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: Spacing.m,
          }}
        >
          <KitIcon name='media' size={16} />
          <View style={{ marginLeft: Spacing.m }}>
            <KitText
              black
              bold
              fontSize={14}
              style={{ lineHeight: 16 }}
              numberOfLines={1}
            >
              {title}
            </KitText>
            {speaker?.length > 0 && (
              <KitText
                fontSize={13}
                black
                style={{ lineHeight: 16 }}
                numberOfLines={1}
              >
                {speaker}
              </KitText>
            )}
          </View>
        </View>
        <TouchableOpacity
          style={[styles.media, { width: mediaWidth, height: mediaHeight }]}
          onPress={onPlayPress}
          onLongPress={handleSelectingMessage}
          activeOpacity={0.7}
          disabled={disabled}
        >
          <KitImage
            source={{ uri: imageBanner }}
            style={{
              ...styles.imageBanner,
              width: mediaWidth,
              height: mediaHeight,
            }}
          />
          <View style={{ position: 'absolute' }}>
            <PlayButton
              mediaWidth={mediaWidth}
              broadcastData={broadcastData}
              playlistData={playlistData}
              hasAudio={hasAudio}
              hasVideo={hasVideo}
              error={error}
            />
          </View>
        </TouchableOpacity>
      </View>
    </Pressable>
  );
}

type PlayButtonProps = {
  mediaWidth: number;
  broadcastData: any;
  playlistData: any;
  hasAudio: boolean;
  hasVideo: boolean;
  error: any;
};

const PlayButton = ({
  mediaWidth,
  broadcastData,
  playlistData,
  hasAudio,
  hasVideo,
  error,
}: PlayButtonProps) => {
  const playButton = (
    <View style={styles.playWrapper}>
      <KitIcon name='play' color={Colors.N0} />
    </View>
  );
  const loading = !broadcastData && !playlistData;

  if (Platform.OS === 'web') {
    return playButton;
  } else if (error) {
    return (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000dd',
          borderRadius: 44,
        }}
      >
        <KitText
          white
          semiBold
          center
          style={{ lineHeight: 44, width: mediaWidth / 1.5 }}
        >
          {error}
        </KitText>
      </View>
    );
  } else if (loading) {
    return <KitLoader large delay={500} color={Colors.N400} />;
  } else if (hasAudio || hasVideo) {
    return playButton;
  } else {
    return null;
  }
};

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.N100,
    borderRadius: 20,
    padding: Spacing.m,
    minWidth: 250,
    overflow: 'hidden',
  },
  media: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.N100,
    minHeight: 100,
    position: 'relative',
    borderRadius: 6,
    overflow: 'hidden',
  },
  imageBanner: {
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // bottom: 0,
    // right: 0,
    borderWidth: 1,
    borderColor: Colors.N100,
    borderRadius: 6,
  },
  playWrapper: {
    backgroundColor: '#00000096',
    borderRadius: 100,
    width: 60,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
