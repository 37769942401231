import { ChatActionTypes } from '../actions/ChatActions';
import { IChatState } from '../types';

const debug = require('debug')('tca:chat:redux:ChatReducer');

// @ts-ignore
export const chatInitialChatState: IChatState = {
  messageList: [],
  prayerList: [],
  memberList: [],
  invitedList: [],
  channel: null,
  channelName: '',
  addedUsersList: null,
  removedUser: null,
  userTyping: null,
  isLoading: false,
  sendToChannel: false,
  inviteAccepted: false,
  joinRequests: [],
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ChatReducer = (
  state = chatInitialChatState,
  action: { type: string; payload: any }
) => {
  if (!ChatActionTypes) {
    return { ...state };
  }

  switch (action.type) {
    case ChatActionTypes.INIT_CHAT_SCREEN:
      return { ...state, messageList: [...state.messageList] };
    case ChatActionTypes.UPDATE_MESSAGE_LIST:
      return { ...state, messageList: [...action.payload] };
    case ChatActionTypes.UPDATE_PRAYER_LIST:
      return { ...state, prayerList: [...action.payload] };
    case ChatActionTypes.SET_USER_TYPING:
      return { ...state, userTyping: action.payload };
    case ChatActionTypes.UPDATE_MEMBER_LIST:
      return { ...state, memberList: action.payload };
    case ChatActionTypes.UPDATE_BLOCKED_USER_LIST:
      return { ...state, blockedUserList: action.payload };
    case ChatActionTypes.SET_BLOCKED_USER:
      return { ...state, blockedUser: action.payload };
    case ChatActionTypes.SET_UNBLOCKED_USER:
      return { ...state, unblockedUser: action.payload };
    case ChatActionTypes.SET_ADDED_USERS:
      return { ...state, addedUsersList: action.payload };
    case ChatActionTypes.SET_REMOVED_MEMBER:
      return { ...state, removedUser: action.payload };
    case ChatActionTypes.SET_CHANNEL:
      return {
        ...state,
        channel: action.payload,
        channelName: action.payload?.name,
      };
    case ChatActionTypes.SET_CHANNEL_NAME:
      return { ...state, channelName: action.payload };
    case ChatActionTypes.UPDATE_INVITED_LIST:
      return { ...state, invitedList: action.payload };
    case ChatActionTypes.UPDATE_INVITED_LIST:
      return { ...state, invitedList: action.payload };
    case ChatActionTypes.SET_PARENT_MESSAGE_ID:
      return { ...state, parentMessageId: action.payload };
    case ChatActionTypes.SET_SEND_TO_CHANNEL:
      return { ...state, sendToChannel: action.payload };
    case ChatActionTypes.SET_INVITE_ACCEPTED:
      return { ...state, inviteAccepted: action.payload };
    case ChatActionTypes.SET_JOIN_REQUESTS:
      return { ...state, joinRequests: action.payload };
    case ChatActionTypes.REMOVE_JOIN_REQUEST:
      return {
        ...state,
        joinRequests: state.joinRequests.filter(
          (request) => request.id !== action.payload.id
        ),
      };
    case ChatActionTypes.RESET_CHAT_STATE:
      return {
        ...chatInitialChatState,
      };
    default:
      return state;
  }
};

export default ChatReducer;
