import { KitButton, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { UserMessage } from '@sendbird/chat/message';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

import { MessageType } from '../../../../shared/redux/types';
import { SendbirdSenderMessage } from '../../../Types';

export default function ConfirmDeleteModalContent({
  selectedMessage,
  setConfirmDeleteModalVisible,
  confirmDeleteMessage,
}: {
  selectedMessage?: SendbirdSenderMessage;
  setConfirmDeleteModalVisible: (visible: boolean) => void;
  confirmDeleteMessage: () => void;
}): JSX.Element {
  const isTextMessage = selectedMessage?.customType === MessageType.Text;
  const { t } = useTranslation();
  const isGroupEventMessage =
    selectedMessage?.customType === MessageType.GroupEvent;
  const preview = isTextMessage
    ? `"${(selectedMessage as UserMessage).message}"`
    : null;
  const type =
    selectedMessage?.customType === 'prayer'
      ? 'prayer request'
      : selectedMessage?.customType;
  const title =
    isTextMessage || isGroupEventMessage
      ? t('messaging:deleteMessageConfirmationTitle')
      : t('messaging:deleteMessageConfirmationTitleWithType', { type: type });

  return (
    <View
      style={{
        backgroundColor: '#00000040',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: -200,
      }}
    >
      <View
        style={{
          width: 500,
          backgroundColor: Colors.N0,
          borderRadius: 12,
          paddingTop: Spacing.xl,
          paddingHorizontal: Spacing.xl,
          justifyContent: 'space-between',
        }}
      >
        <View>
          <KitText
            black
            extraBold
            fontSize={20}
            style={{ marginBottom: Spacing.l }}
          >
            {title}
          </KitText>
          {preview && (
            <KitText black style={{ marginBottom: Spacing.xl }}>
              {preview}
            </KitText>
          )}
        </View>

        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: Spacing.m,
          }}
        >
          <KitButton
            secondary
            title='Cancel'
            marginValue='0 12px'
            onPress={() => setConfirmDeleteModalVisible(false)}
          />
          <KitButton red title='Delete' onPress={confirmDeleteMessage} />
        </View>
      </View>
    </View>
  );
}
