import { BridgeAction, Optional } from '@omni/kit/Types';
import { IHttpResponse } from '@omni/kit/services/BaseServiceV2';
import { Base64 } from 'js-base64';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { Dimensions, NativeModules, PixelRatio, Platform } from 'react-native';

import Environment from '../Environment';

const debug = require('debug')('omni:kit:utilities');

const { width, height } = Dimensions.get('window');

export const SCREEN_WIDTH = width;
export const SCREEN_HEIGHT = height;

export const bytesPerMb = 1000000;
export const maxFileSizeInMb = 5;
export const fileTooLargeMessage =
  'This file exceeds the maximum file size ' +
  `(${maxFileSizeInMb}MB) and could not be sent`;

export enum ImageServiceType {
  ImageJpeg,
  ImagePng,
  BlurJpeg,
  BlurPng,
  Metadata,
  WhitePng,
}

export const logButtonPressEvent = (buttonAction: string): void => {
  logEvent('button_press', { button_action: buttonAction });
};

export const logItemPressEvent = (itemAction: string): void => {
  logEvent('item_press', { item_action: itemAction });
};

export const logEvent = (
  event: string,
  params?: Record<string, unknown>
): void => {
  try {
    NativeModules.ReactPlatformBridge.logEvent(event, params);
  } catch {
    console.warn(`Could not log event: ${event}`);
  }
};

/**
 * Adds a percentage-based alpha value to a given HEX.
 */
export const transparentHexColor = (
  colorHex: string,
  percentage: number
): string => {
  const normalizedPercentage = Math.min(Math.max(Number(percentage), 0), 100);
  const alphaHex = Math.round((normalizedPercentage / 100) * 255).toString(16);

  return `${colorHex}${alphaHex}`;
};

export const parseImageUrl = (
  url: string | undefined,
  width: number,
  height: number,
  type: ImageServiceType,
  name: string | null = null
): string | undefined => {
  let imageUrl = url;

  if (!url || url.length === 0) return '';

  const pixelRatio = PixelRatio.get();
  const scaledWidth = Math.round(Math.round(width * pixelRatio)).toString();
  const scaledHeight = Math.round(Math.round(height * pixelRatio)).toString();

  imageUrl = imageUrl?.replace('{width}', scaledWidth);
  imageUrl = imageUrl?.replace('{height}', scaledHeight);

  name = name || 'image';
  let ext = 'jpg';
  switch (type) {
    case ImageServiceType.ImageJpeg:
      name = 'image';
      ext = 'jpg';
      break;
    case ImageServiceType.ImagePng:
      name = 'image';
      ext = 'png';
      break;
    case ImageServiceType.BlurJpeg:
      name = 'blur';
      ext = 'jpg';
      break;
    case ImageServiceType.BlurPng:
      name = 'blur';
      ext = 'png';
      break;
    case ImageServiceType.Metadata:
      name = 'meta';
      ext = 'jpg';
      break;
    case ImageServiceType.WhitePng:
      name = 'fit-white';
      ext = 'png';
      break;
    default:
      break;
  }
  imageUrl = imageUrl?.replace('{name}', name);
  imageUrl = imageUrl?.replace('{ext}', ext);

  return imageUrl;
};

export const createImageUrl = (id?: string): string | undefined => {
  if (!id) {
    return undefined;
  }

  return `${Environment.imagesHost}/{name}.{ext}?id=${id}&w={width}&h={height}`;
};

export const getImageId = (url: string): string | undefined => {
  if (url && url.includes('?')) {
    const params = getQueryParams({ search: '?' + url.split('?')[1] });

    return params.id;
  }
};

export const imageTypeFromContentType = (
  contentType: string
): ImageServiceType => {
  switch (contentType) {
    case 'image/png':
      return ImageServiceType.ImagePng;
    case 'image/jpg':
    case 'image/jpeg':
      return ImageServiceType.ImageJpeg;
    default:
      return ImageServiceType.ImageJpeg;
  }
};

export const dateFormatter = (
  timestamp: string,
  format: string,
  IANATimeZone?: string
): string => {
  let date = moment(timestamp);

  if (IANATimeZone) {
    date = date.tz(IANATimeZone);
  }

  const dateComponent = date.format(format);

  return dateComponent;
};

// @ts-ignore
export function usePrevious(value: unknown): unknown {
  const ref = useRef<unknown>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// usage example: optimize rendering performance during window resize on web or iPad split view by
// only applying new size if the image width has changed by a factor of 1.5x or if the aspect ratio has changed
// this also prevents the app from re-downloading the same image in rapid succession as the size of the viewport changes
export const useCoalescedSize = (
  width: number,
  height: number
): { coalescedWidth: number; coalescedHeight: number } => {
  const [coalescedWidth, setCoalescedWidth] = useState(width > 0 ? width : 1); // default to 1 to prevent divide by zero error
  const [coalescedHeight, setCoalescedHeight] = useState(
    height > 0 ? height : 1
  );

  useEffect(() => {
    if (width > 0 && height > 0) {
      const aspectRatio = height > 0 ? width / height : 0;
      const coalescedAspectRatio =
        coalescedHeight > 0 ? coalescedWidth / coalescedHeight : 0;

      const scaleFactorDelta =
        Math.max(width, coalescedWidth) / Math.min(width, coalescedWidth);
      const aspectRatioDelta =
        Math.max(aspectRatio, coalescedAspectRatio) /
        Math.min(aspectRatio, coalescedAspectRatio);

      if (scaleFactorDelta >= 1.5 || aspectRatioDelta >= 1.01) {
        setCoalescedWidth(width);
        setCoalescedHeight(height);
      }
    }
  }, [width, height, coalescedWidth, coalescedHeight]);

  return { coalescedWidth, coalescedHeight };
};

export function handleErrorResponse(response: Response): Promise<{
  status: number;
  message: string;
  data: Optional<{ error: string }>;
}> {
  return response.text().then((text) => {
    try {
      const json = JSON.parse(text);

      return Promise.resolve({
        status: response.status,
        message: Array.isArray(json.errors)
          ? json.errors[0].detail
          : json.error_description,
        data: json,
      });
    } catch (e) {
      return Promise.resolve({
        status: response.status,
        message: text,
        data: null,
      });
    }
  });
}

export function handleHttpErrorResponse(response: IHttpResponse): {
  status: number;
  message: string;
  data: Optional<{ error: string }>;
} {
  try {
    return {
      status: response.status || 0,
      message: Array.isArray(response.body?.errors)
        ? response.body.errors[0].detail
        : response.body?.error_description,
      data: response.body,
    };
  } catch (e) {
    return {
      status: response.status || 0,
      message: JSON.stringify(response.body),
      data: null,
    };
  }
}

export const getQueryParams = (location?: {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
}): Record<string, string> => {
  const paramObj: Record<string, string> = {};

  if (location?.search) {
    const noQuestion = location.search.substring(1);
    const queries = noQuestion.split('&');

    queries.forEach((query) => {
      const parts = query.split('=');

      if (parts.length === 2) {
        const [key, value] = parts;
        paramObj[key] = value;
      }
    });
  }

  return paramObj;
};

export function capitalizeFirstLetter(message?: string): string {
  if (message && message.length > 0) {
    return message.charAt(0).toUpperCase() + message.slice(1);
  } else {
    return '';
  }
}

export const isAppKeyTCA = (appKey?: string): boolean => {
  return appKey?.toLowerCase() === 'gh936h';
};

export const isWithinIframe = (): boolean => {
  return (
    Platform.OS === 'web' &&
    (window.frameElement !== null || window.top !== window)
  );
};

/*
 * If within an iframe, sends a postMessage with the content height so the parent
 * window can resize the iframe accordingly.
 * (Min height: 600px)
 *
 * The post message is handled by the Subsplash embed script:
 * https://subsplash.io/ember/web-client/-/blob/main/public/external/embed-1.1.0.js#L93-95
 * */
export const postContentHeight = (height?: number): void => {
  if (!isWithinIframe() || !height) {
    return;
  }

  const MIN_CONTENT_HEIGHT = 600;
  window.parent.postMessage(
    {
      pageHeight: Math.max(MIN_CONTENT_HEIGHT, height),
    },
    '*'
  );
};

/*
 * If within an iframe, sends a postMessage scrollToTop so the parent
 * window can scroll accordingly.
 *
 * The post message is handled by the Subsplash embed script:
 * https://subsplash.io/ember/web-client/-/blob/main/public/external/embed-1.1.0.js#L150-155
 * */
export const postContentScrollToTop = (): void => {
  if (!isWithinIframe()) {
    return;
  }

  window.parent.postMessage(
    {
      scrollToTop: true,
    },
    '*'
  );
};

export function createDeepLink(action: BridgeAction, appKey?: string): string {
  const protocol = appKey ? `sap${appKey.toLowerCase()}` : 'subsplash';

  let payload = Base64.encode(JSON.stringify(action));
  // RFC 4648 Spec where '+' is encoded as '-' and '/' is encoded as '_'.
  // Note: We cannot use Base64.encodeURI as it also removes padding '=' characters
  // which are required for deep links to work
  payload = payload.replace(/\+/g, '-');
  payload = payload.replace(/\//g, '_');

  return `${protocol}://sap/${payload}`;
}

// Receives the date time and timezone and returns the timestamp iso string
export const getTimestampUTC = (
  date: Date,
  timeInHHMM: string,
  timezone: string
): string => {
  // Format the input date as YYYY-MM-DD
  const dateString = moment(date).format('YYYY-MM-DD');

  // Combine the date and time
  const dateTimeString = `${dateString} ${timeInHHMM}`;

  // Create a moment object in the specified timezone
  const momentInTimezone = moment.tz(
    dateTimeString,
    'YYYY-MM-DD HH:mm',
    timezone
  );

  return momentInTimezone.utc().format();
};

export const roundToNextHour = (time: moment.MomentInput): moment.Moment => {
  const mTime = moment(time);
  const minute = mTime.minute();

  if (minute > 0) {
    mTime.add(1, 'hour').startOf('hour');
  }

  return mTime;
};
