import { KitCalendarIcon } from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import { IEvent } from '@omni/kit/services/EventsService/Types';
import {
  ImageServiceType,
  createImageUrl,
  parseImageUrl,
} from '@omni/kit/utilities/utilities';
import React from 'react';
import { View } from 'react-native';

const KitEventIcon = ({ event }: { event: IEvent }): JSX.Element => {
  const imgUrl = parseImageUrl(
    createImageUrl(event?._embedded?.images?.[0].id),
    36,
    36,
    ImageServiceType.ImageJpeg
  );

  return event._embedded?.images?.[0].updated_at ? (
    <View>
      <KitImage
        style={{
          height: 36,
          width: 36,
          borderRadius: 8,
        }}
        source={{
          uri: imgUrl,
        }}
      />
    </View>
  ) : (
    <View>
      <KitCalendarIcon size='m' date={event?.start_at} />
    </View>
  );
};

export default KitEventIcon;
