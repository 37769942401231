import TokensService from '@omni/kit/services/TokensService';

import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroupEvent } from '../Types';

const RESOURCE = '';

interface IGetGroupEventList extends Omit<IUri, 'resource'> {
  appKey?: string;
}
interface IGetGroupEventListResponse extends IHttpResponse {
  body?: {
    _embedded?: {
      events?: IGroupEvent[] | undefined;
    };
    count?: number;
  };
}
export default async ({
  appKey,
}: IGetGroupEventList): Promise<IGetGroupEventListResponse> => {
  // To be uncommented when backend will be ready
  // const url = buildUrl({
  //   host: Environment.groupsHost,
  //   filters: [
  //     ['app_key', appKey],
  //     ['group.public', 'true'],
  //   ],
  //   resource: RESOURCE,
  //   pages: [['size', 100]],
  // });
  const url =
    'https://assets.static.subsplash.com/native/test/events-mock-data-3.json';
  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({ url });
  // To be uncommented when backend will be ready
  // return BaseServiceV2.Get({ url, token: guestToken, skipTimestamp: true });
};
