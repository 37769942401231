import { useShellContext } from '@omni/kit';
import { KitSelect } from '@omni/kit/components';
import { SelectOption } from '@omni/kit/components/KitSelect';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import KitText from '@omni/kit/components/KitText';
import { useFavoriteAppsContext } from '@omni/kit/contexts/FavoriteAppsContext';
import { useRootAppContext } from '@omni/kit/contexts/RootAppContext';
import { useScreenContext } from '@omni/kit/contexts/ScreenContext';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import { convertAppItem } from '@omni/kit/feeds/ListItemConverter';
import { SearchResultItemProps } from '@omni/kit/feeds/searchListItemTypes';
import { useFeatureFlag } from '@omni/kit/hooks/useFeatureFlag';
import { Place } from '@omni/kit/placeTypes';
import AccountsService from '@omni/kit/services/AccountsService';
import GroupsService from '@omni/kit/services/GroupsService';
import SearchService, {
  IPrepareHitsUrl,
} from '@omni/kit/services/SearchService';
import { IAppSearchHit } from '@omni/kit/services/SearchService/AppSearchHitTypes';
import {
  DEFAULT_PAGE_SIZE,
  DOMAIN_APPS,
  DOMAIN_GROUPS,
  DOMAIN_LIBRARY,
} from '@omni/kit/services/SearchService/Constants';
import { parseSearchResults } from '@omni/kit/services/SearchService/parseSearchResults';
import { AppFeatureName } from '@omni/kit/services/Types';
import { UNLEASH_TOGGLES } from '@omni/kit/services/UnleashService';
import { getStoredItem } from '@omni/kit/storage';
import Spacing from '@omni/kit/theming/Spacing';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import {
  getFullDayName,
  getGroupTypeDisplayName,
} from '@omni/kit/utilities/groupUtilities';
import memberAppLabelContextKey from '@omni/kit/utilities/memberAppLabelContextKey';
import { isWithinIframe } from '@omni/kit/utilities/utilities';
import { ParamListBase, RouteProp, useRoute } from '@react-navigation/native';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, View } from 'react-native';

import {
  CONTEXT_GROUPS,
  CONTEXT_MEDIA,
  KEY_LOCATION_QUERY_DESCRIPTION,
  VALUE_CURRENT_LOCATION,
  VALUE_CURRENT_LOCATION_ERROR,
} from '../../Constants';
import FullPageLoader from '../../components/FullPageLoader';
import LocationSearchInput from '../../components/LocationSearchInput';
import { CurrentLocationPlace } from '../../components/LocationSearchInput/LocationSearchInput';
import ScreenContainer from '../../components/ScreenContainer';
import { Domain } from '../../types';
import calculateTotalPages from '../../utils/calculateTotalPages';
import {
  AppFilterOption,
  countNonEmptyArrays,
  extractLabelAndValue,
  extractValuesFromArray,
  getTimeRange,
  initAppFilterOptions,
  isAppFilterValueSearchable,
} from '../../utils/filterOptions';
import GroupFilters from './GroupFilters';
import SearchBody from './SearchBody';
import SearchInput from './SearchInput';

const debug = require('debug')('tca:search:Screens:Search.tsx');

interface Props {
  animationEnabled?: boolean;
  domain?: Domain;
  q?: string;
  typeFilterValue?: string;
}

export interface RouteProps extends RouteProp<ParamListBase, string> {
  params: {
    q?: string;
    type?: string;
  };
}
export interface Option {
  value: string;
  label: string;
}

export default ({
  animationEnabled = true,
  domain = DOMAIN_LIBRARY,
  q,
  typeFilterValue,
}: Props): JSX.Element | null => {
  const { params }: RouteProps = useRoute();
  const { t } = useTranslation('search');
  const { sizeClass, windowWidth } = useSizeClass();

  const [results, setResults] = useState<SearchResultItemProps[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [value, setValue] = useState<string>(params?.q || q || '');
  const [hasCheckedCurrentLocationMode, setHasCheckedCurrentLocationMode] =
    useState(false);
  const hasNextPage = useMemo(() => {
    return total !== undefined && currentPage <= calculateTotalPages(total);
  }, [total, currentPage]);

  const [locationSuggestions, setLocationSuggestions] = useState<
    Place[] | undefined
  >(undefined);

  const [locationInputFocused, setLocationInputFocused] =
    useState<boolean>(false);
  const [_searchInputFocused, setSearchInputFocused] = useState<boolean>(false);

  const [locationQuery, setLocationQuery] = useState<string | undefined>(
    undefined
  );

  const [selectedPlace, setSelectedPlace] = useState<Place | undefined>(
    undefined
  );

  const [selectedGroupType, setSelectedGroupType] = useState<Option[]>([]);
  const [selectedGroupLocations, setSelectedGroupLocations] = useState<
    Option[]
  >([]);
  const [selectedDay, setSelectedDay] = useState<Option[]>([]);
  const [selectedFrequency, setSelectedFrequency] = useState<Option[]>([]);
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<Option[]>([]);

  const { viewPortWidth } = useScreenContext({
    fixedSpacingType: SpacingType.None,
  });

  const selArrays = [
    selectedDay,
    selectedFrequency,
    selectedGroupType,
    selectedTimeOfDay,
    selectedGroupLocations,
  ];
  const nonEmptyCount = countNonEmptyArrays(selArrays);

  const dayOptions: Option[] = [
    { value: 'MO', label: getFullDayName('MO') },
    { value: 'TU', label: getFullDayName('TU') },
    { value: 'WE', label: getFullDayName('WE') },
    { value: 'TH', label: getFullDayName('TH') },
    { value: 'FR', label: getFullDayName('FR') },
    { value: 'SA', label: getFullDayName('SA') },
    { value: 'SU', label: getFullDayName('SU') },
  ];

  const timeOfDayOptions: Option[] = [
    { value: 'morning', label: t('groups:timeOfDay_morning') },
    { value: 'afternoon', label: t('groups:timeOfDay_afternoon') },
    { value: 'evening', label: t('groups:timeOfDay_evening') },
  ];

  const frequencyOptions: Option[] = [
    { value: 'daily', label: t('groups:frequency_daily') },
    { value: 'weekly', label: t('groups:frequency_weekly') },
    {
      value: 'every_other_week',
      label: t('groups:frequency_every_other_week'),
    },
    { value: 'monthly', label: t('groups:frequency_monthly') },
  ];

  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
    marginBottom: Spacing.l,
  });

  /*
   * App Filter
   */
  const { app } = useShellContext();
  const gatedContentEnabled = Boolean(
    app.features.find(
      (f) => f.name === AppFeatureName.GATED_CONTENT_V1 && f.enabled
    )
  );
  const useBlockPageForMediaItem = gatedContentEnabled;

  const appKey = app?.appKey;
  const rootApp = useRootAppContext();
  const rootAppKey = rootApp?.id;
  const { favoriteApps } = useFavoriteAppsContext();
  const appTitle = app?.title;

  const appFilterOptions: AppFilterOption[] = initAppFilterOptions(
    app,
    rootApp,
    favoriteApps
  );

  const [appFilterValue, setAppFilterValue] = useState<string>(appKey ?? '');

  /*
   * Group Type Filter
   */
  const [groupTypeFilterOptions, setGroupTypeFilterOptions] = useState<
    SelectOption[]
  >([]);
  const [groupTypeFilterValue, setGroupTypeFilterValue] = useState<
    string | undefined
  >(params?.type || typeFilterValue);

  /*
   * Locations Filter
   */
  const [groupLocationsFilterOptions, setGroupLocationsFilterOptions] =
    useState<SelectOption[]>([]);

  const [searchable, setSearchable] = useState<boolean>(
    isAppFilterValueSearchable(appFilterValue, appFilterOptions, domain)
  );

  let contextKey;
  switch (domain) {
    case DOMAIN_APPS:
      contextKey = memberAppLabelContextKey(rootApp);
      break;
    case DOMAIN_LIBRARY:
      contextKey = CONTEXT_MEDIA;
      break;
    case DOMAIN_GROUPS:
      contextKey = CONTEXT_GROUPS;
      break;
  }

  const onSearchInputChange = (str: string) => {
    setValue(str);

    if (Platform.OS === 'web') {
      setURLQueryParam('q', str);
    }

    if (str || domain === DOMAIN_GROUPS) {
      setIsLoading(true);
      debouncedFetch(str);
    } else if (!locationQuery && !selectedPlace) {
      debouncedFetch.cancel();
      setResults(undefined);
      setIsLoading(false);
    }
  };

  const onSearchInputRemove = () => {
    onSearchInputChange('');
  };

  const onSearchInputFocus = () => {
    setSearchInputFocused(true);
    setLocationInputFocused(false);
  };

  const onLocationInputFocus = () => {
    setLocationInputFocused(true);
    setSearchInputFocused(false);
  };

  const onLocationInputRemove = () => {
    setSelectedPlace(undefined);
  };

  const onSearch = useCallback(
    async (query: string, page = 1) => {
      try {
        /**
         * Allow user to search apps by app_key:
         * Query for specifically given app key, which can either be
         * published or not. Used to replace legacy App Utility functionality.
         */
        const appKeyQuery =
          domain === 'apps' && query.startsWith(':')
            ? query.replace(/[^0-9A-Za-z]/g, '').toUpperCase()
            : undefined;

        if (appKeyQuery) {
          if (appKeyQuery?.length === 6) {
            const app = await AccountsService.getApp(appKeyQuery);

            const appSearchHit: IAppSearchHit = {
              id: app?.id ?? '',
              type: 'app',
              fields: {
                type: 'app',
                app_key: app?.id ?? '',
                title: app?.title,
              },
            };

            setTotal(1);
            setCurrentPage(page + 1);

            return [convertAppItem(appSearchHit)];
          }

          return [];
        }

        /**
         * Media, Group, and App search
         */
        const config: IPrepareHitsUrl = {
          domain: domain,
          page,
          size: DEFAULT_PAGE_SIZE,
          ...(domain === DOMAIN_APPS ? { containerAppKey: rootAppKey } : {}),
          ...(domain === DOMAIN_LIBRARY
            ? {
                appFilterValue,
                type: ['media-item', 'tag:speaker', 'tag:topic'],
              }
            : {}),
          ...(locationQuery
            ? {
                query: query ? encodeURIComponent(`${query}*`) : undefined,
                filterQuery: `{"loc":["${locationQuery}"],"dist":${30}}`,
              }
            : {
                query: encodeURIComponent(`${query}*`),
              }),
          ...(domain === DOMAIN_GROUPS
            ? {
                filterQuery: encodeURIComponent(
                  `{"app_key":"${appKey}"${
                    selectedGroupType.length !== 0
                      ? `,"group_type": ${JSON.stringify(
                          extractValuesFromArray(selectedGroupType)
                        )}`
                      : `,"group_type": "${
                          groupTypeFilterValue ? groupTypeFilterValue : ''
                        }"`
                  }${
                    selectedGroupLocations.length !== 0
                      ? `,"location":${JSON.stringify(
                          selectedGroupLocations.map((item: any) => ({
                            city: item.value.city,
                            state: item.value.state,
                          }))
                        )}`
                      : ''
                  }${
                    selectedFrequency.length !== 0
                      ? `,"frequency":${JSON.stringify(
                          extractValuesFromArray(selectedFrequency)
                        )}`
                      : ''
                  }${
                    selectedDay.length !== 0
                      ? `,"day_of_week":${JSON.stringify(
                          extractValuesFromArray(selectedDay)
                        )}`
                      : ''
                  }${
                    selectedTimeOfDay.length !== 0
                      ? `,"time_of_day":${JSON.stringify(
                          getTimeRange(selectedTimeOfDay)
                        )},"timezone":"${app.timezone?.name}"`
                      : ''
                  }}`
                ),
                query: query ? encodeURIComponent(`${query}*`) : 'matchall',
              }
            : {}),
        };

        const data = await SearchService.search(config);
        const nextPage = page + 1;
        const totalItems = data?.body?.total || 0;
        setTotal(totalItems);
        setCurrentPage(nextPage);

        return parseSearchResults(data, useBlockPageForMediaItem);
      } catch (e) {
        debug('search request failed', e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      domain,
      rootAppKey,
      appFilterValue,
      locationQuery,
      appKey,
      useBlockPageForMediaItem,
      isSearching,
      selectedDay,
      selectedFrequency,
      selectedTimeOfDay,
      selectedGroupType,
      groupTypeFilterValue,
      selectedGroupLocations,
    ]
  );

  const onFetch = useCallback(
    async (value: string) => {
      try {
        setIsLoading(true);
        const data = await onSearch(value, 1);
        setResults(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
        setIsSearching(false);
      }
    },
    [onSearch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(debounce(onFetch, 500), [onFetch]);

  const onFetchData = (p?: number) => {
    if (p) {
      setCurrentPage(p);
    }

    return onSearch(value, p || currentPage);
  };

  // must be wrapped in useCallback to avoid render-loop when called from child component effects
  const showKitSnack = useCallback((msg: string, dur: number): void => {
    setSnackOptions({
      visible: true,
      message: msg,
      duration: dur,
      marginBottom: Spacing.l,
    });
  }, []);

  const onUserLocationError = useCallback(
    (error: string) => {
      debug(`Error: ${error}`);
      showKitSnack(VALUE_CURRENT_LOCATION_ERROR, KitSnackDuration.SHORT);
      setIsLoading(false);
    },
    [showKitSnack]
  );

  /**
   * Lifecycle:
   * If user enabled 'Current Location' in a previous app session,
   * ensure location input is focused to immediately render location-based results
   */
  useEffect(() => {
    getStoredItem(KEY_LOCATION_QUERY_DESCRIPTION)
      .then((value) => {
        if (VALUE_CURRENT_LOCATION === value) {
          setLocationInputFocused(true);
          setSelectedPlace(CurrentLocationPlace);
          /**
           * setIsLoading(false) will be called later
           * when results are fetched for current location
           * or if the request to get user location failed
           */
        } else if (domain !== DOMAIN_GROUPS) {
          setIsLoading(false);
        }
      })
      .finally(() => setHasCheckedCurrentLocationMode(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show loading state when user choose 'Current Location'
  // within the Search Body list items
  useEffect(() => {
    if (selectedPlace) {
      setIsLoading(true);
    }
  }, [selectedPlace]);

  // Render location suggestions
  useEffect(() => {
    if (
      locationInputFocused &&
      Boolean(locationSuggestions) &&
      !selectedPlace
    ) {
      const placeResults = locationSuggestions?.map((place) => {
        return {
          title: place.description,
          place,
          type: 'location',
        };
      });

      setResults(placeResults);
    }
  }, [locationInputFocused, locationSuggestions, selectedPlace]);

  // Whenever locationQuery changes with a new lat/lng value,
  // fetch results that are nearby that lat/lng
  // Passing an undefined 'query' will fetch all results for a lat/lng
  useEffect(() => {
    if (domain === DOMAIN_APPS && Boolean(locationQuery)) {
      debouncedFetch(value);
    }
  }, [domain, locationQuery, debouncedFetch, value]);

  useEffect(() => {
    if (domain === DOMAIN_GROUPS && appKey) {
      const fetchGroupTypeOptions = async () => {
        const types = await GroupsService.GetGroupTypes({
          appKey: appKey,
        });

        if (types.body?.count && types.body?._embedded?.['group-types']) {
          setGroupTypeFilterOptions(
            types.body?._embedded?.['group-types'].map((type) => ({
              label: getGroupTypeDisplayName(type.name),
              value: type.name,
            }))
          );
        }
      };
      // Load group types
      fetchGroupTypeOptions();

      // Load all groups initially
      debouncedFetch(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTypeFilterValue]);

  useEffect(() => {
    if (domain === DOMAIN_GROUPS && appKey) {
      const fetchGroupLocationsOptions = async () => {
        const locations = await GroupsService.GetGroupLocations({
          appKey: appKey,
        });

        if (locations.body?.count && locations.body?._embedded?.locations) {
          setGroupLocationsFilterOptions(
            extractLabelAndValue(locations.body?._embedded?.locations)
          );
        }
      };
      // Load locations
      fetchGroupLocationsOptions();

      // Load all groups initially
      debouncedFetch(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isSearching && debouncedFetch(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearching]);

  useEffect(() => {
    setSearchable(
      isAppFilterValueSearchable(appFilterValue, appFilterOptions, domain)
    );
  }, [appFilterValue, appFilterOptions, domain]);

  useEffect(() => {
    // Check if we have selected group type from params
    const haveSelectedGroupType = groupTypeFilterOptions.find(
      (x) => x.value === groupTypeFilterValue
    ) as Option;
    setSelectedGroupType(haveSelectedGroupType ? [haveSelectedGroupType] : []);
  }, [groupTypeFilterOptions, groupTypeFilterValue]);

  if (!hasCheckedCurrentLocationMode) {
    return (
      <ScreenContainer>
        <FullPageLoader />
      </ScreenContainer>
    );
  }

  const debouncedOnValueChange = debounce((selectedGroupType?: Option) => {
    if (!isLoading) {
      setIsSearching(true);
      selectedGroupType &&
        setURLQueryParam('type', selectedGroupType.value as string);
      selectedGroupType && setGroupTypeFilterValue(selectedGroupType.value);
    }
  }, 300);

  const handleSaveFilters = () => {
    debouncedOnValueChange(selectedGroupType[0]);
  };

  const handleOnReset = () => {
    setSelectedDay([]);
    setSelectedTimeOfDay([]);
    setSelectedFrequency([]);
    setSelectedGroupType([]);
    setSelectedGroupLocations([]);
    debouncedOnValueChange([] as any);
  };

  const groupsFilterButtonInline =
    domain === DOMAIN_GROUPS && sizeClass !== SizeClass.Small;

  return (
    <ScreenContainer>
      {Boolean(appTitle) && domain === 'groups' && !isWithinIframe() ? (
        <KitText
          extraBold
          black
          fontSize={sizeClass === SizeClass.Small ? 24 : 36}
          center
          style={{
            maxWidth: 550,
            alignSelf: 'center',
            paddingBottom: Spacing.xl,
            marginHorizontal: Spacing.l,
            lineHeight:
              sizeClass === SizeClass.Small ? Spacing.xl : Spacing.xxl,
            marginTop: sizeClass === SizeClass.Small ? Spacing.l : 0,
          }}
        >
          {t('search:pageTitleDiscoverGroups', {
            appTitle: appTitle,
          })}
        </KitText>
      ) : null}

      <View
        style={{
          marginHorizontal: Spacing.xl,
          flexDirection: groupsFilterButtonInline ? 'row' : 'column',
        }}
      >
        <SearchInput
          autoFocus={domain !== DOMAIN_GROUPS && domain !== DOMAIN_APPS}
          // @ts-ignore
          style={{
            ...(groupsFilterButtonInline
              ? {
                  flex: 1,
                  marginRight: Spacing.m,
                }
              : {}),
          }}
          placeholder={t('search:inputPlaceholder', {
            context: contextKey,
          })}
          value={value}
          onFocus={onSearchInputFocus}
          setValue={onSearchInputChange}
          onRemove={onSearchInputRemove}
          editable={searchable}
        />
        {domain === DOMAIN_GROUPS ? (
          <GroupFilters
            style={{
              ...(groupsFilterButtonInline
                ? {
                    width: 300,
                  }
                : { marginTop: Spacing.m }),
            }}
            onSave={handleSaveFilters}
            onReset={handleOnReset}
            placeHolder={
              nonEmptyCount > 0
                ? t('search:filtersTitleWithCount', { count: nonEmptyCount })
                : t('search:filtersTitle')
            }
            groupTypes={groupTypeFilterOptions as Option[]}
            dayOfWeek={dayOptions}
            frequency={frequencyOptions}
            timeOfDay={timeOfDayOptions}
            setSelectedDay={setSelectedDay}
            setSelectedFrequency={setSelectedFrequency}
            setSelectedGroupType={setSelectedGroupType}
            setSelectedTimeOfDay={setSelectedTimeOfDay}
            selectedDay={selectedDay}
            selectedFrequency={selectedFrequency}
            selectedGroupType={selectedGroupType}
            selectedTimeOfDay={selectedTimeOfDay}
            groupLocations={groupLocationsFilterOptions as Option[]}
            setSelectedGroupLocations={setSelectedGroupLocations}
            selectedGroupLocations={selectedGroupLocations}
          />
        ) : null}
      </View>

      {domain === DOMAIN_APPS ? (
        <View style={{ marginHorizontal: Spacing.xl, marginTop: Spacing.m }}>
          <LocationSearchInput
            autoFocus={locationInputFocused}
            onFocus={onLocationInputFocus}
            onRemove={onLocationInputRemove}
            onUserLocationError={onUserLocationError}
            placeholder={t('search:inputPlaceholderLocation')}
            selectedPlace={selectedPlace}
            setLocationQuery={setLocationQuery}
            setLocationSuggestions={setLocationSuggestions}
          />
        </View>
      ) : null}

      {domain === DOMAIN_LIBRARY &&
      rootApp?.is_container &&
      appFilterOptions.length > 1 ? (
        <View style={{ marginHorizontal: Spacing.xl, marginTop: Spacing.m }}>
          <KitSelect
            items={appFilterOptions}
            onValueChange={(value) => setAppFilterValue(value.value as string)}
            value={appFilterValue}
          />
        </View>
      ) : null}

      <View style={{ marginTop: Spacing.l }} />

      <SearchBody<SearchResultItemProps>
        animationEnabled={animationEnabled}
        appFilterValue={appFilterValue}
        domain={domain}
        onFetchData={onFetchData}
        initialData={results}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        setSelectedPlace={setSelectedPlace}
        searchable={searchable}
        total={total}
      />
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
    </ScreenContainer>
  );
};

const setURLQueryParam = (key: string, value?: string) => {
  if (Platform.OS === 'web' && 'URLSearchParams' in window) {
    const searchParams = new URLSearchParams(window.location.search);

    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }

    let newRelativePathQuery = window.location.pathname;

    if (searchParams.toString()) {
      newRelativePathQuery += '?' + searchParams.toString();
    }

    history.pushState(null, '', newRelativePathQuery);
  }
};
