import GetAppHeaders from '../../Downloader/GetAppHeaders';
import Environment from '../../Environment';
import { ICreateTokenResponseBody } from './Types';

export const fetchGuestTokenData = async (): Promise<
  ICreateTokenResponseBody | undefined
> => {
  let appHeaders: Record<string, string> = {};
  try {
    appHeaders = await GetAppHeaders();
  } catch {}

  let status = 0;
  try {
    const response = await fetch(`${Environment.tokensServiceHost}/token`, {
      method: 'POST',
      body: [
        'grant_type=client_credentials',
        `client_id=${Environment.tokensServiceClientId}`,
        `client_secret=${Environment.tokensServiceClientSecret}`,
      ].join('&'),
      headers: {
        ...appHeaders,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    status = response.status;

    const responseBody = await response.json();

    let error_description: string | undefined = responseBody?.error_description;

    if (status === 200 || status === 201) {
      if (!responseBody?.access_token) {
        error_description = `Missing access_token`;
        status = 550;
      } else if (!responseBody?.expires_in) {
        error_description = `Missing expires_in`;
        status = 550;
      }
    }

    return { ...responseBody, error_description, status };
  } catch (e) {
    return {
      error_description: `Token request failed: ${e}`,
      status: status === 200 || status === 201 ? 550 : status,
    };
  }
};
