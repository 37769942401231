import GroupsService from '@omni/kit/services/GroupsService';
import { IGroup } from '@omni/kit/services/GroupsService/Types';
import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ChatService from '../services/ChatService';
import { accessTokenSelector, appKeySelector } from '../shared/redux/selectors';

/*
 * Returns a Group resource for a Chat channelId
 * channelId format: sendbird_group_channel_141958962_93b8a7df0c2b6d3f16ff8292d1d926dfe94611db
 * */
export const useChannelGroup = (channelId?: string): IGroup | null => {
  const accessToken = useSelector(accessTokenSelector);
  const appKey = useSelector(appKeySelector);

  const [group, setGroup] = useState<IGroup | null>(null);

  const isFocused = useIsFocused();

  useEffect(() => {
    const fetchGroup = async (channelId: string) => {
      const chatChannelResponse = await ChatService.getOne(
        'channel',
        channelId,
        [['app_key', appKey]]
      );

      if (chatChannelResponse?.data) {
        const response = await GroupsService.GetGroup({
          token: accessToken,
          id: chatChannelResponse?.data.resource_uuid,
        });

        if (response.body) {
          setGroup(response.body);
        } else {
          console.error('Could not load group for channel');
        }
      }
    };

    if (channelId && isFocused) {
      fetchGroup(channelId);
    }
  }, [accessToken, appKey, channelId, isFocused]);

  return group;
};
