import CreateGroupAddress from './CreateGroupAddress';
import CreateGroupEvent from './CreateGroupEvent';
import CreateGroupLocation from './CreateGroupLocation';
import CreateGroupMember from './CreateGroupMember';
import CreateJoinRequest from './CreateJoinRequest';
import DeleteGroupLocation from './DeleteGroupLocation';
import GetGroup from './GetGroup';
import GetGroupEventList from './GetGroupEventList';
import GetGroupLocation from './GetGroupLocation';
import GetGroupLocations from './GetGroupLocations';
import GetGroupMembers from './GetGroupMembers';
import GetGroupTypes from './GetGroupTypes';
import GetJoinRequest from './GetJoinRequest';
import GetJoinRequests from './GetJoinRequests';
import UpdateGroup from './UpdateGroup';
import UpdateGroupLocation from './UpdateGroupLocation';
import UpdateJoinRequest from './UpdateJoinRequest';

export default {
  CreateGroupMember,
  GetGroup,
  UpdateGroup,
  GetGroupMembers,
  GetGroupTypes,
  GetJoinRequests,
  CreateJoinRequest,
  GetJoinRequest,
  UpdateJoinRequest,
  GetGroupLocation,
  GetGroupLocations,
  GetGroupEventList,
  CreateGroupLocation,
  CreateGroupAddress,
  DeleteGroupLocation,
  UpdateGroupLocation,
  CreateGroupEvent,
};
