import {
  KitFloatingButton,
  KitIcon,
  KitInput,
  KitText,
  KitTouchable,
} from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { parseImageUrl } from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
// @ts-ignore
import osisToEn from 'bible-reference-formatter/es6/en';
import Color from 'color';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FlatList, Platform, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import MediaService from '../../services/MediaService';
import { sendMediaMessage } from '../../shared/redux/actions/ChatActions';
import { appKeySelector, channelSelector } from '../../shared/redux/selectors';
import { MediaMessageDataType } from '../../shared/redux/types';

const debug = require('debug')('omni:chat:screen:MediaSelectionScreen');

type MediaItemType = {
  id: string;
  title: string;
  summary: string;
  _embedded: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function MediaSelectionScreen({
  togglePopup = undefined,
}): JSX.Element {
  const [searchValue, setSearchValue] = useState<string>('');
  const [mediaItems, setMediaItems] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const navigation = useNavigation();
  const channel = useSelector(channelSelector);
  const appKey = useSelector(appKeySelector);
  const dispatch = useDispatch();

  const _onFloatingButtonPressed = () => {
    if (selectedIndex === undefined) {
      return;
    }

    if (togglePopup) {
      // @ts-ignore
      togglePopup();
    } else {
      try {
        navigation.goBack();
      } catch {}
    }

    const item: MediaItemType = mediaItems[selectedIndex as number];
    const data: MediaMessageDataType = {
      id: item.id,
      image: _getImageBanner(
        { _embedded: item._embedded },
        180,
        180 / 1.6,
        false
      ),
      title: item.title || '',
      description: item.summary || '',
      // @ts-ignore
      speaker: item.speaker || '',
    };

    dispatch(sendMediaMessage(channel as GroupChannel, data));
  };

  const _getImageBanner = (
    // @ts-ignore
    { _embedded },
    mediaWidth = 130,
    mediaHeight = 130 / 1.6,
    formatted = true
  ) => {
    // @ts-ignore
    const imageInfo = _embedded?.images?.find((img) => img.type === 'banner');
    const imageUrl = imageInfo?._links?.dynamic?.href;

    let banner = imageUrl;

    if (banner && formatted) {
      banner = parseImageUrl(
        banner,
        mediaWidth,
        mediaHeight,
        imageInfo.content_type
      );
    }

    return banner;
  };

  const _parseScriptures = (scriptures: string[]): string => {
    if (!scriptures || scriptures.length === 0) return '';

    return scriptures
      ?.map((scripture: string) => osisToEn('niv-long', scripture))
      ?.join(', ');
  };

  // @ts-ignore
  const _renderMediaItem = ({ item, index }) => {
    const { title, published_at, scriptures, speaker } = item;

    const mediaWidth = 130;
    const mediaHeight = mediaWidth / 1.6;
    const banner = _getImageBanner(item);
    const parsedScriptures = _parseScriptures(scriptures);
    const dateLabel = moment(published_at).format('MMMM D, YYYY');
    const isSelected = index === selectedIndex;
    const subtitle = [dateLabel, speaker, parsedScriptures]
      .filter((exists) => Boolean(exists))
      .join(' • ');

    return (
      <KitTouchable
        onPress={() => setSelectedIndex(isSelected ? undefined : index)}
        style={{ marginHorizontal: Spacing.s, borderRadius: 10 }}
        borderRadius={10}
      >
        <View style={[styles.mediaItem, isSelected ? styles.selected : {}]}>
          {isSelected && (
            <View style={styles.selectedMarker}>
              <KitIcon name='check' size={16} color={Colors.N0} />
            </View>
          )}
          <View
            style={{
              backgroundColor: Colors.N200,
              marginRight: Spacing.m,
              borderRadius: BorderRadius.m,
            }}
          >
            <KitImage
              source={{ uri: banner }}
              style={{
                width: mediaWidth,
                height: mediaHeight,
                borderRadius: BorderRadius.m,
              }}
            />
          </View>

          <View style={{ maxWidth: '52%' }}>
            <KitText
              black
              semiBold
              numberOfLines={2}
              style={{
                lineHeight: 16,
              }}
            >
              {title}
            </KitText>
            <KitText
              color={Colors.N500}
              fontSize={14}
              numberOfLines={2}
              style={{
                lineHeight: Platform.OS === 'ios' ? -3 : 16,
                marginTop: Spacing.xxs,
              }}
            >
              {subtitle}
            </KitText>
          </View>
        </View>
      </KitTouchable>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMediaItems = useCallback(
    debounce(() => {
      MediaService.getFiltered('media-item', [
        ['app_key', appKey],
        ['status', 'published'],
        ['title', `*${searchValue}*` || '*'],
      ])
        .then((response) => {
          setMediaItems(response.data['media-items']);
        })
        .catch((e) => {
          debug('Error fetching media items: ', e);
        });
    }, 100),
    [searchValue]
  );

  useEffect(() => {
    getMediaItems();
  }, [getMediaItems, searchValue]);

  return (
    <View style={styles.wrapper}>
      <KitInput
        placeholder='Search Media'
        value={searchValue}
        onChangeText={setSearchValue}
        selectTextOnFocus
        style={{
          paddingHorizontal: Spacing.l,
          paddingTop: Spacing.l,
          paddingBottom: Spacing.m,
        }}
      />
      <FlatList
        keyboardShouldPersistTaps='handled'
        data={mediaItems}
        style={{ ...Platform.select({ web: { height: 500 } }) }}
        renderItem={_renderMediaItem}
        keyExtractor={(item) => item.id}
        ListFooterComponent={<View style={{ height: 150 }} />}
      />
      {selectedIndex !== undefined && (
        <View
          // @ts-ignore
          style={{
            position: Platform.select({ web: 'fixed', native: 'absolute' }),
            bottom: Spacing.l,
            right: Spacing.l,
          }}
        >
          <KitFloatingButton icon='send' onPress={_onFloatingButtonPressed} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: Colors.N0,
  },
  mediaItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: Spacing.s,
    borderWidth: 1,
    borderColor: 'transparent',
    position: 'relative',
  },
  selected: {
    borderRadius: 10,
    borderColor: Colors.brand,
    backgroundColor: Color(Colors.brand).lighten(0.47).hex(),
  },
  selectedMarker: {
    width: 24,
    height: 24,
    backgroundColor: Colors.brand,
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
