import { KitText } from '@omni/kit/components';
import KitModalV2 from '@omni/kit/components/KitModalV2';
import {
  KitSnackDuration,
  KitSnackRender,
} from '@omni/kit/components/KitSnack';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ReportCategory } from '@sendbird/chat';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import {
  FileMessage,
  PreviousMessageListQuery,
  Sender,
  UserMessage,
} from '@sendbird/chat/message';
import React, { useEffect, useState } from 'react';
import { Alert, FlatList, Platform, StyleSheet, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { useDispatch, useSelector } from 'react-redux';

import * as Constants from '../../../shared/Constants';
import {
  blockUser,
  getPrevMessageList,
  getPrevPrayerList,
  updatePrayerList,
} from '../../../shared/redux/actions/ChatActions';
import {
  channelSelector,
  currentMessagesSelector,
  currentPrayerSelector,
} from '../../../shared/redux/selectors';
import { MessageType } from '../../../shared/redux/types';
import Message from '../../../shared/scenes/channel/components/Message';
import {
  sbAdjustMessageList,
  sbCreatePreviousMessageListQuery,
} from '../../../utilities/sendbird/chatFunctions';
import WebModal from '../../../web/scenes/app/components/WebModal';
import {
  SendbirdMessage,
  SendbirdSenderMessage,
  SendbirdUserMessage,
} from '../../Types';
import MessageActionSheet from '../../components/chat/MessageActionSheet';
import PrayerRequestScreen from '../../screens/PrayerRequestScreen';
import { ChatContext } from './ChatScreen';
import BlockUserModal from './components/BlockUserModal';
import ConfirmReportMessageContent from './components/ConfirmReportMessageContent';
import ReportUserModal from './components/ReportUserModal';

const debug = require('debug')('tca:chat:screen:PrayerListScreen');

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PrayerListScreen({ navigation }): JSX.Element {
  const [activeMessage, setActiveMessage] =
    useState<SendbirdUserMessage | null>(null);
  const [selectedMessage, setSelectedMessage] =
    useState<SendbirdUserMessage | null>(null);
  const [userToReport, setUserToReport] = useState<Sender | null>(null);
  const [userToBlock, setUserToBlock] = useState<Sender | null>(null);
  const [selectedUser, setSelectedUser] = useState<Sender | null>(null);
  const [showReportMessageSheet, setShowReportMessageSheet] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState<string | null>(null);
  const [prayerList, setPrayerList] = useState<SendbirdMessage[]>([]);
  const [prevPrayerListQuery, setPreviousPrayerListQuery] =
    useState<PreviousMessageListQuery | null>(null);
  const [isAnsweringPrayer, setIsAnsweringPrayer] = useState(false);
  const [snackOptions, setSnackOptions] = useState({
    visible: false,
    message: '',
    duration: KitSnackDuration.SHORT,
  });

  const dispatch = useDispatch();

  const channel = useSelector(channelSelector);
  const prayers = useSelector(currentPrayerSelector);
  const messages = useSelector(currentMessagesSelector);
  const { sizeClass } = useSizeClass();

  const isSmall = useSizeClass().sizeClass === SizeClass.Small;

  const showKitSnack = (msg: string, dur: number) => {
    setSnackOptions({ visible: true, message: msg, duration: dur });
  };

  const promptDeletePrayer = () => {
    // @ts-ignore
    const message = selectedMessage?.message;
    Alert.alert(
      'Delete prayer request',
      `"${message?.slice(0, 150)}${
        message?.length && message.length > 150 ? '...' : ''
      }"`,
      [
        {
          style: 'cancel',
          text: 'Cancel',
        },
        {
          style: 'destructive',
          text: 'Delete',
          onPress: confirmDeletePrayer,
        },
      ]
    );
  };

  const confirmDeletePrayer = () => {
    (channel as GroupChannel)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .deleteMessage(selectedMessage!)
      .then(() => {
        debug('Prayer request deleted.');
        setSelectedMessage(null);
        onDelete();
      })
      .catch((err) => {
        debug('Could not delete prayer request: ', selectedMessage, err);
      });
  };

  const onDelete = () => {
    showKitSnack('Prayer request deleted', KitSnackDuration.SHORT);
  };

  const _getPrayerList = (init = false) => {
    if (!prevPrayerListQuery && !init) {
      return;
    }

    if (init && channel) {
      const prevPrayerQuery = sbCreatePreviousMessageListQuery(
        channel as GroupChannel,
        30,
        [MessageType.Prayer]
      );

      if (prevPrayerQuery) {
        setPreviousPrayerListQuery(prevPrayerQuery);
        dispatch(getPrevPrayerList(prevPrayerQuery));
      } else {
        debug('Error creating previous prayer list query');
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(getPrevMessageList(prevPrayerListQuery!));
    }
  };

  // @ts-ignore
  const _handleSelectingMessage = (message) => {
    ReactNativeHapticFeedback.trigger('impactLight', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    setSelectedMessage(message);
  };

  const _handleSelectingReaction = (message: any, reaction: string) => {
    ReactNativeHapticFeedback.trigger('impactLight', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    setSelectedReaction(reaction);
    setSelectedMessage(message);
  };

  const _hideActionSheet = () => {
    setSelectedMessage(null);
    setSelectedReaction(null);
  };
  const _handleSelectingUser = (user: Sender) => {
    setSelectedUser(user);
  };

  const _onReportPopup = (user: any) => {
    setUserToReport(user);
    setSelectedUser(null);
  };

  const _onBlockPopup = (user: any) => {
    setUserToBlock(user);
    setSelectedUser(null);
  };

  const _onReportMessageWeb = () => {
    setShowReportMessageSheet(true);
  };

  const _confirmReportMessage = (
    category: ReportCategory,
    description: string
  ) => {
    const msg = selectedMessage || activeMessage;

    if (msg) {
      channel
        ?.reportMessage(msg, category, description)
        .then(() => {
          setSelectedMessage(null);
          showKitSnack('Message reported', KitSnackDuration.SHORT);
        })
        .catch((error) => {
          debug('Could not report message: ', error);
          setSelectedMessage(null);
          showKitSnack(
            'Could not report message. Try again later',
            KitSnackDuration.SHORT
          );
        });
    }

    setShowReportMessageSheet(false);
  };

  const _confirmReportUser = (
    category: ReportCategory,
    description: string
  ) => {
    if (userToReport) {
      channel
        ?.reportUser(
          // @ts-ignore
          userToReport,
          category,
          description
        )
        .then(() => {
          showKitSnack(
            `${userToReport.nickname} reported`,
            KitSnackDuration.SHORT
          );

          setUserToReport(null);
        })
        .catch((error) => {
          debug('Could not report user: ', error);
          setUserToReport(null);
          showKitSnack(
            'Could not report person. Try again later',
            KitSnackDuration.SHORT
          );
        });
    }
  };

  const _confirmBlockUser = () => {
    if (userToBlock) {
      if (channel) {
        dispatch(blockUser(channel, userToBlock));
      }

      showKitSnack(`${userToBlock.nickname} blocked`, KitSnackDuration.SHORT);
      setUserToReport(null);
    }
  };

  useEffect(() => {
    setPreviousPrayerListQuery(null);
    _getPrayerList(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    const data: SendbirdMessage[] = sbAdjustMessageList(prayers);
    setPrayerList(
      data.filter((m) => {
        const prData: { isAnswered?: boolean } = JSON.parse(m.data || '{}');

        return (
          m.channelUrl === (channel as GroupChannel).url && !prData.isAnswered
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prayers]);

  useEffect(() => {
    if (channel) {
      dispatch(updatePrayerList([]));
      _getPrayerList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  useEffect(() => {
    if (isAnsweringPrayer && isSmall) {
      setTimeout(() => {
        navigation.navigate(Constants.PRAYER_REQUEST_SCREEN, {
          screen: Constants.PRAYER_REQUEST_SCREEN,
          params: { prayerRequest: activeMessage },
        });
      }, 200);
      setIsAnsweringPrayer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnsweringPrayer, activeMessage, navigation]);

  // @ts-ignore
  const renderPrayerBubble = ({ item }) => {
    return (
      <ChatContext.Provider
        value={{
          setActiveMessage,
          selectedMessage,
          setSelectedMessage,
          setSelectedReaction,
          setIsAnsweringPrayer,
          handleSelectingMessage: () => _handleSelectingMessage(item),
          handleSelectingReaction: _handleSelectingReaction,
          handleSelectingUser: _handleSelectingUser,
          reportMessage: _onReportMessageWeb,
        }}
      >
        <Message
          message={item}
          onReport={_onReportPopup}
          onBlock={_onBlockPopup}
        />
      </ChatContext.Provider>
    );
  };

  return prayerList.length > 0 ? (
    <>
      <FlatList
        style={styles.list}
        data={prayerList}
        renderItem={renderPrayerBubble}
        keyExtractor={(item) => `${item.messageId}`}
        // @ts-ignore
        onEndReached={_getPrayerList}
        contentInset={{ bottom: Spacing.xxl }}
        ListFooterComponent={<View style={{ height: Spacing.l }} />}
      />
      <KitSnackRender
        {...snackOptions}
        setVisible={(value) =>
          setSnackOptions({ ...snackOptions, visible: value })
        }
      />
      {channel && (
        <MessageActionSheet
          channel={channel}
          simpleView
          selectedMessage={selectedMessage as SendbirdUserMessage}
          selectedReaction={selectedReaction}
          setSelectedMessage={setSelectedMessage}
          deleteMessage={promptDeletePrayer}
          onClose={_hideActionSheet}
          onCopy={() => showKitSnack('Text copied', KitSnackDuration.SHORT)}
          setActiveMessage={setActiveMessage}
          setIsAnsweringPrayer={setIsAnsweringPrayer}
        />
      )}
      {Platform.OS === 'web' && (
        <WebModal
          open={isAnsweringPrayer}
          onClose={() => setIsAnsweringPrayer(false)}
        >
          <PrayerRequestScreen
            // @ts-ignore
            prayerToBeAnswered={activeMessage}
            // @ts-ignore
            togglePopup={() => setIsAnsweringPrayer(false)}
          />
        </WebModal>
      )}
      <KitModalV2
        isVisible={showReportMessageSheet}
        onClose={() => setShowReportMessageSheet(false)}
        anchorBottom={sizeClass === SizeClass.Small}
        coverScreen
      >
        <ConfirmReportMessageContent
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedMessage={activeMessage!}
          setConfirmReportMessageVisible={setShowReportMessageSheet}
          confirmReportMessage={_confirmReportMessage}
        />
      </KitModalV2>
      <ReportUserModal
        isVisible={userToReport !== null}
        userToReport={userToReport}
        onClose={() => setUserToReport(null)}
        confirmReportUser={_confirmReportUser}
      />
      <BlockUserModal
        isVisible={userToBlock !== null}
        userToBlock={userToBlock}
        onClose={() => setUserToBlock(null)}
        confirmBlockUser={_confirmBlockUser}
      />
    </>
  ) : (
    <Empty />
  );
}

function Empty() {
  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors.N0,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: Spacing.xxl,
        borderTopWidth: 1,
        borderTopColor: Colors.N100,
      }}
    >
      <KitText center style={{ marginBottom: Spacing.xxl }}>
        There are no prayer requests in this conversation.
      </KitText>
    </View>
  );
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
    backgroundColor: Colors.N0,
    paddingTop: Platform.OS === 'web' ? 0 : 9,
    borderTopWidth: 1,
    borderTopColor: Colors.N100,
  },
});
