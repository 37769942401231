import { fetchVersionDetails } from '@omni/bible/api/bible';
import BibleNavContent from '@omni/bible/components/BibleNavContent';
import versionList from '@omni/bible/components/bibleVersions.json';
import { KitLoader } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import { BibleBook, Version } from '@omni/kit/types/bible';
import { retrieveBibleSettingsFromDevice } from '@omni/kit/utilities/bible';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import { BIBLE_VERSE_SCREEN } from '../../shared/Constants';

export default function BibleLocationScreen(): JSX.Element {
  const [bibleBooks, setBibleBooks] = useState<BibleBook[]>([]);
  const [versionInfo, setVersionInfo] = useState<Version | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(true);

  const navigation = useNavigation();

  const fetchBooks = async (info: Version) => {
    const { id, api } = info;
    const detailResponse = await fetchVersionDetails(id, api);

    if (detailResponse) {
      setBibleBooks(detailResponse.books);
      setIsLoading(false);
    }
  };

  const selectVerse = (chapter: number, osis: string) => {
    const displayBookName = bibleBooks.find((b) => b.osis === osis)?.display;
    navigation.navigate(BIBLE_VERSE_SCREEN, {
      chapter,
      osis,
      versionId: (versionInfo as Version).id,
      displayBookName,
    });
  };

  useEffect(() => {
    retrieveBibleSettingsFromDevice()
      .then((settings) => {
        setVersionInfo({ id: settings.versionDetails.id, api: settings.api });
      })
      .catch(() => {
        const nivVersion = versionList.find((i) => i.id === 'NIV');

        if (nivVersion) {
          setVersionInfo({ id: nivVersion.id, api: nivVersion.api });
        }
      });
  }, []);

  useEffect(() => {
    if (versionInfo) {
      fetchBooks(versionInfo);
    }
  }, [versionInfo]);

  return isLoading ? (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.N0,
      }}
    >
      <KitLoader />
    </View>
  ) : (
    <BibleNavContent bibleBooks={bibleBooks} onSelectChapter={selectVerse} />
  );
}
