import { KitButton, KitIcon, KitText } from '@omni/kit/components';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, View } from 'react-native';

interface EmptyStateGroupEventProps {
  isManager: boolean;
}
export default function GroupEventsEmptyState({
  isManager,
}: EmptyStateGroupEventProps): JSX.Element {
  const { colorForScheme } = useContext(ThemeContext);
  const { t } = useTranslation();

  const bgColor = colorForScheme?.({
    dark: Colors.N900,
    light: Colors.N50,
  });
  const iconColor = colorForScheme?.({
    dark: Colors.N400,
    light: Colors.N300,
  });

  const subtitleColor = colorForScheme?.({
    dark: Colors.N400,
    light: Colors.N500,
  });

  return (
    <View style={styles.emptyStateContainer}>
      <View
        style={[
          styles.emptyStateIcon,
          {
            backgroundColor: bgColor,
          },
        ]}
      >
        <KitIcon name='calendar' size={20} color={iconColor} />
      </View>
      <View>
        <KitText
          extraBold
          center
          color={Colors.N900}
          fontSize={20}
          style={{ marginBottom: Spacing.xs }}
        >
          {t('common:textNoUpcomingEvents')}
        </KitText>
        <KitText center color={subtitleColor} fontSize={16}>
          {t('groups:emptyStateGroupEventsSubtitleTitle')}
        </KitText>
        {isManager && (
          <KitButton
            secondary
            // ToDo
            // onPress={()=>()}
            icon='icons-event'
            iconSize={14}
            testID='CreateGroupEventButton'
            style={{
              marginTop: Spacing.l,
              width: 241,
              height: 44,
              alignSelf: 'center',
            }}
            title={t('groups:createGroupEventButtonTitle')}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  emptyStateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 32,
    marginTop: Platform.OS === 'web' ? 96 : 235,
  },
  emptyStateIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: Spacing.s,
    borderRadius: BorderRadius.m,
    marginBottom: Spacing.m,
  },
});
