import TokensService from '@omni/kit/services/TokensService';

import { ITag } from '../';
import BaseServiceV2, { IHttpResponse } from '../../BaseServiceV2';
import { IPrepareTagUrl, prepareTagUrl } from '../BuildUrls';

const debug = require('debug')('tca:service:TagService:Core:GetAll');

interface ITagGetAll extends Omit<IPrepareTagUrl, 'id'> {
  getFromCache?: boolean;
  appKey?: string;
  headers?: { [key: string]: string };
}

interface ITagGetAllResponse extends IHttpResponse {
  body?: {
    total?: number;
    _embedded: {
      tags: ITag[];
    };
  };
}

export default async ({
  getFromCache,
  appKey,
  headers,
  ...buildUrlProps
}: ITagGetAll): Promise<ITagGetAllResponse> => {
  debug('GetAll');
  const url = prepareTagUrl(buildUrlProps);

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({
    url,
    appKey,
    getFromCache,
    token: guestToken,
    skipTimestamp: true,
    headers,
  });
};
