import AsyncStorage from '@react-native-async-storage/async-storage';
import { useEffect, useState } from 'react';

import useAppLaunchCount from './useAppLaunchCount';

export default function useHasSeenTour(): {
  hasSeenTour: boolean | null;
  setHasSeenTour: (seen: boolean) => void;
} {
  const [hasSeenTour, setHasSeenTour] = useState<boolean | null>(null);
  const appLaunchCount = useAppLaunchCount();

  useEffect(() => {
    if (appLaunchCount !== null && hasSeenTour === null) {
      AsyncStorage.getItem('seen-search-tour').then((value: string | null) => {
        setHasSeenTour(appLaunchCount > 1 || Boolean(value));
      });
    }
  }, [appLaunchCount, hasSeenTour]);

  return { hasSeenTour, setHasSeenTour };
}
