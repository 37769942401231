import { useShellContext } from '@omni/kit/contexts/ShellContext';
import useShellAuthProviders from '@omni/kit/contexts/ShellContext/hooks/useShellAuthProviders';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Downloader, {
  IDownloaderResponse,
  NOT_MODIFIED,
} from '../../Downloader';
import { SUBSPLASH_AUTH_PROVIDER_ID } from '../../constants/identifiers';
import CacheService from '../../services/CacheService';
import { BroadcastStatus, IPlaylistData, IPlaylistList } from './types';

const debug = require('debug')('omni:kit:hooks:useMediaStatus');

let pollIntervalId: NodeJS.Timeout;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useMediaStatus({
  playlistUrl,
  enablePolling = false,
  authProviderId,
}: {
  // Please note that the 'playlistUrl' may be a third party json feed url.
  // Do not attempt to parse this url for params such as the media item id
  // ref: https://subsplash.atlassian.net/browse/PLAT-1586
  playlistUrl: string;
  enablePolling: boolean;
  authProviderId?: string;
}) {
  const { tokens } = useShellContext();

  const { targetProviders } = useShellAuthProviders();

  const guestToken = useMemo(() => tokens.guest, [tokens.guest]);
  const subsplashUserToken = useMemo(() => tokens.user, [tokens.user]);
  const targetProviderToken = useMemo(
    () => (authProviderId ? tokens.accessTokens?.[authProviderId] : undefined),
    [tokens.accessTokens, authProviderId]
  );

  const [playlistData, setPlaylistData] = useState<IPlaylistData | null>(null);
  const [broadcastData, setBroadcastData] = useState<any>(null);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [broadcastStatus, setBroadcastStatus] =
    useState<BroadcastStatus>('none');
  const [hasAudio, setHasAudio] = useState<boolean>(false);
  const [hasVideo, setHasVideo] = useState<boolean>(false);
  const [broadcastStarted, setBroadcastStarted] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const pollingInterval = 10000; // 10 seconds

  const hasBroadcastStarted = useCallback(() => {
    if (broadcastData) {
      const now = moment.utc();
      const startAt = moment.utc(broadcastData?.start_at);

      return now.diff(startAt, 's') >= 0;
    } else {
      return false;
    }
  }, [broadcastData]);

  const updateBroadcastStarted = useCallback(() => {
    setBroadcastStarted(hasBroadcastStarted());
  }, [hasBroadcastStarted]);

  // The playlistUrl may have a third-party/enterprise domain,
  // but the broadcast status endpoint will always be on Subsplash
  const _fetchBroadcastStatus = useCallback(async () => {
    if (broadcastData) {
      const url = broadcastData?._links?.self?.href;
      const token = (subsplashUserToken || guestToken)?.replace('Bearer ', '');

      if (url) {
        Downloader.downloadUrl({
          url,
          options: {
            authProviderId: SUBSPLASH_AUTH_PROVIDER_ID,
            method: 'GET',
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
        })
          .then((response) => {
            if (response?.body) {
              const data = response.body;
              const status = data.status;
              debug('Broadcast status: ', status);
              setBroadcastStatus(status);
              updateBroadcastStarted();
              setError('');
            }
          })
          .catch((err) => {
            debug('Error getting broadcast status: ', err);
            setError('Could not get event status');
          });
      } else {
        debug('Error getting broadcast url');
        setError('Could not get event status');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    broadcastData,
    updateBroadcastStarted,
    subsplashUserToken,
    authProviderId,
  ]);

  const _beginPolling = useCallback(() => {
    debug('_beginPolling');
    clearInterval(pollIntervalId);
    _fetchBroadcastStatus();
    pollIntervalId = setInterval(_fetchBroadcastStatus, pollingInterval);
  }, [_fetchBroadcastStatus]);

  const _handlePlaylistFeedLoadError = (
    code: number,
    err: unknown,
    context: string
  ) => {
    debug(
      'Error downloading playlist data',
      '| Context:',
      context,
      '| Code:',
      code,
      '| Error:',
      err
    );
    setError('Could not get media');
  };

  const _loadCachedMediaData = useCallback(async () => {
    return playlistUrl
      ? await CacheService.getCachedResponse({
          url: playlistUrl,
        })
      : null;
  }, [playlistUrl]);

  const _handlePlaylistFeedResponse = useCallback(
    (response?: IDownloaderResponse | null, context?: string) => {
      if (!response && context === 'cache') {
        debug('Playlist Feed: Cache Miss.');

        return;
      }

      if (response?.status === NOT_MODIFIED) {
        debug('Playlist Feed: 304 Not Modified');

        return;
      }

      const status = response?.status ?? 550;

      if (status >= 400) {
        _handlePlaylistFeedLoadError(status, null, context || 'unknown');

        return;
      }

      const data = response?.body;
      const item = data?.playlist?.[0] as IPlaylistList;

      setBroadcastData(item?.broadcast ?? null);

      // @ts-ignore
      item?.media?.forEach((mediaItem: IMedia) => {
        if (
          mediaItem.format === 'audio' ||
          mediaItem.format === 'mp3' ||
          mediaItem.format === 'm4a'
        ) {
          setHasAudio(true);
        }

        if (
          mediaItem.format === 'video' ||
          mediaItem.format === 'mp4' ||
          mediaItem.format === 'm3u8'
        ) {
          setHasVideo(true);
        }
      });

      setPlaylistData(data);
    },
    []
  );

  const _fetchMediaData = useCallback(
    async (cachedResponse?: IDownloaderResponse | null) => {
      if (playlistUrl) {
        const context = 'network';
        let token = targetProviderToken;

        if (!token && authProviderId === SUBSPLASH_AUTH_PROVIDER_ID) {
          token = guestToken;
        }

        token = token?.replace('Bearer ', '');

        Downloader.downloadUrl({
          url: playlistUrl,
          options: {
            authProviderId,
            targetProviders,
            method: 'GET',
            extraHeaders: {
              Authorization: `Bearer ${token}`,
            },
          },
          cachedResponse: cachedResponse || undefined,
        })
          .then((response) => _handlePlaylistFeedResponse(response, context))
          .catch((err) => {
            _handlePlaylistFeedLoadError(504, err, context);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_handlePlaylistFeedResponse, playlistUrl]
  );

  useEffect(() => {
    if (broadcastData) {
      _fetchBroadcastStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastData]);

  useEffect(() => {
    _loadCachedMediaData().then((cachedResponse) => {
      _handlePlaylistFeedResponse(cachedResponse, 'cache');
      _fetchMediaData(cachedResponse);
    });
  }, [_loadCachedMediaData, _handlePlaylistFeedResponse, _fetchMediaData]);

  useEffect(() => {
    if (isPolling && enablePolling) {
      _beginPolling();
    }

    return () => clearInterval(pollIntervalId);
  }, [isPolling, enablePolling, _beginPolling]);

  useEffect(() => {
    setIsPolling(broadcastStatus === 'scheduled' || broadcastStatus === 'live');
  }, [broadcastStatus]);

  return {
    playlistData,
    broadcastData,
    _beginPolling,
    isPolling,
    broadcastStatus,
    updateBroadcastStarted,
    broadcastStarted,
    hasAudio,
    hasVideo,
    error,
  };
}
