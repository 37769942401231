import { KitIcon, KitText } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';

import { CustomMessageData, SendbirdUserMessage } from '../../../../../Types';
import ParsedMessage from '../../../../../mobile/components/chat/ParsedMessage';
import { ChatContext } from '../../../../../mobile/scenes/channel/ChatScreen';

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PrayerMessage({ message }): JSX.Element {
  const { answeredPrayer } = useContext(ChatContext);

  let data: CustomMessageData | null = null;
  try {
    data = JSON.parse(message.data);
  } catch (e) {}

  return (
    <View style={styles.wrapper}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: Spacing.m,
        }}
      >
        <KitIcon name='prayer' size={16} />
        <KitText black semiBold style={{ marginLeft: Spacing.s }} fontSize={14}>
          Prayer Request{data?.isAnswered ? ' • Answered' : ''}
        </KitText>
      </View>
      {answeredPrayer && (
        <View
          style={{
            paddingHorizontal: Spacing.m,
            paddingVertical: Spacing.xs,
            borderLeftWidth: 3,
            borderLeftColor: Colors.N200,
            marginBottom: Spacing.m,
          }}
        >
          <KitText>{(answeredPrayer as SendbirdUserMessage).message}</KitText>
        </View>
      )}
      <ParsedMessage>{message.message}</ParsedMessage>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: Colors.N100,
    borderRadius: 20,
    padding: Spacing.m,
    backgroundColor: Colors.N0,
  },
});
