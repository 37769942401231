import TokensService from '@omni/kit/services/TokensService';

import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroupLocation } from '../Types';

const RESOURCE = 'locations';

interface IGetGroupLocations extends Omit<IUri, 'resource'> {
  appKey: string;
}

interface IGetGroupLocationsResponse extends IHttpResponse {
  body?: {
    _embedded?: {
      locations?: IGroupLocation[];
    };
    count?: number;
  };
}

export default async ({
  appKey,
}: IGetGroupLocations): Promise<IGetGroupLocationsResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    filters: [
      ['app_key', appKey],
      ['group.public', 'true'],
    ],
    resource: RESOURCE,
    pages: [['size', 100]],
  });

  const guestToken = await TokensService.getGuestToken();

  return BaseServiceV2.Get({ url, token: guestToken, skipTimestamp: true });
};
