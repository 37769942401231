import { BlockPageApp } from '@omni/blocks';
import { GroupsWrapper } from '@omni/groups/GroupsWrapper';
import Environment from '@omni/kit/Environment';
import { CredentialProps } from '@omni/kit/auth';
import Colors from '@omni/kit/theming/Colors';
import { SpacingType } from '@omni/kit/theming/SpacingType';
import { NativeTopBarStyle } from '@omni/kit/utilities/NativeHelpers';
import pluralize from 'pluralize';
import React from 'react';
import { Platform, View } from 'react-native';

import GroupDetail from '../../../../packages/groups/routes/GroupDetail';
import { ResultScreenRouteProp } from '../../types';
import TagResult from './TagResult';

const debug = require('debug')('tca:search:screens:Result');
const feedsService = Environment.feedsService;

export default ({
  appKey,
  horizontalSpacing,
  route,
  credentialProps,
}: {
  appKey?: string;
  horizontalSpacing?: SpacingType;
  route: ResultScreenRouteProp;
  credentialProps?: CredentialProps;
}): JSX.Element => {
  const { id, path, data } = route.params;
  const itemType = data?.type || path;

  if (
    data &&
    (itemType === 'topic' || itemType === 'speaker' || itemType === 'scripture')
  ) {
    return <TagResult route={route} horizontalSpacing={horizontalSpacing} />;
  }

  let content;
  switch (itemType) {
    case 'groups':
    case 'group':
      content = (
        <GroupsWrapper>
          <GroupDetail
            appKey={appKey}
            credentialProps={credentialProps}
            staticParams={{
              id,
              path: data?.type ? pluralize(data.type) : undefined,
            }}
          />
        </GroupsWrapper>
      );
      break;
    default:
      content = (
        <BlockPageApp
          appKey={appKey}
          url={`${feedsService}/${pathToResourceType(itemType)}/${id}`}
          topBarStyle={NativeTopBarStyle.Transparent}
        />
      );
  }

  return (
    <View
      style={{
        backgroundColor: Colors.N0,
        flex: 1,
        maxWidth: Platform.select({ web: 800, default: undefined }),
        margin: 'auto',
        width: '100%',
      }}
    >
      {content}
    </View>
  );
};

const pathToResourceType = (path: string | undefined): string | undefined => {
  switch (path) {
    case 'media':
    case 'media-item':
      return 'media-detail';
    case 'groups':
      return 'groups';
  }
};
