import { Platform, Image as RNImage } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import ImagePicker, {
  Image as ImagePickerImage,
  Options as ImagePickerOptions,
} from 'react-native-image-crop-picker';

import { PlatformAgnosticFile } from '../FileTypes';
import { KitSnack } from '../components';
import {
  nativeImageToAgnosticFile,
  webImageToAgnosticFile,
} from '../components/KitEditableAvatar/imageUtilities';
import { KitSnackDuration } from '../components/KitSnack';
import {
  bytesPerMb,
  fileTooLargeMessage,
  maxFileSizeInMb,
} from '../utilities/utilities';

type ImageTypeReturn = Promise<PlatformAgnosticFile | undefined>;

type UseImageSelectorReturnType = {
  _onChangePhotoPressMobile: (circle: boolean) => ImageTypeReturn;
  _onCameraPress: () => ImageTypeReturn;
  _onFilePress: () => ImageTypeReturn;
  _onChangePhotoPressWeb: (
    event?: React.ChangeEvent<HTMLInputElement>
  ) => ImageTypeReturn;
};

export function useImageSelector(): UseImageSelectorReturnType {
  const _onChangePhotoPressWeb = async (
    event?: React.ChangeEvent<HTMLInputElement>
  ): ImageTypeReturn => {
    if (Platform.OS === 'web' && event) {
      const imageObject = event.target.files?.[0];

      if (!imageObject) {
        return;
      }

      return webImageToAgnosticFile(imageObject);
    }
  };

  const _onChangePhotoPressMobile = async (
    circle: boolean
  ): ImageTypeReturn => {
    const options: ImagePickerOptions = {
      cropping: true,
      cropperCircleOverlay: circle,
      // workaround photo orientation issue on Android by slightly lowering compressImageQuality
      // ref: https://github.com/ivpusic/react-native-image-crop-picker/issues/379#issuecomment-713690758
      ...Platform.select({ android: { compressImageQuality: 0.99 } }),
    };

    let imageObject: ImagePickerImage | undefined;
    try {
      imageObject = await ImagePicker.openPicker(options);
    } catch {}

    if (!imageObject) {
      return;
    }

    return nativeImageToAgnosticFile(imageObject) as PlatformAgnosticFile;
  };

  const _onCameraPress = async (): ImageTypeReturn => {
    const options: ImagePickerOptions = {
      width: 1080,
      height: 1080,
      cropping: true,
    };
    const imageObject = (await ImagePicker.openCamera(
      options
    )) as ImagePickerImage;

    if (!imageObject) {
      return;
    }

    return nativeImageToAgnosticFile(imageObject);
  };

  const _onFilePress = async (): ImageTypeReturn => {
    try {
      const fileObject = await DocumentPicker.pickSingle({
        type: [DocumentPicker.types.images],
      });
      const fileSize = (fileObject.size ?? 0) / bytesPerMb; // filesize in megabytes

      if (fileSize > maxFileSizeInMb) {
        KitSnack.show(fileTooLargeMessage, KitSnackDuration.SHORT);

        return undefined;
      }

      if (fileObject.type?.startsWith('image/')) {
        try {
          await RNImage.getSize(fileObject.uri, (width, height) => {
            if (width !== 0 && height !== 0) {
              const file: PlatformAgnosticFile = {
                uri: fileObject.uri,
                fileName: fileObject.name ? fileObject.name : 'file',
                contentType: fileObject.type ? fileObject.type : 'unknown',
                size: fileObject.size ?? 0,
                _original: {
                  // use default value because _original is only used when uploading from web
                  lastModified: 0,
                  name: '',
                } as File,
              };

              return file;
            }
          });
        } catch (error) {
          return undefined;
        }
      }
    } catch (err) {
      return undefined;
    }
  };

  return {
    _onChangePhotoPressWeb,
    _onChangePhotoPressMobile,
    _onCameraPress,
    _onFilePress,
  };
}
