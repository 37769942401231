import KitLoader from '@omni/kit/components/KitLoader';
import Colors from '@omni/kit/theming/Colors';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import * as React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

export default function LoadingOverlay(): JSX.Element {
  return (
    <View style={styles.backdrop}>
      <View
        style={{
          backgroundColor: Colors.N0,
          width: 240,
          height: 112,
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <KitLoader color={Colors.N200} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: '#00000040',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%', //SCREEN_WIDTH,
    height: '100%', //SCREEN_HEIGHT,
    paddingTop: '33%',
    alignItems: 'center',
    zIndex: 2,
    ...Platform.select({
      android: {
        elevation: 10,
        overflow: 'hidden',
      },
      default: {
        shadowColor: '#000000',
        shadowOpacity: 0.12,
        shadowOffset: { width: 0, height: 10 },
        shadowRadius: 25,
      },
    }),
  },
});
