import { KitIcon, KitInput, KitTouchable } from '@omni/kit/components';
import Colors from '@omni/kit/theming/Colors';
import { ThemeContext } from '@omni/kit/theming/ThemeContext';
import React, { useContext } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export interface Props {
  autoFocus?: boolean;
  editable?: boolean;
  onFocus?: (...args: unknown[]) => void;
  onRemove?: () => void;
  onSubmit?: (...args: unknown[]) => void;
  placeholder?: string;
  setValue?: (inputText: string) => void;
  style?: StyleProp<ViewStyle>;
  value?: string;
}

export default (props: Props): JSX.Element => {
  const { onRemove, onSubmit, setValue, value } = props;

  return (
    <View style={props.style}>
      <KitInput
        inputBorder={Colors.N50}
        leftIcon={<LeftIcon />}
        onChangeText={setValue}
        onSubmitEditing={onSubmit}
        rightIcon={Boolean(value) && <RightIcon onPress={onRemove} />}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
      />
    </View>
  );
};

const LeftIcon = () => {
  const { colorForScheme } = useContext(ThemeContext);

  return (
    <KitIcon
      color={colorForScheme?.({ light: Colors.N300, dark: Colors.N500 })}
      name='search'
      size={20}
      style={{ marginTop: 2 }}
    />
  );
};

const RightIcon = ({ onPress = () => null }: { onPress?: () => void }) => {
  const { colorForScheme } = useContext(ThemeContext);

  return (
    <KitTouchable onPress={onPress}>
      <View
        style={{
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <KitIcon
          color={colorForScheme?.({ light: Colors.N300, dark: Colors.N500 })}
          name='remove'
          size={12}
        />
      </View>
    </KitTouchable>
  );
};
