import { KitText } from '@omni/kit/components';
import Hoverable from '@omni/kit/components/Hoverable';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import React, { useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PopupActions } from 'reactjs-popup/dist/types';

import Dropdown from '../../../../mobile/components/Dropdown';
import LeftNavButton, {
  LeftNavType,
} from '../../../../mobile/components/LeftNavButton';
import ActionSheetItem from '../../../../mobile/components/chat/actionSheet/ActionSheetItem';
import CreateChannelModal from '../../../../mobile/screens/CreateChannelModal';
import {
  canCreateChannelsSelector,
  canCreateDirectMessageSelector,
} from '../../../../shared/redux/selectors';
import CreateDirectContent from '../../../../shared/scenes/createChannel/CreateDirectContent';
import { getShortChannelUrl } from '../../../../utilities/chatUtilities';
import Routes from '../../../navigation/Routes';
import WebModal from '../../app/components/WebModal';
import CreateDropdown from './CreateDropdown';

enum CreateMode {
  message,
  group,
}

export default function CreateMessageOrGroup(): JSX.Element | null {
  const [createMode, setCreateMode] = useState<CreateMode | undefined>(
    undefined
  );
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const canCreateDirectMessage = useSelector(canCreateDirectMessageSelector);
  const canCreateChannels = useSelector(canCreateChannelsSelector);

  const isSmall = useSizeClass().sizeClass === SizeClass.Small;
  const history = useHistory();
  const params = useParams();

  const dropdownMenuRef = useRef<PopupActions>();

  const handleCreateClick = () => {
    if (canCreateChannels && canCreateDirectMessage) {
      dropdownMenuRef.current?.toggle();
    } else if (canCreateChannels) {
      createNewGroup();
    } else {
      createNewMessage();
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const createNewMessage = () => {
    dropdownMenuRef.current?.close();
    setCreateMode(CreateMode.message);
    openModal();
  };

  const createNewGroup = () => {
    dropdownMenuRef.current?.close();
    setCreateMode(CreateMode.group);
    openModal();
  };

  const navToNewChannel = (channelId: string) => {
    history.push(
      // @ts-ignore
      Routes.channelScreen(params.appKey, getShortChannelUrl(channelId))
    );
  };

  return canCreateDirectMessage || canCreateChannels ? (
    <>
      {/* @ts-ignore */}
      <CreateDropdown ref={dropdownMenuRef} onClick={handleCreateClick}>
        <div>
          <Dropdown>
            {canCreateDirectMessage && (
              <Hoverable>
                {(isHovering) => (
                  <View
                    style={{
                      backgroundColor: isHovering ? Colors.N50 : 'transparent',
                    }}
                  >
                    <ActionSheetItem
                      icon='messaging'
                      label='New message'
                      onPress={createNewMessage}
                    />
                  </View>
                )}
              </Hoverable>
            )}
            {canCreateChannels && (
              <Hoverable>
                {(isHovering) => (
                  <View
                    style={{
                      backgroundColor: isHovering ? Colors.N50 : 'transparent',
                    }}
                  >
                    <ActionSheetItem
                      icon='channel'
                      label='New group'
                      onPress={createNewGroup}
                    />
                  </View>
                )}
              </Hoverable>
            )}
          </Dropdown>
        </div>
      </CreateDropdown>
      <WebModal
        open={modalOpen}
        onClose={closeModal}
        contentStyle={isSmall && { width: '100vw', height: '100vh' }}
      >
        {createMode === CreateMode.message ? (
          <CreateDirectContent
            header={
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: Spacing.m,
                }}
              >
                <View style={{ opacity: 0 }}>
                  <LeftNavButton
                    disabled
                    type={LeftNavType.Dismiss}
                    title='New message'
                    onPress={undefined}
                  />
                </View>
                <KitText bold black fontSize={20}>
                  New message
                </KitText>
                <LeftNavButton
                  type={LeftNavType.Dismiss}
                  title='New message'
                  onPress={closeModal}
                />
              </View>
            }
            onSubmit={closeModal}
          />
        ) : (
          <CreateChannelModal
            navigation={{
              goBack: closeModal,
              navigate: navToNewChannel,
              setOptions: () => null,
            }}
          />
        )}
      </WebModal>
    </>
  ) : null;
}

const styles = StyleSheet.create({
  wrapper: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Spacing.l,
    borderBottomWidth: 1,
    borderColor: Colors.N100,
  },
  createIcon: {
    width: 36,
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 2,
  },
});
