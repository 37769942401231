import TokensService from '@omni/kit/services/TokensService';

import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IGroup } from '../Types';

const RESOURCE = 'group';

interface IGetGroup extends Omit<IUri, 'resource'> {
  id: string;
  token?: string;
}

interface IGetGroupResponse extends IHttpResponse {
  body?: IGroup;
}

export default async ({ id, token }: IGetGroup): Promise<IGetGroupResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    id,
    includes: ['type', 'group-members.end-user.profile'],
    fields: [['groups', 'member_count']],
    resource: RESOURCE,
  });

  if (!token?.length) {
    token = await TokensService.getGuestToken();
  }

  return BaseServiceV2.Get({ url, token: token, skipTimestamp: true });
};
