import HealthMonitor from '@omni/kit/HealthMonitor';
import { KitSnack } from '@omni/kit/components';
import { KitSnackDuration } from '@omni/kit/components/KitSnack';
import SendbirdChat, {
  MetaData,
  PushTriggerOption,
  User,
} from '@sendbird/chat';
import {
  GroupChannel,
  GroupChannelListOrder,
  GroupChannelListQuery,
  GroupChannelListQueryParams,
  MembershipFilter,
  PublicGroupChannelListQuery,
  PublicGroupChannelListQueryParams,
  SendbirdGroupChat,
} from '@sendbird/chat/groupChannel';
import moment from 'moment';

import { sbIsConnected } from './userFunctions';

const debug = require('debug')('tca:chat:utilities:channelFunctions');

export const sbCreateGroupChannelListQuery = (
  getPublicChannels = false
): GroupChannelListQuery | PublicGroupChannelListQuery | null => {
  if (!sbIsConnected()) {
    debug('Not connected. Skipping sbCreateGroupChannelListQuery.');

    return null;
  }

  let query: GroupChannelListQuery | PublicGroupChannelListQuery | null = null;

  if (getPublicChannels) {
    const params: PublicGroupChannelListQueryParams = {
      includeEmpty: true,
      limit: 100,
      membershipFilter: MembershipFilter.ALL,
    };
    debug(
      'SendbirdChat.instance.groupChannel.createPublicGroupChannelListQuery'
    );
    query = (
      SendbirdChat.instance as SendbirdGroupChat
    )?.groupChannel.createPublicGroupChannelListQuery(params);
  } else {
    debug('SendbirdChat.instance.groupChannel.createMyGroupChannelListQuery');
    const params: GroupChannelListQueryParams = {
      includeEmpty: true,
      limit: 100,
      order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
    };
    query = (
      SendbirdChat.instance as SendbirdGroupChat
    )?.groupChannel.createMyGroupChannelListQuery(params);
  }

  return query;
};

export const sbGetGroupChannelList = (
  groupChannelListQuery: GroupChannelListQuery | PublicGroupChannelListQuery
): Promise<GroupChannel[]> => {
  if (!sbIsConnected()) {
    debug('Skipping sbGetGroupChannelList query. Not connected.');

    return Promise.reject('Skipping sbGetGroupChannelList. Not connected');
  }

  return groupChannelListQuery.next();
};

export const sbGetGroupChannel = (
  channelUrl: string
): Promise<GroupChannel> => {
  if (!sbIsConnected()) {
    debug('Error getting group channel. Not connected.');

    return Promise.reject('Error getting group channel. Not connected.');
  }

  return (SendbirdChat.instance as SendbirdGroupChat).groupChannel
    .getChannel(channelUrl)
    .catch((error) => {
      // this KitSnack is vital for E2E tests in Detox
      KitSnack.show('Unable to get group', KitSnackDuration.SUPER_LONG);
      debug('Error getting group channel: ', error);
      HealthMonitor.logError(error);

      return Promise.reject(error);
    });
};

export const sbGetBlockedUserList = (): Promise<User[]> => {
  if (!sbIsConnected()) {
    debug('Skipping sbGetBlockedUserList query. Not connected.');

    return Promise.reject('Skipping sbGetBlockedUserList. Not connected');
  }

  const query = SendbirdChat.instance.createBlockedUserListQuery();

  return query.next();
};

export const sbBlockUser = (userId: string): Promise<void> => {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbBlockUser.');
  }

  return SendbirdChat.instance.blockUserWithUserId(userId);
};

export const sbUnblockUser = (userId: string): Promise<void> => {
  if (!sbIsConnected()) {
    return Promise.reject('Not connected. Skipping sbUnblockUser.');
  }

  return SendbirdChat.instance.unblockUserWithUserId(userId);
};

export const sbHideGroupChannel = (
  channelUrl: string
): Promise<GroupChannel> => {
  return sbGetGroupChannel(channelUrl).then((channel) =>
    channel.hide({
      hidePreviousMessages: false,
      allowAutoUnhide: true,
    })
  );
};

export const sbGetUsersById = (userIds: string[]): Promise<User[]> => {
  if (!sbIsConnected()) {
    debug('Skipping sbGetUsersById query. Not connected.');

    return Promise.reject('Skipping sbGetUsersById. Not connected');
  }

  if (SendbirdChat.instance.connectionState === 'OPEN') {
    debug('SendbirdChat.instance.createApplicationUserListQuery');
    const query = SendbirdChat.instance.createApplicationUserListQuery({
      userIdsFilter: userIds,
      limit: 100,
    });

    return query.next();
  } else {
    return Promise.reject('SendBird is not connected.');
  }
};

export const sbSetPushPreference = (
  pushPreference: PushTriggerOption,
  channelUrl: string
): Promise<PushTriggerOption> => {
  return sbGetGroupChannel(channelUrl).then((channel) =>
    channel.setMyPushTriggerOption(pushPreference)
  );
};

export const sbGetPushPreference = (
  channelUrl: string
): Promise<PushTriggerOption> => {
  return sbGetGroupChannel(channelUrl).then((channel) =>
    channel.getMyPushTriggerOption()
  );
};

export const channelTimeSince = (date: number): string => {
  const currentDate = new Date();
  const seconds = Math.floor((currentDate.valueOf() - date.valueOf()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  // Years
  if (interval > 1) {
    return moment(date).format('MMM D YY');
  }

  // Months
  interval = seconds / 2592000;
  if (interval > 1) {
    return moment(date).format('MMM D');
  }

  // Yesterday
  if (Number(moment(date).format('DD')) - Number(moment().format('DD')) === 1) {
    return 'Yesterday';
  }

  // Days
  interval = seconds / 86400;
  if (interval > 6) {
    return moment(date).format('MMM D');
  } else if (interval > 1) {
    return moment(date).format('ddd');
  }

  // Hours
  interval = seconds / 3600;
  if (interval > 1) {
    return moment(date).format('h:mm A');
  }

  // Minutes
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' min';
  }

  // Seconds
  return 'Just now';
};

export const inviteTimeSince = (date: number): string => {
  const seconds = Math.floor((new Date().valueOf() - date) / 1000);
  let interval = Math.floor(seconds / 31536000);

  // Months
  interval = Number((seconds / 2592000).toFixed());
  if (interval > 1) {
    return `${interval} months ago`;
  }

  // Days
  interval = Number((seconds / 86400).toFixed());
  if (interval >= 2) {
    return `${interval} days ago`;
  } else if (interval >= 1) {
    return '1 day ago';
  } else {
    return 'less than a day ago';
  }
};

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sbCreateChannelMetadata = (channel, data): void => {
  // @ts-ignore
  channel.createMetaData(data, (response, error) => {
    if (error) {
      debug('Error creating channel metadata: ', error);

      return;
    } else {
      debug('Created channel metadata.');
    }
  });
};

export const sbGetChannelMetadata = (
  channel: GroupChannel,
  keys: string[]
): Promise<MetaData> => {
  return channel.getMetaData(keys);
};

//******************************************************************************
// Invites
//******************************************************************************

export const formatSnackList = (
  list: string[],
  postfixMessage: string,
  limit: number
): string => {
  let snackString = '';

  if (list.length === 0) {
    return snackString;
  } else if (list.length === 1) {
    snackString = `${list[0]} ${postfixMessage}`;
  } else if (list.length === 2) {
    snackString = `${list[0]} and ${list[1]} ${postfixMessage}`;
  } else {
    if (list.length - 1 < limit) {
      limit = list.length - 1;
    }

    const commaString = list.slice(0, limit).join(', ');
    const andCount = list.length - limit;
    let andString = list[limit + andCount - 1];

    if (andCount > 1) {
      andString = `${andCount} more`;
    }

    snackString = `${commaString} and ${andString} ${postfixMessage}`;
  }

  return snackString;
};

export const sbHasGroupChannels = (): Promise<boolean> => {
  const query = sbCreateGroupChannelListQuery();

  if (!query) {
    debug('Skipping sbHasGroupChannels. No query');

    return Promise.reject('Skipping sbHasGroupChannels. No query');
  }

  if (!sbIsConnected()) {
    debug('Skipping sbHasGroupChannels query. Not connected.');

    return Promise.reject('Skipping sbHasGroupChannels. Not connected');
  }

  return query.next().then((channels) => {
    return Promise.resolve(channels.length > 0);
  });
};
