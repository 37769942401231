import { Cache } from 'react-native-cache';

import defaultCache from './DefaultCache';

const debug = require('debug')('tca:kit:services:CacheService:removeFromCache');

export default async function removeFromCache(
  key: string,
  cache?: Cache
): Promise<void> {
  return new Promise<void>((resolve, _reject) => {
    cache = cache || defaultCache;
    cache
      .remove(key)
      .catch((error) => {
        debug(`Remove from cache error: ${error}`);
      })
      .finally(() => {
        debug(`Data for ${key} removed from cache`);
        resolve();
      });
  });
}
