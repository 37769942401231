import { KitLoader } from '@omni/kit/components';
import {
  IOS_KEYBOARD_EASE,
  useKeyboardContext,
} from '@omni/kit/contexts/KeyboardContext';
import React, { useEffect, useState } from 'react';
import { Animated, Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default (): JSX.Element => {
  const insets = useSafeAreaInsets();
  const { keyboardEvent } = useKeyboardContext();

  const [animateHeight] = useState<Animated.Value>(
    new Animated.Value(keyboardEvent.endCoordinates.height)
  );

  useEffect(() => {
    Animated.timing(animateHeight, {
      duration: 250,
      toValue:
        insets.bottom +
        (keyboardEvent.show ? keyboardEvent.endCoordinates.height : 0),
      useNativeDriver: false,
      easing: IOS_KEYBOARD_EASE,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardEvent.show]);

  /**
   * @Note
   * Cannot use <KeyboardAvoidingView /> because the height is
   * not adjusted if the keyboard is already visible before
   * this component is mounted.
   */
  return (
    <Animated.View
      style={{
        alignItems: 'center',
        bottom: Platform.OS === 'ios' ? animateHeight : insets.bottom,
        justifyContent: 'center',
        left: insets.left,
        position: 'absolute',
        right: insets.right,
        top: insets.top,
      }}
    >
      <KitLoader />
    </Animated.View>
  );
};
