import { KitFloatingButton, KitText } from '@omni/kit/components';
import KitScripture from '@omni/kit/components/KitScripture';
import { SizeClass, useSizeClass } from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { SelectedVersesObj } from '@omni/kit/types/bible';
import { useNavigation, useRoute } from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import { useDispatch, useSelector } from 'react-redux';

import { sendBibleMessage } from '../../shared/redux/actions/ChatActions';
import { channelSelector } from '../../shared/redux/selectors';

const debug = require('debug')('omni:chat:screen:BibleVerseScreen');

type NavParams = {
  chapter: number;
  displayBookName: string;
  osis: string;
  versionId: string;
};

// The prop "initialVerses" is used for snapshot testing only
export default function BibleVerseScreen({
  togglePopup,
  initialVerses = {},
}: {
  togglePopup: () => void;
  initialVerses: SelectedVersesObj;
}): JSX.Element {
  const route = useRoute();
  const { chapter, displayBookName, osis, versionId } =
    route.params as NavParams;

  const [selectedVerses, setSelectedVerses] = useState(initialVerses);

  const { sizeClass } = useSizeClass();
  const channel = useSelector(channelSelector);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const handleUpdatedSelectedVerses = (verses: SelectedVersesObj) => {
    ReactNativeHapticFeedback.trigger('impactLight', {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
    setSelectedVerses(verses);
  };

  const sendVerses = () => {
    if (Object.keys(selectedVerses).length === 0) {
      return;
    }

    navigation.goBack();
    if (sizeClass === SizeClass.Small) {
      // Go back twice to exit bible chooser native modal
      navigation.goBack();
    }

    const osisToSend = parseListToOsis();
    const displayReference = `${displayBookName} ${chapter}:${
      osisToSend.split('.')[osisToSend.split('.').length - 1]
    }`;
    dispatch(
      sendBibleMessage(channel as GroupChannel, {
        osis: osisToSend,
        displayReference,
        versionId,
      })
    );
    togglePopup();
  };

  const parseListToOsis = (): string => {
    const firstVerse = selectedVerses[Object.keys(selectedVerses)[0]];
    let osisToSend = `${firstVerse.location.book}.${firstVerse.location.chapter}`;

    const verses: number[] = [];

    for (const key in selectedVerses) {
      // Verse should already be a number but this maintains previous logic.
      verses.push(Math.floor(Number(selectedVerses[key].location.verse)));
    }

    verses.sort((a, b) => a - b);
    osisToSend = `${osisToSend}.${verses[0]}`;

    for (let i = 0; i < verses.length; i++) {
      // First verse
      if (i === 0) {
        if (verses[i + 1] === verses[i] + 1) {
          osisToSend = osisToSend.concat('-');
        } else if (verses.length > 1) {
          osisToSend = osisToSend.concat(',');
        }

        continue;
      }

      if (osisToSend.endsWith(',') && i !== verses.length - 1) {
        // starting a new verse section
        if (verses[i + 1] === verses[i] + 1) {
          // start a run
          osisToSend = osisToSend.concat(`${verses[i]}-`);
        } else {
          // standalone verse
          osisToSend = osisToSend.concat(`${verses[i]},`);
        }
      }

      if (osisToSend.endsWith('-') && i !== verses.length - 1) {
        // we're in the middle of a run (i.e. 2-5)
        if (verses[i + 1] === verses[i] + 1) {
          // still in the run
        } else {
          // end of run
          osisToSend = osisToSend.concat(`${verses[i]},`);
        }
      }

      // Last verse
      if (i === verses.length - 1) {
        osisToSend = osisToSend.concat(`${verses[i]}`);
      }
    }

    return osisToSend;
  };

  return (
    <View style={{ flex: 1 }}>
      {sizeClass === SizeClass.Large && (
        <KitText
          fontSize={26}
          extraBold
          lineHeight={30}
          style={{
            paddingTop: Spacing.l,
            paddingBottom: Spacing.m,
            backgroundColor: Colors.N0,
          }}
          black
          center
        >
          {displayBookName} {chapter}
        </KitText>
      )}

      <KitScripture
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: Colors.N0,
        }}
        scrollViewStyle={{
          ...Platform.select({ web: { height: 500 } }),
          padding: Spacing.m,
        }}
        enableSelection
        updateSelectedVerses={handleUpdatedSelectedVerses}
        osis={`${osis}.${chapter}`}
        version={versionId}
        baseFontStyle={{
          fontSize: 18,
          lineHeight: 26,
        }}
      />
      {Object.keys(selectedVerses).length > 0 && (
        <View
          style={{
            position: 'absolute',
            bottom: Spacing.l,
            right: Spacing.l,
          }}
        >
          <KitFloatingButton icon='send' onPress={sendVerses} />
        </View>
      )}
    </View>
  );
}
