import KitMapView from '@omni/kit/components/KitMapView';
import { IGroupLocation } from '@omni/kit/services/GroupsService/Types';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export default function EventDetailLocation({
  eventLocation,
  marker,
}: {
  eventLocation: IGroupLocation | undefined;
  marker: string;
}): JSX.Element | null {
  return (
    <>
      {eventLocation && (
        <>
          <View style={{ overflow: 'hidden', borderRadius: 8 }}>
            <KitMapView
              latitude={eventLocation._embedded.address.latitude}
              longitude={eventLocation._embedded.address.longitude}
              zoom={15}
              webStyle={{
                height: 196,
                borderRadius: 8,
              }}
              nativeStyle={{ height: 96, borderRadius: 8 }}
              hideZoomOptions={true}
              marker={{ title: marker }}
              pointerEvents='none'
              scrollEnabled={false}
              zoomEnabled={false}
              customMarker={
                '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="31" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M2 8a8 8 0 1 1 16 0c0 2.504-1.397 4.403-2 5.1l-5.245 6.032a1 1 0 0 1-1.51 0L4 13.1c-.738-.854-2-2.596-2-5.1zm8 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>'
              }
            />
          </View>
        </>
      )}
    </>
  );
}
//******************************************************************************
// Styles
//******************************************************************************
const styles = StyleSheet.create({});
