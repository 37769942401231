import Delete from './Delete';
import Get from './Get';
import Patch from './Patch';
import Post from './Post';
import Put from './Put';

export default {
  Get,
  Patch,
  Post,
  Put,
  Delete,
};
