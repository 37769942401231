import { useSnackbarContext } from '@omni/kit/contexts/Snackbar';
import { UserMessage } from '@sendbird/chat/message';
import { OpenChannel } from '@sendbird/chat/openChannel';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Platform } from 'react-native';

import { sbDeleteMessage } from '../../utils/Sendbird';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import MessageAction from '../components/MessageAction';

const debug = require('debug')('tca:liveChat:ChatContext.tsx');

interface IChatContext {
  confirmDeleteMessage: () => void;
  isOperator: boolean;
  onDeleteMessage: (item?: UserMessage) => void;
  onMessageSelect: (item: UserMessage) => void;
  selectedMessage: UserMessage | null;
  setIsOperator: (isOperator: boolean) => void;
  setSelectedMessage: (item: UserMessage) => void;
}

export const ChatContext = createContext<IChatContext>({
  confirmDeleteMessage: () => undefined,
  isOperator: false,
  onDeleteMessage: () => undefined,
  onMessageSelect: () => undefined,
  selectedMessage: null,
  setIsOperator: () => undefined,
  setSelectedMessage: () => undefined,
});

interface IProviderProps {
  children?: React.ReactNode;
  openChannel: OpenChannel | undefined;
}

export const ChatProvider = ({
  children,
  openChannel,
}: IProviderProps): JSX.Element => {
  const { t } = useTranslation(['liveChat']);
  const { showSnackbar } = useSnackbarContext();
  const [selectedMessage, setSelectedMessage] = useState<UserMessage | null>(
    null
  );
  const [isMessageActionsVisible, setIsMessageActionsVisible] =
    useState<boolean>(false);
  const [isConfirmDeleteVisible, setIsConfirmDeleteVisible] = useState(false);
  const [isOperator, setIsOperator] = useState<boolean>(false);

  const onMessageActionClose = (): void => {
    setIsMessageActionsVisible(false);
    setIsConfirmDeleteVisible(false);
    setSelectedMessage(null);
  };

  const confirmDeleteMessage = async (): Promise<void> => {
    try {
      if (!selectedMessage || !openChannel) return;

      await sbDeleteMessage(openChannel, selectedMessage);
    } catch (e) {
      debug('Could not delete message: ', e);
      showSnackbar(t('deleteMessageError'));
    }
    onMessageActionClose();
  };

  const onDeleteMessage = (): void => {
    if (Platform.OS === 'web') {
      setIsConfirmDeleteVisible(true);

      return;
    }

    Alert.alert(
      t('liveChat:deleteMessageConfirmationTitle'),
      selectedMessage?.message,
      [
        {
          text: t('liveChat:deleteMessageConfirmationCancelButton'),
          style: 'cancel',
        },
        {
          text: t('liveChat:deleteMessageConfirmationDeleteButton'),
          onPress: confirmDeleteMessage,
          style: 'destructive',
        },
      ]
    );
  };

  const onMessageSelect = (message: UserMessage): void => {
    setSelectedMessage(message);
    setIsMessageActionsVisible(true);
  };

  return (
    <ChatContext.Provider
      value={{
        confirmDeleteMessage,
        isOperator,
        onDeleteMessage,
        onMessageSelect,
        selectedMessage,
        setIsOperator,
        setSelectedMessage,
      }}
    >
      {children}
      <MessageAction
        isVisible={isMessageActionsVisible}
        onClose={onMessageActionClose}
      />
      <ConfirmDeleteModal
        isVisible={isConfirmDeleteVisible}
        onClose={onMessageActionClose}
        selectedMessage={selectedMessage}
      />
    </ChatContext.Provider>
  );
};

export const useChatContext = (): IChatContext =>
  useContext<IChatContext>(ChatContext);
