import AsyncStorage from '@react-native-async-storage/async-storage';

import {
  BibleLocation,
  BibleSettings,
  ContentReducerState,
} from '../types/bible';

export const retrieveBibleSettingsFromDevice = (): Promise<BibleSettings> => {
  return retrieveGlobalDataFromDevice(
    'bibleSettings'
  ) as Promise<BibleSettings>;
};

export const saveGlobalDataToDevice = async (
  key: string,
  value: unknown
): Promise<void> => {
  await AsyncStorage.setItem(key, JSON.stringify(value));

  return;
};

export const saveBibleLocationToDevice = (location: unknown): Promise<void> => {
  return saveGlobalDataToDevice('bibleLocation', location);
};

export const retrieveGlobalDataFromDevice = async (
  key: string
): Promise<
  BibleLocation | BibleSettings | ContentReducerState | Record<string, unknown>
> => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);

    if (jsonValue !== null) {
      return JSON.parse(jsonValue) as Record<string, unknown>;
    }

    throw { message: `data with key of *${key}* not found on device` };
  } catch (error) {
    throw error;
  }
};

export const removeGlobalDataFromDevice = async (
  key: string
): Promise<void> => {
  try {
    await AsyncStorage.removeItem(key);

    return;
  } catch (error) {
    throw error;
  }
};
