import Environment from '../../../Environment';
import BaseServiceV2, {
  IHttpResponse,
  IUri,
  buildUrl,
} from '../../BaseServiceV2';
import { IJoinRequests } from '../Types';

const RESOURCE = 'join-requests';

interface IGetJoinRequest extends Omit<IUri, 'resource'> {
  appKey: string;
  token: string;
  groupId: string;
}

interface IGetJoinRequestResponse extends IHttpResponse {
  body?: IJoinRequests;
}

export default ({
  appKey,
  token,
  groupId,
}: IGetJoinRequest): Promise<IGetJoinRequestResponse> => {
  const url = buildUrl({
    host: Environment.groupsHost,
    filters: [
      ['app_key', appKey],
      ['group.id', groupId],
    ],
    resource: RESOURCE,
  });

  return BaseServiceV2.Get({
    url,
    appKey,
    token,
    skipTimestamp: true,
  });
};
