import Environment from '../../../Environment';
import { IFilter, IInclude, IPage, ISort, buildUrl } from '../../BaseServiceV2';

const host = Environment.mediaPersonalizationService;

export interface IPrepareMediaPlaysUrl {
  filters?: IFilter[];
  id?: string;
  includes?: IInclude[];
  pages?: IPage[];
  sorts?: ISort[];
}

export function prepareMediaPlaysUrl({
  filters,
  pages,
  id,
  includes,
  sorts,
}: IPrepareMediaPlaysUrl): string {
  return buildUrl({
    host,
    resource: 'media-plays',
    filters,
    pages,
    includes,
    id,
    sorts,
  });
}
