import LinkPreview from '@omni/kit/components/KitLinkPreview';
import Colors from '@omni/kit/theming/Colors';
import React, { useEffect, useState } from 'react';
import { TextStyle, View } from 'react-native';

import ParsedMessage from '../../../../../mobile/components/chat/ParsedMessage';

interface ParsedTextItem {
  pattern: RegExp;
  style: TextStyle;
  onPress: () => void;
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function TextMessage({ message }): JSX.Element {
  const [parsedTextObjects, setParsedTextObjects] = useState<ParsedTextItem[]>(
    []
  );
  const { mentionedUsers } = message;

  useEffect(() => {
    if (mentionedUsers) {
      // @ts-ignore
      const parsedTextItems = [];
      // @ts-ignore
      mentionedUsers.forEach((user) => {
        parsedTextItems.push({
          pattern: new RegExp(`@${user.nickname}`),
          style: { fontWeight: 'bold' },
          onPress: null,
        });
      });
      // @ts-ignore
      setParsedTextObjects(parsedTextItems);
    }
  }, [mentionedUsers]);

  return (
    <View>
      <ParsedMessage color={Colors.N1000} parsed={parsedTextObjects}>
        {message.message}
      </ParsedMessage>
      <LinkPreview url={message.message} />
    </View>
  );
}
