import { Platform } from 'react-native';

export default {
  action: require('./icon-action.svg'),
  'action-h': require('./icon-action-h.svg'),
  add: require('./icon-add.svg'),
  'add-person': require('./icon-add-person.svg'),
  'add-s': require('./icon-add-s.svg'),
  admin: require('./icon-admin.svg'),
  allergies: require('./icon-allergies.svg'),
  'american-express': require('./icon-american-express.svg'),
  apple: require('./icon-apple.svg'),
  'arrow-down-android': require('./icon-arrow-down-android.svg'),
  'arrow-menu-left': require('./icon-arrow-menu-left.svg'),
  'arrow-menu-right': require('./icon-arrow-menu-right.svg'),
  'arrow-left': require('./icon-arrow-left.svg'),
  'arrow-right': require('./icon-arrow-right.svg'),
  'arrow-down': require('./icon-arrow-down.svg'),
  'back-ios': require('./icon-back-ios.svg'),
  bank: require('./icon-bank.svg'),
  backspace: require('./icon-backspace.svg'),
  'bar-back-android': require('./icon-bar-back-android.svg'),
  'bar-menu': require('./icon-bar-menu.svg'),
  'bar-search': require('./icon-bar-search.svg'),
  bible: require('./icon-bible.svg'),
  block: require('./icon-block.svg'),
  bolt: require('./icon-bolt.svg'),
  calendar: require('./icon-calendar.svg'),
  'calendar-add': require('./icon-calendar-add.svg'),
  'calendar-cancel': require('./icon-calendar-cancel.svg'),
  camera: require('./icon-camera.svg'),
  card: require('./icon-card.svg'),
  channel: require('./icon-channel.svg'),
  chat: require('./icon-chat.svg'),
  check: require('./icon-check.svg'),
  'checkin-logo': require('./checkin-logo.svg'),
  'check-circle': require('./icon-check-circle.svg'),
  'check-circle-fill': require('./icon-check-circle-fill.svg'),
  'chevron-left': require('./icon-chevron-left.svg'),
  'chevron-right': require('./icon-chevron-right.svg'),
  'chevron-down': require('./icon-chevron-down.svg'),
  'circle-outline-messages': require('./icon-circle-outline-messages.svg'),
  classroom: require('./icon-classroom.svg'),
  clock: require('./icon-clock.svg'),
  close: require('./icon-close.svg'),
  'completed-circle': require('./icon-completed-circle.svg'),
  'completed-circle-sm': require('./icon-completed-circle-sm.svg'),
  copy: require('./icon-copy.svg'),
  copyright: require('./icon-copyright.svg'),
  delete: require('./icon-delete.svg'),
  'diners-club-card': require('./icon-diners-club-card.svg'),
  disconnected: require('./icon-disconnected.svg'),
  'discover-card': require('./icon-discover-card.svg'),
  discover: require('./icon-discover.svg'),
  download: require('./icon-download.svg'),
  edit: require('./icon-edit.svg'),
  'edit-alt': require('./icon-edit-alt.svg'),
  email: require('./icon-email.svg'),
  emoji: require('./icon-emoji.svg'),
  'emoji-add': require('./icon-emoji-add.svg'),
  event: require('./icon-event.svg'),
  'icons-event': require('./icons-event.svg'),
  'eye-slash': require('./icon-eye-slash.svg'),
  facebook: require('./icon-facebook.svg'),
  feedback: require('./icon-feedback.svg'),
  file: require('./icon-file.svg'),
  'file-audio': require('./icon-file-audio.svg'),
  'file-csv': require('./icon-file-csv.svg'),
  'file-file': require('./icon-file-file.svg'),
  'file-pdf': require('./icon-file-pdf.svg'),
  'file-text': require('./icon-file-text.svg'),
  'file-video': require('./icon-file-video.svg'),
  'file-zip': require('./icon-file-zip.svg'),
  flag: require('./icon-flag.svg'),
  'forward-ios': require('./icon-forward-ios.svg'),
  fullscreen: require('./icon-fullscreen.svg'),
  'fullscreen-exit': require('./icon-fullscreen-exit.svg'),
  fund: require('./icon-fund.svg'),
  dollar: require('./icon-dollar.svg'),
  gif: require('./icon-gif.svg'),
  give: require('./icon-give.svg'),
  google: require('./icon-google.svg'),
  group: require('./icon-group.svg'),
  heart: require('./icon-heart.svg'),
  help: require('./icon-help.svg'),
  home: require('./icon-home.svg'),
  image: require('./icon-image.svg'),
  'image-library': require('./icon-image-library.svg'),
  info: require('./icon-info.svg'),
  'jcb-card': require('./icon-jcb-card.svg'),
  key: require('./icon-key.svg'),
  'leave-channel': require('./icon-leave-channel.svg'),
  linked: require('./icon-linked.svg'),
  listen: require('./icon-listen.svg'),
  live: require('./icon-live.svg'),
  location: require('./icon-location.svg'),
  'not-filled-location': require('./icon-not-filled-location.svg'),
  lock: require('./icon-lock.svg'),
  'lock-open': require('./icon-lock-open.svg'),
  login: require('./icon-login.svg'),
  logout: require('./icon-logout.svg'),
  laptop: require('./icon-laptop.svg'),
  'map-pin': require('./icon-map-pin.svg'),
  mastercard: require('./icon-mastercard.svg'),
  media: require('./icon-media.svg'),
  megaphone: require('./icon-megaphone.svg'),
  message: require('./icon-message.svg'),
  messaging: require('./icon-messaging.svg'),
  microphone: require('./icon-microphone.svg'),
  'minus-circle': require('./icon-minus-circle.svg'),
  'mobile-app': require('./icon-mobile-app.svg'),
  notes: require('./icon-notes.svg'),
  notifications: require('./icon-notifications.svg'),
  'notifications-off': require('./icon-notifications-off.svg'),
  'now-playing': require('./icon-now-playing.svg'),
  pause: require('./icon-pause.svg'),
  people: require('./icon-people.svg'),
  person: require('./icon-person.svg'),
  'person-circle': require('./icon-person-circle.svg'),
  phone: require('./icon-phone.svg'),
  play: require('./icon-play.svg'),
  'play-circle': require('./icon-play-circle.svg'),
  pledge: require('./icon-pledge.svg'),
  'plus-circle': require('./icon-plus-circle.svg'),
  'square-plus': require('./icons-square-plus.svg'),
  'plus-large': require('./icon-plus-large.svg'),
  poll: require('./icon-poll.svg'),
  prayer: require('./icon-prayer.svg'),
  printer: require('./icon-printer.svg'),
  publish: require('./icon-publish.svg'),
  'push-notification': require('./icon-push-notification.svg'),
  'qr-code': require('./icon-qr-code.svg'),
  registration: require('./icon-registration.svg'),
  'remove-s': require('./icon-remove-s.svg'),
  remove: require('./icon-remove.svg'),
  repeat: require('./icon-repeat.svg'),
  replay: require('./icon-replay.svg'),
  reply: require('./icon-reply.svg'),
  restart: require('./icon-restart.svg'),
  resume: require('./icon-resume.svg'),
  search: require('./icon-search.svg'),
  'seek-forward': require('./icon-seek-forward.svg'),
  'seek-backward': require('./icon-seek-backward.svg'),
  send: require('./icon-send.svg'),
  settings: require('./icon-settings.svg'),
  'settings-filter': require('./icon-settings-filter.svg'),
  share:
    Platform.OS === 'ios'
      ? require('./icon-share-ios.svg')
      : require('./icon-share-android.svg'),
  'share-android': require('./icon-share-android.svg'),
  'shield-check': require('./icon-shield-check.svg'),
  'skip-backward-10': require('./icon-skip-backward-10.svg'),
  'skip-backward-15': require('./icon-skip-backward-15.svg'),
  'skip-forward-10': require('./icon-skip-forward-10.svg'),
  'skip-forward-15': require('./icon-skip-forward-15.svg'),
  smile: require('./icon-smile.svg'),
  sun: require('./icon-sun.svg'),
  favorites: require('./icon-favorites.svg'),
  thread: require('./icon-thread.svg'),
  'unionpay-card': require('./icon-unionpay-card.svg'),
  unlink: require('./icon-unlink.svg'),
  upload: require('./icon-upload.svg'),
  'user-round-outlined': require('./icon-user-round-outlined.svg'),
  'user-search': require('./icon-user-search.svg'),
  'user-add': require('./icon-user-add.svg'),
  visa: require('./icon-visa.svg'),
  wave: require('./icon-wave.svg'),
  'subsplash-logo': require('./icon-subsplash-logo.svg'),
  'indicator-protector': require('./indicator-protector.svg'),
  'x-logo': require('./icon-x-logo.svg'),
} as const;
