import KitText from '@omni/kit/components/KitText';
import BorderRadius from '@omni/kit/theming/BorderRadius';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import Color from 'color';
import React, { useContext, useEffect, useState } from 'react';
import {
  Animated,
  Easing,
  Platform,
  StyleSheet,
  TouchableHighlight,
  View,
} from 'react-native';
import { useSelector } from 'react-redux';
import Popup from 'reactjs-popup';

import {
  channelSelector,
  userIdSelector,
} from '../../../shared/redux/selectors';
import { sbGetUsersById } from '../../../utilities/sendbird/channelFunctions';
import { sbToggleReaction } from '../../../utilities/sendbird/chatFunctions';
import {
  CustomMessageData,
  SendbirdMessage,
  SendbirdSenderMessage,
  SendbirdUserMessage,
} from '../../Types';
import { ChatContext } from '../../scenes/channel/ChatScreen';

const debug = require('debug')('omni:chat:components:Reactions');

interface ReactionProps {
  message: SendbirdMessage;
  onLongPress?: (...args: unknown[]) => void;
  prayerRequest?: boolean;
  disabled?: boolean;
}

export default function ReactionList({
  message,
  prayerRequest = false,
  disabled,
}: ReactionProps): JSX.Element {
  const [doAnimation, setDoAnimation] = useState(false);

  const { handleSelectingReaction } = useContext(ChatContext) as {
    handleSelectingReaction: (
      message: SendbirdSenderMessage | null,
      reaction: string
    ) => void;
  };

  const userId = useSelector(userIdSelector);
  const isUser = message.isUser;

  let data: CustomMessageData | null = null;

  if (message.data) {
    try {
      data = JSON.parse(message.data);
    } catch (e) {}
  }

  useEffect(() => {
    if (!doAnimation) setDoAnimation(true);
  }, [doAnimation]);

  // Remove data reactions (polls)
  const keyRegex = /\{([^{}]*)\}/g;
  const reactions = message.reactions?.filter((reaction) => {
    const result = Array.from(reaction.key.matchAll(keyRegex), ([_, g]) => g);

    return result.length >= 2 ? null : reaction;
  });

  const extraMargin = 8;

  return (
    <View
      style={[
        styles.wrapper,
        { marginTop: reactions?.length || prayerRequest ? extraMargin : 0 },
      ]}
    >
      {reactions?.map((r) => (
        <ReactionBubble
          count={r.userIds.length}
          doAnimation={doAnimation}
          emoji={r.key}
          isSelected={r.userIds.includes(userId as string)}
          isUser={isUser}
          key={r.key}
          message={message as SendbirdUserMessage}
          onLongPress={handleSelectingReaction}
          userIds={r.userIds}
          disabled={disabled}
        />
      ))}
      {!reactions?.length && prayerRequest && (
        <ReactionBubble
          count={0}
          doAnimation={doAnimation}
          emoji={data?.isAnswered || data?.answeredId ? '👏' : '🙏'}
          isSelected={false}
          isUser={isUser}
          message={message as SendbirdUserMessage}
          onLongPress={() => null}
          disabled={disabled}
        />
      )}
    </View>
  );
}

interface ReactionBubbleProps {
  count: number;
  doAnimation: boolean;
  emoji: string;
  isSelected: boolean;
  isUser: boolean;
  message: SendbirdUserMessage;
  onLongPress: (msg: SendbirdUserMessage, emoji: string) => void;
  userIds?: string[];
  disabled?: boolean;
}

const ReactionBubble = ({
  count,
  doAnimation,
  emoji,
  isSelected,
  isUser,
  message,
  onLongPress,
  userIds = [],
  disabled,
}: ReactionBubbleProps) => {
  const [opacity] = useState(new Animated.Value(0));
  const [scale] = useState(new Animated.Value(0));

  const channel = useSelector(channelSelector) as GroupChannel;
  const userId = useSelector(userIdSelector);
  const [userNames, setUserNames] = useState<string[]>([]);

  const onReactionPress = () => {
    if (count === 1 && isUser) {
      Animated.parallel([
        Animated.timing(opacity, {
          duration: 150,
          easing: Easing.out(Easing.ease),
          toValue: 0,
          useNativeDriver: true,
        }),
        Animated.spring(scale, {
          speed: 76,
          toValue: 0,
          useNativeDriver: true,
        }),
      ]).start(() => {
        sbToggleReaction(channel, message, emoji, userId as string);
      });
    } else {
      sbToggleReaction(channel, message, emoji, userId as string);
    }
  };

  const onReactionLongPress = () => {
    onLongPress(message, emoji);
  };

  const _formatUserNames = () => {
    let names = userNames.join(', ');

    if (isSelected && userNames.length) {
      names += ' and you';
    } else if (isSelected) {
      names += 'You';
    }

    return names;
  };

  useEffect(() => {
    const opacityAndScaleAnimations = doAnimation
      ? [
          Animated.timing(opacity, {
            duration: 200,
            easing: Easing.out(Easing.ease),
            toValue: 1,
            useNativeDriver: true,
          }),
          Animated.spring(scale, {
            friction: 7,
            toValue: 1,
            useNativeDriver: true,
          }),
        ]
      : [
          Animated.timing(opacity, {
            duration: 0,
            toValue: 1,
            useNativeDriver: true,
          }),
          Animated.timing(scale, {
            duration: 0,
            toValue: 1,
            useNativeDriver: true,
          }),
        ];

    Animated.parallel(opacityAndScaleAnimations).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (userIds.length) {
      sbGetUsersById(userIds)
        .then((users) => {
          const names = users
            .filter((u) => u.userId !== userId)
            .map((u) => u.nickname);
          setUserNames(names);
        })
        .catch((e) => {
          debug('Could not get users by ID: ', e);
        });
    }
  }, [userIds, userId]);

  return (
    <Animated.View style={{ opacity, transform: [{ scale: scale }] }}>
      {Platform.OS === 'web' && !disabled ? (
        <Popup
          on='hover'
          position={['top center', 'top right']}
          arrow={count > 0}
          arrowStyle={{ color: Colors.N900 }}
          trigger={
            <div
              style={{
                borderRadius: BorderRadius.l,
                marginBottom: 6,
                backgroundColor: isSelected
                  ? Color(Colors.brand).lighten(0.42).hex()
                  : Colors.N100,
                cursor: 'pointer',
                marginRight: Spacing.xs,
                padding: `${Spacing.xxs}px ${Spacing.s}px`,
              }}
              role='button'
              onClick={onReactionPress}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                {count === 0 && (
                  <KitText
                    color={isSelected ? Colors.brand : Colors.N900}
                    bold
                    fontSize={16}
                    style={{ lineHeight: 15 }}
                  >
                    +
                  </KitText>
                )}
                <KitText fontSize={14} color={Colors.N0}>
                  {emoji}&nbsp;
                </KitText>
                {count > 0 && (
                  <KitText
                    color={isSelected ? Colors.brand : Colors.N900}
                    fontSize={12}
                    style={{ lineHeight: 15, marginLeft: Spacing.xs }}
                  >
                    {count}
                  </KitText>
                )}
              </View>
            </div>
          }
        >
          <div style={{ display: count === 0 ? 'none' : 'auto' }}>
            <View style={styles.popup}>
              <View style={styles.emojiContainer}>
                <KitText color={Colors.N0}>{emoji}</KitText>
              </View>
              <KitText color={Colors.N0} style={styles.userNames}>
                {_formatUserNames()}
              </KitText>
            </View>
          </div>
        </Popup>
      ) : (
        <TouchableHighlight
          style={[
            styles.bubble,
            {
              backgroundColor: isSelected
                ? Color(Colors.brand).lighten(0.42).hex()
                : Colors.N100,
            },
          ]}
          underlayColor={
            isSelected ? Color(Colors.brand).lighten(0.32).hex() : Colors.N200
          }
          onPress={onReactionPress}
          onLongPress={onReactionLongPress}
          disabled={disabled}
          delayLongPress={200}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {count === 0 && (
              <KitText
                color={isSelected ? Colors.brand : Colors.N900}
                bold
                fontSize={16}
                style={{ lineHeight: 15 }}
              >
                +
              </KitText>
            )}
            <KitText color={Colors.N0}>{emoji}&nbsp;</KitText>
            {count > 0 && (
              <KitText
                color={isSelected ? Colors.brand : Colors.N900}
                fontSize={12}
                style={{ lineHeight: 15 }}
              >
                {count}
              </KitText>
            )}
          </View>
        </TouchableHighlight>
      )}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  bubble: {
    backgroundColor: Colors.N100,
    borderRadius: BorderRadius.l,
    marginBottom: 6,
    marginRight: 6,
    paddingHorizontal: 6,
    paddingVertical: Spacing.xs,
  },
  emojiContainer: {
    alignItems: 'center',
    backgroundColor: Colors.N0,
    borderRadius: 6,
    flexShrink: 0,
    height: 30,
    justifyContent: 'center',
    margin: 0,
    padding: 0,
    width: 30,
  },
  popup: {
    alignItems: 'center',
    backgroundColor: Colors.N900,
    borderRadius: 6,
    color: Colors.N0,
    justifyContent: 'center',
    maxWidth: 240,
    paddingHorizontal: Spacing.l,
    paddingVertical: Spacing.m,
    ...Platform.select({
      android: { elevation: 10, overflow: 'hidden' },
      default: {
        shadowColor: '#000000',
        shadowOffset: { height: 3, width: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 6,
      },
    }),
  },
  userNames: {
    marginTop: Spacing.s,
    textAlign: 'center',
  },
  wrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
