import {
  KitFloatingButton,
  KitIcon,
  KitInput,
  KitLoader,
} from '@omni/kit/components';
import KitImage from '@omni/kit/components/KitImage';
import {
  SizeClass,
  SizeClassV2,
  useSizeClass,
} from '@omni/kit/contexts/SizeClassContext';
import Colors from '@omni/kit/theming/Colors';
import Spacing from '@omni/kit/theming/Spacing';
import { colorForScheme } from '@omni/kit/theming/Theming';
import { SCREEN_WIDTH } from '@omni/kit/utilities/utilities';
import { useNavigation } from '@react-navigation/native';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FlatList,
  Image,
  ImageStyle,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import TenorService from '../../services/TenorService';
import { sendGifMessage } from '../../shared/redux/actions/ChatActions';
import {
  channelSelector,
  parentMessageIdSelector,
  sendToChannelSelector,
} from '../../shared/redux/selectors';
import { MessageType } from '../../shared/redux/types';
import LeftNavButton, { LeftNavType } from '../components/LeftNavButton';
import { IFile } from '../components/chat/MessageInput';

const debug = require('debug')('tca:chat:screen:GifModal');

const NUM_COLUMNS = {
  [SizeClass.Small]: 2,
  [SizeClass.Medium]: 3,
  [SizeClass.Large]: 2,

  [SizeClassV2.XXS]: 2,
  [SizeClassV2.XS]: 2,
  [SizeClassV2.S]: 2,
  [SizeClassV2.M]: 2,
  [SizeClassV2.L]: 2,
  [SizeClassV2.XL]: 2,
  [SizeClassV2.XXL]: 2,
};

//******************************************************************************
// Types
//******************************************************************************

export interface IMasonryImage {
  uri: string;
  isSelected: boolean;
}

export interface IState {
  channel?: GroupChannel;
  imageList: IMasonryImage[];
  selectedPhoto: IMasonryImage | null;
  searchText: string;
  isLoading: boolean;
}

//******************************************************************************
// Component
//******************************************************************************

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function GifModal({ togglePopup = undefined }): JSX.Element {
  const [loadingQuery, setLoadingQuery] = useState(false);
  const [imageList, setImageList] = useState<IMasonryImage[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { sizeClass } = useSizeClass();

  /**
   * We use TENOR to asynchronously search for GIFs, but we don't want
   * to set state with the GIFs loaded if we've unmounted the component.
   *
   * We use this since we're not using Axios cancel tokens in this case.
   */
  const [isMounted, setIsMounted] = useState(true);

  const navigation = useNavigation();
  const channel = useSelector(channelSelector);
  const parentMessageId = useSelector(parentMessageIdSelector);
  const sendToChannel = useSelector(sendToChannelSelector);
  const dispatch = useDispatch();

  const numColumns = NUM_COLUMNS[sizeClass];

  //****************************************************************************
  // Methods
  //****************************************************************************

  const debouncedImageQuery = _.debounce((inputText: string) => {
    _requestGifs(inputText);
  }, 700);

  // @ts-ignore
  const _onSelectImage = (selectedImage) => {
    let newSelectedPhoto = null;

    // @ts-ignore
    if (!selectedPhoto || selectedImage.uri !== selectedPhoto.uri) {
      newSelectedPhoto = selectedImage;
    }

    setSelectedPhoto(newSelectedPhoto);
  };

  const _onInputFieldChanged = (searchText: string) => {
    setSearchText(searchText);
    setImageList([]);
    setLoadingQuery(true);
    debouncedImageQuery(searchText);
  };

  const _onFloatingButtonPressed = useCallback(() => {
    if (!selectedPhoto) {
      return;
    }

    if (togglePopup) {
      // @ts-ignore
      togglePopup();
    } else {
      try {
        navigation.goBack();
      } catch {}
    }

    const width = 1;
    const height = 1;
    const ratio = width / height;
    debug(`The image dimensions are ${width}x${height}: Ratio: ${ratio}`);

    Image.getSize(
      // @ts-ignore
      selectedPhoto.uri,
      (width, height) => {
        const ratio = width / height;
        debug(`The image dimensions are ${width}x${height}: Ratio: ${ratio}`);
        const source: IFile = {
          // @ts-ignore
          uri: selectedPhoto.uri,
          name: 'Gif',
          type: 'image/*',
          customType: MessageType.Gif,
          // @ts-ignore
          data: { uri: `${selectedPhoto.uri}`, ratio: `${ratio}` },
        };
        dispatch(
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          sendGifMessage(channel!, source, parentMessageId, sendToChannel)
        );
      },
      (error) => {
        console.error(`Couldn't get the image size: ${error.message}`);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPhoto]);

  const _requestGifs = async (search: string) => {
    let gifs;

    if (!search) {
      gifs = await TenorService.fetchTrendingGifs();
    } else {
      gifs = await TenorService.searchGifs(search);
    }

    if (isMounted) {
      setImageList(gifs);
      setLoadingQuery(false);
    }
  };

  useEffect(() => {
    navigation.setOptions({
      title: 'Select a GIF',
      headerLeft: () => (
        <LeftNavButton
          type={LeftNavType.Dismiss}
          title='Select a GIF'
          onPress={navigation.goBack}
        />
      ),
    });

    _requestGifs('');

    return () => {
      setIsMounted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.inputContainer}>
        <KitInput
          placeholder='Search Tenor'
          value={searchText}
          onChangeText={_onInputFieldChanged}
        />
      </View>
      {loadingQuery && (
        <View
          style={{
            flex: 1,
            alignSelf: 'center',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: SCREEN_WIDTH / 2,
          }}
        >
          <KitLoader />
        </View>
      )}
      {!loadingQuery && (
        <FlatList
          contentInset={{ bottom: Spacing.xxl }}
          contentContainerStyle={{ paddingHorizontal: Spacing.m }}
          style={{ ...Platform.select({ web: { height: 500 } }) }}
          data={imageList}
          renderItem={({ item }) => (
            <View
              style={{
                marginVertical: Spacing.s,
                marginHorizontal: Spacing.s,
                flex: 1,
                height: 160,
              }}
            >
              <SelectableGifImage
                style={{ width: '100%', height: '100%' }}
                // @ts-ignore
                source={{ uri: item.uri }}
                // @ts-ignore
                isSelected={selectedPhoto && item.uri === selectedPhoto.uri}
                onPress={() => _onSelectImage(item)}
              />
            </View>
          )}
          numColumns={numColumns}
          keyboardShouldPersistTaps='always'
          keyboardDismissMode='on-drag'
          keyExtractor={(item, index) => index.toString()}
          showsVerticalScrollIndicator={Platform.OS !== 'web'}
          key={numColumns}
        />
      )}

      {selectedPhoto && (
        <View
          // @ts-ignore
          style={{
            position: Platform.select({ web: 'fixed', native: 'absolute' }),
            bottom: Spacing.l,
            right: Spacing.l,
          }}
        >
          <KitFloatingButton icon='send' onPress={_onFloatingButtonPressed} />
        </View>
      )}
    </View>
  );
}

// @ts-ignore
const SelectableGifImage = ({ style, source, isSelected, onPress }) => (
  <TouchableOpacity
    activeOpacity={0.4}
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: Colors.N100,
      borderRadius: 3,
      position: 'relative',
      height: '100%',
    }}
    onPress={onPress}
  >
    <KitImage
      style={[style, { borderRadius: 3 }] as ImageStyle}
      source={{ uri: source.uri }}
    />
    {isSelected && (
      <View style={styles.check}>
        <KitIcon name='check-circle-fill' />
      </View>
    )}
  </TouchableOpacity>
);

//******************************************************************************
// Styles
//******************************************************************************

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    bottom: Spacing.l,
    left: Spacing.l,
    right: Spacing.l,
  },
  container: {
    flex: 1,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
  },
  labelText: {
    color: '#525254',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 18,
    marginBottom: Spacing.s,
  },
  inputContainer: {
    paddingHorizontal: Spacing.l,
    backgroundColor: colorForScheme({ default: Colors.N0 }),
    marginBottom: Spacing.m,
    marginTop: Platform.OS === 'web' ? Spacing.l : 0,
  },
  flexCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  marker: {
    position: 'absolute',
    top: 5,
    right: 5,
    backgroundColor: 'transparent',
  },
  check: {
    backgroundColor: Colors.brand,
    borderRadius: 60,
    width: 25,
    height: 25,
    position: 'absolute',
    top: 10,
    right: 10,
  },
});
